import React, { useEffect, useState } from "react"
import { Link as RouterLink } from "react-router-dom"
import { useAuth0 } from "@auth0/auth0-react"
import { withStyles, WithStyles } from "@material-ui/core/styles"
import { removeToken } from "../../helpers/loginServices"
import { handleSSLogout } from "../../helpers/sagaHelpers"
import ActiveCompanySelector from "../common/ActiveCompanySelector"
import styles from "./styles"
import { AppBar, Box, Toolbar, IconButton } from "@material-ui/core"
import InputIcon from "@material-ui/icons/Input"

const Topbar = ({ classes }: WithStyles) => {
  const { logout } = useAuth0()

  const [isCampaignBiPmgSessionTokenCleared, setIsCampaignBiPmgSessionTokenCleared] =
    useState(false)

  useEffect(() => {
    isCampaignBiPmgSessionTokenCleared &&
      (removeToken(), logout({ returnTo: window.location.origin }))
  }, [isCampaignBiPmgSessionTokenCleared])

  const handleLogout = () => {
    !isCampaignBiPmgSessionTokenCleared &&
      handleSSLogout()
        .then(() => setIsCampaignBiPmgSessionTokenCleared(true))
        .catch(() => setIsCampaignBiPmgSessionTokenCleared(true))
  }

  return (
    <AppBar className={classes.root} data-testid={"topbar"}>
      <Toolbar className={classes.toolbar}>
        <RouterLink to="/">
          <img
            alt="Logo"
            src="/assets/CadentLogo_Reverse_RGB.svg"
            className={classes.cadentLogo}
            data-testid={"cadent-logo"}
          />
        </RouterLink>
        <Box flexGrow={1} />
        <ActiveCompanySelector />
        <IconButton
          className={classes.signOutButton}
          color="inherit"
          data-testid={"sign-out-button"}
          onClick={handleLogout}
        >
          <InputIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  )
}

export default withStyles(styles)(Topbar)
