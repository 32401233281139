import { Box, Tab, Tabs, withStyles } from "@material-ui/core"
import { BreadCrumb, HeaderInfo } from "../../../../../redux/types/newHeaderTypes"
import { Edit as EditIcon, PlusCircle as PlusCircleIcon } from "react-feather"
import { Link, Route, Switch } from "react-router-dom"
import React, { useEffect, useState } from "react"
import { RootState, useAppDispatch } from "../../../../../redux/store"
import {
  fetchPublisherById,
  resetPublisherFormState,
} from "../../../../../redux/actions/publisherActions"

import Header from "../../../../NewHeader"
import { PERMISSIONS } from "../../../../../constants"
import { Publisher } from "../../../../../redux/types/publisherTypes"
import PublisherForm from "./PublisherForm"
import SitesList from "./SitesList"
import { clearErrors } from "../../../../../redux/actions/errorActions"
import { fetchAndExportTagsFromSites } from "../../../../../redux/actions/siteActions"
import styles from "./styles"
import { useSelector } from "react-redux"

export const buildHeaderInfo = (
  isCreate: boolean,
  isEdit: boolean,
  isInfo: boolean,
  isSites: boolean,
  publisher: Publisher = {} as Publisher,
  dispatch,
  disabled: boolean,
  sitesQuery: string,
  sitesSelectedSites: number[]
): HeaderInfo => {
  let headerText
  const breadcrumbs: BreadCrumb[] = [
    {
      name: "Dashboard",
      route: "/",
    },
    {
      name: "Publishers",
      route: "/admin/publishers",
    },
  ]
  const headerButtons = []

  if (isCreate) {
    headerText = "Create New Publisher"
    breadcrumbs.push({
      name: `Create`,
    })
  }

  if (isEdit) {
    headerText = "Publisher: "
    if (publisher.id) {
      headerText = `Publisher: ${publisher.name} (${publisher.id})`
      breadcrumbs.push({
        name: `${publisher.name} (${publisher.id})`,
      })
      breadcrumbs.push({
        name: `Edit`,
      })
    }
  }

  if (isInfo) {
    headerText = "Publisher: "
    if (publisher.id) {
      headerText = `Publisher: ${publisher.name} (${publisher.id})`
      breadcrumbs.push({
        name: `${publisher.name} (${publisher.id})`,
      })

      headerButtons.push({
        name: "EDIT",
        route: `/admin/publishers/${publisher.id}/edit`,
        permission: PERMISSIONS.ADMIN.PUBLISHER.UPDATE,
        icon: <EditIcon />,
      })
    }
  }

  if (isSites) {
    headerText = "Publisher: "
    if (publisher.id) {
      headerText = `Publisher: ${publisher.name} (${publisher.id}) - All Sites`
      breadcrumbs.push({
        name: `${publisher.name} (${publisher.id})`,
        route: `/admin/publishers/${publisher.id}`,
      })
      breadcrumbs.push({
        name: `Sites`,
      })

      headerButtons.push({
        name: "CREATE NEW SITE",
        route: `/admin/publishers/${publisher.id}/sites/create`,
        permission: PERMISSIONS.ADMIN.PUBLISHER.UPDATE,
        icon: <PlusCircleIcon />,
      })
      headerButtons.push({
        name: sitesSelectedSites.length ? "EXPORT SELECTED TAGS" : "EXPORT ALL TAGS",
        buttonFunction: () =>
          dispatch(
            fetchAndExportTagsFromSites({
              publisherId: publisher.id,
              siteFilters: {
                ids: sitesSelectedSites,
              },
            })
          ),
        permission: PERMISSIONS.ADMIN.PUBLISHER.LIST.VIEW,
        disabled,
      })
    }
  }

  return {
    headerText,
    breadcrumbs,
    headerButtons,
  }
}

const PublisherFormWrapper = ({ match }) => {
  const dispatch = useAppDispatch()
  const publisher = useSelector((state: RootState) => state.publisherForm.selectedPublisher)
  const isLoadingTagsForExportSites = useSelector(
    (state: RootState) => state.sites.isLoadingTagsForExportSites
  )
  const [sitesQuery, setSitesQuery] = useState("")
  const [sitesSelectedSites, setSitesSelectedSites] = useState([])

  const endPath = match.path.split("/").pop()
  const isEdit = endPath === "edit"
  const isCreate = endPath === "create"
  const isSites = endPath === "sites"
  const isInfo = !isEdit && !isCreate && !isSites

  const locationPathname = match.url ?? location.pathname
  const tabStates = {
    create: {
      general: {
        name: "General",
        path: "/admin/publishers/create",
      },
    },

    editInfoAndSites: {
      general: {
        name: "General",
        path: `/admin/publishers/${match.params.publisherId}${isEdit ? "/edit" : ""}`,
      },
      sites: {
        name: "Sites",
        path: `/admin/publishers/${match.params.publisherId}/sites`,
      },
    },
  }

  useEffect(() => {
    // clean up stale data when component unmounts
    return () => {
      dispatch(clearErrors())
      dispatch(resetPublisherFormState())
    }
  }, [])

  useEffect(() => {
    if (!isCreate && !publisher.id) dispatch(fetchPublisherById(match.params.publisherId))
  }, [publisher])

  const { headerText, breadcrumbs, headerButtons } = buildHeaderInfo(
    isCreate,
    isEdit,
    isInfo,
    isSites,
    publisher,
    dispatch,
    isLoadingTagsForExportSites,
    sitesQuery,
    sitesSelectedSites
  )

  return (
    <>
      <Header headerText={headerText} breadcrumbs={breadcrumbs} buttons={headerButtons} />
      <Box mt={3} mb={0}>
        {/* render only general tab when is create leading to publisher/create */}
        {isCreate && (
          <Tabs data-testid="publisher-form-tabs-wrapper" value={locationPathname}>
            <Tab
              value={tabStates.create.general.path}
              label={tabStates.create.general.name}
              component={Link}
              to={tabStates.create.general.path}
              data-testid={"publisher-form-tabs-create-general"}
            />
          </Tabs>
        )}

        {/* render general and sites tab leading to dynamic publisher routes when is edit or info or sites urls */}
        {!isCreate && (
          <Tabs data-testid="publisher-form-tabs-wrapper" value={locationPathname}>
            <Tab
              value={tabStates.editInfoAndSites.general.path}
              label={tabStates.editInfoAndSites.general.name}
              component={Link}
              to={tabStates.editInfoAndSites.general.path}
              data-testid={"publisher-form-tabs-non-create-general"}
            />
            <Tab
              value={tabStates.editInfoAndSites.sites.path}
              label={tabStates.editInfoAndSites.sites.name}
              component={Link}
              to={tabStates.editInfoAndSites.sites.path}
              data-testid={"publisher-form-tabs-non-create-sites"}
            />
          </Tabs>
        )}

        <Switch>
          {isCreate && (
            <Route
              exact={true}
              path={tabStates.create.general.path}
              render={() => <PublisherForm match={match} />}
            />
          )}

          {!isCreate && (
            <>
              <Route
                exact={true}
                path={tabStates.editInfoAndSites.general.path}
                render={() => <PublisherForm match={match} />}
              />
              <Route
                path={tabStates.editInfoAndSites.sites.path}
                render={() => (
                  <SitesList
                    setSitesQuery={setSitesQuery}
                    setSitesSelectedSites={setSitesSelectedSites}
                    match={match}
                  />
                )}
              />
            </>
          )}
        </Switch>
      </Box>
    </>
  )
}

export default withStyles(styles)(PublisherFormWrapper)
