import { API, API_ROOT, SECTION } from "../../constants"
import { call, put, takeEvery } from "redux-saga/effects"
import { handleAxiosRequest, handleSagaError } from "../../helpers/sagaHelpers"
import { uploadSegment, uploadSegmentFailed, uploadSegmentSuccess } from "../actions/segmentActions"

import { AxiosRequestConfig } from "axios"
import { PayloadAction } from "@reduxjs/toolkit"
import { SagaIterator } from "redux-saga"
import { SegmentUploadPayload } from "../types/segmentTypes"
import { clearErrors } from "../actions/errorActions"

function* uploadSegmentSaga(action: PayloadAction<SegmentUploadPayload>) {
  // we must use FormData in order to send files
  const formData = new FormData()
  // vendorId is a number but must be stringified due to formdata constraints
  formData.append("segmentVendorId", action.payload.vendorId.toString())
  formData.append("file", action.payload.file)

  const ajaxConfig: AxiosRequestConfig = {
    method: "POST",
    url: `${API_ROOT}${API.V1}${SECTION.ADMIN}/segments/upload`,
    data: formData,
  }
  yield put(clearErrors())
  try {
    const response = yield call(handleAxiosRequest, ajaxConfig)
    yield put(uploadSegmentSuccess(response.data))
  } catch (err) {
    yield handleSagaError(uploadSegmentFailed, err)
  }
}

export function* watchSegmentUpload(): SagaIterator {
  yield takeEvery(uploadSegment, uploadSegmentSaga)
}
