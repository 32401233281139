import * as Yup from "yup"

import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  TextField,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core"
import { RootState, useAppDispatch } from "../../../../../../redux/store"
import { createDomain, updateDomain } from "../../../../../../redux/actions/domainActions"

import { Domain } from "../../../../../../redux/types/domainTypes"
import ErrorMessages from "../../../../../common/Errors"
import { Formik } from "formik"
import React from "react"
import clsx from "clsx"
import dayjs from "dayjs"
import styles from "./styles"
import { trimPayloadProperties } from "../../../../../../helpers/formatterHelper"
import { useSelector } from "react-redux"

const DomainForm = ({ classes, match }) => {
  const dispatch = useAppDispatch()
  const domain = useSelector((state: RootState) => state.domainForm.selectedDomain)
  const isLoading = useSelector((state: RootState) => state.domainForm.isLoading)
  const isSubmitting = useSelector((state: RootState) => state.domainForm.isSubmitting)

  // TODO: FF: remove when removing feature flag
  const isSaveDomainsWithDataUpdateFFEnabled = useSelector(
    (state: RootState) => state.featureFlags.isSaveDomainsWithDataUpdateFFEnabled
  )
  // TODO: End of FF code removal

  const endPath = match.path.split("/").pop()

  const isCreate = endPath === "create"
  const isEdit = endPath === "edit"
  const isInfo = !isEdit && !isCreate

  const initialFormValues = {
    name: domain.name || "",
  }

  const formValidationSchema = Yup.object().shape({
    name: Yup.string().max(255),
  })

  const handleFormikSubmit = (values) => {
    if (isCreate) {
      dispatch(createDomain(values))
    } else if (isEdit) {
      dispatch(
        updateDomain(
          trimPayloadProperties({
            id: domain.id,
            ...values,
          }) as Domain
        )
      )
    }
  }

  return (
    <>
      <Box mt={3} mb={3} className={clsx({ isInfo: classes.cursorOverrideDisable })}>
        {!isLoading ? (
          <Formik
            enableReinitialize={true}
            initialValues={initialFormValues}
            validationSchema={formValidationSchema}
            onSubmit={(values) => handleFormikSubmit(values)}
          >
            {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => {
              return (
                <>
                  <ErrorMessages />
                  <form onSubmit={handleSubmit} className={clsx(isInfo && classes.overrideDisable)}>
                    <Card>
                      <CardContent className={classes.domainFormCardLayout}>
                        <Grid container spacing={3} className={classes.roundedBorder}>
                          {/* Name */}
                          <Grid item md={6}>
                            <TextField
                              error={!!(touched.name && errors.name)}
                              fullWidth
                              helperText={touched.name && errors.name}
                              label={
                                <span className={clsx(isInfo && classes.overrideDisable)}>
                                  Domain Name
                                </span>
                              }
                              name="name"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              required
                              value={values.name}
                              variant="outlined"
                              inputProps={{
                                "data-testid": "domain-form-name-input",
                              }}
                              InputProps={{
                                classes: {
                                  disabled: classes.overrideDisable,
                                },
                              }}
                              disabled={
                                isLoading || isInfo || !isSaveDomainsWithDataUpdateFFEnabled
                              }
                            />
                            {domain.createdAt && (
                              <div
                                className={classes.timestamps}
                                data-testid={"domain-form-timestamps"}
                              >
                                <Typography>
                                  <b>Created on: </b>
                                  {dayjs(domain?.createdAt).format("M-D-YYYY h:mm a")}
                                  <b> Last updated: </b>
                                  {dayjs(domain?.updatedAt).format("M-D-YYYY h:mm a")}
                                </Typography>
                              </div>
                            )}
                          </Grid>
                        </Grid>
                        {!isInfo && (
                          <Box mt={2}>
                            <Tooltip
                              title={
                                !isSaveDomainsWithDataUpdateFFEnabled
                                  ? "Saving of Domains is not currently enabled."
                                  : ""
                              }
                            >
                              {/* wrapped button in span because tooltips do not work directly on disabled buttons*/}
                              <span>
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  type="submit"
                                  disabled={isSubmitting || !isSaveDomainsWithDataUpdateFFEnabled}
                                  data-testid={"domain-form-submit-button"}
                                >
                                  {isCreate && "Create Domain"}
                                  {isEdit && "Update Domain"}
                                </Button>
                              </span>
                            </Tooltip>
                          </Box>
                        )}
                      </CardContent>
                    </Card>
                  </form>
                </>
              )
            }}
          </Formik>
        ) : (
          <Card>
            <CardContent className={classes.loadingForm}>
              <CircularProgress />
            </CardContent>
          </Card>
        )}
      </Box>
    </>
  )
}

export default withStyles(styles)(DomainForm)
