import * as Yup from "yup"

import {
  AudienceWithFormattedSegmentGroups,
  CreateAudiencePayload,
  UpdateAudiencePayload,
} from "../../../../../redux/types/audienceTypes"
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  MenuItem,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core"
import { BreadCrumb, HeaderInfo } from "../../../../../redux/types/newHeaderTypes"
import {
  END_PATHS,
  ENTITY_STATUSES,
  PERMISSIONS,
  POPULATION_TYPES,
  SEGMENT_VENDOR_NAME,
} from "../../../../../constants"
import React, { useEffect, useState } from "react"
import { RootState, useAppDispatch } from "../../../../../redux/store"
import {
  createAudience,
  fetchAudienceById,
  resetAudienceFormState,
  updateAudience,
} from "../../../../../redux/actions/audienceActions"
import {
  generateSegmentVendorDropdownOptions,
  numberWithCommas,
  trimPayloadProperties,
} from "../../../../../helpers/formatterHelper"

import Can from "../../../../common/Can"
import { Edit as EditIcon } from "react-feather"
import ErrorMessages from "../../../../common/Errors"
import { Formik } from "formik"
import InfoIcon from "@material-ui/icons/Info"
import { Link } from "react-router-dom"
import ListItem from "@material-ui/core/ListItem"
import NewHeader from "../../../../NewHeader/NewHeader"
import SegmentGroups from "./SegmentGroups"
import _ from "lodash"
import { clearErrors } from "../../../../../redux/actions/errorActions"
import { clearPricingEstimateData } from "../../../../../redux/actions/pricingEstimateActions"
import clsx from "clsx"
import dayjs from "dayjs"
import { fetchSegmentVendors } from "../../../../../redux/actions/segmentVendorActions"
import { flattenFormattedSegmentGroups } from "../../../../../helpers/segmentHelpers"
import { isPermitted } from "../../../../../helpers/permissionHelper"
import styles from "./styles"
import { useHistory } from "react-router"
import { useSelector } from "react-redux"

export const buildHeaderInfo = (
  isCreate,
  isEdit,
  isInfo,
  isCopy,
  audience: AudienceWithFormattedSegmentGroups = {} as AudienceWithFormattedSegmentGroups
): HeaderInfo => {
  let headerText
  const breadcrumbs: BreadCrumb[] = [
    {
      name: "Dashboard",
      route: "/",
    },
    {
      name: "Audiences",
      route: "/tools/audiences",
    },
  ]
  const headerButtons = []

  if (isCreate) {
    headerText = "Create New Audience"
    breadcrumbs.push({
      name: `Create`,
    })
  }

  if (isEdit) {
    headerText = "Audience: "
    if (audience.id) {
      headerText = `Audience: ${audience.name} (${audience.id})`
      breadcrumbs.push({
        name: `${audience.name} (${audience.id})`,
        route: `/tools/audiences/${audience.id}`,
      })
      breadcrumbs.push({
        name: `Edit`,
      })
    }
  }

  if (isInfo) {
    headerText = "Audience: "
    if (audience.id) {
      headerText = `Audience: ${audience.name} (${audience.id})`
      breadcrumbs.push({
        name: `${audience.name} (${audience.id})`,
      })

      headerButtons.push({
        name: "EDIT",
        route: `/tools/audiences/${audience.id}/edit`,
        permission: PERMISSIONS.TOOLS.AUDIENCE.UPDATE,
        icon: <EditIcon />,
      })
    }
  }

  if (isCopy) {
    headerText = "Copy of Audience: "
    if (audience.id) {
      headerText = `Copy of Audience: ${audience.name} (${audience.id})`
      breadcrumbs.push({
        name: `${audience.name} (${audience.id})`,
        route: `/tools/audiences/${audience.id}`,
      })

      breadcrumbs.push({
        name: `Copy`,
      })
    }
  }

  return {
    headerText,
    breadcrumbs,
    headerButtons,
  }
}

const AudienceForm = ({ classes, match }) => {
  const dispatch = useAppDispatch()

  const audience = useSelector((state: RootState) => state.audienceForm.selectedAudience)
  const isSaved = useSelector((state: RootState) => state.audienceForm.isSaved)
  const isLoading = useSelector((state: RootState) => state.audienceForm.isLoading)
  const isLoadingAudiencePopulation = useSelector(
    (state: RootState) => state.audienceForm.isLoadingAudiencePopulation
  )
  const isSubmitting = useSelector((state: RootState) => state.audienceForm.isSubmitting)
  const pricingEstimateData = useSelector(
    (state: RootState) => state.pricingEstimate.pricingEstimateData
  )
  const totalReach = useSelector((state: RootState) => state.pricingEstimate.totalReach)
  const [popUpValue, setPopUpValue] = useState(null)
  const [anchorElement, setAnchorElement] = useState(null)
  const history = useHistory()
  const endPath = match.path.split("/").pop()

  const isCreate = endPath === END_PATHS.CREATE
  const isEdit = endPath === END_PATHS.EDIT
  const isCopy = endPath === END_PATHS.COPY
  const isInfo = !isCreate && !isEdit && !isCopy

  const isOpen = !!anchorElement
  const currentUser = useSelector((state: RootState) => state.currentUser.currentUser)
  const audienceLabelCenter = !isPermitted(
    PERMISSIONS.TOOLS.AUDIENCE.PRICING.CALCULATE,
    currentUser
  )
  const [isCopyAudience, setIsCopyAudience] = useState(false)
  const canCopy = audience.status !== "archived"

  const isSegmentVendorsLoading = useSelector((state: RootState) => state.segmentVendors.isLoading)
  const segmentVendors = useSelector((state: RootState) => state.segmentVendors.segmentVendors)
  const segmentVendorDropdownOptions = generateSegmentVendorDropdownOptions(
    segmentVendors,
    isSegmentVendorsLoading,
    true
  )

  const activeCompanyId = currentUser.activeCompanyId
    ? currentUser.activeCompanyId
    : currentUser.companyId
  const audienceFormStatusOptions = [
    { value: ENTITY_STATUSES.ACTIVE, label: "Active" },
    { value: ENTITY_STATUSES.INACTIVE, label: "Inactive" },
    { value: ENTITY_STATUSES.ARCHIVED, label: "Archived" },
  ]

  useEffect(() => {
    segmentVendors.length === 0 && dispatch(fetchSegmentVendors())
    // clean up stale data when component unmounts
    return () => {
      dispatch(clearErrors())
      dispatch(resetAudienceFormState())
      dispatch(clearPricingEstimateData())
    }
  }, [])

  // fires only during non-create cases
  useEffect(() => {
    // if edit/info/copy, fetch audience info and pre-populate form data
    if (isInfo || isEdit || isCopy) {
      if (!audience.id) {
        dispatch(fetchAudienceById(match.params.audienceId))
      }
    }

    isCopyAudience && history.push(`/tools/audiences/${audience.id}/copy`)
    // when the audience is successfully created navigate back to audiences list
    isSaved && history.push("/tools/audiences")
  }, [audience, isSaved, isCopyAudience])

  // VendorID and TargetType cannot be explicitly set to undefined/blank after being set
  const initialFormValues = isCreate
    ? {
        name: "",
        notes: "",
        status: "",
        targetType: POPULATION_TYPES.IP,
        vendorId: segmentVendorDropdownOptions[0].id,
        population: 0,
        // for testing validation when segments > MAX_SEGMENTS in the create case, TODO: change later
        segmentGroups: audience.segmentGroups.length
          ? audience.segmentGroups
          : [{ name: "Group", id: "0", segments: [] }],
        companyId: activeCompanyId,
        vendorName: segmentVendorDropdownOptions[0].name,
      }
    : {
        name: isCopy ? `${audience.name}__copy` : audience.name || "",
        notes: isCopy ? "" : audience.notes || "",
        status: audience.status,
        targetType: POPULATION_TYPES.IP,
        vendorId: audience.vendorId || 0,
        population: audience.population,
        segmentGroups: audience.segmentGroups,
        companyId: audience.companyId,
        vendorName: audience.vendor?.name,
      }

  const formValidationSchema = Yup.object().shape({
    name: Yup.string().min(1).max(255, "Name is too long").required("Name is required"),
    notes: Yup.string().max(255).nullable(true),
    status: Yup.string().max(255),
    targetType: Yup.string().max(255),
    vendorId: Yup.number(),
    segmentGroups: Yup.array(),
  })

  //fires when user changes vendor id
  const handleVendorIdChange = (setFieldValue, e) => {
    const selectedVendorId = e.target.value
    setFieldValue("vendorId", selectedVendorId)

    segmentVendorDropdownOptions.forEach((vendor) => {
      if (vendor.id === selectedVendorId) {
        setFieldValue("vendorName", vendor.name)
        const segmentFieldValue =
          vendor.id !== audience.vendorId
            ? [{ name: "Group", id: "0", segments: [] }]
            : [...audience.segmentGroups]
        setFieldValue("segmentGroups", segmentFieldValue)
      }
    })
  }
  const handleFormikSubmit = (values) => {
    const valuesCopy = _.cloneDeep(values)
    valuesCopy.pricingAndReachEstimates = pricingEstimateData
    valuesCopy.vendorId = values.vendorId ? values.vendorId : null
    valuesCopy.companyId = values.companyId
    valuesCopy.segmentGroups = flattenFormattedSegmentGroups(valuesCopy.segmentGroups)
    valuesCopy.audienceCreationType = endPath

    if (isCreate) {
      dispatch(
        createAudience(
          trimPayloadProperties(_.omit(valuesCopy, ["status"])) as CreateAudiencePayload
        )
      )
    } else if (isCopy) {
      dispatch(createAudience(trimPayloadProperties(valuesCopy) as CreateAudiencePayload))
      setIsCopyAudience(false)
    } else if (isEdit) {
      dispatch(
        updateAudience(
          trimPayloadProperties({
            id: audience.id,
            ...valuesCopy,
          }) as UpdateAudiencePayload
        )
      )
    }
  }

  const handlePopoverOpen = (event, popupData) => {
    setPopUpValue(popupData)
    setAnchorElement(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorElement(null)
  }

  const handleCopyAudienceClick = () => {
    setIsCopyAudience(true)
  }

  const renderPopover = (vendorName) => (
    <Popover
      id={`popup-info`}
      className={classes.popover}
      open={isOpen}
      data-testid={`audience-pricing-table-popup-info`}
      style={{ pointerEvents: "none" }}
      anchorEl={anchorElement}
      onClose={handlePopoverClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      PaperProps={{
        style: { maxWidth: "350px" },
      }}
      disableRestoreFocus
    >
      {popUpValue ? popupInfo(popUpValue, vendorName) : null}
    </Popover>
  )

  const popupInfo = (popUpValue?: string, vendorName?: string) => {
    const isAudigentVendor = vendorName === SEGMENT_VENDOR_NAME.AUDIGENT
    // As per EMXD-5945 hide data fee for audigent vendor
    const dataFeeText = vendorName && !isAudigentVendor ? ` (+ $1 Data Fee) ` : " "
    if (popUpValue === "WinningCPMs") {
      return (
        <div className={classes.popoverContent} data-testid={`audience-winningCPMs-popup-info`}>
          <ListItem key={`item-popup-infoText`}>
            <div className={classes.winninPopoverContent}>
              <Typography variant="caption" component="p">
                During the most recently completed month, the CPMs used to win this audience
                resulting in a render impression{!isAudigentVendor ? ` + $1 Data Fee ` : " "}are
                summarized as:
              </Typography>
              <Typography
                variant="caption"
                data-testid={`audience-form-tooltip-winningCPMs-content`}
              >
                <ul>
                  <li>
                    Average: 50th percentile of winning CPMs{dataFeeText}converted to impressions
                  </li>
                  <li>
                    Higher: 75th percentile of winning CPMs{dataFeeText}converted to impressions
                  </li>
                  <li>
                    Lower: 25th percentile of winning CPMs{dataFeeText}converted to impressions
                  </li>
                </ul>
              </Typography>
              <Typography variant="caption" component="p">
                <b>
                  <i>
                    NOTE: All other fees and addons need to be considered separately for your
                    pricing strategy (Vertical & Geo etc.)
                  </i>
                </b>
              </Typography>
            </div>
          </ListItem>
        </div>
      )
    } else {
      return (
        <div className={classes.popoverContent}>
          <ListItem key={`item-popup-infoText`}>
            <Typography variant="caption" component="p">
              {popUpValue}
            </Typography>
          </ListItem>
        </div>
      )
    }
  }

  const renderSegmentVendorDropdownOptions = (values) => {
    const updatedSegmentVendorDropdownOptions = _.cloneDeep(segmentVendorDropdownOptions)

    // Edge Case:
    // when no audience is fetched
    // and segment vendors have been fetched (aka segmentVendorDropdownOptions does not contain something with id 0)
    // This inserts a temporary dropdown option to prevent console warnings.
    // Default vendor will still select first option in list once audience/segment vendors are loaded
    !values.vendorId &&
      !segmentVendorDropdownOptions.some((option) => option.id === 0) &&
      updatedSegmentVendorDropdownOptions.unshift({ name: "", id: 0 })

    return updatedSegmentVendorDropdownOptions.map((option) => (
      <MenuItem key={option.id} value={option.id}>
        {option.name}
      </MenuItem>
    ))
  }

  const getSubmitButtonText = (endPath: string): string => {
    switch (endPath) {
      case END_PATHS.CREATE:
        return "Create Audience"
      case END_PATHS.EDIT:
        return "Update Audience"
      case END_PATHS.COPY:
        return "Save Copied Audience"
      default:
        return "Unknown endPath"
    }
  }

  const renderImpressions = () => {
    return numberWithCommas(
      isInfo
        ? audience.pricingAndReachEstimates?.reduce((acc, val) => acc + val.reach, 0)
        : totalReach
    )
  }

  const { headerText, breadcrumbs, headerButtons } = buildHeaderInfo(
    isCreate,
    isEdit,
    isInfo,
    isCopy,
    audience
  )

  return (
    <>
      <NewHeader headerText={headerText} breadcrumbs={breadcrumbs} buttons={headerButtons} />
      <Box mt={3} mb={3}>
        <Formik
          enableReinitialize={true}
          initialValues={initialFormValues}
          validationSchema={formValidationSchema}
          onSubmit={(values) => handleFormikSubmit(values)}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, setFieldValue, touched, values }) => {
            const totalSegmentCount = values.segmentGroups.reduce((acc, cur) => {
              return acc + (cur?.segments?.length || 0)
            }, 0)
            return (
              <>
                <ErrorMessages />
                <form onSubmit={handleSubmit} className={clsx(isInfo && classes.overrideDisable)}>
                  <Card className={classes.roundedBorder}>
                    <CardContent style={{ padding: 24 }}>
                      <Grid
                        container
                        spacing={3}
                        className={clsx(classes.topMostContainer, classes.roundedBorder)}
                      >
                        {/* Vendor */}
                        <Grid item md={3} xs={12}>
                          <TextField
                            select
                            label={
                              <span className={clsx(isInfo && classes.overrideDisable)}>
                                Vendor
                              </span>
                            }
                            name="vendorId"
                            value={values.vendorId}
                            onChange={(e) => {
                              handleVendorIdChange(setFieldValue, e)
                            }}
                            className={clsx(classes.dropDownField, {
                              [classes.hideArrow]: segmentVendorDropdownOptions.length < 2,
                            })}
                            inputProps={{
                              "data-testid": "audience-form-vendor-id-dropdown-input",
                            }}
                            InputProps={{
                              classes: {
                                disabled: classes.overrideDisable,
                              },
                            }}
                            disabled={
                              !isCreate ||
                              isSegmentVendorsLoading ||
                              segmentVendorDropdownOptions.length < 2
                            }
                          >
                            {renderSegmentVendorDropdownOptions(values)}
                          </TextField>
                        </Grid>
                      </Grid>
                      <Grid container spacing={3} className={classes.roundedBorder}>
                        {/* Audience Name */}
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={!!(touched.name && errors.name)}
                            fullWidth
                            helperText={touched.name && errors.name}
                            label={
                              <span className={clsx(isInfo && classes.overrideDisable)}>
                                Audience Name
                              </span>
                            }
                            name="name"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            required
                            value={values.name}
                            variant="outlined"
                            inputProps={{
                              "data-testid": "audience-form-name-input",
                            }}
                            InputProps={{
                              classes: {
                                disabled: classes.overrideDisable,
                              },
                            }}
                            disabled={isLoading || isInfo}
                          />
                          {audience.createdAt && (
                            <div
                              className={classes.timestamps}
                              data-testid={"audience-form-timestamps"}
                            >
                              <Typography>
                                <b>Created on: </b>
                                {dayjs(audience?.createdAt).format("M-D-YYYY h:mm a")}
                                <b> Last updated: </b>
                                {dayjs(audience?.updatedAt).format("M-D-YYYY h:mm a")}
                              </Typography>
                            </div>
                          )}
                        </Grid>

                        {/* Audience Status */}
                        {(isEdit || isInfo || isCopy) && (
                          <Grid item md={3} xs={6}>
                            <TextField
                              select
                              label={
                                <span className={clsx(isInfo && classes.overrideDisable)}>
                                  Audience Status
                                </span>
                              }
                              name="status"
                              value={values.status}
                              onChange={(e) => {
                                setFieldValue("status", e.target.value)
                              }}
                              className={classes.dropDownField}
                              inputProps={{
                                "data-testid": "audience-form-status-input",
                              }}
                              InputProps={{
                                classes: {
                                  disabled: classes.overrideDisable,
                                },
                              }}
                              disabled={isLoading || isInfo}
                            >
                              {audienceFormStatusOptions.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Grid>
                        )}
                      </Grid>
                      <Grid
                        container
                        className={classes.roundedBorder}
                        direction="row"
                        spacing={3}
                        justifyContent="center"
                      >
                        <Grid
                          item
                          md={12}
                          xs={12}
                          className={classes.displayFlex}
                          data-testid={`audience-form-population-impressions-table`}
                        >
                          {/* Uniques */}
                          <Box className={classes.customCard}>
                            <Box mb={2} className={classes.customCardHeader}>
                              <Typography
                                align="left"
                                variant="h5"
                                className={classes.customCardHeaderTitle}
                              >
                                Uniques
                                <InfoIcon
                                  className="infoIcon"
                                  fontSize="small"
                                  onMouseLeave={handlePopoverClose}
                                  onMouseEnter={(e) =>
                                    handlePopoverOpen(
                                      e,
                                      "Number of deduplicated households or businesses."
                                    )
                                  }
                                />
                              </Typography>
                            </Box>
                            <Box className={classes.customCardBody}>
                              <Typography
                                variant={"h1"}
                                color={"primary"}
                                className={classes.customTestBox}
                              >
                                {!(isLoadingAudiencePopulation || isLoading) ? (
                                  <div data-testid={"estimated-audience-population"}>
                                    {`${numberWithCommas(values?.population)}`}
                                  </div>
                                ) : (
                                  <CircularProgress />
                                )}
                              </Typography>
                            </Box>
                          </Box>

                          {/* Impressions */}
                          <Box className={classes.customCard}>
                            <Box mb={2} className={classes.customCardHeader}>
                              <Typography
                                align="left"
                                variant="h5"
                                className={classes.customCardHeaderTitle}
                              >
                                Impressions
                                <InfoIcon
                                  className="infoIcon"
                                  fontSize="small"
                                  onMouseLeave={handlePopoverClose}
                                  onMouseEnter={(e) =>
                                    handlePopoverOpen(
                                      e,
                                      "Reachable impressions based on historical data."
                                    )
                                  }
                                />
                              </Typography>
                            </Box>
                            <Box className={classes.customCardBody}>
                              <Typography
                                variant={"h1"}
                                color={"primary"}
                                className={classes.customTestBox}
                              >
                                {!(isLoadingAudiencePopulation || isLoading) ? (
                                  <div data-testid={"audience-form-impressions"}>
                                    {renderImpressions()}
                                  </div>
                                ) : (
                                  <CircularProgress />
                                )}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                      {renderPopover(values.vendorName)}
                      {!isInfo && (
                        <Grid
                          container
                          className={classes.roundedBorder}
                          direction="row"
                          spacing={3}
                          justifyContent="space-between"
                          data-testid={`audience-form-historical-estimate-table`}
                        >
                          <Grid item md={3}>
                            <Box
                              mb={3}
                              className={clsx(classes.infoBox, {
                                [classes.audienceLabelStyle]: audienceLabelCenter,
                              })}
                            >
                              <Typography align="center" variant="h5">
                                <b>Historical Suggestions</b>
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item md={9}>
                            <TableContainer component={Paper} className={classes.noneBoxShadow}>
                              <Table
                                className={clsx(classes.table, classes.tableReachPricing)}
                                size="small"
                              >
                                <TableHead>
                                  <TableRow>
                                    {pricingEstimateData.map((row, i) => (
                                      <TableCell
                                        align="center"
                                        data-testid={`audience-form-pricing-estimate-table-${row.mediaType}`}
                                        key={row.mediaType + i}
                                      >
                                        <Typography variant="h6" align="center">
                                          <Box fontWeight="fontWeightBold">{row.mediaType}</Box>
                                        </Typography>
                                      </TableCell>
                                    ))}
                                    <TableCell
                                      key={`blankHeader`}
                                      className={classes.displayNone}
                                    />
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  <TableRow>
                                    {pricingEstimateData.map((row, i) => (
                                      <TableCell
                                        align="center"
                                        key={row.reach + i + "reach"}
                                        className={classes.textColorPrimary}
                                        data-testid={`audience-form-reach-${i}`}
                                      >
                                        <b>{numberWithCommas(row.reach)}</b>
                                      </TableCell>
                                    ))}
                                    <TableCell className={classes.tableReachPricing}>
                                      <Typography variant="h6" align="center" color={"primary"}>
                                        <b>Impressions</b>
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                  <Can
                                    perform={PERMISSIONS.TOOLS.AUDIENCE.PRICING.CALCULATE}
                                    yes={
                                      <>
                                        <TableRow
                                          data-testid={`audience-form-high-pricing-estimate-row`}
                                        >
                                          {pricingEstimateData.map((row, i) => (
                                            <TableCell
                                              align="center"
                                              key={row.highCpm + i + "high"}
                                              className={classes.textColorSuccess}
                                              data-testid={`audience-form-pricing-high-${i}`}
                                            >
                                              {row.highCpm.toLocaleString("en-US", {
                                                style: "currency",
                                                currency: "USD",
                                              })}
                                            </TableCell>
                                          ))}
                                          <TableCell className={classes.textColorSuccess}>
                                            Higher
                                          </TableCell>
                                          <TableCell
                                            rowSpan={3}
                                            className={clsx({
                                              [classes.noPadding]: true,
                                              [classes.noBorder]: true,
                                            })}
                                            data-testid={`audience-form-cpm-titles`}
                                          >
                                            <Typography
                                              variant="h6"
                                              align="center"
                                              className={clsx({
                                                [classes.winningCPMsCell]: true,
                                                [classes.paddedCell]: true,
                                              })}
                                            >
                                              <b>
                                                Winning CPMs
                                                <InfoIcon
                                                  className="infoIcon"
                                                  data-testid={`audience-form-winning-cpm-info-icon`}
                                                  fontSize="small"
                                                  onMouseLeave={handlePopoverClose}
                                                  onMouseEnter={(e) =>
                                                    handlePopoverOpen(e, "WinningCPMs")
                                                  }
                                                />
                                              </b>
                                              {values.vendorName !==
                                              SEGMENT_VENDOR_NAME.AUDIGENT ? (
                                                <b>+ Data Fee(USD)</b>
                                              ) : null}
                                            </Typography>
                                          </TableCell>
                                        </TableRow>
                                        <TableRow
                                          data-testid={`audience-form-medium-pricing-estimate-row`}
                                        >
                                          {pricingEstimateData.map((row, i) => (
                                            <TableCell
                                              align="center"
                                              key={row.medianCpm + i + "median"}
                                              className={classes.textColorFaded}
                                              data-testid={`audience-form-pricing-median-${i}`}
                                            >
                                              <Typography variant="body2" align="center">
                                                <b>
                                                  {row.medianCpm.toLocaleString("en-US", {
                                                    style: "currency",
                                                    currency: "USD",
                                                  })}
                                                </b>
                                              </Typography>
                                            </TableCell>
                                          ))}
                                          <TableCell className={classes.textColorFaded}>
                                            <Typography variant="body2" align="center">
                                              <b>Average</b>
                                            </Typography>
                                          </TableCell>
                                        </TableRow>
                                        <TableRow
                                          data-testid={`audience-form-low-pricing-estimate-row`}
                                        >
                                          {pricingEstimateData.map((row, i) => (
                                            <TableCell
                                              align="center"
                                              key={row.lowCpm + i + "low"}
                                              className={classes.textColorFaded}
                                              data-testid={`audience-form-pricing-low-${i}`}
                                            >
                                              <Typography variant="body2" align="center">
                                                <b>
                                                  {row.lowCpm.toLocaleString("en-US", {
                                                    style: "currency",
                                                    currency: "USD",
                                                  })}
                                                </b>
                                              </Typography>
                                            </TableCell>
                                          ))}
                                          <TableCell className={classes.textColorFaded}>
                                            <Typography variant="body2" align="center">
                                              <b>Lower</b>
                                            </Typography>
                                          </TableCell>
                                        </TableRow>
                                      </>
                                    }
                                  />
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                        </Grid>
                      )}
                      {/* Segment Groups */}
                      <Grid
                        container
                        spacing={3}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        className={classes.roundedBorder}
                      >
                        <Grid item md={12} xs={9}>
                          <SegmentGroups
                            isLoadingAudience={isLoading}
                            setFieldValue={setFieldValue}
                            audienceTargetType={values.targetType}
                            segmentGroupsValue={values.segmentGroups}
                            totalSegmentCount={totalSegmentCount}
                            segmentVendor={{ id: values.vendorId, name: values.vendorName }}
                            disabled={isInfo}
                          />
                        </Grid>
                      </Grid>
                      {/* Notes section */}
                      <Grid container className={classes.roundedBorder} spacing={3}>
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={!!(touched.notes && errors.notes)}
                            fullWidth
                            multiline
                            helperText={touched.notes && errors.notes}
                            label={
                              <span
                                className={clsx(isInfo && values.notes && classes.overrideDisable)}
                              >
                                Notes
                              </span>
                            }
                            name="notes"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.notes}
                            variant="outlined"
                            inputProps={{
                              "data-testid": "audience-form-notes-input",
                            }}
                            InputProps={{
                              classes: {
                                disabled: classes.overrideDisable,
                              },
                            }}
                            disabled={isLoading || isInfo}
                          />
                        </Grid>
                      </Grid>
                      {/* Submit section */}
                      {!isInfo && (
                        <Box mt={2}>
                          <Button
                            variant="contained"
                            color="secondary"
                            type="submit"
                            disabled={isSubmitting || isLoading || totalSegmentCount > 10}
                            data-testid={"audience-form-submit-button"}
                          >
                            {getSubmitButtonText(endPath)}
                          </Button>
                          <Can
                            perform={PERMISSIONS.TOOLS.AUDIENCE.CREATE}
                            yes={
                              <>
                                {isEdit && canCopy && (
                                  <Button
                                    className={classes.buttonSpacing}
                                    variant="contained"
                                    color="secondary"
                                    type="button"
                                    disabled={isSubmitting || isLoading || totalSegmentCount > 10}
                                    onClick={() => {
                                      handleCopyAudienceClick()
                                    }}
                                    data-testid={"audience-form-copy-button"}
                                  >
                                    Copy Audience
                                  </Button>
                                )}
                              </>
                            }
                          />
                          <Button
                            className={classes.buttonSpacing}
                            variant="contained"
                            color="secondary"
                            type="button"
                            disabled={isSubmitting || isLoading}
                            component={Link}
                            to={"/tools/audiences"}
                            data-testid={"audience-form-cancel-button"}
                          >
                            Cancel
                          </Button>
                          {totalSegmentCount > 10 && (
                            <Typography color="error">
                              You have exceeded the total limit of 10 segments per audience
                            </Typography>
                          )}
                        </Box>
                      )}
                    </CardContent>
                  </Card>
                </form>
              </>
            )
          }}
        </Formik>
      </Box>
    </>
  )
}

export default withStyles(styles)(AudienceForm)
