import { Theme, createStyles } from "@material-ui/core/styles"

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default (theme: Theme) => {
  return createStyles({
    queryField: {
      width: 500,
      marginRight: theme.spacing(1),
    },
    publishersStatusField: {
      marginRight: theme.spacing(1),
      width: "15ch",
    },
    publishersSortByField: {
      marginRight: theme.spacing(1),
    },
    publisherStatusInactive: {
      backgroundColor: "#dd4b39",
    },
    publisherStatusEnabled: {
      backgroundColor: "#5cb85c",
    },
    label: {
      display: "inline",
      padding: ".2em .6em .3em",
      fontSize: "75%",
      lineHeight: "1",
      color: "#fff",
      textAlign: "center",
      whiteSpace: "nowrap",
      verticalAlign: "baseline",
      borderRadius: ".25em",
      margin: ".2em",
    },
    bulkOperations: {
      position: "relative",
    },
    bulkActions: {
      paddingLeft: 4,
      paddingRight: 4,
      marginTop: 6,
      position: "absolute",
      width: "100%",
      zIndex: 2,
      backgroundColor: theme.palette.background.default,
    },
    bulkAction: {
      marginLeft: theme.spacing(2),
    },
    avatar: {
      height: 42,
      width: 42,
      marginRight: theme.spacing(1),
    },
  })
}
