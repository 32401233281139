import { API, API_ROOT, HOMEPAGE_MESSAGE, SECTION } from "../../constants"
import { call, put, takeEvery } from "redux-saga/effects"
import { handleAxiosRequest, handleSagaError } from "../../helpers/sagaHelpers"
import {
  updateHomePage,
  updateHomePageFailed,
  updateHomePageSuccess,
} from "../actions/homePageActions"

import { AxiosRequestConfig } from "axios"
import { HomePage } from "../types/homePageTypes"
import { PayloadAction } from "@reduxjs/toolkit"
import { SagaIterator } from "redux-saga"

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* updateHomePageSaga(action: PayloadAction<HomePage>) {
  const ajaxConfig: AxiosRequestConfig = {
    method: "PATCH",
    url: `${API_ROOT}${API.V1}${SECTION.ADMIN}/configuration/home-page/message`,
    data: { name: HOMEPAGE_MESSAGE.FIELD_NAME, ...action.payload },
  }
  try {
    const response = yield call(handleAxiosRequest, ajaxConfig)
    yield put(updateHomePageSuccess(response.data))
  } catch (err) {
    yield handleSagaError(updateHomePageFailed, err)
  }
}

export function* watchUpdateHomePage(): SagaIterator {
  yield takeEvery(updateHomePage, updateHomePageSaga)
}
