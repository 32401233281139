import _ from "lodash"
import { put } from "redux-saga/effects"
import { SagaIterator } from "redux-saga"

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* handleDealRequestSagaValidationErrors(action, error): SagaIterator {
  const { validations } = error
  const constraintErrors = []
  let pmpDealErrorIDsInString = ""
  const pmpDealErrorIDs = validations.map((validation) => {
    if (validation.property === "pmpDealId") {
      return validation.target.id
    }
  })
  pmpDealErrorIDsInString = pmpDealErrorIDs.join(", ")
  for (let i = 0; i < validations.length; i += 1) {
    const validation = validations[i]
    if (validation.constraints) {
      _.forOwn(validation.constraints, (constraint) => {
        validation.property === "pmpDealId"
          ? constraintErrors.push(`${constraint} for deal requests ID-${pmpDealErrorIDsInString}`)
          : constraintErrors.push(constraint)
      })
    }
  }
  for (let i = 0; i < constraintErrors.length; i += 1) {
    const constraintError = constraintErrors[i]
    yield put(action(constraintError))
  }
}
