import React from "react"
import { withStyles } from "@material-ui/core/styles"
import styles from "./styles"
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Button,
  Typography,
} from "@material-ui/core"
import WarningIcon from "@material-ui/icons/Warning"

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export interface ConfirmationDialogProps {
  id: string
  keepMounted: boolean
  open: boolean
  onClose: (value?: boolean) => void
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  classes: any
}
const ConfirmationDialog = (props: ConfirmationDialogProps) => {
  const { onClose, open, classes, ...other } = props
  const handleOk = () => {
    onClose(true)
  }

  const handleCancel = () => {
    onClose()
  }

  return (
    <Dialog
      open={open}
      aria-labelledby="active-company-change-dialog"
      {...other}
      data-testid="confirmation-dialouge"
    >
      <DialogTitle id="active-company-change-dialog-title">
        <div className={classes.iconicTitle}>
          <WarningIcon color="action" className={classes.icon} />
          <span className={classes.title}>Warning!!!</span>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="body1">
          Unsaved changes will lost if you change active company. Do you want to proceed anyway?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={handleCancel}
          color="default"
          variant="contained"
          data-testid="confirmation-cancel"
        >
          Cancel
        </Button>
        <Button
          onClick={handleOk}
          color="secondary"
          variant="contained"
          autoFocus
          data-testid="confirmation-done"
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withStyles(styles)(ConfirmationDialog)
