import { API, API_ROOT, SECTION } from "../../constants"
import { call, put, takeEvery } from "redux-saga/effects"
import { handleAxiosRequest, handleSagaError } from "../../helpers/sagaHelpers"
import { updateRole, updateRoleFailed, updateRoleSuccess } from "../actions/roleActions"

import { AxiosRequestConfig } from "axios"
import { PayloadAction } from "@reduxjs/toolkit"
import { SagaIterator } from "redux-saga"
import { UpdateRolePayload } from "../types/roleTypes"
import _ from "lodash"
import { clearErrors } from "../actions/errorActions"

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* updateRoleSaga(action: PayloadAction<UpdateRolePayload>) {
  const ajaxConfig: AxiosRequestConfig = {
    method: "PATCH",
    url: `${API_ROOT}${API.V1}${SECTION.ADMIN}/roles/${action.payload.id}`,
    data: {
      role: _.omit(action.payload, ["id"]),
    },
  }
  yield put(clearErrors())
  try {
    const response = yield call(handleAxiosRequest, ajaxConfig)
    yield put(updateRoleSuccess(response.data))
  } catch (err) {
    yield handleSagaError(updateRoleFailed, err)
  }
}

export function* watchUpdateRole(): SagaIterator {
  yield takeEvery(updateRole, updateRoleSaga)
}
