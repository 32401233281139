import { createStyles, Theme } from "@material-ui/core/styles"

// currently duplicate styles, duplicated for easier development
// should be handled in a css ticket in the future

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default (theme: Theme) => {
  return createStyles({
    popoverContent: {
      background: "lightyellow",
      fontSize: "8px",
    },
    popover: {
      pointerEvents: "none",
    },
    paper: {
      padding: theme.spacing(1),
    },
    groupCompareLogic: {
      paddingLeft: "13px",
      color: "blue",
      fontWeight: 900,
    },
    segmentCompareLogic: {
      color: "red ",
      fontWeight: 900,
    },
    segmentGroup: {
      fontWeight: 900,
      marginRight: "10px",
    },
    displayFlex: {
      display: "flex",
      marginBottom: "10px",
      padding: 0,
    },
    listSubheader: {
      display: "flex",
      padding: "12px 0",
    },
    overflowVisible: {
      overflow: "visible",
    },
    segmentBorder: {
      border: "1px solid #eee",
    },
  })
}
