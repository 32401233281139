import { Box, Tab, Tabs, withStyles } from "@material-ui/core"
import { BreadCrumb, HeaderInfo } from "../../../../../redux/types/newHeaderTypes"
import { Link, Route, Switch } from "react-router-dom"
import React, { useEffect } from "react"
import { RootState, useAppDispatch } from "../../../../../redux/store"
import {
  fetchCompanyById,
  resetCompanyFormState,
} from "../../../../../redux/actions/companyActions"

import CampaignBISettingsForm from "./CampaignBISettingsForm/CampaignBISettingsForm"
import { Company } from "../../../../../redux/types/companyTypes"
import CompanyForm from "./CompanyForm/CompanyForm"
import { Edit as EditIcon } from "react-feather"
import NewHeader from "../../../../NewHeader"
import { PERMISSIONS } from "../../../../../constants"
import { clearErrors } from "../../../../../redux/actions/errorActions"
import styles from "./styles"
import { useSelector } from "react-redux"

export const buildHeaderInfo = (
  isCreate,
  isEdit,
  isInfo,
  company: Company = {} as Company
): HeaderInfo => {
  let headerText
  const breadcrumbs: BreadCrumb[] = [
    {
      name: "Dashboard",
      route: "/",
    },
    {
      name: "Companies",
      route: "/admin/companies",
    },
  ]
  const headerButtons = []

  if (isCreate) {
    headerText = "Create New Company"
    breadcrumbs.push({
      name: `Create`,
    })
  }

  if (isEdit) {
    headerText = "Company: "
    if (company.id) {
      headerText = `Company: ${company.name} (${company.id})`
      breadcrumbs.push({
        name: `${company.name} (${company.id})`,
        route: `/admin/companies/${company.id}`,
      })
      breadcrumbs.push({
        name: `Edit`,
      })
    }
  }

  if (isInfo) {
    headerText = "Company: "
    if (company.id) {
      headerText = `Company: ${company.name} (${company.id})`
      breadcrumbs.push({
        name: `${company.name} (${company.id})`,
      })

      headerButtons.push({
        name: "EDIT",
        route: `/admin/companies/${company.id}/edit`,
        permission: PERMISSIONS.ADMIN.COMPANY.UPDATE,
        icon: <EditIcon />,
      })
    }
  }

  return {
    headerText,
    breadcrumbs,
    headerButtons,
  }
}

const CompanyFormWrapper = ({ match }) => {
  const dispatch = useAppDispatch()
  const companyForm = useSelector((state: RootState) => state.companyForm)
  const company = useSelector((state: RootState) => state.companyForm.selectedCompany)
  const endPath = match.path.split("/").pop()

  const isCreate = endPath === "create"
  const isEdit = endPath === "edit"
  const isInfo = !isCreate && !isEdit

  const locationPathname = match.url ?? location.pathname

  const tabStates = {
    create: {
      general: {
        name: "General",
        path: "/admin/companies/create",
      },
    },
    edit: {
      general: {
        name: "General",
        path: `/admin/companies/${match.params.companyId}/edit`,
      },
      campaignbi: {
        name: "CampaignBI",
        path: `/admin/companies/${match.params.companyId}/campaign-bi-settings/edit`,
      },
    },
    info: {
      general: {
        name: "General",
        path: `/admin/companies/${match.params.companyId}`,
      },
    },
  }

  useEffect(() => {
    // if form is edit or info/view, fetch company info and pre-populate form data
    if ((isEdit || isInfo) && !company.id) dispatch(fetchCompanyById(match.params.companyId))
  }, [company])

  useEffect(() => {
    return () => {
      // clean up stale data when component unmounts
      dispatch(clearErrors())
      dispatch(resetCompanyFormState())
    }
  }, [])

  const { headerText, breadcrumbs, headerButtons } = buildHeaderInfo(
    isCreate,
    isEdit,
    isInfo,
    company
  )

  return (
    <>
      <NewHeader headerText={headerText} breadcrumbs={breadcrumbs} buttons={headerButtons} />
      <Box mt={3} mb={0}>
        {isCreate && (
          <Tabs data-testid="company-form-create-tabs" value={locationPathname}>
            <Tab
              data-testid="company-form-general-tab"
              value={tabStates.create.general.path}
              label={tabStates.create.general.name}
              component={Link}
              to={tabStates.create.general.path}
            />
          </Tabs>
        )}
        {isEdit && (
          <Tabs data-testid="company-form-edit-tabs" value={locationPathname}>
            <Tab
              data-testid="company-form-general-tab"
              value={tabStates.edit.general.path}
              label={tabStates.edit.general.name}
              component={Link}
              to={tabStates.edit.general.path}
            />
            <Tab
              data-testid="company-form-campaignbi-tab"
              value={tabStates.edit.campaignbi.path}
              label={tabStates.edit.campaignbi.name}
              component={Link}
              to={tabStates.edit.campaignbi.path}
            />
          </Tabs>
        )}
        {isInfo && (
          <Tabs data-testid="company-form-info-tabs" value={locationPathname}>
            <Tab
              data-testid="company-form-general-tab"
              value={tabStates.info.general.path}
              label={tabStates.info.general.name}
              component={Link}
              to={tabStates.info.general.path}
            />
          </Tabs>
        )}
        <Switch>
          <Route
            exact
            path={tabStates.create.general.path}
            render={() => <CompanyForm match={match} />}
          />
          <Route
            exact
            path={[tabStates.edit.general.path, tabStates.info.general.path]}
            render={() => <CompanyForm match={match} />}
          />
          <Route
            exact
            path={tabStates.edit.campaignbi.path}
            render={() => <CampaignBISettingsForm companyForm={companyForm} />}
          />
        </Switch>
      </Box>
    </>
  )
}

export default withStyles(styles)(CompanyFormWrapper)
