import {
  clearPublishers,
  closeSavePublisherSuccess,
  closeUpdatePublisherSellersJsonError,
  createPublisherSuccess,
  fetchAndExportPublishersSuccess,
  fetchPublishersSuccess,
  setLoadingPublishersForExportFlag,
  setPublishersLoadingFlag,
  updatePublisherSellersJsonFailed,
  updatePublisherSuccess,
} from "../actions/publisherActions"

import { PaginatedComponentState } from "../types/sharedTypes"
import { Publisher } from "../types/publisherTypes"
import { createReducer } from "@reduxjs/toolkit"

interface InitialPublisherState extends PaginatedComponentState {
  publishers: Publisher[]
  isLoading: boolean
  isLoadingPublishersForExport: boolean
  publisherSuccessMessage: string
  updatePublisherSellersJsonErrorMessage: string
}

export const initialState: InitialPublisherState = {
  publishers: [],
  isLoading: true,
  isLoadingPublishersForExport: false,
  publisherSuccessMessage: "",
  updatePublisherSellersJsonErrorMessage: "",
  pager: {
    totalCount: 0,
    page: 0,
  },
}

export default createReducer(initialState, (builder) =>
  builder
    /*================================ Publisher List action handlers ================================*/
    .addCase(setPublishersLoadingFlag, (state, action) => {
      state.isLoading = action.payload
    })
    .addCase(setLoadingPublishersForExportFlag, (state, action) => {
      state.isLoadingPublishersForExport = action.payload
    })
    .addCase(fetchPublishersSuccess, (state, action) => {
      state.publishers = action.payload.publishers
      state.pager = action.payload.pager
    })
    .addCase(fetchAndExportPublishersSuccess, (state, action) => {
      state.publisherSuccessMessage = action.payload
    })
    .addCase(clearPublishers, () => initialState)

    /*======== Publisher Form action handlers (triggered by Publisher Form component, displayed in Publisher List component)  ========*/
    .addCase(createPublisherSuccess, (state, action) => {
      state.publisherSuccessMessage = `Successfully created publisher: ${action.payload.name} (${action.payload.id})`
    })
    .addCase(updatePublisherSuccess, (state, action) => {
      state.publisherSuccessMessage = `Successfully updated publisher: ${action.payload.name} (${action.payload.id})`
    })
    .addCase(closeSavePublisherSuccess, (state) => {
      state.publisherSuccessMessage = initialState.publisherSuccessMessage
    })
    .addCase(updatePublisherSellersJsonFailed, (state, action) => {
      if (state.publisherSuccessMessage) {
        state.updatePublisherSellersJsonErrorMessage = action.payload
      }
    })
    .addCase(closeUpdatePublisherSellersJsonError, (state) => {
      state.updatePublisherSellersJsonErrorMessage =
        initialState.updatePublisherSellersJsonErrorMessage
    })
)
