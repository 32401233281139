import { Button, Chip, InputLabel, Tooltip, withStyles } from "@material-ui/core"
import React, { useEffect, useState } from "react"

import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined"
import _ from "lodash"
import clsx from "clsx"
import styles from "./styles"
import { useAppDispatch } from "../../../../../../redux/store"
import { usePapaParse } from "react-papaparse"

const CsvUpload = ({
  classes,
  values,
  setFieldValue,
  type,
  uploadedList,
  uploadSuccessAction,
  fieldValue,
  clearAction,
  disabled = false,
  toolTipText = "",
  className = "",
}) => {
  const dispatch = useAppDispatch()

  const [fileName, setFileName] = useState("")
  const [parsedCSVData, setParsedCSVData] = useState([])
  const { readString } = usePapaParse()

  // to avoid duplicate chips from being duplicated, these are in a separate useEffect
  useEffect(() => {
    uploadedList.length || setFileName("")
    setFieldValue(fieldValue, _.uniq([...values[fieldValue], ...uploadedList]))
  }, [uploadedList])

  useEffect(() => {
    if (parsedCSVData.length) {
      const formattedCSVData: string[] = parsedCSVData?.reduce((acc: string[], val: string[]) => {
        return [...acc, ...val]
      }, [])
      uploadSuccessAction(formattedCSVData)
    } else {
      setFileName("")
    }
  }, [parsedCSVData])

  const handleUpload = ({ target }) => {
    const file = target.files[0]

    // if the file you are trying to upload has already been uploaded, do nothing
    if (file.name === fileName) return

    // logic to clear out old domains from a previously uploaded csv (if applicable) to avoid duplicates
    handleRemoveFile()

    const reader = new FileReader()
    reader.onload = (e) => {
      const stringifiedCSV = e.target.result as string
      // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
      //@toDo : Check if we can handle error
      readString(stringifiedCSV, {
        delimiter: ",",
        header: false,
        skipEmptyLines: true,
        worker: true,
        complete: (results) => {
          setParsedCSVData(results.data)
        },
      })
      setFileName(file.name)
    }
    reader.readAsText(file)
  }

  const handleRemoveFile = () => {
    setFileName("")
    setFieldValue(
      fieldValue,
      values[fieldValue].filter(
        (itemToCheckForRemoval) => !uploadedList.includes(itemToCheckForRemoval)
      )
    )
    dispatch(clearAction())
  }

  return (
    <div className={clsx(classes.csvUploadContainer, className)}>
      <input
        id={`uploadInput${type}`}
        accept=".csv"
        onClick={(e) => {
          // reset input field value, otherwise nothing will happen when
          // you try to re-add a previously removed csv
          const target = e.target as HTMLTextAreaElement
          target.value = null
        }}
        onChange={handleUpload}
        data-testid={"csv-upload-input"}
        type="file"
        hidden
        disabled={disabled}
      />
      <Tooltip title={toolTipText}>
        <InputLabel htmlFor={`uploadInput${type}`}>
          <Button
            data-testid={`csv-upload-button-${type}`}
            variant="contained"
            color="secondary"
            size="small"
            component="span"
            disabled={disabled}
          >
            <CloudUploadOutlinedIcon />
          </Button>
        </InputLabel>
      </Tooltip>
      <div className={classes.csvUploadChips} data-testid={"csv-upload-chips"}>
        {fileName && (
          <Tooltip title={fileName}>
            <Chip label={fileName} onDelete={handleRemoveFile} style={{ maxWidth: "150px" }} />
          </Tooltip>
        )}
      </div>
    </div>
  )
}

export default withStyles(styles)(CsvUpload)
