import { API, API_ROOT, SECTION } from "../../constants"
import { call, put, takeEvery } from "redux-saga/effects"
import { createDeal, createDealFailed, createDealSuccess } from "../actions/dealActions"
import { handleAxiosRequest, handleSagaError } from "../../helpers/sagaHelpers"

import { AxiosRequestConfig } from "axios"
import { CreateAndUpdateDealPayload } from "../types/dealTypes"
import { PayloadAction } from "@reduxjs/toolkit"
import { SagaIterator } from "redux-saga"
import { clearErrors } from "../actions/errorActions"

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* createDealSaga(action: PayloadAction<CreateAndUpdateDealPayload>) {
  const ajaxConfig: AxiosRequestConfig = {
    method: "POST",
    url: `${API_ROOT}${API.V1}${SECTION.TOOLS}/deals`,
    data: action.payload,
  }
  yield put(clearErrors())
  try {
    const response = yield call(handleAxiosRequest, ajaxConfig)
    yield put(
      createDealSuccess({
        ...response.data,
        isSaveDealsWithDataUpdateV2FFEnabled: action.payload.isSaveDealsWithDataUpdateV2FFEnabled,
      })
    )
  } catch (err) {
    yield handleSagaError(createDealFailed, err)
  }
}

export function* watchCreateDeal(): SagaIterator {
  yield takeEvery(createDeal, createDealSaga)
}
