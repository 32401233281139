import { createStyles } from "@material-ui/core/styles"

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default () => {
  return createStyles({
    logo: {
      width: 250,
      height: 100,
    },
  })
}
