import { call, put, takeEvery } from "redux-saga/effects"
import { API_ROOT, API, SECTION } from "../../constants"
import { AxiosRequestConfig } from "axios"
import {
  fetchSellersJson,
  fetchSellersJsonFailed,
  fetchSellersJsonSuccess,
  setSellersJsonLoadingFlag,
} from "../actions/sellersJsonActions"
import { handleAxiosRequest, handleSagaError } from "../../helpers/sagaHelpers"
import { SagaIterator } from "redux-saga"

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* fetchSellersJsonSaga() {
  const ajaxConfig: AxiosRequestConfig = {
    method: "GET",
    url: `${API_ROOT}${API.V1}${SECTION.ADMIN}/publishers/sellers-json/fetch`,
  }
  yield put(setSellersJsonLoadingFlag(true))
  try {
    const response = yield call(handleAxiosRequest, ajaxConfig)
    yield put(fetchSellersJsonSuccess(response.data))
  } catch (err) {
    yield handleSagaError(fetchSellersJsonFailed, err)
  }
  yield put(setSellersJsonLoadingFlag(false))
}

export function* watchFetchSellersJson(): SagaIterator {
  yield takeEvery(fetchSellersJson, fetchSellersJsonSaga)
}
