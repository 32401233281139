import { API, API_ROOT, SECTION } from "../../constants"
import { call, put, takeEvery } from "redux-saga/effects"
import { handleAxiosRequest, handleSagaError } from "../../helpers/sagaHelpers"
import {
  updateUserLastLogin,
  updateUserLastLoginFailed,
  updateUserLastLoginSuccess,
} from "../actions/userActions"

import { AxiosRequestConfig } from "axios"
import { SagaIterator } from "redux-saga"

function* updateUserLastLoginSaga(action) {
  const ajaxConfig: AxiosRequestConfig = {
    method: "PUT",
    url: `${API_ROOT}${API.V1}${SECTION.ADMIN}/users/logged-in-at`,
    data: {
      user: action.payload,
    },
  }
  try {
    const response = yield call(handleAxiosRequest, ajaxConfig)
    yield put(updateUserLastLoginSuccess(response.data))
  } catch (err) {
    yield handleSagaError(updateUserLastLoginFailed, err)
  }
}

export function* watchUpdateUserLastLogin(): SagaIterator {
  yield takeEvery(updateUserLastLogin, updateUserLastLoginSaga)
}
