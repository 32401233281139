import { Theme, createStyles } from "@material-ui/core/styles"

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default (theme: Theme) => {
  return createStyles({
    sellersJsonSellerTypeField: {
      margin: theme.spacing(1),
      width: "25ch",
    },
    overrideDisable: {
      color: "rgba(0, 0, 0, 1)",
      cursor: "not-allowed !important",
    },
    sellersJsonCheckboxFields: {
      margin: theme.spacing(1),
    },
    "@keyframes hidingNameDomain": {
      "0%": {
        opacity: 1,
      },
      "100%": {
        opacity: 0,
        display: "none",
        position: "fixed",
      },
    },
    hide: {
      animation: "$hidingNameDomain 1s ease-in",
      animationFillMode: "forwards",
    },
  })
}
