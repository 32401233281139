import { Box, List, ListItem, ListSubheader, Typography } from "@material-ui/core"

import Can from "../../common/Can"
import Collapsible from "../Collapsible"
import { NavLink } from "react-router-dom"
import React from "react"
import clsx from "clsx"
import { lowerCaseAndHyphenate } from "../../../helpers/formatterHelper"
import styles from "./styles"
import { withStyles } from "@material-ui/core/styles"

const SidebarNav = ({ navBarEntries, classes, isSidebarExpanded }) => {
  return (
    <>
      {navBarEntries.map(({ subheader, listItems }) => {
        return (
          <Can
            key={subheader.title}
            perform={subheader.permissions}
            yes={
              <List
                key={subheader.title}
                subheader={
                  <ListSubheader disableGutters disableSticky className={classes.navListSubheader}>
                    <Typography variant="body1" component="span" title={subheader.title}>
                      <Box fontWeight="fontWeightBold">
                        {isSidebarExpanded
                          ? subheader.title
                          : subheader.title.substring(0, 3) + `...`}
                      </Box>
                    </Typography>
                  </ListSubheader>
                }
                data-testid={`${lowerCaseAndHyphenate(subheader.title)}-sidebar-subheader`}
              >
                {listItems.map((listItem) => {
                  return (
                    <Can
                      key={listItem.title}
                      perform={listItem.permission}
                      yes={
                        listItem.listItems?.length > 0 ? (
                          <Collapsible isSidebarExpanded={isSidebarExpanded} listItem={listItem} />
                        ) : (
                          <ListItem
                            button
                            className={classes.navListSubItem}
                            disableGutters
                            key={listItem.title}
                            component={NavLink}
                            to={
                              listItem.isOpenInNewTab ? { pathname: listItem.href } : listItem.href
                            }
                            target={listItem.isOpenInNewTab && "_blank"}
                            exact
                            activeClassName={classes.active}
                            data-testid={`${lowerCaseAndHyphenate(
                              listItem.title
                            )}-sidebar-nav-button`}
                            title={listItem.title}
                          >
                            <div className={classes.icon}>{listItem.icon}</div>
                            <span
                              className={clsx({
                                [classes.displayNone]: !isSidebarExpanded,
                              })}
                            >
                              {listItem.title}
                            </span>
                          </ListItem>
                        )
                      }
                    />
                  )
                })}
              </List>
            }
          />
        )
      })}
    </>
  )
}

export default withStyles(styles)(SidebarNav)
