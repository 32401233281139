import { InputAdornment, SvgIcon, TextField } from "@material-ui/core"
import React, { useEffect, useState } from "react"

import Checkbox from "@material-ui/core/Checkbox"
import Paper from "@material-ui/core/Paper"
import { Search as SearchIcon } from "react-feather"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import _ from "lodash"
import styles from "./styles"
import { withStyles } from "@material-ui/core/styles"

function applyFilters(listItems, query) {
  return listItems.filter((listItem) => {
    let matches = true

    if (query) {
      const properties = ["name", "description"]
      let containsQuery = false

      properties.forEach((property) => {
        const listItemProperty = listItem[property]
        if (listItemProperty?.toLowerCase().includes(query.toLowerCase())) {
          containsQuery = true
        }
      })

      if (!containsQuery) {
        matches = false
      }
    }

    return matches
  })
}

const EntityAssignmentTable = ({
  classes,
  label,
  testId,
  listItems,
  checkedItems,
  onChange,
  limit = null,
  disabled = false,
}) => {
  const [checkedValues, setCheckedValues] = useState(checkedItems)
  const [query, setQuery] = useState("")

  const sortedListItems = applyFilters(listItems, query).sort((a, b) => {
    return a.name.localeCompare(b.name)
  })

  useEffect(() => {
    setCheckedValues(checkedItems)
  })

  const handleQueryChange = (event) => {
    setQuery(event.target.value)
  }

  const handleToggle = (value) => () => {
    if (disabled) return
    const currentIndex = _.findIndex(checkedValues, { id: value.id })
    const newCheckedValues = [...checkedValues]

    if (!limit) {
      if (currentIndex === -1) {
        newCheckedValues.push({ ...value })
      } else {
        newCheckedValues.splice(currentIndex, 1)
      }
    } else {
      if (currentIndex === -1) {
        if (newCheckedValues.length < limit) {
          newCheckedValues.push({ id: value.id })
        }
      } else {
        newCheckedValues.splice(currentIndex, 1)
      }
    }

    setCheckedValues(newCheckedValues)
    onChange(newCheckedValues)
  }

  return (
    <>
      <TableContainer component={Paper}>
        {!disabled && (
          <TextField
            size="small"
            className={classes.queryField}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon fontSize="small" color="action">
                    <SearchIcon />
                  </SvgIcon>
                </InputAdornment>
              ),
            }}
            onChange={handleQueryChange}
            placeholder={`Search ${label}`}
            value={query}
            variant="outlined"
            data-testid={"entity-list-search-input"}
          />
        )}
        <Table
          className={classes.table}
          data-testid={testId}
          size="small"
          aria-label={`${label} table`}
        >
          <TableHead>
            <TableRow>
              <TableCell style={{ width: "50%" }}>
                {label}
                {limit && ` (limit ${limit})`}
              </TableCell>
              <TableCell align="left" style={{ width: "10%" }}>
                Enabled/Disabled
              </TableCell>
              <TableCell
                align="left"
                style={{
                  width: "40%",
                  maxWidth: "500px",
                }}
              >
                Description
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedListItems.map((item) => (
              <TableRow key={item.name}>
                <TableCell component="th" scope="row" style={{ width: "50%" }}>
                  {item.name}
                </TableCell>
                <TableCell align="left" onClick={handleToggle(item)} style={{ minWidth: "10%" }}>
                  <Checkbox
                    role="checkbox"
                    data-testid={`${testId}-${item.id}`}
                    checked={!!_.find(checkedValues, { id: item.id })}
                    disabled={disabled}
                  />
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  style={{ maxWidth: "500px", wordWrap: "break-word" }}
                >
                  {item?.description || ""}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default withStyles(styles)(EntityAssignmentTable)
