import {
  FetchAndExportPublishersPayload,
  FetchPublishersSuccessResponse,
  Publisher,
} from "../types/publisherTypes"

import { FetchListPayload } from "../types/sharedTypes"
import { createAction } from "@reduxjs/toolkit"

/* ===================================== Publisher List actions ====================================== */
export const fetchPublishers = createAction<FetchListPayload>("Effects_FETCH_PUBLISHERS")
export const fetchPublishersSuccess = createAction<FetchPublishersSuccessResponse>(
  "FETCH_PUBLISHERS_SUCCESS"
)
export const setPublishersLoadingFlag = createAction<boolean>("SET_PUBLISHERS_LOADING_FLAG")
export const fetchPublishersFailed = createAction<string>("FETCH_PUBLISHERS_FAILED")

export const fetchAndExportPublishers = createAction<FetchAndExportPublishersPayload>(
  "Effects_FETCH_AND_EXPORT_PUBLISHERS"
)
export const fetchAndExportPublishersSuccess = createAction<string>(
  "FETCH_AND_EXPORT_PUBLISHERS_SUCCESS"
)
export const fetchAndExportPublishersFailed = createAction<string>(
  "FETCH_AND_EXPORT_PUBLISHERS_FAILED"
)
export const setLoadingPublishersForExportFlag = createAction<boolean>(
  "SET_LOADING_PUBLISHERS_FOR_EXPORT_PUBLISHERS_FLAG"
)

export const clearPublishers = createAction("CLEAR_PUBLISHERS")
export const closeSavePublisherSuccess = createAction("CLOSE_SAVE_PUBLISHER_SUCCESS")
export const closeUpdatePublisherSellersJsonError = createAction(
  "CLOSE_UPDATE_PUBLISHER_SELLERS_JSON_ERROR"
)

/* ===================================== Publisher Form actions ====================================== */
// Fetch Single Publisher
export const fetchPublisherById = createAction<number>("Effects_FETCH_PUBLISHER_BY_ID")
export const fetchPublisherByIdSuccess = createAction<Publisher>("FETCH_PUBLISHER_BY_ID_SUCCESS")
export const fetchPublisherByIdFailed = createAction<string>("FETCH_PUBLISHER_BY_ID_FAILED")

// Create
export const createPublisher = createAction<Publisher>("Effects_CREATE_PUBLISHER")
export const createPublisherSuccess = createAction<Publisher>("CREATE_PUBLISHER_SUCCESS")
export const createPublisherFailed = createAction<string>("CREATE_PUBLISHER_FAILED")

// Update
export const updatePublisher = createAction<Publisher>("Effects_UPDATE_PUBLISHER")
export const updatePublisherSuccess = createAction<Publisher>("UPDATE_PUBLISHER_SUCCESS")
export const updatePublisherFailed = createAction<string>("UPDATE_PUBLISHER_FAILED")
export const updatePublisherSellersJsonFailed = createAction<string>(
  "UPDATE_PUBLISHER_SELLERS_JSON_FAILED"
)

// Shared form actions
export const setPublisherFormLoadingFlag = createAction<boolean>("SET_PUBLISHER_FORM_LOADING_FLAG")
export const setPublisherFormSubmittingFlag = createAction<boolean>(
  "SET_PUBLISHER_FORM_SUBMITTING_FLAG"
)
export const closeMigratePublisherByAppNexusIdSuccessMessage = createAction(
  "CLOSE_MIGRATE_PUBLISHER_BY_APP_NEXUS_ID_SUCCESS"
)
export const resetPublisherFormState = createAction("RESET_PUBLISHER_FORM_STATE")
