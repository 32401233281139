import { createStyles, Theme } from "@material-ui/core/styles"

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default (theme: Theme) => {
  const logoWidth = 96
  const logoHeight = 64

  return createStyles({
    cadentLogo: {
      width: logoWidth,
      height: logoHeight,
    },
    root: {
      display: "flex",
      width: "100%",
      height: 64,
      boxShadow: "none",
      backgroundColor: theme.palette.black,
    },
    signOutButton: {
      marginLeft: theme.spacing(1),
    },
    toolbar: {
      height: 64,
    },
  })
}
