import {
  fetchDomainByIdSuccess,
  resetDomainFormState,
  setDomainFormLoadingFlag,
  setDomainFormSubmittingFlag,
} from "../actions/domainActions"

import { Domain } from "../types/domainTypes"
import { createReducer } from "@reduxjs/toolkit"

interface InitialDomainFormState {
  selectedDomain: Domain
  isLoading: boolean
  isSubmitting: boolean
}

export const initialDomainState = {
  id: null,
  name: "",
  createdAt: "",
  updatedAt: "",
}

export const initialState: InitialDomainFormState = {
  selectedDomain: initialDomainState,
  isLoading: false,
  isSubmitting: false,
}

export default createReducer(initialState, (builder) =>
  builder
    /*================================ Domain Form action handlers ================================*/
    .addCase(setDomainFormLoadingFlag, (state, action) => {
      state.isLoading = action.payload
    })
    .addCase(setDomainFormSubmittingFlag, (state, action) => {
      state.isSubmitting = action.payload
    })
    .addCase(fetchDomainByIdSuccess, (state, action) => {
      state.selectedDomain = action.payload
    })
    .addCase(resetDomainFormState, () => initialState)
)
