import { createStyles } from "@material-ui/core/styles"
import { DEFAULT_CSS_STYLES } from "../../../constants"

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default () => {
  return createStyles({
    multiSelectTreeStyle: {
      lineHeight: 1.5,
      "& .dropdown": {
        "& .dropdown-content": {
          width: "100%",
          "& .search": {
            padding: "10px",
          },
          "& .no-matches": {
            padding: "10px",
            display: "block",
          },
        },
      },
      "& .toggle": {
        font: "normal normal normal 18px/1 'Material Icons'",
        color: "#555",
        whiteSpace: "pre",
        marginRight: "4px",
      },
      "& .collapsed::after": {
        cursor: "pointer",
        content: "/e5cf",
        verticalAlign: "middle",
      },
      "& .expanded::after": {
        cursor: "pointer",
        content: "/e5cf",
        verticalAlign: "middle",
      },
      "& .infinite-scroll-component": {
        "& .checkbox-item": {
          position: "relative",
          width: "18px",
          borderRadius: "3px",
          height: "18px",
          marginRight: "0.75rem",
          cursor: "pointer",
          outline: 0,
          verticalAlign: "baseline",
          "&::before": {
            content: "''",
            position: "absolute",
            width: "15px",
            height: "15px",
            border: "2px solid #aaa",
            backgroundColor: "#fff",
            borderRadius: "3px",
            transition: "all 0.1s ease-in-out",
          },
          "&::after": {
            left: 0,
            top: 0,
            content: "''",
            position: "absolute",
            width: "15px",
            height: "15px",
            border: "2px solid #aaa",
            borderRadius: "3px",
            transform: "rotate(0deg)",
            transition: "all 0.2s ease-in-out",
          },
        },
        "& .checkbox-item:checked": {
          "&::before": {
            border: "2px solid #303030",
            backgroundColor: "#303030",
            transition: "all 0.1s ease-in-out",
          },
          "&::after": {
            zIndex: 1,
            height: "6px",
            width: "11px",
            top: "3px",
            left: "3px",
            border: "2px solid #303030",
            borderTopStyle: "none",
            borderRightStyle: "none",
            borderColor: "#fff",
            transform: "rotate(-045deg)",
            "-webkit-transform": "rotate(-045deg)",
            transition: "all 0.2s ease-in-out",
          },
        },
      },
      "& .tag": {
        backgroundColor: "#f4f4f4",
        border: "1px solid #e9e9e9",
        padding: "2px 0 2px 2px",
        borderRadius: "2px",
        display: "inline-block",
        "&:focus-within": {
          backgroundColor: "#e9e9e9",
          borderColor: "#a0a0a0",
        },
      },
      "& .tag-remove": {
        color: "#a0a0a0",
        fontSize: "75%",
        lineHeight: "100%",
        cursor: "pointer",
        backgroundColor: "transparent",
        border: "none",
        outline: "none",
        "&:focus": {
          color: "#3c3c3c",
        },
        "&.disabled": {
          cursor: "not-allowed",
        },
        "&.readOnly": {
          cursor: "not-allowed",
        },
      },
      "& .node": {
        listStyle: "none",
        whiteSpace: "nowrap",
        padding: "4px",
        "&> label": {
          cursor: "pointer",
          marginLeft: "2px",
        },
        "&.leaf": {
          "&.collapsed": {
            display: "none",
          },
        },
        "&.disabled > *": {
          color: "gray",
          cursor: "not-allowed",
        },
        "&.focused": {
          backgroundColor: "#f4f4f4",
        },
        "&.match-in-children.hide .node-label": {
          opacity: "0.5",
        },
      },
      "& .tag-list": {
        display: "inline-block",
        padding: 0,
        margin: 0,
        width: "97%",
      },
      "& .tag-item": {
        display: "inline-block",
        margin: "4px",
        "&:last-child": {
          marginRight: "4px",
          display: "block",
        },
        "& .search": {
          border: "none",
          borderBottom: "1px solid #ccc",
          outline: "none",
        },
      },
      "& .checkbox-item": {
        verticalAlign: "middle",
        margin: "0 4px 0 0",
      },
      "& .radio-item": {
        verticalAlign: "middle",
        margin: "0 4px 0 0",
      },
      "& .checkbox-item.simple-select": {
        display: "none",
      },
      "& .radio-item.simple-select": {
        display: "none",
      },
      "& .hide:not(.match-in-children)": {
        display: "none",
      },
      "&.react-dropdown-tree-select .dropdown": {
        position: "relative",
        display: "table",
        width: "100%",
      },
      "&.react-dropdown-tree-select .dropdown .dropdown-trigger": {
        padding: "4px",
        lineHeight: "20px",
        maxHeight: "200px",
        display: "inline-block",
        overflow: "auto",
        border: "1px solid #b9b9b9",
        width: "100%",
      },
      "&.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow": {
        cursor: "pointer",
        "&.bottom:after": {
          verticalAlign: "middle",
          color: "#3c3c3c",
          marginRight: "2px",
        },
        "&.top:after": {
          verticalAlign: "middle",
          color: "#3c3c3c",
          marginRight: "2px",
        },
      },
      "&.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.disabled": {
        cursor: "not-allowed",
      },
      "&.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.disabled.bottom:after": {
        color: "#b9b9b9",
      },
      "&.react-dropdown-tree-select .dropdown .dropdown-content": {
        position: "absolute",
        padding: "4px",
        zIndex: 1,
        background: "#fff",
        borderTop: "1px solid rgba(0, 0, 0, 0.05)",
        "-webkit-box-shadow": "0 5px 8px rgba(0, 0, 0, 0.15)",
        boxShadow: "0 5px 8px rgba(0, 0, 0, 0.15)",
      },
      "&.react-dropdown-tree-select .dropdown .dropdown-content .search": {
        width: "100%",
        border: "none",
        borderBottom: "1px solid #ccc",
        outline: "none",
      },
      "&.react-dropdown-tree-select .dropdown .dropdown-content ul": {
        margin: 0,
        padding: 0,
      },
      "&.react-dropdown-tree-select": {
        fontFamily: DEFAULT_CSS_STYLES.FONT_FAMILY,
        fontSize: "14px",
      },
      "& .dropdown .tag-item .tag-remove": {
        width: "30px",
        color: "red",
        fontWeight: "bold",
        textTransform: "uppercase",
      },
      "& .dropdown-content .infinite-scroll-component": {
        maxHeight: "400px",
        width: "400px",
        maxWidth: "800px",
        overflow: "inherit !important",
      },
      "& .node.disabled > * .checkbox-item": {
        display: "none",
      },
    },
  })
}
