import {
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core"

import React from "react"
import { SELLER_TYPE } from "../../../redux/types/sellersJsonTypes"
import clsx from "clsx"
import styles from "./styles"
import { withStyles } from "@material-ui/core/styles"

const SellersJson = ({
  classes,
  touched,
  handleBlur,
  handleChange,
  errors,
  isInfo,
  values,
  setFieldValue,
  parentTestId,
}) => {
  const sellersJsonTypeOptions = Object.keys(SELLER_TYPE).map((key) => SELLER_TYPE[key])

  return (
    <>
      <Grid item md={12} xs={12}>
        <Typography variant="h4" component="h4">
          Sellers.JSON settings
        </Typography>
      </Grid>

      {/* Name */}
      <Grid
        item
        md={6}
        xs={12}
        className={clsx(values.sellersJsonEntry?.isConfidential && classes.hide)}
        // data-testId={`sellers-json-name-input-grid-wrapper`}
      >
        <TextField
          error={!!(touched.sellersJsonEntry?.name && errors.sellersJsonEntry?.name)}
          fullWidth
          helperText={touched.sellersJsonEntry?.name && errors.sellersJsonEntry?.name}
          label={<span className={clsx(isInfo && classes.overrideDisable)}>Name</span>}
          name="sellersJsonEntry.name"
          onBlur={handleBlur}
          onChange={handleChange}
          required
          value={values.sellersJsonEntry?.name}
          variant="outlined"
          inputProps={{ "data-testid": `${parentTestId}-sellers-json-name-input` }}
          InputProps={{
            classes: {
              disabled: classes.overrideDisable,
            },
          }}
          disabled={isInfo}
        />
      </Grid>

      {/* Domain */}
      <Grid
        item
        md={6}
        xs={12}
        className={clsx(values.sellersJsonEntry?.isConfidential && classes.hide)}
      >
        <TextField
          error={!!(touched.sellersJsonEntry?.domain && errors.sellersJsonEntry?.domain)}
          fullWidth
          helperText={touched.sellersJsonEntry?.domain && errors.sellersJsonEntry?.domain}
          label={<span className={clsx(isInfo && classes.overrideDisable)}>Domain</span>}
          name="sellersJsonEntry.domain"
          onBlur={handleBlur}
          onChange={handleChange}
          className={classes.hidingNameDomain}
          required
          value={values.sellersJsonEntry?.domain}
          variant="outlined"
          inputProps={{ "data-testid": `${parentTestId}-sellers-json-domain-input` }}
          InputProps={{
            classes: {
              disabled: classes.overrideDisable,
            },
          }}
          disabled={isInfo}
        />
      </Grid>

      {/* Comment */}
      <Grid item md={6} xs={12}>
        <TextField
          error={!!(touched.sellersJsonEntry?.comment && errors.sellersJsonEntry?.comment)}
          fullWidth
          helperText={touched.sellersJsonEntry?.comment && errors.sellersJsonEntry?.comment}
          label={<span className={clsx(isInfo && classes.overrideDisable)}>Comment</span>}
          name="sellersJsonEntry.comment"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.sellersJsonEntry?.comment}
          variant="outlined"
          inputProps={{ "data-testid": `${parentTestId}-sellers-json-comment-input` }}
          InputProps={{
            classes: {
              disabled: classes.overrideDisable,
            },
          }}
          disabled={isInfo}
        />
      </Grid>

      {/* Type */}
      <Grid item md={2} xs={12}>
        <TextField
          select
          label={<Typography className={clsx(isInfo && classes.overrideDisable)}>Type</Typography>}
          name="sellersJsonEntry.sellerType"
          value={values.sellersJsonEntry?.sellerType}
          onChange={(e) => {
            setFieldValue("sellersJsonEntry.sellerType", e.target.value)
          }}
          className={classes.sellersJsonSellerTypeField}
          inputProps={{
            "data-testid": `${parentTestId}-sellers-json-seller-type-input`,
          }}
          InputProps={{
            classes: {
              disabled: classes.overrideDisable,
            },
          }}
          disabled={isInfo}
        >
          {sellersJsonTypeOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      {/* Passthrough */}
      <Grid item md={2} xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={values.sellersJsonEntry?.isPassthrough}
              onChange={(event) => {
                setFieldValue("sellersJsonEntry.isPassthrough", event.target.checked)
              }}
              data-testid={`${parentTestId}-sellers-json-is-passthrough-input`}
              disabled={isInfo}
              className={clsx(isInfo && classes.overrideDisable, classes.sellersJsonCheckboxFields)}
            />
          }
          name="sellersJsonEntry.isPassthrough"
          className={clsx(isInfo && classes.overrideDisable)}
          label={
            <Typography className={clsx(isInfo && classes.overrideDisable)}>Passthrough</Typography>
          }
        />
      </Grid>

      {/* Confidential */}
      <Grid item md={2} xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={values.sellersJsonEntry?.isConfidential}
              onChange={(event) => {
                setFieldValue("sellersJsonEntry.isConfidential", event.target.checked)
              }}
              data-testid={`${parentTestId}-sellers-json-is-confidential-input`}
              disabled={isInfo}
              className={clsx(isInfo && classes.overrideDisable, classes.sellersJsonCheckboxFields)}
            />
          }
          name="sellersJsonEntry.isConfidential"
          className={clsx(isInfo && classes.overrideDisable)}
          label={
            <Typography className={clsx(isInfo && classes.overrideDisable)}>
              Confidential
            </Typography>
          }
        />
      </Grid>
    </>
  )
}

export default withStyles(styles)(SellersJson)
