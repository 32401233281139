import {
  clearAppNexusPublisher,
  fetchPublisherByAppNexusIdSuccess,
  migratePublisherByAppNexusIdSuccess,
  resetAppNexusMigrationFormState,
  setAppNexusMigrationFormFindingPublisherFlag,
  setAppNexusMigrationFormFindPublisherButtonClickedFlag,
  setAppNexusMigrationFormMigratingPublisherFlag,
  setPublisherAppNexusId,
} from "../actions/appNexusMigrationActions"

import { MigrateAppNexusPublisher } from "../types/publisherTypes"
import { createReducer } from "@reduxjs/toolkit"

export interface InitialAppNexusMigrationState {
  // Partial because this form only needs three fields on Publisher, name, appNexusId, and isRTB (comes from firstTag)
  publisher: Partial<MigrateAppNexusPublisher>
  isFindPublisherButtonClicked: boolean
  isFindingPublisher: boolean
  isMigratingPublisherFlag: boolean
}

export const initialAppNexusMigrationFormState = {
  name: "",
  appNexusId: null,
  // firstTag needed for isRTB value
  firstTag: null,
}

export const initialState: InitialAppNexusMigrationState = {
  publisher: initialAppNexusMigrationFormState,
  isFindPublisherButtonClicked: false,
  isFindingPublisher: false,
  isMigratingPublisherFlag: false,
}

export default createReducer(initialState, (builder) =>
  builder
    /*============================== Publisher form action handlers ==============================*/
    .addCase(setAppNexusMigrationFormFindPublisherButtonClickedFlag, (state, action) => {
      state.isFindPublisherButtonClicked = action.payload
    })
    .addCase(setAppNexusMigrationFormFindingPublisherFlag, (state, action) => {
      state.isFindingPublisher = action.payload
    })
    .addCase(setAppNexusMigrationFormMigratingPublisherFlag, (state, action) => {
      state.isMigratingPublisherFlag = action.payload
    })
    .addCase(fetchPublisherByAppNexusIdSuccess, (state, action) => {
      state.publisher = action.payload
    })
    .addCase(migratePublisherByAppNexusIdSuccess, (state, action) => {
      state.publisher = action.payload
    })
    //This is a unique case where we need to show the id for the failed fetch in the form
    .addCase(setPublisherAppNexusId, (state, action) => {
      state.publisher.appNexusId = action.payload
    })
    .addCase(clearAppNexusPublisher, (state) => {
      state.publisher = initialState.publisher
    })
    .addCase(resetAppNexusMigrationFormState, () => initialState)
)
