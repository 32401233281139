import { createAction } from "@reduxjs/toolkit"
import {
  FetchSegmentsSuccessResponse,
  SegmentUploadPayload,
  SegmentUploadSuccessResponse,
  SegmentTreeNodesWithVendorId,
} from "../types/segmentTypes"
import { FetchListPayload } from "../types/sharedTypes"

/* ===================================== Segment actions ===================================== */
export const fetchSegments = createAction<FetchListPayload>("Effects_FETCH_SEGMENTS")
export const fetchSegmentsSuccess =
  createAction<FetchSegmentsSuccessResponse>("FETCH_SEGMENTS_SUCCESS")
export const fetchSegmentsFailed = createAction<string>("FETCH_SEGMENTS_FAILED")
export const setSegmentsLoadingFlag = createAction<boolean>("SET_SEGMENTS_LOADING_FLAG")

/* ===================================== Segment Upload actions ===================================== */
export const uploadSegment = createAction<SegmentUploadPayload>("Effects_UPLOAD_SEGMENT")
export const uploadSegmentSuccess =
  createAction<SegmentUploadSuccessResponse>("UPLOAD_SEGMENT_SUCCESS")
export const uploadSegmentFailed = createAction<string>("UPLOAD_SEGMENT_FAILED")

export const displaySegmentCSVValidationErrors = createAction<string[]>(
  "DISPLAY_SEGMENT_CSV_VALIDATION_ERRORS"
)

export const initSegmentCSVUpload = createAction("INIT_SEGMENT_CSV_UPLOAD")
export const clearSegmentUploadSuccessMessage = createAction("CLOSE_SEGMENT_UPLOAD_SUCCESS_MESSAGE")

export const setSegmentTree = createAction<SegmentTreeNodesWithVendorId>("SET_SEGMENT_TREE")
