import { createAction } from "@reduxjs/toolkit"
import {
  CreateUserPayload,
  CurrentUser,
  FetchUserByIdSuccessResponse,
  FetchUsersSuccessResponse,
  UpdateUserLastLoginPayload,
  UpdateUserPayload,
  User,
} from "../types/userTypes"
import { FetchListPayload } from "../types/sharedTypes"

/* ===================================== User List actions ====================================== */
export const fetchUsers = createAction<FetchListPayload>("Effects_FETCH_USERS")
export const fetchUsersSuccess = createAction<FetchUsersSuccessResponse>("FETCH_USER_SUCCESS")
export const fetchUsersFailed = createAction<string>("FETCH_USER_FAILED")

export const clearUsers = createAction("CLEAR_USERS")
export const closeSaveUserSuccess = createAction("CLOSE_SAVE_USER_SUCCESS")

/* ===================================== User Form actions ====================================== */
export const createUser = createAction<CreateUserPayload>("Effects_CREATE_USER")
export const createUserSuccess = createAction<User>("CREATE_USER_SUCCESS")
export const createUserFailed = createAction<string>("CREATE_USER_FAILED")

export const updateUser = createAction<UpdateUserPayload>("Effects_UPDATE_USER")
export const updateUserLastLogin = createAction<UpdateUserLastLoginPayload>(
  "Effects_UPDATE_USER_LAST_LOGIN"
)
export const updateUserLastLoginSuccess = createAction<UpdateUserLastLoginPayload>(
  "UPDATE_USER_LAST_LOGIN_SUCCESS"
)

export const updateUserSuccess = createAction<User>("UPDATE_USER_SUCCESS")
export const updateUserFailed = createAction<string>("UPDATE_USER_FAILED")
export const updateUserLastLoginFailed = createAction<string>("UPDATE_USER_LAST_LOGIN_FAILED")

export const updateUserActiveCompany = createAction<number>("Effects_UPDATE_USER_ACTIVE_COMPANY")
export const updateUserActiveCompanySuccess = createAction("UPDATE_USER_ACTIVE_COMPANY_SUCCESS")
export const updateUserActiveCompanyFailed = createAction<string>(
  "UPDATE_USER_ACTIVE_COMPANY_FAILED"
)

export const resetUserFormState = createAction("RESET_USER_FORM_STATE")

/* ==================================== Shared User actions ===================================== */
export const fetchUserById = createAction<number>("Effects_FETCH_USER_BY_ID")
export const fetchUserByIdSuccess = createAction<FetchUserByIdSuccessResponse>(
  "FETCH_USER_BY_ID_SUCCESS"
)
export const fetchUserByIdFailed = createAction<string>("FETCH_USER_BY_ID_FAILED")

/* ===================================== Current User actions ===================================== */
export const fetchCurrentUser = createAction("Effects_FETCH_CURRENT_USER")
export const fetchCurrentUserSuccess = createAction<CurrentUser>("FETCH_CURRENT_USER_SUCCESS")
export const fetchCurrentUserFailed = createAction<string>("FETCH_CURRENT_USER_FAILED")
