import { createReducer } from "@reduxjs/toolkit"
import { HOMEPAGE_MESSAGE } from "../../constants"
import {
  fetchHomePageSuccess,
  fetchHomePageFailed,
  updateHomePageSuccess,
  resetHomePageState,
  setHomePageLoading,
} from "../actions/homePageActions"

import { HomePage } from "../types/homePageTypes"

interface InitialHomePageState {
  homePage: HomePage
  isLoadingHomePage?: boolean
  savedHomePageMessage?: string
}

export const initialState: InitialHomePageState = {
  homePage: {
    name: "",
    value: "",
  },
  isLoadingHomePage: true,
  savedHomePageMessage: "",
}

export default createReducer(initialState, (builder) =>
  builder
    /*============================== Fetch Home Page action handlers ==============================*/

    .addCase(fetchHomePageSuccess, (state, action) => {
      state.homePage = action.payload
    })
    .addCase(fetchHomePageFailed, (state) => {
      state.homePage.value = HOMEPAGE_MESSAGE.DEFAULT
    })
    .addCase(setHomePageLoading, (state, action) => {
      state.isLoadingHomePage = action.payload
    })

    /*============================== Edit HomePage form action handlers ==============================*/

    .addCase(updateHomePageSuccess, (state, action) => {
      state.homePage = action.payload
      state.savedHomePageMessage = "Successfully saved Home Page"
    })
    .addCase(resetHomePageState, () => initialState)
)
