import React from "react"
import { withStyles } from "@material-ui/core/styles"
import styles from "./styles"
import DropdownTreeSelect from "react-dropdown-tree-select"
import _ from "lodash"
import produce from "immer"

const DropdownTreeSelectContainer = ({
  data,
  // clearSearchOnChange,
  keepTreeOnSearch,
  keepChildrenOnSearch,
  // keepOpenOnSelect,
  texts,
  // showDropdown,
  //className,
  classes,
  onChange,
  // onAction,
  // onNodeToggle,
  // onFocus,
  // onBlur,
  mode,
  // showPartiallySelected,
  disabled,
  // readOnly,
  // id,
  // searchPredicate,
  // inlineSearchInput,
  selectedSegmentGroup,
}) => {
  const segmentTreeCopy = produce(data, (segmentTreeCopy) => {
    if (data.length) {
      const segmentsToSetChecked = selectedSegmentGroup?.segments || []

      const recurse = (segmentTree) => {
        if (!segmentTree.length) return

        segmentTree.forEach((segment) => {
          if (segmentsToSetChecked.includes(segment?.externalId)) {
            segment.checked = true
          }
          recurse(segment.children)
        })
      }
      recurse(segmentTreeCopy)
    }
  })

  return (
    <DropdownTreeSelect
      data={segmentTreeCopy}
      onChange={onChange}
      className={classes.multiSelectTreeStyle}
      keepTreeOnSearch={keepTreeOnSearch}
      keepChildrenOnSearch={keepChildrenOnSearch}
      inlineSearchInput
      texts={texts}
      mode={mode}
      disabled={disabled}
    />
  )
}

export default withStyles(styles)(
  React.memo(
    DropdownTreeSelectContainer,
    (props, nextProps) =>
      _.isEqual(props.data, nextProps.data) &&
      _.isEqual(props.selectedSegmentGroup?.id, nextProps.selectedSegmentGroup?.id) &&
      _.isEqual(props.disabled, nextProps.disabled)
  )
)
