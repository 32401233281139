import { TablePaginationActionsProps } from "@material-ui/core/TablePagination/TablePaginationActions"

export const generateTablePaginationActionHandlers = (props: TablePaginationActionsProps) => {
  const { count, page, rowsPerPage, onPageChange } = props

  const tenPercentJump = Math.ceil(count / rowsPerPage / 10)

  return {
    handleJumpBackButtonClick(event: React.MouseEvent<HTMLButtonElement>) {
      onPageChange(event, Math.max(0, page - tenPercentJump))
    },
    handleBackButtonClick(event: React.MouseEvent<HTMLButtonElement>) {
      onPageChange(event, page - 1)
    },
    handleForwardButtonClick(event: React.MouseEvent<HTMLButtonElement>) {
      onPageChange(event, page + 1)
    },
    handleJumpForwardButtonClick(event: React.MouseEvent<HTMLButtonElement>) {
      onPageChange(event, Math.min(page + tenPercentJump, Math.ceil(count / rowsPerPage) - 1))
    },
  }
}
