import { createReducer } from "@reduxjs/toolkit"
import {
  clearRoles,
  closeSaveRoleSuccess,
  createRoleSuccess,
  fetchRoles,
  fetchRolesFailed,
  fetchRolesSuccess,
  updateRoleSuccess,
} from "../actions/roleActions"
import { Role } from "../types/roleTypes"
import { PaginatedComponentState } from "../types/sharedTypes"

interface InitialRoleState extends PaginatedComponentState {
  roles: Role[]
  isLoading: boolean
  roleSuccessMessage: string
}

const initialState: InitialRoleState = {
  roles: [],
  isLoading: true,
  roleSuccessMessage: "",
  pager: {
    totalCount: 0,
    page: 0,
  },
}

export default createReducer(initialState, (builder) =>
  builder
    /*================================ Role List action handlers ================================*/
    .addCase(fetchRoles, (state) => {
      state.isLoading = true
    })
    .addCase(fetchRolesSuccess, (state, action) => {
      state.isLoading = false
      state.roles = action.payload.roles
      state.pager = action.payload.pager
    })
    .addCase(fetchRolesFailed, (state) => {
      state.isLoading = false
    })
    .addCase(clearRoles, () => initialState)

    /*================================ Role Form action handlers ================================*/
    .addCase(createRoleSuccess, (state, action) => {
      state.roleSuccessMessage = `Successfully created role: ${action.payload.name} (${action.payload.id})`
    })
    .addCase(updateRoleSuccess, (state, action) => {
      state.roleSuccessMessage = `Successfully updated role: ${action.payload.name} (${action.payload.id})`
    })
    .addCase(closeSaveRoleSuccess, (state) => {
      state.roleSuccessMessage = initialState.roleSuccessMessage
    })
)
