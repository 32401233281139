import { API, API_ROOT, SECTION } from "../../constants"
import { call, put, takeEvery } from "redux-saga/effects"
import { fetchUserById, fetchUserByIdFailed, fetchUserByIdSuccess } from "../actions/userActions"
import { handleAxiosRequest, handleSagaError } from "../../helpers/sagaHelpers"

import { AxiosRequestConfig } from "axios"
import { PayloadAction } from "@reduxjs/toolkit"
import { SagaIterator } from "redux-saga"
import _ from "lodash"
import { clearErrors } from "../actions/errorActions"
import { forwardTo } from "../../helpers/historyHelper"

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* fetchUserByIdSaga(action: PayloadAction<number>) {
  const ajaxConfig: AxiosRequestConfig = {
    method: "GET",
    url: `${API_ROOT}${API.V1}${SECTION.ADMIN}/users/${action.payload}`,
  }
  yield put(clearErrors())
  try {
    const response = yield call(handleAxiosRequest, ajaxConfig)
    yield put(fetchUserByIdSuccess(response.data))
  } catch (err) {
    if (_.get(err, "response.data.type") === "not_found" || "unknown") {
      yield call(forwardTo, "/admin/users")
    }
    yield handleSagaError(fetchUserByIdFailed, err)
  }
}

export function* watchFetchUserById(): SagaIterator {
  yield takeEvery(fetchUserById, fetchUserByIdSaga)
}
