import { createAction } from "@reduxjs/toolkit"
import { FetchListPayload } from "../types/sharedTypes"
import {
  FetchDealRequestsSuccessResponse,
  UpdateDealRequestsPayload,
} from "../types/dealRequestTypes"

/* ===================================== Deal Request List actions ===================================== */
export const fetchDealRequests = createAction<FetchListPayload>("Effects_FETCH_DEAL_REQUESTS")
export const fetchDealRequestsSuccess = createAction<FetchDealRequestsSuccessResponse>(
  "FETCH_DEAL_REQUESTS_SUCCESS"
)
export const fetchDealRequestsFailed = createAction<string>("FETCH_DEAL_REQUESTS_FAILED")
export const setDealRequestIsLoading = createAction<boolean>("SET_DEAL_REQUEST_IS_LOADING")

//UPDATE Deal Requests status
export const updateDealRequests = createAction<UpdateDealRequestsPayload>("UPDATE_DEAL_REQUESTS")
export const updatDealRequestsSuccess = createAction<number[]>("UPDATE_DEAL_REQUESTS_SUCCESS")
export const updatDealRequestsFailed = createAction<string>("UPDATE_DEAL_REQUESTS_FAILED")
export const closeUpdateDealRequestsSuccess = createAction("CLOSE_UPDATE_DEAL_REQUESTS_SUCCESS")

export const clearDealRequests = createAction("CLEAR_DEAL_REQUESTS")
export const setReloadDealRequests = createAction<boolean>("SET_DEAL_REQUESTS_RELOAD")
