import { API, API_ROOT, SECTION } from "../../constants"
import { call, put, takeEvery } from "redux-saga/effects"
import {
  fetchExternalAudiences,
  fetchExternalAudiencesFailed,
  fetchExternalAudiencesSuccess,
  setExternalAudiencesLoading,
} from "../actions/externalAudienceActions"
import { handleAxiosRequest, handleSagaError } from "../../helpers/sagaHelpers"

import { AxiosRequestConfig } from "axios"
import { FetchListPayload } from "../types/sharedTypes"
import { PayloadAction } from "@reduxjs/toolkit"
import { SagaIterator } from "redux-saga"
import { clearErrors } from "../actions/errorActions"

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* fetchExternalAudiencesSaga(action: PayloadAction<FetchListPayload>) {
  const ajaxConfig: AxiosRequestConfig = {
    method: "POST",
    url: `${API_ROOT}${API.V1}${SECTION.TOOLS}/deals/external-audiences/find`,
    data: action.payload,
  }
  yield put(clearErrors())
  yield put(setExternalAudiencesLoading(true))
  try {
    const response = yield call(handleAxiosRequest, ajaxConfig)
    const page = parseInt(response.headers["x-pagination-page"], 10)
    const totalCount = parseInt(response.headers["x-pagination-total-count"], 10)
    yield put(
      fetchExternalAudiencesSuccess({
        externalAudiences: response.data,
        pager: {
          page,
          totalCount,
        },
      })
    )
  } catch (err) {
    yield handleSagaError(fetchExternalAudiencesFailed, err)
  }
  yield put(setExternalAudiencesLoading(false))
}

export function* watchFetchExternalAudiences(): SagaIterator {
  yield takeEvery(fetchExternalAudiences, fetchExternalAudiencesSaga)
}
