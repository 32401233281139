import { createStyles, Theme } from "@material-ui/core/styles"

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default (theme: Theme) => {
  return createStyles({
    content: {
      height: "100%",
      padding: 24,
    },
    root: {
      display: "flex",
      alignItems: "stretch",
      height: "100%",
    },
    hide: {
      display: "none",
    },
    wrapper: {
      flexGrow: 1,
      paddingTop: "64px",
      height: "100%",
      overflow: "auto",
      backgroundColor: theme.palette.background.default,
    },
    wrapperShift: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
  })
}
