import { FetchChangeLogSuccessResponse } from "../types/changeLogTypes"
import { FetchChangelogPayload } from "../types/changeLogTypes"
import { createAction } from "@reduxjs/toolkit"

export const fetchChangeLog = createAction<FetchChangelogPayload>("Effects_FETCH_CHANGE_LOG")
export const fetchChangeLogSuccess = createAction<FetchChangeLogSuccessResponse>(
  "FETCH_CHANGE_LOG_SUCCESS"
)
export const fetchChangeLogFailed = createAction<string>("FETCH_CHANGE_LOG_FAILED")

export const clearChangeLog = createAction("CLEAR_CHANGE_LOG")
