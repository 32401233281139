import {
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  CircularProgress,
  Input,
  InputAdornment,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  withStyles,
} from "@material-ui/core"
import { BreadCrumb, HeaderInfo } from "../../../../../redux/types/newHeaderTypes"
import {
  DEAL_REQUEST_STATUS,
  DEAL_REQUEST_STATUS_OPTIONS,
  PERMISSIONS,
} from "../../../../../constants"
import React, { useCallback, useEffect, useState } from "react"
import { RootState, useAppDispatch } from "../../../../../redux/store"
import {
  clearDealRequests,
  closeUpdateDealRequestsSuccess,
  fetchDealRequests,
  updateDealRequests,
} from "../../../../../redux/actions/dealRequestsActions"
import { clearErrors, closeError } from "../../../../../redux/actions/errorActions"

import { Alert } from "@material-ui/lab"
import Can from "../../../../common/Can"
import { DealRequest } from "../../../../../redux/types/dealRequestTypes"
import Header from "../../../../NewHeader"
import LocalizedFormat from "dayjs/plugin/localizedFormat"
import { PAGE_LIMIT } from "../../../../../constants"
import PerfectScrollbar from "react-perfect-scrollbar"
import { Search as SearchIcon } from "react-feather"
import TablePaginationActions from "../../../../common/TablePaginationActions"
import _ from "lodash"
import clsx from "clsx"
import dayjs from "dayjs"
import { debounceFn } from "../../../../../helpers/utilityHelpers"
import styles from "./styles"
import { useSelector } from "react-redux"

dayjs.extend(LocalizedFormat)

export const buildHeaderInfo = (): HeaderInfo => {
  const headerText = "All Deal Requests"
  const headerButtons = []

  const breadcrumbs: BreadCrumb[] = [
    {
      name: "Dashboard",
      route: "/",
    },
    {
      name: "Deal Requests",
    },
  ]

  return {
    headerText,
    breadcrumbs,
    headerButtons,
  }
}

const DealRequestList = ({ classes }) => {
  const dispatch = useAppDispatch()

  const isLoading = useSelector((state: RootState) => state.dealRequests.isLoading)
  const shouldReloadDealRequests = useSelector(
    (state: RootState) => state.dealRequests.shouldReloadDealRequests
  )
  const allDealRequests = useSelector((state: RootState) => state.dealRequests.dealRequests)
  const dealRequestsSuccessMessage = useSelector(
    (state: RootState) => state.dealRequests.dealRequestsSuccessMessage
  )
  const errors = useSelector((state: RootState) => state.errors.generalErrors)
  const pager = useSelector((state: RootState) => state.dealRequests.pager)
  const [dealRequestsStatusFilter, setDealRequestsStatusFilter] = useState(DEAL_REQUEST_STATUS.ALL)
  const [selectedDealRequestIds, setSelectedDealRequestIds] = useState<number[]>([])
  const sortOptions = [
    {
      value: "id|DESC",
      label: "ID (descending)",
    },
    {
      value: "id|ASC",
      label: "ID (ascending)",
    },
    {
      value: "name|ASC",
      label: "Name (ascending)",
    },
    {
      value: "name|DESC",
      label: "Name (descending)",
    },
    {
      value: "companyId|ASC",
      label: "Company Id (ascending)",
    },
    {
      value: "companyId|DESC",
      label: "Company Id (descending)",
    },
    {
      value: "endCustomerId|ASC",
      label: "End Customer Id (ascending)",
    },
    {
      value: "endCustomerId|DESC",
      label: "End Customer Id (descending)",
    },
    {
      value: "endCustomerName|ASC",
      label: "End Customer Name (ascending)",
    },
    {
      value: "endCustomerName|DESC",
      label: "End Customer Name (descending)",
    },
    {
      value: "createdAt|DESC",
      label: "Date Created (descending)",
    },
    {
      value: "createdAt|ASC",
      label: "Date Created (ascending)",
    },
    {
      value: "updatedAt|DESC",
      label: "Date Updated (descending)",
    },
    {
      value: "updatedAt|ASC",
      label: "Date Updated (ascending)",
    },
  ]
  const [allDealRequestsCopy, setAllDealRequestsCopy] = useState([])
  const [sortByFilter, setSortByFilter] = useState({
    value: "id",
    order: "DESC",
  })
  const [pageLimit, setPageLimit] = useState(PAGE_LIMIT.DEFAULT)
  const [selectedPage, setSelectedPage] = useState(0)
  const [query, setQuery] = useState("")
  const selectedDealRequestsCount = selectedDealRequestIds.length
  const totalDealRequestsCount = allDealRequests.length
  const updateStatusOptions = DEAL_REQUEST_STATUS_OPTIONS.slice(1)
  const [pmpDealIdValidationError, setPmpDealIdValidationError] = useState("")
  const [isUpdateBtnDisabled, setIsUpdateDisabled] = useState(true)

  const fetchDealRequestsPayload = {
    pagination: {
      limit: pageLimit,
      page: selectedPage,
    },
    sort: {
      value: sortByFilter.value,
      order: sortByFilter.order,
    },
    filters: {
      term: query,
      dealRequestStatus: dealRequestsStatusFilter,
    },
  }

  dealRequestsStatusFilter === DEAL_REQUEST_STATUS.ALL &&
    delete fetchDealRequestsPayload.filters.dealRequestStatus

  const dispatchFetchDealRequests = (value = query) => {
    fetchDealRequestsPayload.filters.term = value
    dispatch(fetchDealRequests(fetchDealRequestsPayload))
  }

  useEffect(() => {
    if (!isLoading && !shouldReloadDealRequests) {
      setSelectedDealRequestIds(() => [])
      setAllDealRequestsCopy([...allDealRequests])
    }
  }, [allDealRequests])

  useEffect(() => {
    shouldReloadDealRequests && dispatch(fetchDealRequests(fetchDealRequestsPayload))
  }, [shouldReloadDealRequests])

  useEffect(dispatchFetchDealRequests, [
    pageLimit,
    sortByFilter,
    selectedPage,
    dealRequestsStatusFilter,
  ])

  useEffect(() => {
    return () => {
      dispatch(clearErrors())
      dispatch(clearDealRequests())
      dispatch(closeUpdateDealRequestsSuccess())
    }
  }, [])

  useEffect(() => {
    const isBtnDisabled =
      isLoading ||
      selectedDealRequestsCount < 1 ||
      shouldReloadDealRequests ||
      _.isEqual(allDealRequestsCopy, allDealRequests)

    isBtnDisabled ? setIsUpdateDisabled(true) : setIsUpdateDisabled(false)
  }, [isLoading, isLoading, shouldReloadDealRequests, allDealRequestsCopy])

  const debounceSearch = useCallback(debounceFn(dispatchFetchDealRequests), [
    pageLimit,
    sortByFilter,
    dealRequestsStatusFilter,
  ])

  const handleQueryChange = (event) => {
    clearDealRequestSelection()
    setSelectedPage(0)
    setQuery(event.target.value)
    debounceSearch(event.target.value)
  }

  const handleSortChange = (event) => {
    const [sortValue, orderValue] = event.target.value.split("|")
    setSortByFilter({
      value: sortValue,
      order: orderValue,
    })
    setSelectedPage(0)
    clearDealRequestSelection()
  }

  const handleStatusFilterChange = (e) => {
    clearDealRequestSelection()
    setDealRequestsStatusFilter(e.target.value)
    setSelectedPage(0)
  }

  const handlePageChange = (event, newPage) => {
    setSelectedPage(newPage)
    clearDealRequestSelection()
  }

  const handleLimitChange = (event) => {
    setPageLimit(event.target.value)
    setSelectedPage(0)
    clearDealRequestSelection()
  }

  //On single deal request select
  const handleDealRequestSelect = (event, dealRequest) => {
    selectedDealRequestIds.includes(dealRequest.id)
      ? setSelectedDealRequestIds((prevSelected) =>
          prevSelected.filter((id) => id !== dealRequest.id)
        )
      : setSelectedDealRequestIds((prevSelected) => [...prevSelected, dealRequest.id])

    const prevDealRequest = findInitialDealRequest(dealRequest.id)
    const updatedDealRequest =
      event.target.checked === false ? { ...prevDealRequest } : { ...dealRequest }
    const updatedAllDealRequests = prepareUpdatedDealRequests(updatedDealRequest)
    setAllDealRequestsCopy([...updatedAllDealRequests])
  }

  //Find DealRequest by id from initial DealRequest's
  const findInitialDealRequest = (id): DealRequest => {
    const prevDealRequest = allDealRequests.find(
      (currentDealRequest) => currentDealRequest.id === id
    )
    return prevDealRequest
  }

  const filterDataByKey = (dataTofilter, dataToFindArray, dealRequestKey) => {
    const filteredData = dataTofilter.filter((dealRequest) => {
      return dataToFindArray.find((element) => {
        return element === dealRequest[dealRequestKey]
      })
    })
    return filteredData
  }

  const validatePmpDealId = (dealRequestList) => {
    const selectedDealRequests = filterDataByKey(dealRequestList, selectedDealRequestIds, "id")
    //all pmpDealIds in selected DealRequest's
    const selectedPmpDealIds = selectedDealRequests.map((dealRequest) => {
      if (dealRequest.pmpDealId !== null && dealRequest.pmpDealId !== "") {
        return dealRequest.pmpDealId
      }
    })
    //find unique pmpDealIds
    const uniquePmpDealIds = selectedPmpDealIds.filter((pmpDealID, index) => {
      return selectedPmpDealIds.indexOf(pmpDealID) === index
    })
    //find duplicatePmpDealIds
    let duplicatePmpDealIds = [...selectedPmpDealIds]
    uniquePmpDealIds.forEach((item) => {
      if (duplicatePmpDealIds.length !== 0) {
        const i = duplicatePmpDealIds.indexOf(item)
        duplicatePmpDealIds = duplicatePmpDealIds
          .slice(0, i)
          .concat(duplicatePmpDealIds.slice(i + 1, duplicatePmpDealIds.length))
      }
    })

    //find invalid DealRequest having duplicate pmpDealId
    const invalidDealRequests = filterDataByKey(
      selectedDealRequests,
      duplicatePmpDealIds,
      "pmpDealId"
    )
    const isInvalid =
      invalidDealRequests.length > 0 && invalidDealRequests.length <= selectedDealRequests.length
    return { isInvalid, invalidDealRequests }
  }

  //On update deal request status btn click
  const handleUpdateDealRequests = () => {
    dispatch(clearErrors())
    const updateDealRequestsPayload = []
    const { isInvalid, invalidDealRequests } = validatePmpDealId(allDealRequestsCopy)
    if (isInvalid) {
      const invalidDealRequestIds = invalidDealRequests
        .map((dealRequest) => {
          return dealRequest.id
        })
        .join(", ")
      setPmpDealIdValidationError(
        `pmp deal id already in use for deal requests: Id-${invalidDealRequestIds}`
      )
      setIsUpdateDisabled(true)
    } else {
      setPmpDealIdValidationError("")
      allDealRequestsCopy.map((dealRequest) => {
        selectedDealRequestIds.forEach((selectedDealId) => {
          if (selectedDealId === dealRequest.id) {
            updateDealRequestsPayload.push({
              id: dealRequest.id,
              dealRequestStatus: dealRequest.dealRequestStatus,
              pmpDealId:
                dealRequest.dealRequestStatus === DEAL_REQUEST_STATUS.DONE &&
                dealRequest.pmpDealId !== ""
                  ? dealRequest.pmpDealId
                  : null,
            })
          }
        })
      })

      dispatch(
        updateDealRequests({
          dealRequests: [...updateDealRequestsPayload],
          shouldReloadDealRequests: true,
        })
      )
    }
  }

  //On checked/unchecked of parent checkbox
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const allDealRequestIds = allDealRequestsCopy.map((dealRequest) => dealRequest.id)
      setSelectedDealRequestIds(allDealRequestIds)
    } else {
      setSelectedDealRequestIds([])
      setAllDealRequestsCopy([...allDealRequests])
    }
  }

  //Unselect/uncheck all deal requests
  const clearDealRequestSelection = () => {
    dispatch(closeUpdateDealRequestsSuccess())
    setSelectedDealRequestIds(() => [])
    setAllDealRequestsCopy([...allDealRequests])
  }

  //Prepare deal request array based on modified key values
  const prepareUpdatedDealRequests = (dealRequest): DealRequest[] => {
    const updatedDealRequests = allDealRequestsCopy.map((row) => {
      if (row.id === dealRequest.id) {
        return { ...row, ...dealRequest }
      }
      return row
    })
    return updatedDealRequests
  }

  //On change of pmpDealId
  const handlePmpDealIdChange = (e, dealRequest) => {
    const updatedDealRequest = { id: dealRequest.id, pmpDealId: e.target.value }
    const updatedDealRequests = prepareUpdatedDealRequests(updatedDealRequest)
    setAllDealRequestsCopy([...updatedDealRequests])
  }

  //On change of inline edit status option
  const handleStatusChange = (e, dealRequest) => {
    const prevDealRequest = findInitialDealRequest(dealRequest.id)
    const updatedDealRequest = {
      id: dealRequest.id,
      dealRequestStatus: e.target.value,
      pmpDealId: e.target.value === DEAL_REQUEST_STATUS.DONE ? prevDealRequest.pmpDealId : null,
    }
    const updatedDealRequests = prepareUpdatedDealRequests(updatedDealRequest)
    setAllDealRequestsCopy([...updatedDealRequests])
  }

  const selectedSomeDealRequest =
    selectedDealRequestsCount > 0 && selectedDealRequestsCount < totalDealRequestsCount
  const selectedAllDealRequest =
    selectedDealRequestsCount === totalDealRequestsCount && totalDealRequestsCount > 0

  const { headerText, breadcrumbs } = buildHeaderInfo()

  return (
    <>
      <Header headerText={headerText} breadcrumbs={breadcrumbs} />
      <Box mt={3} mb={3}>
        {errors.length > 0 &&
          errors.map((error, i) => (
            <Alert severity="error" key={`${error}-${i}`} onClose={() => dispatch(closeError(i))}>
              {error}
            </Alert>
          ))}
        {dealRequestsSuccessMessage.length > 0 && (
          <Alert
            severity="success"
            key={dealRequestsSuccessMessage}
            onClose={() => dispatch(closeUpdateDealRequestsSuccess())}
          >
            {dealRequestsSuccessMessage}
          </Alert>
        )}
        {pmpDealIdValidationError.length > 0 && (
          <Alert
            severity="error"
            key={pmpDealIdValidationError}
            onClose={() => setPmpDealIdValidationError("")}
            data-testid="deal-requests-list-pmpdealId-error"
          >
            {pmpDealIdValidationError}
          </Alert>
        )}
        <Card data-testid={"deal-requests-list-table-container"}>
          <Box p={2} minHeight={56} display="flex" alignItems="center">
            <TextField
              className={classes.queryField}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              onChange={handleQueryChange}
              placeholder="Search Deal Request"
              value={query}
              variant="outlined"
              data-testid={"deal-requests-list-search-input"}
            />
            <TextField
              label="Filter By Status"
              name="statusFilter"
              className={classes.statusField}
              onChange={handleStatusFilterChange}
              select
              SelectProps={{ native: true }}
              value={dealRequestsStatusFilter}
              variant="outlined"
              inputProps={{
                "data-testid": "deal-requests-list-status-filter-input",
              }}
            >
              {DEAL_REQUEST_STATUS_OPTIONS.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>

            <TextField
              label="Sort By"
              name="sort"
              onChange={handleSortChange}
              select
              SelectProps={{ native: true }}
              value={`${sortByFilter.value}|${sortByFilter.order}`}
              variant="outlined"
              data-testid={"deal-requests-list-sort-input"}
            >
              {sortOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
            <Can
              perform={[PERMISSIONS.TOOLS.DEAL_REQUEST.UPDATE]}
              yes={
                <Button
                  variant="contained"
                  color="secondary"
                  type="button"
                  className={classes.statusBtn}
                  onClick={handleUpdateDealRequests}
                  data-testid={"deal-requests-list-status-button"}
                  disabled={isUpdateBtnDisabled}
                >
                  Update Deal Request
                </Button>
              }
            />
            <Box flexGrow={1} />
            <TablePagination
              component="div"
              count={pager.totalCount}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleLimitChange}
              page={selectedPage}
              rowsPerPage={pageLimit}
              rowsPerPageOptions={[...PAGE_LIMIT.OPTIONS]}
              data-testid={"deal-requests-list-pagination"}
              ActionsComponent={TablePaginationActions}
            />
          </Box>
          <PerfectScrollbar>
            <Box p={2}>
              <TableContainer className={classes.tableWrapper}>
                <Table
                  size="medium"
                  data-testid={"deal-requests-list-table"}
                  className={classes.tableCustomeWidth}
                >
                  <TableHead>
                    <TableRow>
                      <Can
                        perform={[PERMISSIONS.TOOLS.DEAL_REQUEST.UPDATE]}
                        yes={
                          <TableCell
                            padding="checkbox"
                            data-testid={`deal-requests-list-select-all`}
                          >
                            <Checkbox
                              color="secondary"
                              indeterminate={selectedSomeDealRequest}
                              checked={selectedAllDealRequest}
                              onChange={() => {
                                dispatch(closeUpdateDealRequestsSuccess())
                                handleSelectAllClick(event)
                              }}
                              inputProps={{
                                "aria-label": "select all",
                              }}
                            />
                          </TableCell>
                        }
                      />
                      <TableCell data-testid={"deal-requests-list-id-table-column-header"}>
                        ID
                      </TableCell>
                      <TableCell data-testid={"deal-requests-list-status-table-column-header"}>
                        Status
                      </TableCell>
                      <TableCell
                        className={classes.colWidth_150}
                        data-testid={"deal-requests-list-pmp-deal-id-table-column-header"}
                      >
                        Pmp Deal Id
                      </TableCell>
                      <TableCell
                        className={classes.colWidth_200}
                        data-testid={"deal-requests-list-name-table-column-header"}
                      >
                        Deal Name
                      </TableCell>
                      <TableCell
                        className={classes.colWidth_150}
                        data-testid={"deal-requests-list-start-date-table-column-header"}
                      >
                        Start Date
                      </TableCell>

                      <TableCell data-testid={"deal-requests-list-buyer-table-column-header"}>
                        Buyer
                      </TableCell>
                      <TableCell
                        className={classes.colWidth_150}
                        data-testid={"deal-requests-list-media-type-table-column-header"}
                      >
                        Media Type
                      </TableCell>
                      <TableCell
                        className={classes.colWidth_200}
                        data-testid={"deal-requests-list-data-segment-table-column-header"}
                      >
                        Data Segment
                      </TableCell>
                      <TableCell
                        className={classes.colWidth_150}
                        data-testid={"deal-requests-list-data-fee-table-column-header"}
                      >
                        Audigent Fee
                      </TableCell>
                      <TableCell
                        className={classes.colWidth_150}
                        data-testid={"deal-requests-list-company-id-table-column-header"}
                      >
                        Company Id
                      </TableCell>
                      <TableCell
                        className={classes.colWidth_150}
                        data-testid={"deal-requests-list-end-customer-id-table-column-header"}
                      >
                        End Customer Id
                      </TableCell>
                      <TableCell
                        className={classes.colWidth_200}
                        data-testid={"deal-requests-list-end-customer-name-table-column-header"}
                      >
                        End Customer Name
                      </TableCell>
                      <TableCell
                        className={classes.colWidth_200}
                        data-testid={"deal-requests-list-seat-id-table-column-header"}
                      >
                        Seat ID
                      </TableCell>
                      <TableCell
                        className={classes.colWidth_200}
                        data-testid={"deal-requests-list-primary-kpi-table-column-header"}
                      >
                        Primary Kpi
                      </TableCell>
                      <TableCell
                        className={classes.colWidth_200}
                        data-testid={"deal-requests-list-geo-table-column-header"}
                      >
                        Geo
                      </TableCell>
                      <TableCell
                        className={classes.colWidth_200}
                        data-testid={"deal-requests-list-notes-table-column-header"}
                      >
                        Notes
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {isLoading ? (
                    <TableBody>
                      <TableRow>
                        <TableCell colSpan={11} align="center">
                          <CircularProgress />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ) : (
                    <TableBody data-testid={"deal-requests-list-content"}>
                      {allDealRequestsCopy.map((dealRequest) => {
                        const isDealRequestSelected = selectedDealRequestIds.includes(
                          dealRequest.id
                        )
                        return (
                          <TableRow hover key={dealRequest.id}>
                            <Can
                              perform={[PERMISSIONS.TOOLS.DEAL_REQUEST.UPDATE]}
                              yes={
                                <TableCell
                                  padding="checkbox"
                                  data-testid={`deal-requests-list-checkbox-${dealRequest.id}`}
                                >
                                  <Checkbox
                                    checked={isDealRequestSelected}
                                    onChange={(event) => {
                                      dispatch(closeUpdateDealRequestsSuccess())
                                      handleDealRequestSelect(event, dealRequest)
                                    }}
                                    value={isDealRequestSelected}
                                  />
                                </TableCell>
                              }
                            />

                            <TableCell data-testid={`deal-requests-list-id-${dealRequest.id}`}>
                              {dealRequest.id}
                            </TableCell>
                            <TableCell data-testid={`deal-requests-list-status-${dealRequest.id}`}>
                              {isDealRequestSelected ? (
                                <TextField
                                  label="Change Status to:"
                                  name="changeStatus"
                                  className={classes.statusField}
                                  onChange={(e) => {
                                    handleStatusChange(e, dealRequest)
                                  }}
                                  select
                                  SelectProps={{ native: true }}
                                  value={
                                    dealRequest.dealRequestStatus
                                      ? dealRequest.dealRequestStatus
                                      : ""
                                  }
                                  variant="standard"
                                  inputProps={{
                                    "data-testid": `deal-requests-list-select-status-input-${dealRequest.id}`,
                                  }}
                                >
                                  {updateStatusOptions.map((option) => (
                                    <option key={option.value} value={option.value}>
                                      {option.label}
                                    </option>
                                  ))}
                                </TextField>
                              ) : (
                                <Chip
                                  key={`status-${dealRequest.id}`}
                                  label={
                                    dealRequest.dealRequestStatus
                                      ? dealRequest.dealRequestStatus
                                      : ""
                                  }
                                  color="primary"
                                  className={clsx({
                                    [classes.statusDone]:
                                      dealRequest.dealRequestStatus === DEAL_REQUEST_STATUS.DONE,
                                    [classes.statusNew]:
                                      dealRequest.dealRequestStatus === DEAL_REQUEST_STATUS.NEW,
                                  })}
                                />
                              )}
                            </TableCell>
                            <TableCell
                              data-testid={`deal-requests-list-pmp-deal-id-${dealRequest.id}`}
                              className="pmpDealId"
                            >
                              {isDealRequestSelected ? (
                                <>
                                  <Input
                                    value={dealRequest.pmpDealId ? dealRequest.pmpDealId : ""}
                                    name={`pmpDealId-${dealRequest.id}`}
                                    onChange={(e) => {
                                      handlePmpDealIdChange(e, dealRequest)
                                    }}
                                    disabled={
                                      dealRequest.dealRequestStatus === DEAL_REQUEST_STATUS.NEW
                                    }
                                  />
                                </>
                              ) : dealRequest.pmpDealId ? (
                                dealRequest.pmpDealId
                              ) : (
                                ""
                              )}
                            </TableCell>
                            <TableCell data-testid={`deal-requests-list-name-${dealRequest.id}`}>
                              {dealRequest.name}
                            </TableCell>
                            <TableCell
                              data-testid={`deal-requests-list-start-date-${dealRequest.id}`}
                            >
                              {dayjs(dealRequest.startDate).format("YYYY-MM-DD")}
                            </TableCell>
                            <TableCell data-testid={`deal-requests-list-buyer-${dealRequest.id}`}>
                              {dealRequest.buyer}
                            </TableCell>
                            <TableCell
                              data-testid={`deal-requests-list-media-type-${dealRequest.id}`}
                            >
                              {dealRequest.mediaType}
                            </TableCell>
                            <TableCell
                              data-testid={`deal-requests-list-data-segment-${dealRequest.id}`}
                            >
                              {dealRequest.dataSegment}
                            </TableCell>
                            <TableCell
                              data-testid={`deal-requests-list-data-fee-${dealRequest.id}`}
                            >
                              {dealRequest.dataFee}
                            </TableCell>
                            <TableCell
                              data-testid={`deal-requests-list-company-id-${dealRequest.id}`}
                            >
                              {dealRequest.companyId}
                            </TableCell>
                            <TableCell
                              data-testid={`deal-requests-list-end-customer-id-${dealRequest.id}`}
                            >
                              {dealRequest.endCustomerId}
                            </TableCell>
                            <TableCell
                              data-testid={`deal-requests-list-end-customer-name-${dealRequest.id}`}
                            >
                              {dealRequest.endCustomerName}
                            </TableCell>
                            <TableCell data-testid={`deal-requests-list-seat-id-${dealRequest.id}`}>
                              {dealRequest.seatId}
                            </TableCell>
                            <TableCell
                              data-testid={`deal-requests-list-primary-kpi-${dealRequest.id}`}
                            >
                              {dealRequest.primaryKpi ? dealRequest.primaryKpi : ""}
                            </TableCell>
                            <TableCell data-testid={`deal-requests-list-geo-${dealRequest.id}`}>
                              {dealRequest.geo}
                            </TableCell>
                            <TableCell data-testid={`deal-requests-list-notes-${dealRequest.id}`}>
                              {dealRequest.notes ? dealRequest.notes : ""}
                            </TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Box>
          </PerfectScrollbar>
          <TablePagination
            component="div"
            count={pager.totalCount}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={selectedPage}
            rowsPerPage={pageLimit}
            rowsPerPageOptions={[...PAGE_LIMIT.OPTIONS]}
            data-testid={"deal-requests-list-pagination"}
            ActionsComponent={TablePaginationActions}
          />
        </Card>
      </Box>
    </>
  )
}

export default withStyles(styles)(DealRequestList)
