import { Alert } from "@material-ui/lab"
import { closeError } from "../../../redux/actions/errorActions"
import React from "react"
import { useSelector } from "react-redux"
import { RootState, useAppDispatch } from "../../../redux/store"

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ErrorMessages = () => {
  const dispatch = useAppDispatch()

  const errors = useSelector((state: RootState) => state.errors.generalErrors)

  return errors.length ? (
    <div data-testid={"error-messages"}>
      {errors.map((error, i) => (
        <Alert
          severity="error"
          data-testid={error}
          key={`${error}-${i}`}
          onClose={() => dispatch(closeError(i))}
        >
          {error}
        </Alert>
      ))}
    </div>
  ) : null
}

export default ErrorMessages
