import { call, put, takeEvery } from "redux-saga/effects"
import { API_ROOT, API, SECTION } from "../../constants"
import { AxiosRequestConfig } from "axios"
import {
  fetchSegmentVendors,
  fetchSegmentVendorsFailed,
  fetchSegmentVendorsSuccess,
} from "../actions/segmentVendorActions"
import { handleAxiosRequest, handleSagaError } from "../../helpers/sagaHelpers"
import { SagaIterator } from "redux-saga"

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* fetchSegmentVendorsSaga() {
  const ajaxConfig: AxiosRequestConfig = {
    method: "POST",
    url: `${API_ROOT}${API.V1}${SECTION.TOOLS}/segment-vendors/find`,
  }
  try {
    const response = yield call(handleAxiosRequest, ajaxConfig)
    const page =
      response.headers["x-pagination-page"] !== undefined
        ? parseInt(response.headers["x-pagination-page"], 10)
        : 0
    const totalCount =
      response.headers["x-pagination-total-count"] !== undefined
        ? parseInt(response.headers["x-pagination-total-count"], 10)
        : 0

    yield put(
      fetchSegmentVendorsSuccess({
        segmentVendors: response.data,
        pager: {
          page,
          totalCount,
        },
      })
    )
  } catch (err) {
    yield handleSagaError(fetchSegmentVendorsFailed, err)
  }
}

export function* watchFetchSegmentVendors(): SagaIterator {
  yield takeEvery(fetchSegmentVendors, fetchSegmentVendorsSaga)
}
