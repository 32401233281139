import {
  Box,
  Card,
  CircularProgress,
  InputAdornment,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  withStyles,
} from "@material-ui/core"
import { BreadCrumb, HeaderInfo } from "../../../../../redux/types/newHeaderTypes"
import { ENTITY_STATUSES, PAGE_LIMIT } from "../../../../../constants"
import React, { useCallback, useEffect, useState } from "react"
import { RootState, useAppDispatch } from "../../../../../redux/store"
import { clearErrors, closeError } from "../../../../../redux/actions/errorActions"
import {
  clearExternalAudiences,
  fetchExternalAudiences,
  setExternalAudiencesLoading,
} from "../../../../../redux/actions/externalAudienceActions"

import { Alert } from "@material-ui/lab"
import Header from "../../../../NewHeader"
import { Search as SearchIcon } from "react-feather"
import TablePaginationActions from "../../../../common/TablePaginationActions"
import clsx from "clsx"
import dayjs from "dayjs"
import { debounceFn } from "../../../../../helpers/utilityHelpers"
import styles from "./styles"
import { useSelector } from "react-redux"

export const buildHeaderInfo = (): HeaderInfo => {
  const headerText = "All Cadent Audiences"
  const headerButtons = []

  const breadcrumbs: BreadCrumb[] = [
    {
      name: "Dashboard",
      route: "/",
    },
    {
      name: "Cadent Audiences",
    },
  ]

  return {
    headerText,
    breadcrumbs,
    headerButtons,
  }
}

const ExternalAudiencesList = ({
  classes,
  testId = null,
  showHeader = true,
  showErrors = true,
  selectLimit = null,
  rowsPerPageOptions = PAGE_LIMIT.OPTIONS,
}) => {
  const dispatch = useAppDispatch()

  const isLoading = useSelector((state: RootState) => state.externalAudiences.isLoading)
  const externalAudiences = useSelector((state: RootState) => state.externalAudiences.data)
  const errors = useSelector((state: RootState) => state.errors.generalErrors)

  const pager = useSelector((state: RootState) => state.externalAudiences.pager)

  const sortOptions = [
    {
      value: "segment_id|DESC",
      label: "Segment ID (descending)",
    },
    {
      value: "segment_id|ASC",
      label: "Segment ID (ascending)",
    },
    {
      value: "segment_name|DESC",
      label: "Name (descending)",
    },
    {
      value: "segment_name|ASC",
      label: "Name (ascending)",
    },
    {
      value: "createdAt|DESC",
      label: "Date Created (descending)",
    },
    {
      value: "createdAt|ASC",
      label: "Date Created (ascending)",
    },
    {
      value: "updatedAt|DESC",
      label: "Date Updated (descending)",
    },
    {
      value: "updatedAt|ASC",
      label: "Date Updated (ascending)",
    },
  ]

  const [query, setQuery] = useState("")

  const [externalAudienceStatusFilter, setExternalAudienceStatusFilter] = useState(
    ENTITY_STATUSES.ACTIVE
  )

  const [sortByFilter, setSortByFilter] = useState({
    value: "createdAt",
    order: "DESC",
  })
  const [pageLimit, setPageLimit] = useState(selectLimit ? selectLimit : PAGE_LIMIT.DEFAULT)
  const [selectedPage, setSelectedPage] = useState(0)

  const externalAudiencesListStatusOptions = [
    { value: ENTITY_STATUSES.ALL, label: "All (excluding Archived)" },
    { value: ENTITY_STATUSES.ACTIVE, label: "Active" },
    { value: ENTITY_STATUSES.INACTIVE, label: "Inactive" },
    { value: ENTITY_STATUSES.ARCHIVED, label: "Archived" },
  ]

  const dispatchFetchExternalAudiences = (value = query) => {
    const fetchExternalAudiencesArgsObj = {
      pagination: {
        limit: pageLimit,
        page: selectedPage,
      },
      sort: {
        value: sortByFilter.value,
        order: sortByFilter.order,
      },
      filters: {
        term: value,
        status: externalAudienceStatusFilter,
      },
    }
    externalAudienceStatusFilter === ENTITY_STATUSES.ALL &&
      delete fetchExternalAudiencesArgsObj.filters.status

    dispatch(fetchExternalAudiences(fetchExternalAudiencesArgsObj))
  }

  const dispatchFetchExternalAudiencesOrStopLoading = () => {
    dispatchFetchExternalAudiences()
    dispatch(setExternalAudiencesLoading(false))
  }

  useEffect(() => {
    return () => {
      dispatch(clearErrors())
      dispatch(clearExternalAudiences())
    }
  }, [])

  useEffect(() => {
    dispatchFetchExternalAudiencesOrStopLoading()
  }, [pageLimit, sortByFilter, externalAudienceStatusFilter, selectedPage])

  const debounceSearch = useCallback(debounceFn(dispatchFetchExternalAudiences), [
    pageLimit,
    sortByFilter,
    externalAudienceStatusFilter,
  ])
  const handleFilterStatusChange = (e) => {
    setExternalAudienceStatusFilter(e.target.value)
    setSelectedPage(0)
  }

  const handleQueryChange = (event) => {
    setQuery(event.target.value)
    debounceSearch(event.target.value)
    setSelectedPage(0)
  }
  const handleSortChange = (event) => {
    const [sortValue, orderValue] = event.target.value.split("|")
    setSortByFilter({
      value: sortValue,
      order: orderValue,
    })
    setSelectedPage(0)
  }
  const handlePageChange = (event, newPage) => {
    setSelectedPage(newPage)
  }
  const handleLimitChange = (event) => {
    setPageLimit(event.target.value)
    setSelectedPage(0)
  }

  const { headerText, breadcrumbs, headerButtons } = buildHeaderInfo()

  return (
    <>
      {showHeader && (
        <Header headerText={headerText} breadcrumbs={breadcrumbs} buttons={headerButtons} />
      )}
      <Box mt={3} mb={3}>
        {errors.length > 0 &&
          showErrors &&
          errors.map((error, i) => (
            <Alert severity="error" key={`${error}-${i}`} onClose={() => dispatch(closeError(i))}>
              {error}
            </Alert>
          ))}

        <Card data-testid="external-audience-list-table-container">
          <Box
            sx={{
              p: 2,
              pr: 0,
            }}
            minHeight={56}
            display="flex"
            alignItems="center"
          >
            <TextField
              className={classes.queryField}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              onChange={handleQueryChange}
              placeholder="Search Cadent audiences"
              value={query}
              variant="outlined"
              data-testid={"external-audiences-list-search-input"}
            />

            <TextField
              label="Filter By Status"
              name="statusFilter"
              className={classes.audienceStatusField}
              onChange={handleFilterStatusChange}
              select
              SelectProps={{ native: true }}
              value={externalAudienceStatusFilter}
              variant="outlined"
              inputProps={{
                "data-testid": "external-audiences-list-status-filter-input",
              }}
            >
              {externalAudiencesListStatusOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
            <TextField
              label="Sort By"
              name="sort"
              className={classes.externalAudienceSortByField}
              onChange={handleSortChange}
              select
              SelectProps={{ native: true }}
              value={`${sortByFilter.value}|${sortByFilter.order}`}
              variant="outlined"
              data-testid={"external-audiences-list-sort-input"}
            >
              {sortOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>

            <Box flexGrow={1} />
            <TablePagination
              component="div"
              count={pager.totalCount}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleLimitChange}
              page={selectedPage}
              rowsPerPage={pageLimit}
              rowsPerPageOptions={rowsPerPageOptions}
              data-testid={"external-audiences-list-pagination"}
              ActionsComponent={TablePaginationActions}
            />
          </Box>
          <Box className={classes.scrollTable}>
            <Table size="small" data-testid={testId}>
              <TableHead>
                <TableRow>
                  <TableCell data-testid={"external-audiences-list-id-table-column-header"}>
                    Segment ID
                  </TableCell>
                  <TableCell data-testid={"external-audiences-list-name-table-column-header"}>
                    Audience Name
                  </TableCell>
                  <TableCell data-testid={"external-audiences-list-cpm-table-column-header"}>
                    CPM
                  </TableCell>
                  <TableCell data-testid={"external-audiences-list-status-table-column-header"}>
                    Status
                  </TableCell>
                  <TableCell data-testid={"external-audiences-list-created-at-table-column-header"}>
                    Created On
                  </TableCell>
                </TableRow>
              </TableHead>
              {isLoading ? (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={11} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody data-testid={"external-audiences-list-content"}>
                  {externalAudiences.map((externalAudience) => {
                    return (
                      <TableRow hover key={externalAudience.segmentId}>
                        <TableCell
                          data-testid={`external-audiences-list-id-${externalAudience.segmentId}`}
                        >
                          {externalAudience.segmentId}
                        </TableCell>
                        <TableCell
                          data-testid={`external-audiences-list-name-${externalAudience.segmentId}`}
                        >
                          {externalAudience.segmentName}
                        </TableCell>

                        <TableCell
                          data-testid={`external-audiences-list-cpm-${externalAudience.id}`}
                        >
                          {externalAudience.cpm}
                        </TableCell>

                        <TableCell
                          data-testid={`external-audiences-list-status-${externalAudience.segmentId}`}
                        >
                          <small
                            className={clsx({
                              [classes.label]: true,
                              [classes.audienceStatusActive]:
                                externalAudience.status === ENTITY_STATUSES.ACTIVE,
                              [classes.audienceStatusInactive]:
                                externalAudience.status === ENTITY_STATUSES.INACTIVE,
                              [classes.audienceStatusArchived]:
                                externalAudience.status === ENTITY_STATUSES.ARCHIVED,
                            })}
                          >
                            {externalAudience.status}
                          </small>
                        </TableCell>

                        <TableCell
                          data-testid={`external-audiences-created-at-id-${externalAudience.segmentId}`}
                        >
                          {dayjs(externalAudience.createdAt).format("LLL")}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              )}
            </Table>
          </Box>
          <TablePagination
            component="div"
            count={pager.totalCount}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={selectedPage}
            rowsPerPage={pageLimit}
            rowsPerPageOptions={rowsPerPageOptions}
            data-testid={"external-audiences-list-pagination"}
            ActionsComponent={TablePaginationActions}
          />
        </Card>
      </Box>
    </>
  )
}

export default withStyles(styles)(ExternalAudiencesList)
