import {
  CreateAndUpdateDealPayload,
  Deal,
  DealEntity,
  ExternalAudience,
  FetchDealDomainsPayload,
  FetchDealDomainsSuccessResponse,
  FetchDealPublishersPayload,
  FetchDealPublishersSuccessResponse,
  FetchDealsSuccessResponse,
  IabContentCategory,
  SaveDealSuccessPayload,
} from "../types/dealTypes"

import { Bidder } from "../types/sharedTypes"
import { FetchListPayload } from "../types/sharedTypes"
import { createAction } from "@reduxjs/toolkit"

/* ===================================== Deal List actions ===================================== */
export const fetchDeals = createAction<FetchListPayload>("Effects_FETCH_DEALS")
export const fetchDealsSuccess = createAction<FetchDealsSuccessResponse>("FETCH_DEALS_SUCCESS")
export const fetchDealsFailed = createAction<string>("FETCH_DEALS_FAILED")

export const fetchAndExportDeals = createAction<FetchListPayload & { focusedCompanyName: string }>(
  "Effects_FETCH_AND_EXPORT_DEALS"
)
export const fetchAndExportDealsSuccess = createAction<string>("FETCH_AND_EXPORT_DEALS_SUCCESS")
export const fetchAndExportDealsFailed = createAction<string>("FETCH_AND_EXPORT_DEALS_FAILED")

export const clearDeals = createAction("CLEAR_DEALS")
export const closeSaveDealSuccess = createAction("CLOSE_SAVE_DEAL_SUCCESS")

// Bidders
export const fetchDealBidders = createAction("Effects_FETCH_DEAL_BIDDERS")
export const fetchDealBiddersSuccess = createAction<Bidder[]>("FETCH_DEAL_BIDDERS_SUCCESS")
export const fetchDealBiddersFailed = createAction<string>("FETCH_DEAL_BIDDERS_FAILED")

// Countries
export const fetchDealCountries = createAction("Effects_FETCH_DEAL_COUNTRIES")
export const fetchDealCountriesSuccess = createAction<DealEntity[]>("FETCH_DEAL_COUNTRIES_SUCCESS")
export const fetchDealCountriesFailed = createAction<string>("FETCH_DEAL_COUNTRIES_FAILED")

// iab content categories
export const fetchDealIabContentCategories = createAction(
  "Effects_FETCH_DEAL_IAB_CONTENT_CATEGORIES"
)
export const fetchDealIabContentCategoriesSuccess = createAction<IabContentCategory[]>(
  "FETCH_DEAL_IAB_CONTENT_CATEGORIES_SUCCESS"
)
export const fetchDealIabContentCategoriesFailed = createAction<string>(
  "FETCH_DEAL_IAB_CONTENT_CATEGORIES_FAILED"
)

// Domains
export const clearDealDomains = createAction("CLEAR_DEAL_DOMAINS")
export const fetchDealDomains = createAction<FetchDealDomainsPayload>("Effects_FETCH_DEAL_DOMAINS")
export const fetchDealDomainsSuccess = createAction<FetchDealDomainsSuccessResponse>(
  "FETCH_DEAL_DOMAINS_SUCCESS"
)
export const fetchDealDomainsFailed = createAction<string>("FETCH_DEAL_DOMAINS_FAILED")

export const uploadDealDomainsSuccess = createAction<string[]>("UPLOAD_DEAL_DOMAINS_SUCCESS")
export const clearUploadedDealDomains = createAction("CLEAR_UPLOADED_DEAL_DOMAINS")

// Publishers
export const clearPublishers = createAction("CLEAR_PUBLISHERS")
export const fetchDealPublishers = createAction<FetchDealPublishersPayload>(
  "Effects_FETCH_DEAL_PUBLISHERS"
)
export const fetchDealPublishersSuccess = createAction<FetchDealPublishersSuccessResponse>(
  "FETCH_DEAL_PUBLISHERS_SUCCESS"
)
export const fetchDealPublishersFailed = createAction<string>("FETCH_DEAL_PUBLISHERS_FAILED")

export const uploadDealPublishersSuccess = createAction<string[]>("UPLOAD_DEAL_PUBLISHERS_SUCCESS")
export const clearUploadedDealPublishers = createAction("CLEAR_UPLOADED_DEAL_PUBLISHERS")

/* ======================================= Deal form actions ======================================= */
export const createDeal = createAction<CreateAndUpdateDealPayload>("Effects_CREATE_DEAL")
export const createDealSuccess = createAction<SaveDealSuccessPayload>("CREATE_DEAL_SUCCESS")
export const createDealFailed = createAction<string>("CREATE_DEAL_FAILED")

export const updateDeal = createAction<CreateAndUpdateDealPayload>("Effects_UPDATE_DEAL")
export const updateDealSuccess = createAction<SaveDealSuccessPayload>("UPDATE_DEAL_SUCCESS")
export const updateDealFailed = createAction<string>("UPDATE_DEAL_FAILED")

export const fetchDealById = createAction<number>("Effects_FETCH_DEAL_BY_ID")
export const fetchDealByIdSuccess = createAction<Deal>("FETCH_DEAL_BY_ID_SUCCESS")
export const fetchDealByIdFailed = createAction<string>("FETCH_DEAL_BY_ID_FAILED")

export const clearDealInfo = createAction("CLEAR_DEAL_INFO")

export const resetDealFormState = createAction("RESET_DEAL_FORM_STATE")

/* ===================================== External Audiences List actions ===================================== */
export const fetchDealExternalAudiences = createAction<FetchListPayload>(
  "Effects_FETCH_DEAL_EXTERNAL_AUDIENCE"
)
export const fetchDealExternalAudienceSuccess = createAction<ExternalAudience[]>(
  "FETCH_DEAL_EXTERNAL_AUDIENCE_SUCCESS"
)
export const fetchDealExternalAudienceFailed = createAction<string>(
  "FETCH_DEAL_EXTERNAL_AUDIENCE_FAILED"
)
