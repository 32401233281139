import {
  BlockAttribute,
  BlockCategory,
  BlockType,
  FetchSiteByIdPayload,
  FetchSitesPayload,
  FetchSitesSuccessResponse,
  Site,
} from "../types/siteTypes"

import { Bidder } from "../types/sharedTypes"
import { FetchAndExportTagsFromSitesPayload } from "../types/tagTypes"
import { createAction } from "@reduxjs/toolkit"

/* ===================================== Site List actions ====================================== */
export const fetchSites = createAction<FetchSitesPayload>("Effects_FETCH_SITES")
export const fetchSitesSuccess = createAction<FetchSitesSuccessResponse>("FETCH_SITES_SUCCESS")
export const setSitesLoadingFlag = createAction<boolean>("SET_SITES_LOADING_FLAG")
export const fetchSitesFailed = createAction<string>("FETCH_SITES_FAILED")

export const fetchAndExportTagsFromSites = createAction<FetchAndExportTagsFromSitesPayload>(
  "Effects_FETCH_AND_EXPORT_TAGS_FROM_SITES"
)
export const fetchAndExportTagsFromSitesSuccess = createAction<string>(
  "FETCH_AND_EXPORT_TAGS_FROM_SITES_SUCCESS"
)
export const fetchAndExportTagsFromSitesFailed = createAction<string>(
  "FETCH_AND_EXPORT_TAGS_FROM_SITES_FAILED"
)
export const setLoadingTagsForExportSitesFlag = createAction<boolean>(
  "SET_LOADING_TAGS_FOR_EXPORT_SITES_FLAG"
)

export const clearSites = createAction("CLEAR_SITES")
export const closeSaveSiteSuccess = createAction("CLOSE_SAVE_SITE_SUCCESS")

/* ===================================== Site Form actions ====================================== */
// Fetch Single Site
export const fetchSiteById = createAction<FetchSiteByIdPayload>("Effects_FETCH_SITE_BY_ID")
export const fetchSiteByIdSuccess = createAction<Site>("FETCH_SITE_BY_ID_SUCCESS")
export const fetchSiteByIdFailed = createAction<string>("FETCH_SITE_BY_ID_FAILED")

// Fetch Bidders
export const fetchSiteBidders = createAction("Effects_FETCH_SITE_BIDDERS")
export const fetchSiteBiddersSuccess = createAction<Bidder[]>("FETCH_SITE_BIDDERS_SUCCESS")
export const fetchSiteBiddersFailed = createAction<string>("FETCH_SITE_BIDDERS_FAILED")
export const setSiteFormLoadingBiddersFlag = createAction<boolean>(
  "SET_SITE_FORM_LOADING_BIDDERS_FLAG"
)

// Fetch Site Block Categories
export const fetchSiteBlockCategories = createAction("Effects_FETCH_SITE_BLOCK_CATEGORIES")
export const fetchSiteBlockCategoriesSuccess = createAction<BlockCategory[]>(
  "FETCH_SITE_BLOCK_CATEGORIES_SUCCESS"
)
export const fetchSiteBlockCategoriesFailed = createAction<string>(
  "FETCH_SITE_BLOCK_CATEGORIES_FAILED"
)
export const setSiteFormLoadingBlockCategoriesFlag = createAction<boolean>(
  "SET_SITE_FORM_LOADING_BLOCK_CATEGORIES_FLAG"
)

// Fetch Site Block Attributes
export const fetchSiteBlockAttributes = createAction("Effects_FETCH_SITE_BLOCK_ATTRIBUTES")
export const fetchSiteBlockAttributesSuccess = createAction<BlockAttribute[]>(
  "FETCH_SITE_BLOCK_ATTRIBUTES_SUCCESS"
)
export const fetchSiteBlockAttributesFailed = createAction<string>(
  "FETCH_SITE_BLOCK_ATTRIBUTES_FAILED"
)
export const setSiteFormLoadingBlockAttributesFlag = createAction<boolean>(
  "SET_SITE_FORM_LOADING_BLOCK_ATTRIBUTES_FLAG"
)

// Fetch Site Block Types
export const fetchSiteBlockTypes = createAction("Effects_FETCH_SITE_BLOCK_TYPES")
export const fetchSiteBlockTypesSuccess = createAction<BlockType[]>(
  "FETCH_SITE_BLOCK_TYPES_SUCCESS"
)
export const fetchSiteBlockTypesFailed = createAction<string>("FETCH_SITE_BLOCK_TYPES_FAILED")
export const setSiteFormLoadingBlockTypesFlag = createAction<boolean>(
  "SET_SITE_FORM_LOADING_BLOCK_TYPES_FLAG"
)

// Create
export const createSite = createAction<Site>("Effects_CREATE_SITE")
export const createSiteSuccess = createAction<Site>("CREATE_SITE_SUCCESS")
export const createSiteFailed = createAction<string>("CREATE_SITE_FAILED")

// Update
export const updateSite = createAction<Site>("Effects_UPDATE_SITE")
export const updateSiteSuccess = createAction<Site>("UPDATE_SITE_SUCCESS")
export const updateSiteFailed = createAction<string>("UPDATE_SITE_FAILED")

// Shared form actions
export const setSiteFormLoadingFlag = createAction<boolean>("SET_SITE_FORM_LOADING_FLAG")
export const setSiteFormSubmittingFlag = createAction<boolean>("SET_SITE_FORM_SUBMITTING_FLAG")
export const resetSiteFormState = createAction("RESET_SITE_FORM_STATE")
