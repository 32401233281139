import { API, API_ROOT, SECTION } from "../../constants"
import { call, put, takeEvery } from "redux-saga/effects"
import {
  fetchTagById,
  fetchTagByIdFailed,
  fetchTagByIdSuccess,
  setTagFormLoadingFlag,
} from "../actions/tagActions"
import { handleAxiosRequest, handleSagaError } from "../../helpers/sagaHelpers"

import { AxiosRequestConfig } from "axios"
import { FetchTagByIdPayload } from "../types/tagTypes"
import { PayloadAction } from "@reduxjs/toolkit"
import { SagaIterator } from "redux-saga"
import _ from "lodash"
import { clearErrors } from "../actions/errorActions"
import { forwardTo } from "../../helpers/historyHelper"

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* fetchTagByIdSaga(action: PayloadAction<FetchTagByIdPayload>) {
  const ajaxConfig: AxiosRequestConfig = {
    method: "GET",
    url: `${API_ROOT}${API.V1}${SECTION.ADMIN}/publishers/${action.payload.publisherId}/sites/${action.payload.siteId}/tags/${action.payload.tagId}`,
  }
  yield put(clearErrors())
  yield put(setTagFormLoadingFlag(true))
  try {
    const response = yield call(handleAxiosRequest, ajaxConfig)
    yield put(fetchTagByIdSuccess(response.data))
  } catch (err) {
    if (_.get(err, "response.data.type") === "not_found" || "unknown") {
      yield call(
        forwardTo,
        `/admin/publishers/${action.payload.publisherId}/sites/${action.payload.siteId}/tags`
      )
    }
    yield handleSagaError(fetchTagByIdFailed, err)
  }
  yield put(setTagFormLoadingFlag(false))
}

export function* watchFetchTagById(): SagaIterator {
  yield takeEvery(fetchTagById, fetchTagByIdSaga)
}
