import { Theme, createStyles } from "@material-ui/core/styles"

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default (theme: Theme) => {
  return createStyles({
    publisherStatusField: {
      margin: theme.spacing(1),
      width: "25ch",
    },
    loadingForm: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "300px",
      weight: "100%",
    },
    overrideDisable: {
      color: "rgba(0, 0, 0, 1)",
      cursor: "not-allowed !important",
    },
    helperText: {
      width: "max-content",
    },
    cardPadding: {
      padding: 24,
    },
    roundedBorder: {
      border: "1px solid lightgray",
      borderRadius: 5,
      color: "grey",
      marginBottom: "10px",
    },
    sellersJsonBox: {
      marginTop: "10px",
    },
    smallField: {
      margin: theme.spacing(1),
      width: "25ch",
    },
  })
}
