import { call, put, takeEvery } from "redux-saga/effects"
import {
  fetchCompanies,
  fetchCompaniesFailed,
  fetchCompaniesSuccess,
} from "../actions/companyActions"
import { handleAxiosRequest, handleSagaError } from "../../helpers/sagaHelpers"
import { SagaIterator } from "redux-saga"
import { API, API_ROOT, SECTION } from "../../constants"
import { AxiosRequestConfig } from "axios"
import { PayloadAction } from "@reduxjs/toolkit"
import { FetchListPayload } from "../types/sharedTypes"

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* fetchCompaniesSaga(action: PayloadAction<FetchListPayload>) {
  const ajaxConfig: AxiosRequestConfig = {
    method: "POST",
    url: `${API_ROOT}${API.V1}${SECTION.ADMIN}/companies/find`,
    data: action.payload,
  }
  try {
    const response = yield call(handleAxiosRequest, ajaxConfig)
    const isWithoutPaginate = action.payload.pagination?.paginate === false
    const page =
      response.headers["x-pagination-page"] !== undefined
        ? parseInt(response.headers["x-pagination-page"], 10)
        : 0
    const totalCount =
      response.headers["x-pagination-total-count"] !== undefined
        ? parseInt(response.headers["x-pagination-total-count"], 10)
        : 0
    yield put(
      fetchCompaniesSuccess({
        companies: response.data,
        isWithoutPaginate: isWithoutPaginate,
        pager: {
          page,
          totalCount,
        },
      })
    )
  } catch (err) {
    yield handleSagaError(fetchCompaniesFailed, err)
  }
}

export function* watchFetchCompanies(): SagaIterator {
  yield takeEvery(fetchCompanies, fetchCompaniesSaga)
}
