import { API_ROOT, API, SECTION } from "../../constants"
import { call, put, takeEvery } from "redux-saga/effects"
import { AxiosRequestConfig } from "axios"
import {
  fetchDealPublishersSuccess,
  fetchDealPublishersFailed,
  fetchDealPublishers,
} from "../actions/dealActions"
import { handleAxiosRequest, handleSagaError } from "../../helpers/sagaHelpers"
import { SagaIterator } from "redux-saga"
import { PayloadAction } from "@reduxjs/toolkit"
import { FetchDealPublishersPayload } from "../types/dealTypes"

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* fetchDealPublishersSaga(action: PayloadAction<FetchDealPublishersPayload>) {
  const ajaxConfig: AxiosRequestConfig = {
    method: "POST",
    url: `${API_ROOT}${API.V1}${SECTION.TOOLS}/deals/publishers/find`,
    data: {
      filters: {
        term: action.payload.term,
      },
      pagination: {
        limit: 100,
        page: action.payload.page,
      },
    },
  }
  try {
    const response = yield call(handleAxiosRequest, ajaxConfig)
    yield put(
      fetchDealPublishersSuccess({
        publishers: response.data,
        page: parseInt(response.headers["x-pagination-page"]),
        clear: action.payload.clear,
      })
    )
  } catch (err) {
    yield handleSagaError(fetchDealPublishersFailed, err)
  }
}

export function* watchFetchDealPublishers(): SagaIterator {
  yield takeEvery(fetchDealPublishers, fetchDealPublishersSaga)
}
