import * as Yup from "yup"

import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  TextField,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core"
import { BreadCrumb, HeaderInfo } from "../../../../../../redux/types/newHeaderTypes"
import { FEATURE_FLAGS, PERMISSIONS } from "../../../../../../constants"
import React, { useEffect } from "react"
import { RootState, useAppDispatch } from "../../../../../../redux/store"
import {
  clearFeatureFlags,
  fetchFeatureFlagByTerm,
} from "../../../../../../redux/actions/featureFlagActions"
import {
  createSubdomain,
  fetchSubdomainById,
  resetSubdomainFormState,
  updateSubdomain,
} from "../../../../../../redux/actions/subdomainActions"

import { Domain } from "../../../../../../redux/types/domainTypes"
import { Edit as EditIcon } from "react-feather"
import ErrorMessages from "../../../../../common/Errors"
import { Formik } from "formik"
import NewHeader from "../../../../../NewHeader"
import { Subdomain } from "../../../../../../redux/types/subdomainTypes"
import clsx from "clsx"
import dayjs from "dayjs"
import { fetchDomainById } from "../../../../../../redux/actions/domainActions"
import styles from "./styles"
import { useSelector } from "react-redux"

export const buildHeaderInfo = (
  formType: {
    isCreate?: boolean
    isEdit?: boolean
    isInfo?: boolean
    isSaveDomainsWithDataUpdateFFEnabled?: boolean
  },
  domain: Domain = {} as Domain,
  subdomain: Subdomain = {} as Subdomain
): HeaderInfo => {
  let headerText
  const { isCreate, isInfo, isEdit, isSaveDomainsWithDataUpdateFFEnabled } = formType

  const breadcrumbs: BreadCrumb[] = [
    {
      name: "Dashboard",
      route: "/",
    },
    {
      name: "Domains",
      route: "/admin/domains",
    },
    {
      name: `${domain.name} (${domain.id})`,
      route: `/admin/domains/${domain.id}`,
    },
    {
      name: `Subdomains`,
      route: `/admin/domains/${domain.id}/subdomains`,
    },
  ]
  const headerButtons = []

  if (isCreate) {
    headerText = "Create New Subdomain"
    breadcrumbs.push({
      name: `Create`,
    })
  }

  if (isInfo) {
    headerText = "Subdomain: "
    if (subdomain.id) {
      headerText = `Subdomain: ${subdomain.name} (${subdomain.id})`
      breadcrumbs.push({
        name: `${subdomain.name} (${subdomain.id})`,
      })

      headerButtons.push({
        name: "EDIT",
        route: `/admin/domains/${domain.id}/subdomains/${subdomain.id}/edit`,
        permission: PERMISSIONS.ADMIN.DOMAIN.UPDATE,
        disabled: !isSaveDomainsWithDataUpdateFFEnabled,
        disabledTooltipText:
          !isSaveDomainsWithDataUpdateFFEnabled && "Subdomain editing is disabled",
        icon: <EditIcon />,
      })
    }
  }

  if (isEdit) {
    headerText = "Subdomain: "
    if (subdomain.id) {
      headerText = `Subdomain: ${subdomain.name} (${subdomain.id})`
      breadcrumbs.push({
        name: `${subdomain.name} (${subdomain.id})`,
        route: `/admin/domains/${domain.id}/subdomains/${subdomain.id}`,
      })
      breadcrumbs.push({
        name: `Edit`,
      })
    }
  }

  return {
    headerText,
    breadcrumbs,
    headerButtons,
  }
}

const SubdomainForm = ({ classes, match }) => {
  const dispatch = useAppDispatch()
  const domain = useSelector((state: RootState) => state.domainForm.selectedDomain)
  const subdomain = useSelector((state: RootState) => state.subdomainForm.selectedSubdomain)
  const isLoading = useSelector((state: RootState) => state.subdomainForm.isLoading)
  const isSubmitting = useSelector((state: RootState) => state.subdomainForm.isSubmitting)

  const endPath = match.path.split("/").pop()
  const isCreate = endPath === "create"
  const isEdit = endPath === "edit"
  const isInfo = !isEdit && !isCreate

  // TODO: FF: remove when removing feature flag
  const isSaveDomainsWithDataUpdateFFEnabled = useSelector(
    (state: RootState) => state.featureFlags.isSaveDomainsWithDataUpdateFFEnabled
  )
  useEffect(() => {
    dispatch(fetchFeatureFlagByTerm(FEATURE_FLAGS.SAVE_DOMAINS_WITH_DATA_UPDATE))
    return () => {
      dispatch(clearFeatureFlags())
      dispatch(resetSubdomainFormState())
    }
  }, [])

  useEffect(() => {
    if (!domain.id) dispatch(fetchDomainById(match.params.domainId))
    if (!isCreate && !subdomain.id)
      dispatch(
        fetchSubdomainById({
          domainId: match.params.domainId,
          subdomainId: match.params.subdomainId,
        })
      )
  }, [domain, subdomain])

  const initialFormValues = {
    name: subdomain.name || "",
  }

  const formValidationSchema = Yup.object().shape({
    name: Yup.string().max(255),
  })

  const handleFormikSubmit = (values) => {
    if (isCreate) {
      dispatch(createSubdomain({ ...values, domainId: domain.id }))
    } else if (isEdit) {
      dispatch(
        updateSubdomain({
          ...values,
          id: subdomain.id,
          domainId: subdomain.domainId,
        })
      )
    }
  }

  const { headerText, breadcrumbs, headerButtons } = buildHeaderInfo(
    { isCreate, isEdit, isInfo, isSaveDomainsWithDataUpdateFFEnabled },
    domain,
    subdomain
  )

  return (
    <>
      <NewHeader headerText={headerText} breadcrumbs={breadcrumbs} buttons={headerButtons} />
      <Box mt={3} mb={3} className={clsx({ isInfo: classes.cursorOverrideDisable })}>
        {!isLoading ? (
          <Formik
            enableReinitialize={true}
            initialValues={initialFormValues}
            validationSchema={formValidationSchema}
            onSubmit={(values) => handleFormikSubmit(values)}
          >
            {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => {
              return (
                <>
                  <ErrorMessages />
                  <form onSubmit={handleSubmit} className={clsx(isInfo && classes.overrideDisable)}>
                    <Card>
                      <CardContent className={classes.subdomainFormCardLayout}>
                        <Grid container spacing={3} className={classes.roundedBorder}>
                          {/* Name */}
                          <Grid item md={6}>
                            <TextField
                              error={!!(touched.name && errors.name)}
                              fullWidth
                              helperText={touched.name && errors.name}
                              label={
                                <span className={clsx(isInfo && classes.overrideDisable)}>
                                  Subdomain Name
                                </span>
                              }
                              name="name"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              required
                              value={values.name}
                              variant="outlined"
                              inputProps={{
                                "data-testid": "subdomain-form-name-input",
                              }}
                              InputProps={{
                                classes: {
                                  disabled: classes.overrideDisable,
                                },
                              }}
                              disabled={
                                isLoading || isInfo || !isSaveDomainsWithDataUpdateFFEnabled
                              }
                            />
                            {subdomain.createdAt && (
                              <div
                                className={classes.timestamps}
                                data-testid={"subdomain-form-timestamps"}
                              >
                                <Typography data-testid={"subdomain-form-created-at-updated-at"}>
                                  <b>Created on: </b>
                                  {dayjs(subdomain?.createdAt).format("M-D-YYYY h:mm a")}
                                  <b> Last updated: </b>
                                  {dayjs(subdomain?.updatedAt).format("M-D-YYYY h:mm a")}
                                </Typography>
                              </div>
                            )}
                          </Grid>
                        </Grid>
                        {!isInfo && (
                          <Box mt={2}>
                            <Tooltip
                              title={
                                !isSaveDomainsWithDataUpdateFFEnabled
                                  ? "Saving of Domains is not currently enabled."
                                  : ""
                              }
                            >
                              {/* wrapped button in span because tooltips do not work directly on disabled buttons*/}
                              <span>
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  type="submit"
                                  disabled={isSubmitting || !isSaveDomainsWithDataUpdateFFEnabled}
                                  data-testid={"subdomain-form-submit-button"}
                                >
                                  {isCreate && "Create Subdomain"}
                                  {isEdit && "Update Subdomain"}
                                </Button>
                              </span>
                            </Tooltip>
                          </Box>
                        )}
                      </CardContent>
                    </Card>
                  </form>
                </>
              )
            }}
          </Formik>
        ) : (
          <Card>
            <CardContent className={classes.loadingForm}>
              <CircularProgress />
            </CardContent>
          </Card>
        )}
      </Box>
    </>
  )
}

export default withStyles(styles)(SubdomainForm)
