/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Breadcrumbs,
  Button,
  Grid,
  Link,
  SvgIcon,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core"

import Can from "../common/Can"
import { HeaderButton } from "../../redux/types/newHeaderTypes"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"
import React from "react"
import { Link as RouterLink } from "react-router-dom"
import clsx from "clsx"
import { lowerCaseAndHyphenate } from "../../helpers/formatterHelper"
import styles from "./styles"
import { useDocumentTitle } from "../../helpers/utilityHelpers"

function NewHeader({ headerText, breadcrumbs, buttons = [], classes }) {
  useDocumentTitle(headerText)

  const renderBreadcrumbs = () => (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
      data-testid={"header-breadcrumbs"}
    >
      {breadcrumbs?.map((breadcrumbObj, i) => {
        return breadcrumbObj.route ? (
          <Link
            variant="body1"
            color="inherit"
            to={breadcrumbObj.route}
            component={RouterLink}
            key={`${breadcrumbObj.name}-${i}`}
            data-testid={`header-breadcrumb-${lowerCaseAndHyphenate(breadcrumbObj.name)}-link`}
          >
            {breadcrumbObj.name}
          </Link>
        ) : (
          <span
            data-testid={`header-breadcrumb-${lowerCaseAndHyphenate(breadcrumbObj.name)}-non-link`}
            key={`${breadcrumbObj.name}-${i}`}
          >
            {breadcrumbObj.name}
          </span>
        )
      })}
    </Breadcrumbs>
  )

  const renderHeaderText = () => (
    <Typography
      variant="h3"
      color="textPrimary"
      className="capitalize"
      data-testid={"header-text-value"}
    >
      {headerText}
    </Typography>
  )

  const renderButton = (buttonInfo: HeaderButton, i) => {
    let button

    if (buttonInfo.buttonFunction) {
      button = (
        <Can
          perform={buttonInfo.permission}
          yes={
            <Tooltip
              title={
                buttonInfo.disabled && buttonInfo.disabledTooltipText
                  ? buttonInfo.disabledTooltipText
                  : ""
              }
            >
              {/* wrapped button in span because tooltips do not work directly on disabled buttons*/}
              <span>
                <Button
                  color="secondary"
                  variant="contained"
                  className={clsx(classes.capitalize, classes.headerButton)}
                  component={Button}
                  onClick={buttonInfo.buttonFunction}
                  data-testid={`header-${lowerCaseAndHyphenate(buttonInfo.name)}-button`}
                  disabled={buttonInfo.disabled}
                >
                  {buttonInfo.icon && (
                    <SvgIcon fontSize="small" className={classes.actionIcon}>
                      {buttonInfo.icon}
                    </SvgIcon>
                  )}
                  {buttonInfo.name}
                </Button>
              </span>
            </Tooltip>
          }
          key={`${buttonInfo.name}-${i}`}
        />
      )
    } else if (buttonInfo.route) {
      button = (
        <Can
          perform={buttonInfo.permission}
          yes={
            <Tooltip
              title={
                buttonInfo.disabled && buttonInfo.disabledTooltipText
                  ? buttonInfo.disabledTooltipText
                  : ""
              }
            >
              {/* wrapped button in span because tooltips do not work directly on disabled buttons*/}
              <span>
                <Button
                  color="secondary"
                  variant="contained"
                  className={clsx(classes.capitalize, classes.headerButton)}
                  to={buttonInfo.route}
                  component={RouterLink}
                  data-testid={`header-${lowerCaseAndHyphenate(buttonInfo.name)}-button`}
                  disabled={buttonInfo.disabled}
                >
                  {buttonInfo.icon && (
                    <SvgIcon fontSize="small" className={classes.actionIcon}>
                      {buttonInfo.icon}
                    </SvgIcon>
                  )}
                  {buttonInfo.name}
                </Button>
              </span>
            </Tooltip>
          }
          key={`${buttonInfo.name}-${i}`}
        />
      )
    }

    return button
  }

  const renderButtons = () => {
    return (
      <Grid item data-testid={"header-button-section"}>
        {buttons.map((buttonInfo: HeaderButton, i) => renderButton(buttonInfo, i))}
      </Grid>
    )
  }

  return (
    <Grid container spacing={3} justifyContent="space-between" data-testid={`header`}>
      <Grid item>
        {renderBreadcrumbs()}
        {renderHeaderText()}
      </Grid>
      <Grid item>{renderButtons()}</Grid>
    </Grid>
  )
}

export default withStyles(styles)(NewHeader)
