import * as React from "react"
import * as ReactDOM from "react-dom"
import { Auth0Provider } from "@auth0/auth0-react"
import { Router } from "react-router-dom"
import { Provider } from "react-redux"
import store from "./redux/store"
import { ThemeProvider } from "@material-ui/styles"
import theme from "./theme"
import App from "./components/App"
import { history } from "./helpers/historyHelper"
import "normalize.css"
import "react-date-range/dist/styles.css"
import "react-date-range/dist/theme/default.css"

ReactDOM.render(
  <Auth0Provider
    domain={process.env.AUTH0_DOMAIN}
    clientId={process.env.AUTH0_CLIENT_ID}
    audience={process.env.AUTH0_AUDIENCE}
    scope="openid read:current_user"
    redirectUri={window.location.origin}
  >
    <ThemeProvider theme={theme}>
      <Router history={history}>
        <Provider store={store}>
          <App />
        </Provider>
      </Router>
    </ThemeProvider>
  </Auth0Provider>,
  document.getElementById("root") as HTMLElement
)
