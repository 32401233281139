import { FetchExternalAudiencesSuccessResponse } from "../types/externalAudienceTypes"
import { FetchListPayload } from "../types/sharedTypes"
import { createAction } from "@reduxjs/toolkit"

/* ===================================== External Audiences List actions ===================================== */
export const fetchExternalAudiences = createAction<FetchListPayload>(
  "Effects_FETCH_EXTERNAL_AUDIENCES"
)
export const fetchExternalAudiencesSuccess = createAction<FetchExternalAudiencesSuccessResponse>(
  "FETCH_EXTERNAL_AUDIENCES_SUCCESS"
)
export const fetchExternalAudiencesFailed = createAction<string>("FETCH_EXTERNAL_AUDIENCES_FAILED")
export const clearExternalAudiences = createAction("CLEAR_ExternalAudiences")

export const setExternalAudiencesLoading = createAction<boolean>("SET_EXTERNAL_AUDIENCES_LOADING")
