import React, { useEffect, useState } from "react"
import _ from "lodash"
import styles from "./styles"
import {
  Button,
  TextField,
  Box,
  Card,
  CardContent,
  Grid,
  MenuItem,
  withStyles,
} from "@material-ui/core"
import DataGrid, { TextEditor } from "react-data-grid"
import { RootState, useAppDispatch } from "../../../../../../../redux/store"
import { useSelector } from "react-redux"
import {
  createCompanyExternalLineItemDisplayName,
  fetchCompanyExternalLineItemDisplayNamesByCompanyId,
  setExternalLineItemDisplayNameSavedFlag,
} from "../../../../../../../redux/actions/companyActions"
import { ExternalLineItemDisplayName } from "../../../../../../../redux/types/companyTypes"
import { clearErrors } from "../../../../../../../redux/actions/errorActions"
import dayjs from "dayjs"
import { MEDIA_FORMAT } from "../../../../../../../constants"
import { InitialCompanyState } from "../../../../../../../redux/reducers/companyFormReducer"

const ExternalLineItemDisplayNamesMappingTable = ({
  companyForm,
}: {
  companyForm: InitialCompanyState
}) => {
  const dispatch = useAppDispatch()
  const companyId = companyForm.selectedCompany.id
  const isSubmitting = companyForm.isSubmitting
  const externalLineItemDisplayNames = companyForm.externalLineItemDisplayNames
  const isExternalLineItemDisplayNameSaved = companyForm.isExternalLineItemDisplayNameSaved
  const generalErrors = useSelector((state: RootState) => state.errors.generalErrors)

  const mediaFormatOptions = [
    { value: MEDIA_FORMAT.BVOD, label: "BVOD" },
    { value: MEDIA_FORMAT.PRE_ROLL, label: "Pre-Roll" },
    { value: MEDIA_FORMAT.DISPLAY, label: "Display" },
  ]

  const initialEditableRow: ExternalLineItemDisplayName = {
    companyId: null,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    advertiserExternalId: "" as any,
    advertiserDisplayName: "",
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    insertionOrderExternalId: "" as any,
    insertionOrderDisplayName: "",
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    lineItemExternalId: "" as any,
    lineItemDisplayName: "",
    startDate: dayjs().format("YYYY-MM-DD"),
    endDate: dayjs().add(1, "day").format("YYYY-MM-DD"),
    mediaFormat: "",
    editable: true,
  }

  const [editableRow, setEditableRow] = useState(initialEditableRow)

  const rows = [editableRow, ...externalLineItemDisplayNames]

  const handleStartDate = (startDate: string) => {
    const cloneStartDate = _.cloneDeep(startDate)
    const valuesCopy = _.cloneDeep(editableRow)
    valuesCopy.startDate = cloneStartDate
    setEditableRow(valuesCopy)
  }

  const handleEndDate = (endDate: string) => {
    const cloneEndDate = _.cloneDeep(endDate)
    const valuesCopy = _.cloneDeep(editableRow)
    valuesCopy.endDate = cloneEndDate
    setEditableRow(valuesCopy)
  }

  const handleMediaFormat = (mediaFormat: MEDIA_FORMAT) => {
    const cloneMediaFormat = _.cloneDeep(mediaFormat)
    const valuesCopy = _.cloneDeep(editableRow)
    valuesCopy.mediaFormat = cloneMediaFormat
    setEditableRow(valuesCopy)
  }

  const handleCreate = () => {
    const valuesCopy = _.cloneDeep(editableRow)
    valuesCopy.companyId = companyId
    valuesCopy.advertiserExternalId = parseInt(valuesCopy.advertiserExternalId as unknown as string)
    valuesCopy.insertionOrderExternalId = parseInt(
      editableRow.insertionOrderExternalId as unknown as string
    )
    valuesCopy.lineItemExternalId = parseInt(editableRow.lineItemExternalId as unknown as string)
    dispatch(clearErrors()) &&
      dispatch(createCompanyExternalLineItemDisplayName(valuesCopy as ExternalLineItemDisplayName))
  }

  useEffect(() => {
    if (!isExternalLineItemDisplayNameSaved && !generalErrors.length) {
      setEditableRow(initialEditableRow)
      return
    }
  }, [isExternalLineItemDisplayNameSaved])

  useEffect(() => {
    !isExternalLineItemDisplayNameSaved &&
      !generalErrors.length &&
      companyId &&
      dispatch(fetchCompanyExternalLineItemDisplayNamesByCompanyId(companyId)) &&
      dispatch(setExternalLineItemDisplayNameSavedFlag(true))
  }, [companyId, isExternalLineItemDisplayNameSaved])

  const onRowsChange = (rows) => {
    setEditableRow(rows.shift())
    return rows
  }

  const columns = [
    {
      key: "advertiserExternalId",
      name: "Advertiser ID",
      resizable: true,
      editor: TextEditor,
      editable: (rowData) => {
        return !!rowData.editable
      },
    },
    {
      key: "advertiserDisplayName",
      name: "Advertiser Display Name",
      resizable: true,
      width: 275,
      editor: TextEditor,
      editable: (rowData) => {
        return !!rowData.editable
      },
    },
    {
      key: "insertionOrderExternalId",
      name: "Insertion Order ID",
      resizable: true,
      editor: TextEditor,
      editable: (rowData) => {
        return !!rowData.editable
      },
    },
    {
      key: "insertionOrderDisplayName",
      name: "Insertion Order Display Name",
      resizable: true,
      width: 275,
      editor: TextEditor,
      editable: (rowData) => {
        return !!rowData.editable
      },
    },
    {
      key: "lineItemExternalId",
      name: "Line Item ID",
      resizable: true,
      editor: TextEditor,
      editable: (rowData) => {
        return !!rowData.editable
      },
    },
    {
      key: "lineItemDisplayName",
      name: "Line Item Display Name",
      resizable: true,
      width: 275,
      editor: TextEditor,
      editable: (rowData) => {
        return !!rowData.editable
      },
    },
    {
      key: "startDate",
      name: "Start Date",
      resizable: true,
      width: 155,
      formatter: (params) => {
        return !!params.row.editable ? (
          <TextField
            type="date"
            onChange={(dateObject) => handleStartDate(dateObject.target.value)}
            value={params.row.startDate}
            InputLabelProps={{ shrink: true }}
            inputProps={{
              "data-testid": `external-line-item-display-names-mapping-table-start-date`,
              min: dayjs().format("YYYY-MM-DD"),
              max: dayjs().add(20, "year").format("YYYY-MM-DD"),
            }}
          />
        ) : (
          <>{params.row.startDate}</>
        )
      },
    },
    {
      key: "endDate",
      name: "End Date",
      resizable: true,
      width: 155,
      formatter: (params) => {
        return !!params.row.editable ? (
          <TextField
            id="date"
            type="date"
            onChange={(dateObject) => handleEndDate(dateObject.target.value)}
            value={params.row.endDate}
            InputLabelProps={{ shrink: true }}
            data-testid={`external-line-item-form-start-date-input`}
            inputProps={{
              "data-testid": "external-line-item-form-start-date-input",
              min: dayjs().add(1, "day").format("YYYY-MM-DD"),
              max: dayjs().add(20, "year").format("YYYY-MM-DD"),
            }}
          />
        ) : (
          <>{params.row.endDate}</>
        )
      },
    },
    {
      key: "mediaFormat",
      name: "Media Format",
      resizable: true,
      width: 110,
      editor: TextEditor,
      editable: (rowData) => {
        return !!rowData.editable
      },
      formatter: (params) => {
        return !!params.row.editable ? (
          <TextField
            select
            name="mediaFormat"
            value={params.row.mediaFormat}
            onChange={(e) => {
              handleMediaFormat(e.target.value as MEDIA_FORMAT)
            }}
            inputProps={{
              "data-testid": "external-line-item-form-media-format-input",
            }}
          >
            {mediaFormatOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        ) : (
          <>{params.row.mediaFormat}</>
        )
      },
    },
    {
      key: "button",
      name: "",
      resizable: true,
      width: 100,
      formatter: (params) => {
        return !!params.row.editable ? (
          <Button
            onClick={handleCreate}
            variant="contained"
            color="secondary"
            disabled={isSubmitting}
          >
            Create
          </Button>
        ) : (
          <></>
        )
      },
    },
  ]

  return (
    <div data-testid="external-line-item-display-names-mapping-table">
      <Card style={{ marginTop: "10px" }}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              <Box mt={3} mb={3}>
                <div style={{ width: "100%", height: "100%" }}>
                  <DataGrid
                    data-testid="external-line-item-display-names-mapping-table-datagrid"
                    className="rdg-light"
                    style={{ fontFamily: "Roboto, Helvetica, Arial, sans-serif" }}
                    headerRowHeight={50}
                    rows={rows}
                    columns={columns}
                    onRowsChange={onRowsChange}
                  />
                </div>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  )
}

export default withStyles(styles)(ExternalLineItemDisplayNamesMappingTable)
