import { createStyles } from "@material-ui/core/styles"

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default () => {
  return createStyles({
    iconicTitle: {
      display: "flex",
    },
    icon: {
      marginRight: "10px",
    },
    title: {
      fontSize: "20px",
    },
  })
}
