import { createAction } from "@reduxjs/toolkit"
import {
  FetchPricingEstimatePayload,
  FetchPricingEstimateSuccessResponse,
} from "../types/pricingEstimateTypes"

export const fetchPricingEstimate = createAction<FetchPricingEstimatePayload>(
  "Effects_FETCH_PRICING_ESTIMATE"
)
export const fetchPricingEstimateSuccess = createAction<FetchPricingEstimateSuccessResponse>(
  "FETCH_PRICING_ESTIMATE_SUCCESS"
)
export const fetchPricingEstimateFailed = createAction<string>("FETCH_PRICING_ESTIMATE_FAILED")

export const clearPricingEstimateData = createAction("CLEAR_PRICING_ESTIMATE_DATA")
