import { createStyles, Theme } from "@material-ui/core/styles"

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default (theme: Theme) => {
  return createStyles({
    actionIcon: {
      marginRight: theme.spacing(1),
    },
    capitalize: {
      textTransform: "capitalize",
    },
    headerButton: {
      marginLeft: "5px",
    },
  })
}
