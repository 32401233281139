import { User } from "../redux/types/userTypes"
import { Role } from "../redux/types/roleTypes"

export const isPermitted = (perform: string | string[] = [], user: User): boolean => {
  if (user.roles.some((role: Role) => role.isSuper)) return true

  const userPermissionSlugs = user.activePermissions.map((permission) => permission.slug)
  if (typeof perform === "string") perform = [perform]

  return perform.some((permission) => userPermissionSlugs.includes(permission))
}
