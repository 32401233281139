import { createReducer } from "@reduxjs/toolkit"
import {
  fetchApplicationVersion,
  fetchApplicationVersionFailed,
  fetchApplicationVersionSuccess,
} from "../actions/applicationVersionActions"

interface InitialApplicationVersionState {
  version: {
    currentVersion: string
  }
  isLoading: boolean
}

const initialState: InitialApplicationVersionState = {
  version: {
    currentVersion: "",
  },
  isLoading: false,
}
export default createReducer(initialState, (builder) => {
  builder
    /*==============================  Application version form action handlers ==============================*/

    .addCase(fetchApplicationVersion, (state) => {
      state.isLoading = true
    })
    .addCase(fetchApplicationVersionSuccess, (state, action) => {
      state.version.currentVersion = action.payload
      state.isLoading = false
    })
    .addCase(fetchApplicationVersionFailed, (state) => {
      state.isLoading = false
    })
})
