import { ArrowRight as ArrowRightIcon, Edit as EditIcon, Search as SearchIcon } from "react-feather"
import {
  Box,
  Card,
  Checkbox,
  CircularProgress,
  IconButton,
  InputAdornment,
  Link,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  withStyles,
} from "@material-ui/core"
import { ENTITY_STATUSES, PAGE_LIMIT, PERMISSIONS } from "../../../../../../constants"
import React, { useCallback, useEffect, useState } from "react"
import { RootState, useAppDispatch } from "../../../../../../redux/store"
import {
  clearTags,
  closeSaveTagSuccess,
  fetchTags,
} from "../../../../../../redux/actions/tagActions"

import { Alert } from "@material-ui/lab"
import Can from "../../../../../common/Can"
import ErrorMessages from "../../../../../common/Errors"
import PerfectScrollbar from "react-perfect-scrollbar"
import { Link as RouterLink } from "react-router-dom"
import TablePaginationActions from "../../../../../common/TablePaginationActions"
import { clearErrors } from "../../../../../../redux/actions/errorActions"
import clsx from "clsx"
import { debounceFn } from "../../../../../../helpers/utilityHelpers"
import styles from "./styles"
import { useSelector } from "react-redux"

const TagsList = ({
  setTagsQuery,
  setTagsStatusFilterTags,
  setTagsSelectedTags,
  classes,
  match,
}) => {
  const dispatch = useAppDispatch()

  const isLoading = useSelector((state: RootState) => state.tags.isLoading)
  const tags = useSelector((state: RootState) => state.tags.tags)
  const tagSuccessMessage = useSelector((state: RootState) => state.tags.tagSuccessMessage)
  const pager = useSelector((state: RootState) => state.tags.pager)

  const sortOptions = [
    {
      value: "id|DESC",
      label: "ID (descending)",
    },
    {
      value: "id|ASC",
      label: "ID (ascending)",
    },
    {
      value: "name|ASC",
      label: "Name (A-Z)",
    },
    {
      value: "name|DESC",
      label: "Name (Z-A)",
    },
  ]
  const [query, setQuery] = useState("")
  const tagsListStatusOptions = [
    { value: ENTITY_STATUSES.ALL, label: "All" },
    { value: ENTITY_STATUSES.ACTIVE, label: "Active" },
    { value: ENTITY_STATUSES.INACTIVE, label: "Inactive" },
  ]
  const [selectedPage, setSelectedPage] = useState(0)
  const [pageLimit, setPageLimit] = useState(PAGE_LIMIT.DEFAULT)
  const [tagsStatusFilter, setTagsStatusFilter] = useState(ENTITY_STATUSES.ACTIVE)
  const [sortByFilter, setSortByFilter] = useState({
    value: "id",
    order: "DESC",
  })
  const [selectedTags, setSelectedTags] = useState([])

  const fetchTagsArgsObj = {
    publisherId: match.params.publisherId,
    siteId: match.params.siteId,
    pagination: {
      page: selectedPage,
      limit: pageLimit,
    },
    filters: {
      term: query,
      status: tagsStatusFilter,
    },
    sort: {
      value: sortByFilter.value,
      order: sortByFilter.order,
    },
  }
  tagsStatusFilter === ENTITY_STATUSES.ALL && delete fetchTagsArgsObj.filters.status

  const dispatchFetchTags = (tagSearchTerm: string = query) => {
    dispatch(
      fetchTags({
        ...fetchTagsArgsObj,
        filters: {
          ...fetchTagsArgsObj.filters,
          term: tagSearchTerm,
        },
      })
    )
  }
  useEffect(dispatchFetchTags, [selectedPage, pageLimit, tagsStatusFilter, sortByFilter])

  useEffect(() => {
    return () => {
      dispatch(clearErrors())
      dispatch(clearTags())
      dispatch(closeSaveTagSuccess())
    }
  }, [])

  const debounceSearch = useCallback(debounceFn(dispatchFetchTags), [
    pageLimit,
    tagsStatusFilter,
    sortByFilter,
  ])
  const handleStatusFilterChange = (e) => {
    setTagsStatusFilterTags(e.target.value)
    setTagsStatusFilter(e.target.value)
    setSelectedPage(0)
  }
  const handleQueryChange = (event) => {
    setTagsQuery(event.target.value)
    setQuery(event.target.value)
    debounceSearch(event.target.value)
  }
  const handleSortChange = (event) => {
    const [sortValue, orderValue] = event.target.value.split("|")
    setSortByFilter({
      value: sortValue,
      order: orderValue,
    })
    setSelectedPage(0)
  }
  const handleSelectAllTags = (event) => {
    const checkedSites = event.target.checked ? tags.map((tag) => tag.id) : []
    setTagsSelectedTags(checkedSites)
    setSelectedTags(checkedSites)
  }
  const handleSelectOneTag = (event, tagId) => {
    if (!selectedTags.includes(tagId)) {
      const selectedTags = (prevSelected) => [...prevSelected, tagId]
      setTagsSelectedTags(selectedTags)
      setSelectedTags(selectedTags)
    } else {
      const selectedTags = (prevSelected) => prevSelected.filter((id) => id !== tagId)
      setTagsSelectedTags(selectedTags)
      setSelectedTags(selectedTags)
    }
  }
  const handlePageChange = (event, newPage) => {
    setSelectedPage(newPage)
  }
  const handleLimitChange = (event) => {
    setPageLimit(event.target.value)
    setSelectedPage(0)
  }

  const selectedSomeTags = selectedTags.length > 0 && selectedTags.length < tags.length
  const selectedAllTags = selectedTags.length === tags.length && tags.length > 0

  return (
    <>
      <Box mt={3} mb={3}>
        <ErrorMessages />
        {tagSuccessMessage.length > 0 && (
          <Alert
            severity="success"
            key={tagSuccessMessage}
            onClose={() => dispatch(closeSaveTagSuccess())}
          >
            {tagSuccessMessage}
          </Alert>
        )}

        {/* Search, Active Filter, Sort order */}
        <Card data-testid="tags-list-table-container">
          <Box
            sx={{
              p: 2,
              pr: 0,
            }}
            minHeight={56}
            display="flex"
            alignItems="center"
          >
            {/* Search */}
            <TextField
              className={classes.queryField}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              onChange={handleQueryChange}
              placeholder="Search tags"
              value={query}
              variant="outlined"
              data-testid={"tags-list-search-input"}
            />

            {/* Status Filter */}
            <TextField
              label="Filter by Status"
              name="status-filter"
              className={classes.tagsStatusField}
              onChange={handleStatusFilterChange}
              select
              SelectProps={{ native: true }}
              value={`${tagsStatusFilter}`}
              variant="outlined"
              data-testid={"tags-list-status-filter-input"}
            >
              {tagsListStatusOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>

            {/* Sort */}
            <TextField
              label="Sort By"
              name="sort"
              onChange={handleSortChange}
              select
              SelectProps={{ native: true }}
              value={`${sortByFilter.value}|${sortByFilter.order}`}
              variant="outlined"
              data-testid={"tags-list-sort-input"}
            >
              {sortOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
            <Box flexGrow={1} />
            <TablePagination
              component="div"
              count={pager.totalCount}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleLimitChange}
              page={selectedPage}
              rowsPerPage={pageLimit}
              rowsPerPageOptions={PAGE_LIMIT.OPTIONS}
              data-testid="tags-list-pagination"
              ActionsComponent={TablePaginationActions}
            />
          </Box>
          <PerfectScrollbar>
            <Box minWidth={700}>
              <Table size="small">
                {/* Table Headers */}
                <TableHead>
                  <TableRow>
                    <TableCell width="5%" padding="checkbox">
                      <Checkbox
                        checked={selectedAllTags}
                        indeterminate={selectedSomeTags}
                        onChange={handleSelectAllTags}
                        data-testid={"tags-list-select-all-tags-checkbox"}
                      />
                    </TableCell>
                    <TableCell data-testid={"tags-list-id-table-column-header"}>ID</TableCell>
                    <TableCell data-testid={"tags-list-status-table-column-header"}>
                      Status
                    </TableCell>
                    <TableCell data-testid={"tags-list-name-table-column-header"}>Name</TableCell>
                    <TableCell data-testid={"tags-list-deals-table-column-header"}>Deals</TableCell>
                    <TableCell data-testid={"tags-list-hardfloor-table-column-header"}>
                      Hardfloor
                    </TableCell>
                    <TableCell data-testid={"tags-list-site-code-table-column-header"}>
                      Site Code
                    </TableCell>
                    <TableCell data-testid={"tags-list-publisher-code-table-column-header"}>
                      Publisher Code
                    </TableCell>
                    <TableCell data-testid={"tags-list-code-table-column-header"}>Code</TableCell>
                    <TableCell data-testid={"tags-list-app-nexu-tag-id-table-column-header"}>
                      App Nexus Tag ID
                    </TableCell>
                    <Can
                      perform={[
                        PERMISSIONS.ADMIN.PUBLISHER.VIEW,
                        PERMISSIONS.ADMIN.PUBLISHER.UPDATE,
                      ]}
                      yes={
                        <TableCell
                          align="right"
                          data-testid={"tags-list-actions-table-column-header"}
                        >
                          Actions
                        </TableCell>
                      }
                    />
                  </TableRow>
                </TableHead>

                {/* Table Body */}
                {isLoading ? (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={10} align="center">
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody data-testid="tags-list-content">
                    {tags.map((tag) => {
                      const isTagSelected = selectedTags.includes(tag.id)

                      return (
                        <TableRow hover key={tag.id} selected={isTagSelected}>
                          {/* Checkbox */}
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isTagSelected}
                              onChange={(event) => handleSelectOneTag(event, tag.id)}
                              value={isTagSelected}
                              data-testid={`tags-list-checkbox-${tag.id}`}
                            />
                          </TableCell>

                          {/* Tag Id */}
                          <TableCell>
                            <Box display="flex" alignItems="center">
                              <div>
                                <Link
                                  color="inherit"
                                  component={RouterLink}
                                  to={`/admin/publishers/${match.params.publisherId}/sites/${match.params.siteId}/tags/${tag.id}`}
                                  variant="h6"
                                  data-testid={`tags-list-id-${tag.id}`}
                                >
                                  {`${tag.id}`}
                                </Link>
                              </div>
                            </Box>
                          </TableCell>

                          {/* Status */}
                          <TableCell data-testid={`tags-list-status-${tag.id}`}>
                            <small
                              className={clsx({
                                [classes.label]: true,
                                [classes.tagStatusActive]: tag.status === ENTITY_STATUSES.ACTIVE,
                                [classes.tagStatusInactive]:
                                  tag.status === ENTITY_STATUSES.INACTIVE,
                              })}
                            >
                              {tag.status}
                            </small>
                          </TableCell>

                          {/* Tag Name */}
                          <TableCell>
                            <Box display="flex" alignItems="center">
                              <div>
                                <Link
                                  color="inherit"
                                  component={RouterLink}
                                  to={`/admin/publishers/${match.params.publisherId}/sites/${match.params.siteId}/tags/${tag.id}`}
                                  variant="h6"
                                  data-testid={`tags-list-name-${tag.id}`}
                                >
                                  {`${tag.name}`}
                                </Link>
                              </div>
                            </Box>
                          </TableCell>

                          {/* Deals */}
                          <TableCell data-testid={`tags-list-deals-${tag.id}`}>
                            {tag.deals && tag.deals.toString()}
                          </TableCell>

                          {/* Hardfloor */}
                          <TableCell data-testid={`tags-list-hardfloor-${tag.id}`}>
                            {tag.hardfloor}
                          </TableCell>

                          {/* Site code */}
                          <TableCell data-testid={`tags-list-site-code-${tag.id}`}>
                            {tag.siteCode && tag.siteCode}
                          </TableCell>

                          {/* Publisher code */}
                          <TableCell data-testid={`tags-list-publisher-code-${tag.id}`}>
                            {tag.publisherCode && tag.publisherCode}
                          </TableCell>

                          {/* Code */}
                          <TableCell data-testid={`tags-list-code-${tag.id}`}>{tag.code}</TableCell>

                          {/* App Nexus Tag ID */}
                          <TableCell data-testid={`tags-list-app-nexus-tag-id-${tag.id}`}>
                            {tag.appNexusTagId || "N/A"}
                          </TableCell>

                          {/* Edit/Info Action buttons */}
                          <Can
                            perform={[
                              PERMISSIONS.ADMIN.PUBLISHER.VIEW,
                              PERMISSIONS.ADMIN.PUBLISHER.UPDATE,
                            ]}
                            yes={
                              <TableCell align="right">
                                {/* Edit Button*/}
                                <Can
                                  perform={PERMISSIONS.ADMIN.PUBLISHER.UPDATE}
                                  yes={
                                    <IconButton
                                      component={RouterLink}
                                      to={`/admin/publishers/${match.params.publisherId}/sites/${match.params.siteId}/tags/${tag.id}/edit`}
                                      data-testid={`tags-list-edit-button-${tag.id}`}
                                    >
                                      <SvgIcon fontSize="small">
                                        <EditIcon />
                                      </SvgIcon>
                                    </IconButton>
                                  }
                                />

                                {/* Info Button */}
                                <Can
                                  perform={PERMISSIONS.ADMIN.PUBLISHER.VIEW}
                                  yes={
                                    <IconButton
                                      component={RouterLink}
                                      to={`/admin/publishers/${match.params.publisherId}/sites/${match.params.siteId}/tags/${tag.id}`}
                                      data-testid={`tags-list-view-button-${tag.id}`}
                                    >
                                      <SvgIcon fontSize="small">
                                        <ArrowRightIcon />
                                      </SvgIcon>
                                    </IconButton>
                                  }
                                />
                              </TableCell>
                            }
                          />
                        </TableRow>
                      )
                    })}
                  </TableBody>
                )}
              </Table>
            </Box>
          </PerfectScrollbar>
          <TablePagination
            component="div"
            count={pager.totalCount}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={selectedPage}
            rowsPerPage={pageLimit}
            rowsPerPageOptions={PAGE_LIMIT.OPTIONS}
            data-testid="tags-list-pagination"
            ActionsComponent={TablePaginationActions}
          />
        </Card>
      </Box>
      <Box sx={{ height: "24px" }} />
    </>
  )
}

export default withStyles(styles)(TagsList)
