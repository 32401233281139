import {
  createPublisherSuccess,
  fetchPublisherByIdSuccess,
  resetPublisherFormState,
  setPublisherFormLoadingFlag,
  setPublisherFormSubmittingFlag,
  updatePublisherSuccess,
} from "../actions/publisherActions"

import { ENTITY_STATUSES } from "../../constants"
import { Publisher } from "../types/publisherTypes"
import { SELLER_TYPE } from "../types/sellersJsonTypes"
import { createReducer } from "@reduxjs/toolkit"

export interface InitialPublisherFormState {
  selectedPublisher: Publisher
  isLoading: boolean
  isSaved: boolean
  isSubmitting: boolean
}

export const initialPublisherState = {
  name: "",
  appNexusId: null,
  isRtbxEnabled: false,
  revShare: null,
  isOandO: false,
  isSellersJsonEnabled: false,
  sitesCount: 0,
  status: ENTITY_STATUSES.ACTIVE,
  sellersJsonEntry: {
    sellerId: "",
    isConfidential: false,
    sellerType: SELLER_TYPE.PUBLISHER,
    isPassthrough: false,
    name: "",
    domain: "",
    comment: "",
  },
}

export const initialState: InitialPublisherFormState = {
  selectedPublisher: initialPublisherState,
  isLoading: false,
  isSaved: false,
  isSubmitting: false,
}

export default createReducer(initialState, (builder) =>
  builder
    /*============================== Publisher form action handlers ==============================*/
    .addCase(createPublisherSuccess, (state) => {
      state.isSaved = true
    })
    .addCase(setPublisherFormLoadingFlag, (state, action) => {
      state.isLoading = action.payload
    })
    .addCase(setPublisherFormSubmittingFlag, (state, action) => {
      state.isSubmitting = action.payload
    })
    .addCase(fetchPublisherByIdSuccess, (state, action) => {
      state.selectedPublisher = action.payload
    })
    .addCase(updatePublisherSuccess, (state, action) => {
      state.selectedPublisher = action.payload
      state.isSaved = true
    })
    .addCase(resetPublisherFormState, () => initialState)
)
