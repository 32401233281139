import { numberWithCommas } from "./formatterHelper"
import _ from "lodash"
import {
  Segment,
  SegmentTreeNode,
  SegmentsByVendors,
  SegmentsTreeByVendor,
} from "../redux/types/segmentTypes"
import { SegmentGroup } from "../redux/types/audienceTypes"
import { TARGET_TYPES } from "../constants"
import { RtbxSegment } from "../redux/types/sharedTypes"

export const buildRecursiveTree = (segmentData: Segment[]): SegmentTreeNode[] => {
  const result = []

  const recurse = (hierarchy, segmentObj, currentNodeArray) => {
    if (!hierarchy.length) return

    const label = hierarchy.shift()
    // we want to group the items we find with the same label
    // in order to make the parent segment node have the population text and render children properly,
    // we needed to add additional logic to account for this case
    let nextNode = currentNodeArray.find((el) => el.label.split(" (population:")[0] === label)
    // First time we are seeing this node,
    if (!nextNode) {
      nextNode = {
        label: label.toString(),
        children: [],
        disabled: true,
      }
      // If we are looking at the very last node
      if (!hierarchy.length) {
        const segmentCopy = _.omit(segmentObj, "id")
        const segmentIPPopulation = segmentCopy.populations.find(
          (segmentObject) => segmentObject.segmentTargetTypeId === TARGET_TYPES.IP
        ).population
        const externalId = segmentCopy.externalId
        nextNode = {
          ...nextNode,
          ...segmentCopy,
          label: `${label.toString()} (population: ${numberWithCommas(
            segmentIPPopulation
          )}) (externalId: ${externalId.toString()})`,
          tagLabel: `${segmentCopy.hierarchy.join(" > ")} (${numberWithCommas(
            segmentIPPopulation
          )})`,
          disabled: false,
        }
        currentNodeArray.push(nextNode)
        return
      }
      currentNodeArray.push(nextNode)
    }
    recurse(hierarchy, segmentObj, nextNode.children)
  }

  segmentData.forEach((segmentObj) => {
    const hierarchy = _.clone(segmentObj.hierarchy)
    recurse(hierarchy, segmentObj, result)
  })

  return result
}

// example input
// [
//   [
//     {
//       name: "exampleSegmentGroup1Segment1",
//       segment: "1asvjleihc",
//     },
//     {
//       name: "exampleSegmentGroup1Segment2",
//       segment: "2bhcije",
//     },
//   ],
//   [
//     {
//       name: "exampleSegmentGroup2Segment1",
//       segment: "3gldjghe",
//     },
//   ],
// ]

// output
// [
//   {
//     name: "Group",
//     id: "omblc",
//     segments: ["1asvjleihc", "2bhcije"],
//   },
//   {
//     name: "Group",
//     id: "mks1c",
//     segments: ["3gldjghe"],
//   },
// ]
export const formatRtbxSegmentGroups = (segmentGroups: RtbxSegment[][]): SegmentGroup[] => {
  const segmentGroupsAsStringArrays: string[][] = segmentGroups.map((group) =>
    group.map((segment) => segment.segment)
  )

  return formatSegmentGroups(segmentGroupsAsStringArrays)
}

// example input
// [["1asvjleihc", "2bhcije"], ["3gldjghe"]]

// output
// [
//   {
//     name: "Group",
//     id: "omblc",
//     segments: ["1asvjleihc", "2bhcije"],
//   },
//   {
//     name: "Group",
//     id: "mks1c",
//     segments: ["3gldjghe"],
//   },
// ]
export const formatSegmentGroups = (segmentGroups: string[][]): SegmentGroup[] => {
  const formattedSegmentGroups: SegmentGroup[] = segmentGroups.map(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (segmentGroupSegments: any, i: number): SegmentGroup => {
      return { name: "Group", id: i.toString(), segments: segmentGroupSegments }
    }
  )

  // in the case where fetched audience has 0 segments, create a default selected segment group
  if (!formattedSegmentGroups.length) {
    formattedSegmentGroups.push({ name: "Group", id: "999999", segments: [] })
  }

  return formattedSegmentGroups
}

export const flattenFormattedSegmentGroups = (
  formattedSegmentGroups: SegmentGroup[]
): string[][] => {
  // compact strips out falsy values
  return _.compact(
    formattedSegmentGroups.map((segmentGroup) =>
      segmentGroup.segments.length > 0 ? segmentGroup.segments : null
    )
  )
}

export const condenseSegmentGroupsForAudiencePopulation = (
  segmentGroupsValue: SegmentGroup[]
): string[] => {
  return _.compact(
    segmentGroupsValue.map((segmentGroup) => {
      if (segmentGroup.segments?.length > 0) {
        return segmentGroup.segments.join()
      }
      return null
    })
  )
}

// Find segmentsTree in state for selected vendor id
export const getSegmentsTreeWithVendorId = (
  segmentTreesByVendorId: SegmentsTreeByVendor[],
  vendorId: number
): SegmentTreeNode[] => {
  const currentSegmentTreeVendor = segmentTreesByVendorId.find((segmentsTeeByVendor) => {
    if (segmentsTeeByVendor.vendorId === vendorId) {
      return segmentsTeeByVendor
    }
  })

  return currentSegmentTreeVendor ? currentSegmentTreeVendor.segmentTree : []
}

// Find segments in state for selected vendor id
export const getSegmentsWithVendorId = (
  segmentsByVendorId: SegmentsByVendors[],
  vendorId: number
): Segment[] => {
  const currentSegmentVendor = segmentsByVendorId.find((segmentsByVendor) => {
    if (segmentsByVendor.vendorId === vendorId) {
      return segmentsByVendor
    }
  })
  return currentSegmentVendor ? currentSegmentVendor.segments : []
}
