import { call, put, all, takeEvery } from "redux-saga/effects"
import { API_ROOT, API, SECTION } from "../../constants"
import { AxiosRequestConfig } from "axios"
import {
  fetchSegments,
  fetchSegmentsFailed,
  fetchSegmentsSuccess,
  setSegmentsLoadingFlag,
  setSegmentTree,
} from "../actions/segmentActions"
import { handleAxiosRequest, handleSagaError } from "../../helpers/sagaHelpers"
import { SagaIterator } from "redux-saga"
import { buildRecursiveTree } from "../../helpers/segmentHelpers"
import { PayloadAction } from "@reduxjs/toolkit"
import { FetchListPayload } from "../types/sharedTypes"

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* fetchSegmentsSaga(action: PayloadAction<FetchListPayload>) {
  const ajaxConfig: AxiosRequestConfig = {
    method: "POST",
    url: `${API_ROOT}${API.V1}${SECTION.ADMIN}/segments/find`,
    data: action.payload,
  }

  yield put(setSegmentsLoadingFlag(true))
  try {
    const response = yield call(handleAxiosRequest, ajaxConfig)
    const vendorId = action.payload.filters.segmentVendorId
    const segmentResponseObj = {
      segments: response.data,
      vendorId: vendorId,
    }
    yield all([
      put(fetchSegmentsSuccess(segmentResponseObj)),
      put(setSegmentTree({ segmentTree: buildRecursiveTree(response.data), vendorId: vendorId })),
    ])
  } catch (err) {
    yield handleSagaError(fetchSegmentsFailed, err)
  }
  yield put(setSegmentsLoadingFlag(false))
}

export function* watchFetchSegments(): SagaIterator {
  yield takeEvery(fetchSegments, fetchSegmentsSaga)
}
