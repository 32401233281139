import {
  clearSubdomains,
  closeSaveSubdomainSuccess,
  createSubdomainSuccess,
  fetchSubdomainsSuccess,
  setSubdomainsLoadingFlag,
  updateSubdomainSuccess,
} from "../actions/subdomainActions"

import { PaginatedComponentState } from "../types/sharedTypes"
import { Subdomain } from "../types/subdomainTypes"
import { createReducer } from "@reduxjs/toolkit"

interface InitialSubdomainState extends PaginatedComponentState {
  subdomains: Subdomain[]
  isLoading: boolean
  subdomainSuccessMessage: string
}

const initialState: InitialSubdomainState = {
  subdomains: [],
  isLoading: true,
  subdomainSuccessMessage: "",
  pager: {
    totalCount: 0,
    page: 0,
  },
}

export default createReducer(initialState, (builder) =>
  builder
    /*================================ Subdomain List action handlers ================================*/
    .addCase(setSubdomainsLoadingFlag, (state, action) => {
      state.isLoading = action.payload
    })
    .addCase(fetchSubdomainsSuccess, (state, action) => {
      state.subdomains = action.payload.subdomains
      state.pager = action.payload.pager
    })
    .addCase(clearSubdomains, () => initialState)

    /*================================ Subdomain Form action handlers ================================*/
    .addCase(createSubdomainSuccess, (state, action) => {
      state.subdomainSuccessMessage = `Successfully created subdomain: ${action.payload.name} (${action.payload.id})`
    })
    .addCase(updateSubdomainSuccess, (state, action) => {
      state.subdomainSuccessMessage = `Successfully updated subdomain: ${action.payload.name} (${action.payload.id})`
    })
    .addCase(closeSaveSubdomainSuccess, (state) => {
      state.subdomainSuccessMessage = initialState.subdomainSuccessMessage
    })
)
