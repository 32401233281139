import { FeatureFlag, FetchFeatureFlagsSuccessResponse } from "../types/featureFlagTypes"
import { createAction } from "@reduxjs/toolkit"

/* ===================================== FeatureFlag List actions ====================================== */
export const fetchFeatureFlags = createAction("Effects_FETCH_FEATURE_FLAGS")
export const fetchFeatureFlagByTerm = createAction<string>("Effects_FETCH_FEATURE_FLAG_BY_TERM")
export const fetchFeatureFlagsSuccess = createAction<FetchFeatureFlagsSuccessResponse>(
  "FETCH_FEATURE_FLAGS_SUCCESS"
)
export const fetchFeatureFlagByTermSuccess = createAction<FetchFeatureFlagsSuccessResponse>(
  "FETCH_FEATURE_FLAG_BY_TERM_SUCCESS"
)
export const updateFeatureFlags = createAction<FeatureFlag[]>("Effects_UPDATE_FEATURE_FLAGS")

export const setFeatureFlagsLoadingFlag = createAction<boolean>("SET_FEATURE_FLAGS_LOADING_FLAG")
export const setFeatureFlagsSubmittingFlag = createAction<boolean>(
  "SET_FEATURE_FLAGS_SUBMITTING_FLAG"
)
export const fetchFeatureFlagsFailed = createAction<string>("FETCH_FEATURE_FLAGS_FAILED")
export const updateFeatureFlagsFailed = createAction<string>("UPDATE_FEATURE_FLAGS_FAILED")
export const updateFeatureFlagsSuccess = createAction<string>("UPDATE_FEATURE_FLAGS_SUCCESS")

export const clearFeatureFlags = createAction("CLEAR_FEATURE_FLAGS")
export const clearSaveFeatureFlagSuccess = createAction("CLEAR_SAVE_FEATURE_FLAGS_SUCCESS")
