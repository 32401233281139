import * as Yup from "yup"

import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  MenuItem,
  TextField,
  withStyles,
} from "@material-ui/core"
import { BreadCrumb, HeaderInfo } from "../../../../../../redux/types/newHeaderTypes"
import { ENTITY_STATUSES, PERMISSIONS } from "../../../../../../constants"
import React, { useEffect } from "react"
import { RootState, useAppDispatch } from "../../../../../../redux/store"
import { SaveTagPayload, Tag } from "../../../../../../redux/types/tagTypes"
import {
  createTag,
  fetchTagById,
  resetTagFormState,
  updateTag,
} from "../../../../../../redux/actions/tagActions"
import {
  fetchPublisherById,
  resetPublisherFormState,
} from "../../../../../../redux/actions/publisherActions"
import { fetchSiteById, resetSiteFormState } from "../../../../../../redux/actions/siteActions"

import { Autocomplete } from "@material-ui/lab"
import { Edit as EditIcon } from "react-feather"
import ErrorMessages from "../../../../../common/Errors"
import { Formik } from "formik"
import Header from "../../../../../NewHeader/NewHeader"
import { Publisher } from "../../../../../../redux/types/publisherTypes"
import { Site } from "../../../../../../redux/types/siteTypes"
import clsx from "clsx"
import styles from "./styles"
import { trimPayloadProperties } from "../../../../../../helpers/formatterHelper"
import { useHistory } from "react-router"
import { useSelector } from "react-redux"

export const buildHeaderInfo = (
  isCreate,
  isEdit,
  isInfo,
  publisher: Publisher = {} as Publisher,
  site = {} as Site,
  tag = {} as Tag
): HeaderInfo => {
  let headerText
  const breadcrumbs: BreadCrumb[] = [
    {
      name: "Dashboard",
      route: "/",
    },
    {
      name: "Publishers",
      route: "/admin/publishers",
    },
    {
      name: `${publisher.name} (${publisher.id})`,
      route: `/admin/publishers/${publisher.id}`,
    },
    {
      name: "Sites",
      route: `/admin/publishers/${publisher.id}/sites`,
    },
    {
      name: `${site.name} (${site.id})`,
      route: `/admin/publishers/${publisher.id}/sites/${site.id}`,
    },
    {
      name: "Tags",
      route: `/admin/publishers/${publisher.id}/sites/${site.id}/tags`,
    },
  ]
  const headerButtons = []

  if (isCreate) {
    headerText = "Create New Tag"
    breadcrumbs.push({
      name: `Create`,
    })
  }

  if (isEdit && tag.id) {
    headerText = `Tag: ${tag.name} (${tag.id})`
    breadcrumbs.push({
      name: `${tag.name} (${tag.id})`,
      route: `/admin/publishers/${publisher.id}/sites/${site.id}/tags/${tag.id}`,
    })
    breadcrumbs.push({
      name: `Edit`,
    })
  }

  if (isInfo && tag.id) {
    headerText = `Tag: ${tag.name} (${tag.id})`
    breadcrumbs.push({
      name: `${tag.name} (${tag.id})`,
    })

    headerButtons.push({
      name: "EDIT",
      route: `/admin/publishers/${publisher.id}/sites/${site.id}/tags/${tag.id}/edit`,
      permission: PERMISSIONS.ADMIN.PUBLISHER.UPDATE,
      icon: <EditIcon />,
    })
  }

  return {
    headerText,
    breadcrumbs,
    headerButtons,
  }
}

const TagForm = ({ classes, match }) => {
  const dispatch = useAppDispatch()
  const tag = useSelector((state: RootState) => state.tagForm.selectedTag)
  const publisher = useSelector((state: RootState) => state.publisherForm.selectedPublisher)
  const site = useSelector((state: RootState) => state.siteForm.selectedSite)
  const isSaved = useSelector((state: RootState) => state.tagForm.isSaved)
  const isLoading = useSelector((state: RootState) => state.tagForm.isLoading)
  const isSubmitting = useSelector((state: RootState) => state.tagForm.isSubmitting)
  const history = useHistory()
  const endPath = match.path.split("/").pop()

  const isEdit = endPath === "edit"
  const isCreate = endPath === "create"
  const isInfo = !isEdit && !isCreate

  useEffect(() => {
    // when the tag is successfully created navigate back to tags list
    isSaved && history.push(`/admin/publishers/${publisher.id}/sites/${site.id}/tags`)
  }, [tag, isSaved])

  useEffect(() => {
    dispatch(fetchPublisherById(match.params.publisherId))
    dispatch(fetchSiteById({ publisherId: match.params.publisherId, siteId: match.params.siteId }))
    // clean up stale data when component unmounts
    return () => {
      dispatch(resetPublisherFormState())
      dispatch(resetSiteFormState())
      dispatch(resetTagFormState())
    }
  }, [])

  // separate useEffects are necessary due to timing issues where navigating from view to edit will result in fields clearing
  useEffect(() => {
    if (!isCreate && !tag.id)
      dispatch(
        fetchTagById({
          publisherId: match.params.publisherId,
          siteId: match.params.siteId,
          tagId: match.params.tagId,
        })
      )
  }, [tag])

  const initialFormValues = {
    name: tag.name || "",
    status: tag.status || ENTITY_STATUSES.ACTIVE,
    deals: tag.deals || [],
    hardfloor: tag.hardfloor || 0,
    siteCode: tag.siteCode || "",
    publisherCode: tag.publisherCode || "",
    code: tag.code || "",
    isRTB: tag.isRTB ? "true" : "false",
    blocklisted: tag.blocklisted ? "true" : "false",
    appNexusTagId: tag.appNexusTagId || "N/A",
  }

  const formValidationSchema = Yup.object({
    name: Yup.string().max(255).required(),
    status: Yup.string(),
    deals: Yup.array(),
    hardfloor: Yup.number()
      .min(0, "Please select a value between 0 and 99")
      .max(99, "Please select a value between 0 and 99")
      .required(),
    siteCode: Yup.string(),
    publisherCode: Yup.string().when(["isRTB"], {
      is: (isRTB) => isRTB === "true",
      then: Yup.string().required("Publisher Code is required if isRTB is selected"),
    }),
    code: Yup.string(),
    isRTB: Yup.string(),
    blocklisted: Yup.string(),
  })

  const tagFormStatusOptions = [
    { value: ENTITY_STATUSES.ACTIVE, label: "Active" },
    { value: ENTITY_STATUSES.INACTIVE, label: "Inactive" },
  ]
  const booleanOptions = [
    { value: "true", label: "Yes" },
    { value: "false", label: "No" },
  ]

  const handleFormikSubmit = (values) => {
    values.isRTB = values.isRTB === "true"
    values.blocklisted = values.blocklisted === "true"
    if (!isEdit) {
      dispatch(
        createTag(
          trimPayloadProperties({
            ...values,
            publisherId: parseInt(match.params.publisherId, 10),
            siteId: parseInt(match.params.siteId, 10),
          }) as SaveTagPayload
        )
      )
    } else if (isEdit) {
      dispatch(
        updateTag(
          trimPayloadProperties({
            ...values,
            id: tag.id,
            publisherId: parseInt(match.params.publisherId, 10),
            siteId: parseInt(match.params.siteId, 10),
          }) as SaveTagPayload
        )
      )
    }
  }

  const { headerText, breadcrumbs, headerButtons } = buildHeaderInfo(
    isCreate,
    isEdit,
    isInfo,
    publisher,
    site,
    tag
  )

  return (
    <>
      <Header headerText={headerText} breadcrumbs={breadcrumbs} buttons={headerButtons} />
      {!isLoading ? (
        <Box mt={3} mb={3} className={clsx({ isInfo: classes.cursorOverrideDisable })}>
          <Formik
            enableReinitialize={true}
            initialValues={initialFormValues}
            validationSchema={formValidationSchema}
            onSubmit={(values) => handleFormikSubmit(values)}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldTouched,
              setFieldValue,
              touched,
              values,
            }) => {
              return (
                <>
                  <ErrorMessages />
                  <form onSubmit={handleSubmit} className={clsx(isInfo && classes.overrideDisable)}>
                    <Card>
                      <CardContent className={classes.tagFormCardLayout}>
                        <Grid container spacing={3} className={classes.roundedBorder}>
                          {/* Tag Name */}
                          <Grid item md={6} xs={12}>
                            <TextField
                              error={!!(touched.name && errors.name)}
                              fullWidth
                              helperText={touched.name && errors.name}
                              label={
                                <span className={clsx(isInfo && classes.overrideDisable)}>
                                  Tag name
                                </span>
                              }
                              name="name"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              required
                              value={values.name}
                              variant="outlined"
                              inputProps={{ "data-testid": "tag-form-name-input" }}
                              InputProps={{
                                classes: {
                                  disabled: classes.overrideDisable,
                                },
                              }}
                              disabled={isInfo}
                            />
                          </Grid>

                          {/* Status */}
                          <Grid item md={2} xs={12}>
                            <TextField
                              select
                              label={
                                <span className={clsx(isInfo && classes.overrideDisable)}>
                                  Status
                                </span>
                              }
                              name="status"
                              error={!!(touched.status && errors.status)}
                              helperText={touched.status && errors.status}
                              value={values.status}
                              onChange={handleChange}
                              className={classes.dropDownField}
                              inputProps={{
                                "data-testid": "tag-form-status-input",
                              }}
                              InputProps={{ classes: { disabled: classes.overrideDisable } }}
                              disabled={isInfo}
                            >
                              {tagFormStatusOptions.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Grid>

                          {/* Deals */}
                          <Grid item md={6} xs={12}>
                            <Autocomplete
                              options={[]}
                              value={values.deals}
                              multiple
                              fullWidth
                              freeSolo
                              data-testid={"tag-form-deals-input"}
                              onBlur={() => setFieldTouched("deals")}
                              onChange={(e, newValue) => {
                                setFieldValue("deals", newValue)
                              }}
                              renderInput={(params) => {
                                return (
                                  <TextField
                                    {...params}
                                    label={
                                      <span className={clsx(isInfo && classes.overrideDisable)}>
                                        Deals
                                      </span>
                                    }
                                    name="deals"
                                    error={!!(touched.deals && errors.deals)}
                                    helperText={touched.deals && errors.deals}
                                    fullWidth
                                    variant="outlined"
                                    InputProps={{
                                      ...params.InputProps,
                                      classes: {
                                        disabled: classes.overrideDisable,
                                      },
                                    }}
                                  />
                                )
                              }}
                              disabled={isInfo}
                            />
                          </Grid>

                          {/* Hardfloor */}
                          <Grid item md={2} xs={12}>
                            <TextField
                              label={
                                <span className={clsx(isInfo && classes.overrideDisable)}>
                                  Hardfloor
                                </span>
                              }
                              name="hardfloor"
                              type="number"
                              error={!!(touched.hardfloor && errors.hardfloor)}
                              helperText={touched.hardfloor && errors.hardfloor}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              required
                              value={values.hardfloor}
                              className={classes.dropDownField}
                              inputProps={{
                                "data-testid": "tag-form-hardfloor-input",
                              }}
                              InputProps={{
                                classes: {
                                  disabled: classes.overrideDisable,
                                },
                              }}
                              onWheel={(e) => (e.target as HTMLElement).blur()}
                              disabled={isInfo}
                            />
                          </Grid>

                          {/* Site Code */}
                          <Grid item md={6} xs={12}>
                            <TextField
                              error={!!(touched.siteCode && errors.siteCode)}
                              fullWidth
                              helperText={touched.siteCode && errors.siteCode}
                              label={
                                <span className={clsx(isInfo && classes.overrideDisable)}>
                                  Site Code
                                </span>
                              }
                              name="siteCode"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.siteCode}
                              variant="outlined"
                              inputProps={{ "data-testid": "tag-form-site-code-input" }}
                              InputProps={{
                                classes: {
                                  disabled: classes.overrideDisable,
                                },
                              }}
                              disabled={isInfo}
                            />
                          </Grid>

                          {/* Blocklisted */}
                          <Grid item md={2} xs={12}>
                            <TextField
                              select
                              label={
                                <span className={clsx(isInfo && classes.overrideDisable)}>
                                  Blocklisted
                                </span>
                              }
                              name="blocklisted"
                              error={!!(touched.blocklisted && errors.blocklisted)}
                              helperText={touched.blocklisted && errors.blocklisted}
                              value={values.blocklisted}
                              onChange={handleChange}
                              className={classes.dropDownField}
                              inputProps={{
                                "data-testid": "tag-form-blocklisted-input",
                              }}
                              InputProps={{
                                classes: {
                                  disabled: classes.overrideDisable,
                                },
                              }}
                              disabled={isInfo}
                            >
                              {booleanOptions
                                ? booleanOptions.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                      {option.label}
                                    </MenuItem>
                                  ))
                                : null}
                            </TextField>
                          </Grid>

                          {/* Publisher Code */}
                          <Grid item md={6} xs={12}>
                            <TextField
                              error={!!(touched.publisherCode && errors.publisherCode)}
                              fullWidth
                              helperText={touched.publisherCode && errors.publisherCode}
                              label={
                                <span className={clsx(isInfo && classes.overrideDisable)}>
                                  Publisher Code
                                </span>
                              }
                              name="publisherCode"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.publisherCode}
                              variant="outlined"
                              inputProps={{ "data-testid": "tag-form-publisher-code-input" }}
                              InputProps={{
                                classes: {
                                  disabled: classes.overrideDisable,
                                },
                              }}
                              disabled={isInfo}
                            />
                          </Grid>

                          {/* isRTB */}
                          <Grid item md={2} xs={12}>
                            <TextField
                              select
                              label={
                                <span className={clsx(isInfo && classes.overrideDisable)}>
                                  Is RTB
                                </span>
                              }
                              name="isRTB"
                              error={!!(touched.isRTB && errors.isRTB)}
                              helperText={touched.isRTB && errors.isRTB}
                              value={values.isRTB}
                              onChange={handleChange}
                              className={classes.dropDownField}
                              inputProps={{
                                "data-testid": "tag-form-is-rtb-input",
                              }}
                              InputProps={{
                                classes: {
                                  disabled: classes.overrideDisable,
                                },
                              }}
                              disabled={isInfo}
                            >
                              {booleanOptions
                                ? booleanOptions.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                      {option.label}
                                    </MenuItem>
                                  ))
                                : null}
                            </TextField>
                          </Grid>

                          {/* Code */}
                          <Grid item md={6} xs={12}>
                            <TextField
                              error={!!(touched.code && errors.code)}
                              fullWidth
                              helperText={touched.code && errors.code}
                              label={
                                <span className={clsx(isInfo && classes.overrideDisable)}>
                                  Code
                                </span>
                              }
                              name="code"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.code}
                              variant="outlined"
                              inputProps={{ "data-testid": "tag-form-code-input" }}
                              InputProps={{
                                classes: {
                                  disabled: classes.overrideDisable,
                                },
                              }}
                              disabled={isInfo}
                            />
                          </Grid>

                          {/*AppNexus Tag Id*/}
                          <Grid item md={2} xs={12}>
                            <TextField
                              label={
                                <span className={clsx(isInfo && classes.overrideDisable)}>
                                  AppNexus Tag ID
                                </span>
                              }
                              name="appNexusTagId"
                              value={values.appNexusTagId}
                              className={clsx(classes.dropDownField, classes.disableInputCursor)}
                              inputProps={{
                                "data-testid": "tag-form-app-nexus-tag-id-input",
                                readOnly: true,
                              }}
                              InputProps={{
                                classes: {
                                  disabled: classes.overrideDisable,
                                },
                              }}
                            />
                          </Grid>
                        </Grid>

                        <Box mt={2}>
                          {!isInfo && (
                            <Button
                              variant="contained"
                              color="secondary"
                              type="submit"
                              disabled={isSubmitting}
                              data-testid={"tag-form-submit-button"}
                            >
                              {isCreate && "Create Tag"}
                              {isEdit && "Update Tag"}
                            </Button>
                          )}
                        </Box>
                      </CardContent>
                    </Card>
                  </form>
                </>
              )
            }}
          </Formik>
        </Box>
      ) : (
        <Card>
          <CardContent className={classes.loadingForm}>
            <CircularProgress />
          </CardContent>
        </Card>
      )}
    </>
  )
}

export default withStyles(styles)(TagForm)
