import { API, API_ROOT, SECTION } from "../../constants"
import { call, put, takeEvery } from "redux-saga/effects"
import {
  fetchAndExportPublishers,
  fetchAndExportPublishersFailed,
  fetchAndExportPublishersSuccess,
  setLoadingPublishersForExportFlag,
} from "../actions/publisherActions"
import { handleAxiosRequest, handleSagaError } from "../../helpers/sagaHelpers"

import { AxiosRequestConfig } from "axios"
import { FetchAndExportPublishersPayload } from "../types/publisherTypes"
import { PayloadAction } from "@reduxjs/toolkit"
import { SagaIterator } from "redux-saga"
import { clearErrors } from "../actions/errorActions"
import { exportPublishersAsCsv } from "../../helpers/csvHelpers"

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* fetchAndExportPublishersSaga(
  action: PayloadAction<FetchAndExportPublishersPayload>
) {
  const ajaxConfig: AxiosRequestConfig = {
    method: "POST",
    url: `${API_ROOT}${API.V1}${SECTION.ADMIN}/publishers/export`,
    data: action.payload,
  }

  yield put(clearErrors())
  yield put(setLoadingPublishersForExportFlag(true))
  try {
    const response = yield call(handleAxiosRequest, ajaxConfig)
    exportPublishersAsCsv(response.data, action.payload)
    yield put(fetchAndExportPublishersSuccess("Successfully exported publishers"))
  } catch {
    yield handleSagaError(fetchAndExportPublishersFailed, "Failed to export publishers")
  }
  yield put(setLoadingPublishersForExportFlag(false))
}

export function* watchFetchAndExportPublishers(): SagaIterator {
  yield takeEvery(fetchAndExportPublishers, fetchAndExportPublishersSaga)
}
