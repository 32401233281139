import { API, API_ROOT, SECTION } from "../../constants"
import { call, put, takeEvery } from "redux-saga/effects"
import {
  fetchSubdomains,
  fetchSubdomainsFailed,
  fetchSubdomainsSuccess,
  setSubdomainsLoadingFlag,
} from "../actions/subdomainActions"
import { handleAxiosRequest, handleSagaError } from "../../helpers/sagaHelpers"

import { AxiosRequestConfig } from "axios"
import { FetchSubdomainsPayload } from "../types/subdomainTypes"
import { PayloadAction } from "@reduxjs/toolkit"
import { SagaIterator } from "redux-saga"
import _ from "lodash"
import { clearErrors } from "../actions/errorActions"

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* fetchSubdomainsSaga(action: PayloadAction<FetchSubdomainsPayload>) {
  const ajaxConfig: AxiosRequestConfig = {
    method: "POST",
    url: `${API_ROOT}${API.V1}${SECTION.ADMIN}/domains/${action.payload.domainId}/subdomains/find`,
    data: _.omit(action.payload, "domainId"),
  }
  yield put(clearErrors())
  yield put(setSubdomainsLoadingFlag(true))
  try {
    const response = yield call(handleAxiosRequest, ajaxConfig)
    const page = parseInt(response.headers["x-pagination-page"], 10)
    const totalCount = parseInt(response.headers["x-pagination-total-count"], 10)
    yield put(
      fetchSubdomainsSuccess({
        subdomains: response.data,
        pager: {
          page,
          totalCount,
        },
      })
    )
  } catch (err) {
    yield handleSagaError(fetchSubdomainsFailed, err)
  }
  yield put(setSubdomainsLoadingFlag(false))
}

export function* watchFetchSubdomains(): SagaIterator {
  yield takeEvery(fetchSubdomains, fetchSubdomainsSaga)
}
