import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit"

import createSagaMiddleware from "redux-saga"
import reducer from "./reducers/rootReducer"
import rootSaga from "./sagas"
import { useDispatch } from "react-redux"

const sagaMiddleware = createSagaMiddleware()
const middleware = [...getDefaultMiddleware({ thunk: false }), sagaMiddleware]

const store = configureStore({
  reducer,
  middleware,
})
sagaMiddleware.run(rootSaga)

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch
export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>()

export default store
