export interface Identifier {
  name: string
  value: string
}

export enum SELLER_TYPE {
  PUBLISHER = "PUBLISHER",
  INTERMEDIARY = "INTERMEDIARY",
  BOTH = "BOTH",
}

export interface Seller {
  sellerId?: string
  isConfidential?: boolean
  sellerType: SELLER_TYPE
  isPassthrough: boolean
  name?: string
  domain?: string
  comment?: string
}

export interface SellersJson {
  contactEmail: string
  contactAddress: string
  version: number
  sellers: Seller[]
  identifiers: Identifier[]
}
