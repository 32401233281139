import React, { useEffect } from "react"
import { RootState, useAppDispatch } from "./redux/store"
import { fetchCurrentUser, updateUserLastLogin } from "./redux/actions/userActions"

import Loading from "./components/Loading/Loading"
import Maintenance from "./components/Maintenance"
import { renderRoutes } from "react-router-config"
import routes from "./routes"
import { useSelector } from "react-redux"

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Authorization = ({ auth0User }) => {
  const dispatch = useAppDispatch()
  const isLoadingCurrentUser = useSelector(
    (state: RootState) => state.currentUser.isLoadingCurrentUser
  )
  const currentUser = useSelector((state: RootState) => state.currentUser.currentUser)
  const retries = useSelector((state: RootState) => state.currentUser.retries)

  useEffect(() => {
    retries < 5 && setTimeout(() => dispatch(fetchCurrentUser()))
  }, [retries])

  if (isLoadingCurrentUser) {
    return retries > 0 ? (
      <Loading message={"Hmmm... Something went wrong loading user data. Retrying..."} />
    ) : (
      <Loading />
    )
  } else {
    if (currentUser.id) {
      if (auth0User) {
        dispatch(
          updateUserLastLogin({
            loggedInAt: auth0User.updated_at.toLocaleString(),
          })
        )
      }
      return renderRoutes(routes(currentUser.dataViews))
    } else if (retries === 5) {
      return <Maintenance />
    }
    return <Loading />
  }
}

export default Authorization
