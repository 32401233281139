import {
  ArrowRight as ArrowRightIcon,
  Edit as EditIcon,
  PlusCircle as PlusCircleIcon,
  Search as SearchIcon,
  Upload as UploadIcon,
} from "react-feather"
import {
  Box,
  Button,
  Card,
  CircularProgress,
  IconButton,
  InputAdornment,
  Link,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  withStyles,
} from "@material-ui/core"
import { BreadCrumb, HeaderInfo } from "../../../../../redux/types/newHeaderTypes"
import { ENTITY_STATUSES, PAGE_LIMIT, PERMISSIONS } from "../../../../../constants"
import React, { useCallback, useEffect, useState } from "react"
import { RootState, useAppDispatch } from "../../../../../redux/store"
import {
  clearPublishers,
  closeSavePublisherSuccess,
  closeUpdatePublisherSellersJsonError,
  fetchAndExportPublishers,
  fetchPublishers,
} from "../../../../../redux/actions/publisherActions"

import { Alert } from "@material-ui/lab"
import Can from "../../../../common/Can"
import ErrorMessages from "../../../../common/Errors"
import Header from "../../../../NewHeader"
import PerfectScrollbar from "react-perfect-scrollbar"
import { Link as RouterLink } from "react-router-dom"
import TablePaginationActions from "../../../../common/TablePaginationActions"
import { clearErrors } from "../../../../../redux/actions/errorActions"
import clsx from "clsx"
import { debounceFn } from "../../../../../helpers/utilityHelpers"
import styles from "./styles"
import { useSelector } from "react-redux"

export const buildHeaderInfo = (): HeaderInfo => {
  const headerText = "All Publishers"
  const headerButtons = [
    {
      name: "CREATE NEW PUBLISHER",
      route: `/admin/publishers/create`,
      permission: PERMISSIONS.ADMIN.PUBLISHER.CREATE,
      disabled: false,
      icon: <PlusCircleIcon />,
    },
  ]

  const breadcrumbs: BreadCrumb[] = [
    {
      name: "Dashboard",
      route: "/",
    },
    {
      name: "Publishers",
    },
  ]

  return {
    headerText,
    breadcrumbs,
    headerButtons,
  }
}

const PublishersList = ({ classes }) => {
  const dispatch = useAppDispatch()

  const isLoading = useSelector((state: RootState) => state.publishers.isLoading)
  const isLoadingPublishersForExport = useSelector(
    (state: RootState) => state.publishers.isLoadingPublishersForExport
  )
  const publishers = useSelector((state: RootState) => state.publishers.publishers)
  const publisherSuccessMessage = useSelector(
    (state: RootState) => state.publishers.publisherSuccessMessage
  )
  const updatePublisherSellersJsonErrorMessage = useSelector(
    (state: RootState) => state.publishers.updatePublisherSellersJsonErrorMessage
  )
  const pager = useSelector((state: RootState) => state.publishers.pager)

  const sortOptions = [
    {
      value: "id|DESC",
      label: "ID (descending)",
    },
    {
      value: "id|ASC",
      label: "ID (ascending)",
    },
    {
      value: "name|ASC",
      label: "Name (A-Z)",
    },
    {
      value: "name|DESC",
      label: "Name (Z-A)",
    },
  ]
  const [query, setQuery] = useState("")
  const publishersListStatusOptions = [
    { value: ENTITY_STATUSES.ALL, label: "All" },
    { value: ENTITY_STATUSES.ACTIVE, label: "Active" },
    { value: ENTITY_STATUSES.INACTIVE, label: "Inactive" },
  ]
  const [selectedPage, setSelectedPage] = useState(0)
  const [pageLimit, setPageLimit] = useState(PAGE_LIMIT.DEFAULT)
  const [publisherStatusFilter, setPublisherStatusFilter] = useState(ENTITY_STATUSES.ACTIVE)
  const [sortByFilter, setSortByFilter] = useState({
    value: "id",
    order: "DESC",
  })

  const fetchPublishersArgsObj = {
    pagination: {
      page: selectedPage,
      limit: pageLimit,
    },
    filters: {
      term: query,
      status: publisherStatusFilter,
    },
    sort: {
      value: sortByFilter.value,
      order: sortByFilter.order,
    },
  }
  publisherStatusFilter === ENTITY_STATUSES.ALL && delete fetchPublishersArgsObj.filters.status

  const dispatchFetchPublishers = (publishersSearchTerm: string = query) => {
    dispatch(
      fetchPublishers({
        ...fetchPublishersArgsObj,
        filters: {
          ...fetchPublishersArgsObj.filters,
          term: publishersSearchTerm,
        },
      })
    )
  }
  useEffect(dispatchFetchPublishers, [selectedPage, pageLimit, publisherStatusFilter, sortByFilter])

  useEffect(() => {
    return () => {
      dispatch(clearErrors())
      dispatch(clearPublishers())
      dispatch(closeSavePublisherSuccess())
    }
  }, [])

  const debounceSearch = useCallback(debounceFn(dispatchFetchPublishers), [
    pageLimit,
    publisherStatusFilter,
    sortByFilter,
  ])
  const handleStatusFilterChange = (e) => {
    setPublisherStatusFilter(e.target.value)
    setSelectedPage(0)
  }
  const handleQueryChange = (event) => {
    setQuery(event.target.value)
    debounceSearch(event.target.value)
  }
  const handleSortChange = (event) => {
    const [sortValue, orderValue] = event.target.value.split("|")
    setSortByFilter({
      value: sortValue,
      order: orderValue,
    })
    setSelectedPage(0)
  }

  const handlePageChange = (event, newPage) => {
    setSelectedPage(newPage)
  }
  const handleLimitChange = (event) => {
    setPageLimit(event.target.value)
    setSelectedPage(0)
  }

  const dispatchFetchAndExportPublishers = () => {
    dispatch(
      fetchAndExportPublishers({
        filters: {
          status: fetchPublishersArgsObj.filters.status,
          term: fetchPublishersArgsObj.filters.term,
        },
      })
    )
  }

  const { headerText, breadcrumbs, headerButtons } = buildHeaderInfo()

  return (
    <>
      <Header headerText={headerText} breadcrumbs={breadcrumbs} buttons={headerButtons} />
      <Box mt={3} mb={3}>
        <ErrorMessages />
        {publisherSuccessMessage.length > 0 && (
          <div data-testid={"publishers-list-publisher-success-message-banner"}>
            <Alert
              severity="success"
              key={publisherSuccessMessage}
              onClose={() => dispatch(closeSavePublisherSuccess())}
            >
              {publisherSuccessMessage}
            </Alert>
          </div>
        )}

        {updatePublisherSellersJsonErrorMessage.length > 0 && (
          <div data-testid={"publishers-list-update-publisher-sellers-json-error-message-banner"}>
            <Alert
              severity="error"
              key={updatePublisherSellersJsonErrorMessage}
              onClose={() => dispatch(closeUpdatePublisherSellersJsonError())}
            >
              {updatePublisherSellersJsonErrorMessage}
            </Alert>
          </div>
        )}

        {/* Search, Active Filter, Sort order */}
        <Card data-testid="publishers-list-table-container">
          <Box
            sx={{
              p: 2,
              pr: 0,
            }}
            minHeight={56}
            display="flex"
            alignItems="center"
          >
            {/* Search */}
            <TextField
              className={classes.queryField}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              onChange={handleQueryChange}
              placeholder="Search publishers"
              value={query}
              variant="outlined"
              data-testid={"publishers-list-search-input"}
            />

            {/* Status Filter */}
            <TextField
              label="Filter by Status"
              name="status-filter"
              className={classes.publishersStatusField}
              onChange={handleStatusFilterChange}
              select
              SelectProps={{ native: true }}
              value={`${publisherStatusFilter}`}
              variant="outlined"
              data-testid={"publishers-list-status-filter-input"}
            >
              {publishersListStatusOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>

            {/* Sort */}
            <TextField
              label="Sort By"
              name="sort"
              className={classes.publishersSortByField}
              onChange={handleSortChange}
              select
              SelectProps={{ native: true }}
              value={`${sortByFilter.value}|${sortByFilter.order}`}
              variant="outlined"
              data-testid={"publishers-list-sort-input"}
            >
              {sortOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
            <Tooltip title="Export Publishers List">
              <IconButton
                component={Button}
                onClick={dispatchFetchAndExportPublishers}
                disabled={isLoading || !publishers.length || isLoadingPublishersForExport}
                data-testid={"publishers-list-export-csv-button"}
              >
                <SvgIcon fontSize="small">
                  <UploadIcon />
                </SvgIcon>
              </IconButton>
            </Tooltip>

            <Box flexGrow={1} />
            <TablePagination
              component="div"
              count={pager.totalCount}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleLimitChange}
              page={selectedPage}
              rowsPerPage={pageLimit}
              rowsPerPageOptions={PAGE_LIMIT.OPTIONS}
              data-testid="publishers-list-pagination"
              ActionsComponent={TablePaginationActions}
            />
          </Box>
          <PerfectScrollbar>
            <Box minWidth={700}>
              <Table size="small">
                {/* Table Headers */}
                <TableHead>
                  <TableRow>
                    <TableCell data-testid={"publishers-list-id-table-column-header"}>ID</TableCell>
                    <TableCell data-testid={"publishers-list-status-table-column-header"}>
                      Status
                    </TableCell>
                    <TableCell data-testid={"publishers-list-name-table-column-header"}>
                      Name
                    </TableCell>
                    <TableCell data-testid={"publishers-list-app-nexus-id-table-column-header"}>
                      AppNexus ID
                    </TableCell>
                    <TableCell data-testid={"publishers-list-sites-table-column-header"}>
                      Sites
                    </TableCell>
                    <TableCell data-testid={"publishers-list-rtbx-enabled-table-column-header"}>
                      RTBx Enabled
                    </TableCell>
                    <TableCell
                      data-testid={"publishers-list-sellers-json-enabled-table-column-header"}
                    >
                      Sellers.json Enabled
                    </TableCell>
                    <Can
                      perform={[
                        PERMISSIONS.ADMIN.PUBLISHER.VIEW,
                        PERMISSIONS.ADMIN.PUBLISHER.UPDATE,
                      ]}
                      yes={
                        <TableCell
                          align="right"
                          data-testid={"publishers-list-actions-table-column-header"}
                        >
                          Actions
                        </TableCell>
                      }
                    />
                  </TableRow>
                </TableHead>

                {/* Table Body */}
                {isLoading ? (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={9} align="center">
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody data-testid="publishers-list-content">
                    {publishers.map((publisher) => {
                      return (
                        <TableRow hover key={publisher.id}>
                          {/* Id */}
                          <TableCell>
                            <Box display="flex" alignItems="center">
                              <div>
                                <Link
                                  color="inherit"
                                  component={RouterLink}
                                  to={`/admin/publishers/${publisher.id}/sites`}
                                  variant="h6"
                                  data-testid={`publishers-list-id-${publisher.id}`}
                                >
                                  {`${publisher.id}`}
                                </Link>
                              </div>
                            </Box>
                          </TableCell>

                          {/* Status */}
                          <TableCell data-testid={`publishers-list-status-${publisher.id}`}>
                            <small
                              className={clsx({
                                [classes.label]: true,
                                [classes.publisherStatusEnabled]:
                                  publisher.status === ENTITY_STATUSES.ACTIVE,
                                [classes.publisherStatusInactive]:
                                  publisher.status === ENTITY_STATUSES.INACTIVE,
                              })}
                            >
                              {publisher.status}
                            </small>
                          </TableCell>

                          {/* Name */}
                          <TableCell>
                            <Box display="flex" alignItems="center">
                              <div>
                                <Link
                                  color="inherit"
                                  component={RouterLink}
                                  to={`/admin/publishers/${publisher.id}/sites`}
                                  variant="h6"
                                  data-testid={`publishers-list-name-${publisher.id}`}
                                >
                                  {`${publisher.name}`}
                                </Link>
                              </div>
                            </Box>
                          </TableCell>

                          {/* AppNexus id */}
                          <TableCell data-testid={`publishers-list-app-nexus-id-${publisher.id}`}>
                            {publisher.appNexusId}
                          </TableCell>

                          {/* Site count and link */}
                          <TableCell data-testid={`publishers-list-sites-${publisher.id}`}>
                            <Button
                              color="secondary"
                              variant="contained"
                              to={`/admin/publishers/${publisher.id}/sites`}
                              component={RouterLink}
                              disabled={!publisher.sitesCount}
                              data-testid={`header-edit-button`}
                            >
                              {`List (${publisher.sitesCount})`}
                            </Button>
                          </TableCell>

                          {/* Rtbx Enabled */}
                          <TableCell data-testid={`publishers-list-rtbx-enabled-${publisher.id}`}>
                            {publisher.isRtbxEnabled ? "Yes" : "No"}
                          </TableCell>

                          {/* Sellers.json enabled */}
                          <TableCell
                            data-testid={`publishers-list-sellers-json-enabled-${publisher.id}`}
                          >
                            {publisher.isSellersJsonEnabled ? "Yes" : "No"}
                          </TableCell>

                          {/* Edit/Info Action buttons */}
                          <Can
                            perform={[
                              PERMISSIONS.ADMIN.PUBLISHER.VIEW,
                              PERMISSIONS.ADMIN.PUBLISHER.UPDATE,
                            ]}
                            yes={
                              <TableCell align="right">
                                <Can
                                  perform={PERMISSIONS.ADMIN.PUBLISHER.UPDATE}
                                  yes={
                                    <IconButton
                                      component={RouterLink}
                                      to={`/admin/publishers/${publisher.id}/edit`}
                                      data-testid={`publishers-list-edit-button-${publisher.id}`}
                                    >
                                      <SvgIcon fontSize="small">
                                        <EditIcon />
                                      </SvgIcon>
                                    </IconButton>
                                  }
                                />
                                <Can
                                  perform={PERMISSIONS.ADMIN.PUBLISHER.VIEW}
                                  yes={
                                    <IconButton
                                      component={RouterLink}
                                      to={`/admin/publishers/${publisher.id}`}
                                      data-testid={`publishers-list-view-button-${publisher.id}`}
                                    >
                                      <SvgIcon fontSize="small">
                                        <ArrowRightIcon />
                                      </SvgIcon>
                                    </IconButton>
                                  }
                                />
                              </TableCell>
                            }
                          />
                        </TableRow>
                      )
                    })}
                  </TableBody>
                )}
              </Table>
            </Box>
          </PerfectScrollbar>
          <TablePagination
            component="div"
            count={pager.totalCount}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={selectedPage}
            rowsPerPage={pageLimit}
            rowsPerPageOptions={PAGE_LIMIT.OPTIONS}
            data-testid="publishers-list-pagination"
            ActionsComponent={TablePaginationActions}
          />
        </Card>
      </Box>
      <Box sx={{ height: "24px" }} />
    </>
  )
}

export default withStyles(styles)(PublishersList)
