import {
  FetchAndExportTagsFromTagsPayload,
  FetchTagByIdPayload,
  FetchTagsPayload,
  FetchTagsSuccessResponse,
  SaveTagPayload,
  Tag,
} from "../types/tagTypes"

import { createAction } from "@reduxjs/toolkit"

/* ===================================== Tag List actions ====================================== */
export const fetchTags = createAction<FetchTagsPayload>("Effects_FETCH_TAGS")
export const fetchTagsSuccess = createAction<FetchTagsSuccessResponse>("FETCH_TAGS_SUCCESS")
export const setTagsLoadingFlag = createAction<boolean>("SET_TAGS_LOADING_FLAG")
export const fetchTagsFailed = createAction<string>("FETCH_TAGS_FAILED")

export const fetchAndExportTagsFromTags = createAction<FetchAndExportTagsFromTagsPayload>(
  "Effects_FETCH_AND_EXPORT_TAGS_FROM_TAGS"
)
export const fetchAndExportTagsFromTagsSuccess = createAction<string>(
  "FETCH_AND_EXPORT_TAGS_FROM_TAGS_SUCCESS"
)
export const fetchAndExportTagsFromTagsFailed = createAction<string>(
  "FETCH_AND_EXPORT_TAGS_FROM_TAGS_FAILED"
)

export const setLoadingTagsForExportTagsFlag = createAction<boolean>(
  "SET_LOADING_TAGS_FOR_EXPORT_TAGS_FLAG"
)

export const clearTags = createAction("CLEAR_TAGS")
export const closeSaveTagSuccess = createAction("CLOSE_SAVE_TAG_SUCCESS")

// /* ===================================== Tag Form actions ====================================== */
// Fetch Single Tag
export const fetchTagById = createAction<FetchTagByIdPayload>("Effects_FETCH_TAG_BY_ID")
export const fetchTagByIdSuccess = createAction<Tag>("FETCH_TAG_BY_ID_SUCCESS")
export const fetchTagByIdFailed = createAction<string>("FETCH_TAG_BY_ID_FAILED")

// Create
export const createTag = createAction<SaveTagPayload>("Effects_CREATE_TAG")
export const createTagSuccess = createAction<Tag>("CREATE_TAG_SUCCESS")
export const createTagFailed = createAction<string>("CREATE_TAG_FAILED")

// Update
export const updateTag = createAction<SaveTagPayload>("Effects_UPDATE_TAG")
export const updateTagSuccess = createAction<Tag>("UPDATE_TAG_SUCCESS")
export const updateTagFailed = createAction<string>("UPDATE_TAG_FAILED")

// Shared form actions
export const setTagFormLoadingFlag = createAction<boolean>("SET_TAG_FORM_LOADING_FLAG")
export const setTagFormSubmittingFlag = createAction<boolean>("SET_TAG_FORM_SUBMITTING_FLAG")
export const resetTagFormState = createAction("RESET_TAG_FORM_STATE")
