import axios, { AxiosPromise, AxiosRequestConfig } from "axios"
import { call, put } from "redux-saga/effects"

import { ERRORS } from "../constants"
import { SagaIterator } from "redux-saga"
import _ from "lodash"
import { forwardTo } from "./historyHelper"
import { getToken } from "./loginServices"

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* handleSagaError(action, error): SagaIterator {
  if (typeof error === "string") {
    yield put(action(error))
    return
  }

  if (_.get(error, "response.data.message")) {
    if (error.response.data.message === "Invalid token") {
      yield call(forwardTo, "/logout")
    } else if (_.get(error, "response.data.validations")) {
      yield call(handleSagaValidationErrors, action, error.response.data)
    } else {
      yield put(action(error.response.data.message))
    }
  } else if (_.get(error, "message") === "Network Error") {
    yield put(action("There was an error connecting to the server"))
  } else {
    yield put(action(ERRORS.UNKNOWN))
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* handleSagaValidationErrors(action, error): SagaIterator {
  const { validations, message } = error
  !validations.length && (yield put(action(message)))

  const constraintErrors = []
  for (let i = 0; i < validations.length; i += 1) {
    const validation = validations[i]
    if (validation.constraints) {
      _.forOwn(validation.constraints, (constraint) => {
        constraintErrors.push(constraint)
      })
    }
  }
  for (let i = 0; i < constraintErrors.length; i += 1) {
    const constraintError = constraintErrors[i]
    yield put(action(constraintError))
  }
}

export function handleAxiosRequest(config: AxiosRequestConfig): AxiosPromise {
  config.headers = config.headers || {}
  config.headers.Authorization = `Bearer ${getToken()}`
  return axios(config)
}

export function handleCustomAxiosRequest(config: AxiosRequestConfig): AxiosPromise {
  config.headers = config.headers || {}

  return axios(config)
}

export function handleSSLogout(): AxiosPromise {
  return handleCustomAxiosRequest({
    method: "GET",
    url: `${process.env.CAMPAIGN_BI_PMG_BASE_URL}/logout/`,
    withCredentials: true,
  })
}
