import {
  ArrowRight as ArrowRightIcon,
  Edit as EditIcon,
  PlusCircle as PlusCircleIcon,
  Search as SearchIcon,
} from "react-feather"
import {
  Box,
  Card,
  Checkbox,
  CircularProgress,
  IconButton,
  InputAdornment,
  Link,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  withStyles,
} from "@material-ui/core"
import { BreadCrumb, HeaderInfo } from "../../../../../redux/types/newHeaderTypes"
import { PAGE_LIMIT, PERMISSIONS } from "../../../../../constants"
import React, { useCallback, useEffect, useState } from "react"
import { RootState, useAppDispatch } from "../../../../../redux/store"
import {
  clearCompanies,
  closeSaveCompanySuccess,
  fetchCompanies,
} from "../../../../../redux/actions/companyActions"
import { clearErrors, closeError } from "../../../../../redux/actions/errorActions"

import { Alert } from "@material-ui/lab"
import Can from "../../../../common/Can"
import Header from "../../../../NewHeader"
import PerfectScrollbar from "react-perfect-scrollbar"
import { Link as RouterLink } from "react-router-dom"
import TablePaginationActions from "../../../../common/TablePaginationActions"
import { debounceFn } from "../../../../../helpers/utilityHelpers"
import styles from "./styles"
import { useSelector } from "react-redux"

export const buildHeaderInfo = (): HeaderInfo => {
  const headerText = "All Companies"
  const headerButtons = [
    {
      name: "CREATE NEW COMPANY",
      route: `/admin/companies/create`,
      permission: PERMISSIONS.ADMIN.COMPANY.CREATE,
      disabled: false,
      icon: <PlusCircleIcon />,
    },
  ]

  const breadcrumbs: BreadCrumb[] = [
    {
      name: "Dashboard",
      route: "/",
    },
    {
      name: "Companies",
    },
  ]

  return {
    headerText,
    breadcrumbs,
    headerButtons,
  }
}

const CompaniesList = ({ classes }) => {
  const dispatch = useAppDispatch()

  const isLoading = useSelector((state: RootState) => state.companies.isLoading)
  const companies = useSelector((state: RootState) => state.companies.companies)
  const companySuccessMessage = useSelector(
    (state: RootState) => state.companies.companySuccessMessage
  )
  const errors = useSelector((state: RootState) => state.errors.generalErrors)
  const pager = useSelector((state: RootState) => state.companies.pager)

  const sortOptions = [
    {
      value: "id|DESC",
      label: "ID (descending)",
    },
    {
      value: "id|ASC",
      label: "ID (ascending)",
    },
    {
      value: "name|ASC",
      label: "Name (ascending)",
    },
    {
      value: "name|DESC",
      label: "Name (descending)",
    },
  ]

  const [selectedCompanies, setSelectedCompanies] = useState([])
  const [query, setQuery] = useState("")
  const [sortByFilter, setSortByFilter] = useState({
    value: "id",
    order: "DESC",
  })
  const [pageLimit, setPageLimit] = useState(PAGE_LIMIT.DEFAULT)
  const [selectedPage, setSelectedPage] = useState(0)

  const dispatchFetchCompanies = (value = query) => {
    dispatch(
      fetchCompanies({
        pagination: {
          limit: pageLimit,
          page: selectedPage,
        },
        sort: {
          value: sortByFilter.value,
          order: sortByFilter.order,
        },
        filters: {
          term: value,
        },
      })
    )
  }

  useEffect(dispatchFetchCompanies, [pageLimit, sortByFilter, selectedPage])

  useEffect(() => {
    return () => {
      dispatch(clearErrors())
      dispatch(clearCompanies())
      dispatch(closeSaveCompanySuccess())
    }
  }, [])

  const debounceSearch = useCallback(debounceFn(dispatchFetchCompanies), [pageLimit, sortByFilter])

  const handleQueryChange = (event) => {
    setQuery(event.target.value)
    debounceSearch(event.target.value)
    setSelectedPage(0)
  }
  const handleSortChange = (event) => {
    const [sortValue, orderValue] = event.target.value.split("|")
    setSortByFilter({
      value: sortValue,
      order: orderValue,
    })
    setSelectedPage(0)
  }
  const handleSelectAllCompanies = (event) => {
    setSelectedCompanies(event.target.checked ? companies.map((company) => company.id) : [])
  }
  const handleSelectOneCompany = (event, companyId) => {
    if (!selectedCompanies.includes(companyId)) {
      setSelectedCompanies((prevSelected) => [...prevSelected, companyId])
    } else {
      setSelectedCompanies((prevSelected) => prevSelected.filter((id) => id !== companyId))
    }
  }
  const handlePageChange = (event, newPage) => {
    setSelectedPage(newPage)
  }
  const handleLimitChange = (event) => {
    setPageLimit(event.target.value)
    setSelectedPage(0)
  }

  const selectedSomeCompanies =
    selectedCompanies.length > 0 && selectedCompanies.length < companies.length
  const selectedAllCompanies = selectedCompanies.length === companies.length && companies.length > 0

  const { headerText, breadcrumbs, headerButtons } = buildHeaderInfo()

  return (
    <>
      <Header headerText={headerText} breadcrumbs={breadcrumbs} buttons={headerButtons} />
      <Box mt={3} mb={3}>
        {errors.length > 0 &&
          errors.map((error, i) => (
            <Alert severity="error" key={`${error}-${i}`} onClose={() => dispatch(closeError(i))}>
              {error}
            </Alert>
          ))}
        {companySuccessMessage.length > 0 && (
          <Alert
            severity="success"
            key={companySuccessMessage}
            onClose={() => dispatch(closeSaveCompanySuccess())}
          >
            {companySuccessMessage}
          </Alert>
        )}
        <Card data-testid="companies-list-table-container">
          <Box
            sx={{
              p: 2,
              pr: 0,
            }}
            minHeight={56}
            display="flex"
            alignItems="center"
          >
            <TextField
              className={classes.queryField}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              onChange={handleQueryChange}
              placeholder="Search companies"
              value={query}
              variant="outlined"
              data-testid={"companies-list-search-input"}
            />
            <TextField
              label="Sort By"
              name="sort"
              onChange={handleSortChange}
              select
              SelectProps={{ native: true }}
              value={`${sortByFilter.value}|${sortByFilter.order}`}
              variant="outlined"
              data-testid={"companies-list-sort-input"}
            >
              {sortOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>

            <Box flexGrow={1} />
            <Box minHeight={56} display="flex" alignItems="right">
              <TablePagination
                component="div"
                count={pager.totalCount}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={selectedPage}
                rowsPerPage={pageLimit}
                rowsPerPageOptions={PAGE_LIMIT.OPTIONS}
                data-testid="companies-list-pagination"
                ActionsComponent={TablePaginationActions}
              />
            </Box>
          </Box>
          <PerfectScrollbar>
            <Box minWidth={700}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell width="5%" padding="checkbox">
                      <Checkbox
                        checked={selectedAllCompanies}
                        indeterminate={selectedSomeCompanies}
                        onChange={handleSelectAllCompanies}
                      />
                    </TableCell>
                    <TableCell data-testid={"companies-list-name-table-column-header"}>
                      Name
                    </TableCell>
                    <TableCell
                      data-testid={"companies-list-vendor-table-column-header"}
                      align="center"
                    >
                      Vendor
                    </TableCell>
                    <TableCell data-testid={"companies-list-status-table-column-header"}>
                      Status
                    </TableCell>
                    <Can
                      perform={[PERMISSIONS.ADMIN.COMPANY.VIEW, PERMISSIONS.ADMIN.COMPANY.UPDATE]}
                      yes={
                        <TableCell
                          align="right"
                          data-testid={"companies-list-actions-table-column-header"}
                        >
                          Actions
                        </TableCell>
                      }
                    />
                  </TableRow>
                </TableHead>
                {isLoading ? (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody data-testid={"companies-list-content"}>
                    {companies.map((company) => {
                      const isCompanySelected = selectedCompanies.includes(company.id)

                      return (
                        <TableRow hover key={company.id} selected={isCompanySelected}>
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isCompanySelected}
                              onChange={(event) => handleSelectOneCompany(event, company.id)}
                              value={isCompanySelected}
                            />
                          </TableCell>
                          <TableCell>
                            <Box display="flex" alignItems="center">
                              <div>
                                <Link
                                  color="inherit"
                                  component={RouterLink}
                                  to={`/admin/companies/${company.id}`}
                                  variant="h6"
                                  data-testid={`companies-list-name-${company.id}`}
                                >
                                  {company.name}
                                </Link>
                              </div>
                            </Box>
                          </TableCell>
                          <TableCell
                            data-testid={`companies-list-vendor-${company.id}`}
                            align="center"
                          >
                            {company.segmentVendors.map((option) => option.name).join(", ") || "-"}
                          </TableCell>
                          <TableCell data-testid={`companies-list-status-${company.id}`}>
                            {company.status}
                          </TableCell>
                          <Can
                            perform={[
                              PERMISSIONS.ADMIN.COMPANY.VIEW,
                              PERMISSIONS.ADMIN.COMPANY.UPDATE,
                            ]}
                            yes={
                              <TableCell align="right">
                                <Can
                                  perform={PERMISSIONS.ADMIN.COMPANY.UPDATE}
                                  yes={
                                    <IconButton
                                      component={RouterLink}
                                      to={`/admin/companies/${company.id}/edit`}
                                      data-testid={`companies-list-edit-button-${company.id}`}
                                    >
                                      <SvgIcon fontSize="small">
                                        <EditIcon />
                                      </SvgIcon>
                                    </IconButton>
                                  }
                                />
                                <Can
                                  perform={PERMISSIONS.ADMIN.COMPANY.VIEW}
                                  yes={
                                    <IconButton
                                      component={RouterLink}
                                      to={`/admin/companies/${company.id}`}
                                      data-testid={`companies-list-view-button-${company.id}`}
                                    >
                                      <SvgIcon fontSize="small">
                                        <ArrowRightIcon />
                                      </SvgIcon>
                                    </IconButton>
                                  }
                                />
                              </TableCell>
                            }
                          />
                        </TableRow>
                      )
                    })}
                  </TableBody>
                )}
              </Table>
            </Box>
          </PerfectScrollbar>
          <TablePagination
            component="div"
            count={pager.totalCount}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={selectedPage}
            rowsPerPage={pageLimit}
            rowsPerPageOptions={PAGE_LIMIT.OPTIONS}
            data-testid="companies-list-pagination"
            ActionsComponent={TablePaginationActions}
          />
        </Card>
      </Box>
      <Box sx={{ height: "24px" }} />
    </>
  )
}

export default withStyles(styles)(CompaniesList)
