import * as Yup from "yup"

import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Hidden,
  MenuItem,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core"
import { BreadCrumb, HeaderInfo } from "../../../../../redux/types/newHeaderTypes"
import { CreateRolePayload, Role, UpdateRolePayload } from "../../../../../redux/types/roleTypes"
import { ENTITY_STATUSES, PERMISSIONS } from "../../../../../constants"
import React, { useEffect } from "react"
import { RootState, useAppDispatch } from "../../../../../redux/store"
import { clearErrors, closeError } from "../../../../../redux/actions/errorActions"
import {
  createRole,
  fetchRoleById,
  resetRoleFormState,
  updateRole,
} from "../../../../../redux/actions/roleActions"
import {
  fetchPermissions,
  resetPermissionsState,
} from "../../../../../redux/actions/permissionActions"

import { Alert } from "@material-ui/lab"
import Can from "../../../../common/Can"
import { Edit as EditIcon } from "react-feather"
import EntityAssignmentTable from "../../../../common/EntityAssignmentTable"
import { Formik } from "formik"
import NewHeader from "../../../../NewHeader"
import _ from "lodash"
import clsx from "clsx"
import dayjs from "dayjs"
import { isPermitted } from "../../../../../helpers/permissionHelper"
import styles from "./styles"
import { trimPayloadProperties } from "../../../../../helpers/formatterHelper"
import { useHistory } from "react-router"
import { useSelector } from "react-redux"

export const buildHeaderInfo = (isCreate, isEdit, isInfo, role: Role = {} as Role): HeaderInfo => {
  let headerText
  const breadcrumbs: BreadCrumb[] = [
    {
      name: "Dashboard",
      route: "/",
    },
    {
      name: "Roles",
      route: "/admin/roles",
    },
  ]
  const headerButtons = []

  if (isCreate) {
    headerText = "Create New Role"
    breadcrumbs.push({
      name: `Create`,
    })
  }

  if (isEdit) {
    headerText = "Role: "
    if (role.id) {
      headerText = `Role: ${role.name} (${role.id})`
      breadcrumbs.push({
        name: `${role.name} (${role.id})`,
        route: `/admin/roles/${role.id}`,
      })
      breadcrumbs.push({
        name: `Edit`,
      })
    }
  }

  if (isInfo) {
    headerText = "Role: "
    if (role.id) {
      headerText = `Role: ${role.name} (${role.id})`
      breadcrumbs.push({
        name: `${role.name} (${role.id})`,
      })

      headerButtons.push({
        name: "EDIT",
        route: `/admin/roles/${role.id}/edit`,
        permission: PERMISSIONS.ADMIN.ROLE.UPDATE,
        icon: <EditIcon />,
      })
    }
  }

  return {
    headerText,
    breadcrumbs,
    headerButtons,
  }
}

const RoleForm = ({ classes, match }) => {
  const dispatch = useAppDispatch()
  const currentUser = useSelector((state: RootState) => state.currentUser.currentUser)
  const role = useSelector((state: RootState) => state.roleForm.selectedRole)
  const permissions = useSelector((state: RootState) => state.permissions.permissions)
  const isSaved = useSelector((state: RootState) => state.roleForm.isSaved)
  const isLoading = useSelector((state: RootState) => state.roleForm.isLoading)
  const isSubmitting = useSelector((state: RootState) => state.roleForm.isSubmitting)
  const generalErrors = useSelector((state: RootState) => state.errors.generalErrors)
  const history = useHistory()
  const endPath = match.path.split("/").pop()

  const isEdit = endPath === "edit"
  const isCreate = endPath === "create"
  const isInfo = !isEdit && !isCreate

  useEffect(() => {
    // checks if form is create or edit, if edit, fetch role info and pre-populate form data
    if ((isEdit || isInfo) && !role.id) dispatch(fetchRoleById(match.params.roleId))
    // when the role is successfully created navigate back to roles list
    isSaved && history.push("/admin/roles")
  }, [role, isSaved])

  useEffect(() => {
    // get full list of available permissions that can be assigned to a role
    if (isPermitted(PERMISSIONS.ADMIN.PERMISSION.LIST.VIEW, currentUser)) {
      dispatch(fetchPermissions())
    }
    // clean up stale data when component unmounts
    return () => {
      dispatch(clearErrors())
      dispatch(resetRoleFormState())
      dispatch(resetPermissionsState())
    }
  }, [])

  const initialFormValues = {
    name: role.name || "",
    description: role.description || "",
    notes: role.notes || "",
    status: role.status || "",
    permissions: role.permissions || [],
  }
  const formValidationSchema = Yup.object().shape({
    name: Yup.string().max(255),
    description: Yup.string().max(255),
    notes: Yup.string().max(255),
    status: Yup.string().max(255),
  })
  const handleFormikSubmit = (values) => {
    if (isCreate) {
      dispatch(createRole(trimPayloadProperties(_.omit(values, ["status"])) as CreateRolePayload))
    } else if (isEdit) {
      dispatch(
        updateRole(
          trimPayloadProperties({
            id: role.id,
            ...values,
          }) as UpdateRolePayload
        )
      )
    }
  }

  const statusOptions = [
    { value: ENTITY_STATUSES.ACTIVE, label: "Active" },
    { value: ENTITY_STATUSES.INACTIVE, label: "Inactive" },
    { value: ENTITY_STATUSES.ARCHIVED, label: "Archived" },
  ]

  const { headerText, breadcrumbs, headerButtons } = buildHeaderInfo(isCreate, isEdit, isInfo, role)

  return (
    <>
      <NewHeader headerText={headerText} breadcrumbs={breadcrumbs} buttons={headerButtons} />
      <Box mt={3} mb={3}>
        <Formik
          enableReinitialize={true}
          initialValues={initialFormValues}
          validationSchema={formValidationSchema}
          onSubmit={(values) => handleFormikSubmit(values)}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, setFieldValue, touched, values }) => {
            return (
              <>
                {generalErrors.length > 0 &&
                  generalErrors.map((error, i) => (
                    <Alert
                      severity="error"
                      key={`${error}-${i}`}
                      onClose={() => dispatch(closeError(i))}
                    >
                      {error}
                    </Alert>
                  ))}
                <form onSubmit={handleSubmit} className={clsx(isInfo && classes.overrideDisable)}>
                  <Card>
                    <CardContent className={classes.roleFormCardLayout}>
                      <Grid container spacing={3} className={classes.roundedBorder}>
                        {/* Role Name */}
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={!!(touched.name && errors.name)}
                            fullWidth
                            helperText={touched.name && errors.name}
                            label={
                              <span className={clsx(isInfo && classes.overrideDisable)}>
                                Role Name
                              </span>
                            }
                            name="name"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            required
                            value={values.name}
                            variant="outlined"
                            inputProps={{
                              "data-testid": "role-form-name-input",
                            }}
                            InputProps={{
                              classes: {
                                disabled: classes.overrideDisable,
                              },
                            }}
                            disabled={isLoading || isInfo}
                          />
                          {role.createdAt && (
                            <div
                              className={classes.timestamps}
                              data-testid={"role-form-timestamps"}
                            >
                              <Typography>
                                <b>Created on: </b>
                                {dayjs(role?.createdAt).format("M-D-YYYY h:mm a")}
                                <b> Last updated: </b>
                                {dayjs(role?.updatedAt).format("M-D-YYYY h:mm a")}
                              </Typography>
                            </div>
                          )}
                        </Grid>

                        {/* Role Status */}
                        {isEdit || isInfo ? (
                          <Grid item md={3} xs={12}>
                            <TextField
                              select
                              label={
                                <span className={clsx(isInfo && classes.overrideDisable)}>
                                  Role Status
                                </span>
                              }
                              name="status"
                              value={values.status}
                              onChange={(e) => {
                                setFieldValue("status", e.target.value)
                              }}
                              className={classes.dropDownField}
                              inputProps={{
                                "data-testid": "role-form-status-input",
                              }}
                              InputProps={{
                                classes: {
                                  disabled: classes.overrideDisable,
                                },
                              }}
                              disabled={isLoading || isInfo}
                            >
                              {statusOptions.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Grid>
                        ) : (
                          <Hidden smDown>
                            <Grid item md={3} />
                          </Hidden>
                        )}

                        {/* Role Description */}
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={!!(touched.description && errors.description)}
                            fullWidth
                            multiline
                            helperText={touched.description && errors.description}
                            label={
                              <span
                                className={clsx(
                                  isInfo && values.description && classes.overrideDisable
                                )}
                              >
                                Description
                              </span>
                            }
                            name="description"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.description}
                            variant="outlined"
                            inputProps={{
                              "data-testid": "role-form-description-input",
                            }}
                            InputProps={{
                              classes: {
                                disabled: classes.overrideDisable,
                              },
                            }}
                            disabled={isLoading || isInfo}
                          />
                        </Grid>
                        <Hidden smDown>
                          <Grid item md={6} />
                        </Hidden>

                        {/* Notes */}
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={!!(touched.notes && errors.notes)}
                            fullWidth
                            multiline
                            helperText={touched.notes && errors.notes}
                            label={
                              <span
                                className={clsx(isInfo && values.notes && classes.overrideDisable)}
                              >
                                Notes
                              </span>
                            }
                            name="notes"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.notes}
                            variant="outlined"
                            inputProps={{
                              "data-testid": "role-form-notes-input",
                            }}
                            InputProps={{
                              classes: {
                                disabled: classes.overrideDisable,
                              },
                            }}
                            disabled={isLoading || isInfo}
                          />
                        </Grid>

                        {/* Permissions List */}
                        <Can
                          perform={PERMISSIONS.ADMIN.PERMISSION.LIST.VIEW}
                          yes={
                            <Grid item md={12} xs={12}>
                              <EntityAssignmentTable
                                testId={"role-form-permissions-table"}
                                label={"Permissions"}
                                listItems={isInfo ? values.permissions : permissions}
                                checkedItems={values.permissions}
                                onChange={(selectedPermissions) => {
                                  setFieldValue("permissions", selectedPermissions)
                                }}
                                disabled={isInfo}
                              />
                            </Grid>
                          }
                        />
                      </Grid>

                      {/* Button */}
                      {!isInfo && (
                        <Box mt={2}>
                          <Button
                            variant="contained"
                            color="secondary"
                            type="submit"
                            disabled={isSubmitting || isLoading}
                            data-testid={"role-form-submit-button"}
                          >
                            {isCreate && "Create Role"}
                            {isEdit && "Update Role"}
                          </Button>
                        </Box>
                      )}
                    </CardContent>
                  </Card>
                </form>
              </>
            )
          }}
        </Formik>
      </Box>
    </>
  )
}

export default withStyles(styles)(RoleForm)
