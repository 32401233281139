import { createStyles, Theme } from "@material-ui/core/styles"
import { colors } from "@material-ui/core"
import { DEFAULT_CSS_STYLES } from "../../../constants"

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default (theme: Theme) => {
  return createStyles({
    navListSubheader: {
      minHeight: "48px",
      display: "flex",
      alignItems: "center",
      padding: "0 14px 0 18px",
    },
    navListSubItem: {
      display: "flex",
      minHeight: "48px",
      padding: "0 14px 0 18px",
      color: colors.grey[800],
      textTransform: "none",
      letterSpacing: 0,
      width: "100%",
      fontSize: "14px",
      //@todo: This is a quick fix. We need to keep checking it with library updates
      "font-weight": theme.typography.fontWeightMedium,
      fontFamily: DEFAULT_CSS_STYLES.FONT_FAMILY,
      "&:hover": {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.black,
      },
    },
    active: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.black,
    },
    icon: {
      color: theme.palette.icon,
      width: 24,
      height: 24,
      display: "flex",
      alignItems: "center",
      marginRight: theme.spacing(1),
    },
    displayBlock: {
      display: "block",
    },
    displayNone: {
      display: "none",
    },
  })
}
