import { createAction } from "@reduxjs/toolkit"
import {
  Audience,
  CreateAudiencePayload,
  CreateAudienceSuccessResponse,
  FetchAudiencePopulationPayload,
  FetchAudiencePopulationSuccessResponse,
  FetchAudiencesSuccessResponse,
  UpdateAudiencePayload,
  UpdateAudienceSuccessResponse,
} from "../types/audienceTypes"
import { FetchListPayload } from "../types/sharedTypes"

/* ===================================== Audiences List actions ===================================== */
export const fetchAudiences = createAction<FetchListPayload>("Effects_FETCH_AUDIENCES")
export const fetchAudiencesSuccess =
  createAction<FetchAudiencesSuccessResponse>("FETCH_AUDIENCES_SUCCESS")
export const fetchAudiencesFailed = createAction<string>("FETCH_AUDIENCES_FAILED")

export const clearAudiences = createAction("CLEAR_AUDIENCES")
export const closeSaveAudienceSuccess = createAction("CLOSE_SAVE_AUDIENCE_SUCCESS")

export const setAudiencesLoading = createAction<boolean>("SET_AUDIENCES_LOADING")

/* ===================================== Audience Form actions ===================================== */

export const createAudience = createAction<CreateAudiencePayload>("Effects_CREATE_AUDIENCE")
export const createAudienceSuccess =
  createAction<CreateAudienceSuccessResponse>("CREATE_AUDIENCE_SUCCESS")
export const createAudienceFailed = createAction<string>("CREATE_AUDIENCE_FAILED")

export const updateAudience = createAction<UpdateAudiencePayload>("Effects_UPDATE_AUDIENCE")
export const updateAudienceSuccess =
  createAction<UpdateAudienceSuccessResponse>("UPDATE_AUDIENCE_SUCCESS")
export const updateAudienceFailed = createAction<string>("UPDATE_AUDIENCE_FAILED")

export const fetchAudienceById = createAction<number>("Effects_FETCH_AUDIENCE_BY_ID")
export const fetchAudienceByIdSuccess = createAction<Audience>("FETCH_AUDIENCE_BY_ID_SUCCESS")
export const fetchAudienceByIdFailed = createAction<string>("FETCH_AUDIENCE_BY_ID_FAILED")

export const fetchAudiencePopulation = createAction<FetchAudiencePopulationPayload>(
  "Effects_FETCH_AUDIENCE_POPULATION"
)
export const fetchAudiencePopulationSuccess = createAction<FetchAudiencePopulationSuccessResponse>(
  "FETCH_AUDIENCE_POPULATION_SUCCESS"
)
export const fetchAudiencePopulationFailed = createAction<string>(
  "FETCH_AUDIENCE_POPULATION_FAILED"
)

export const clearAudiencePopulation = createAction("CLEAR_AUDIENCE_POPULATION")

export const resetAudienceFormState = createAction("RESET_AUDIENCE_FORM_STATE")
