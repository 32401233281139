import { Box, CircularProgress, Grid, withStyles } from "@material-ui/core"
import { BreadCrumb, HeaderInfo } from "../../../../../redux/types/newHeaderTypes"
import React, { useEffect } from "react"
import { RootState, useAppDispatch } from "../../../../../redux/store"

import ErrorMessages from "../../../../common/Errors"
import Header from "../../../../NewHeader"
import SegmentUpload from "../SegmentUpload"
import SuccessMessage from "../../../../common/SuccessMessage"
import { clearErrors } from "../../../../../redux/actions/errorActions"
import { clearSegmentUploadSuccessMessage } from "../../../../../redux/actions/segmentActions"
import styles from "./styles"
import { useSelector } from "react-redux"

export const buildHeaderInfo = (): HeaderInfo => {
  const headerText = "Segments"
  const headerButtons = []

  const breadcrumbs: BreadCrumb[] = [
    {
      name: "Dashboard",
      route: "/",
    },
    {
      name: "Segments",
    },
  ]

  return {
    headerText,
    breadcrumbs,
    headerButtons,
  }
}

const Segment = () => {
  const dispatch = useAppDispatch()

  const isLoading = useSelector((state: RootState) => state.segments.isUploading)
  const segmentUploadSuccessMessage = useSelector(
    (state: RootState) => state.segments.segmentUploadSuccessMessage
  )

  useEffect(() => {
    return () => {
      dispatch(clearErrors())
      dispatch(clearSegmentUploadSuccessMessage())
    }
  }, [])

  const { headerText, breadcrumbs } = buildHeaderInfo()

  return (
    <>
      <Header headerText={headerText} breadcrumbs={breadcrumbs} />
      <Box mt={3} mb={3}>
        <SuccessMessage
          successMessage={segmentUploadSuccessMessage}
          closeSuccessMessage={() => dispatch(clearSegmentUploadSuccessMessage())}
        />
        <ErrorMessages />

        {isLoading && <CircularProgress />}
        <Grid item data-testid={`upload-segments-button`}>
          <SegmentUpload isLoading={isLoading} />
        </Grid>
      </Box>
    </>
  )
}

export default withStyles(styles)(Segment)
