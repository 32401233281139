import { createReducer } from "@reduxjs/toolkit"
import {
  fetchPricingEstimate,
  fetchPricingEstimateSuccess,
  fetchPricingEstimateFailed,
  clearPricingEstimateData,
} from "../actions/pricingEstimateActions"
import { PricingAndReachEstimate } from "../types/pricingEstimateTypes"
import { calculateCpmMetrics } from "../../helpers/formatterHelper"

interface InitialPricingEstimateState {
  pricingEstimateData: PricingAndReachEstimate[]
  isLoading: boolean
  totalReach: number
}

const pricingObj = {
  highCpm: 0,
  lowCpm: 0,
  medianCpm: 0,
  reach: 0,
}

const initialState: InitialPricingEstimateState = {
  pricingEstimateData: [
    { ...pricingObj, mediaType: "CTV" },
    { ...pricingObj, mediaType: "Video" },
    { ...pricingObj, mediaType: "Display" },
  ],
  totalReach: 0,
  isLoading: false,
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(fetchPricingEstimate, (state) => {
      state.isLoading = true
      state.totalReach = 0
    })
    .addCase(fetchPricingEstimateSuccess, (state, action) => {
      const { pricingEstimateData, vendorName } = action.payload
      const { totalReach, pricingEstimateDataIncremented } = pricingEstimateData.length
        ? calculateCpmMetrics(pricingEstimateData, vendorName)
        : {
            totalReach: initialState.totalReach,
            pricingEstimateDataIncremented: initialState.pricingEstimateData,
          }
      state.totalReach = totalReach
      state.pricingEstimateData = pricingEstimateDataIncremented
      state.isLoading = false
    })
    .addCase(fetchPricingEstimateFailed, (state) => {
      state.isLoading = false
    })
    .addCase(clearPricingEstimateData, () => initialState)
)
