import { GridColDef, GridCellParams } from "@material-ui/data-grid"
import dayjs from "dayjs"
import LocalizedFormat from "dayjs/plugin/localizedFormat"
import _ from "lodash"
import jsonDiff from "json-diff"
import { User } from "../../../../redux/types/userTypes"
import React from "react"

dayjs.extend(LocalizedFormat)

function changeDiff(params: GridCellParams): string {
  const differences = _.omit(
    jsonDiff.diff(params.getValue(params.id, "before"), params.getValue(params.id, "after")),
    [
      "__old.created_at",
      "__new.created_at",
      "__old.updated_at",
      "__new.updated_at",
      "created_at",
      "updated_at",
    ]
  )
  differences.id = _.get(params.getValue(params.id, "after"), "id")
  // for RTBxAdmin, mapping tables have the Deal ID saved as `pmp_id`
  // so if we find a `pmp_id` in the after snapshot, we should always include that so
  // we know what fields were updated.  Even if pmp_id never changes.
  if (
    _.get(params.getValue(params.id, "after"), "pmp_id") ||
    _.get(params.getValue(params.id, "before"), "pmp_id")
  )
    differences.pmp_id =
      _.get(params.getValue(params.id, "after"), "pmp_id") ||
      _.get(params.getValue(params.id, "before"), "pmp_id")
  return JSON.stringify(differences, null, 2)
}

export default [
  {
    field: "id",
    headerName: "ID",
    sortable: false,
    width: 80,
  },
  {
    field: "userId",
    headerName: "User ID",
    sortable: false,
    width: 120,
  },
  {
    field: "userName",
    headerName: "Username",
    sortable: false,
    valueGetter: (params: GridCellParams): string | null => {
      const user = params?.row?.user
      return user ? `${user["firstName"]} ${user["lastName"]}` : null
    },
    width: 180,
  },
  {
    field: "userEmail",
    headerName: "Email",
    sortable: false,
    valueGetter: (params: GridCellParams): string | null => {
      const user = params?.row?.user
      return user ? params?.row?.user["email"] : null
    },
    width: 220,
  },
  {
    field: "operation",
    headerName: "Operation",
    sortable: false,
    width: 190,
  },
  {
    field: "entity",
    headerName: "Entity Name",
    sortable: false,
    width: 170,
  },
  {
    field: "entityId",
    headerName: "Entity ID",
    sortable: false,
    valueGetter: (params: GridCellParams): string | null => {
      const after = params.getValue(params.id, "after")
      return after ? params.getValue(params.id, "after")["id"] : null
    },
    width: 120,
  },
  {
    field: "Combined",
    headerName: "Changes",
    sortable: false,
    renderCell: (params: GridCellParams) => React.createElement("pre", {}, changeDiff(params)),
    width: 300,
  },
  {
    field: "before",
    headerName: "Previous value",
    sortable: false,
    hide: true,
    valueFormatter: ({ value }: { value: Partial<User> }): string => JSON.stringify(value, null, 2),
    width: 240,
  },
  {
    field: "after",
    headerName: "Current value",
    sortable: false,
    width: 240,
    hide: true,
    valueFormatter: ({ value }: { value: Partial<User> }): string => JSON.stringify(value, null, 2),
  },
  {
    field: "timestamp",
    headerName: "Date",
    sortable: false,
    valueFormatter: ({ value }: { value: string }): string => dayjs(value).format("LLL"),
    width: 300,
  },
] as GridColDef[]
