import * as Yup from "yup"

import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormHelperText,
  Grid,
  MenuItem,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core"
import React, { useEffect } from "react"
import { RootState, useAppDispatch } from "../../../../../../redux/store"
import {
  createPublisher,
  resetPublisherFormState,
  updatePublisher,
} from "../../../../../../redux/actions/publisherActions"

import { ENTITY_STATUSES } from "../../../../../../constants"
import ErrorMessages from "../../../../../common/Errors"
import { Formik } from "formik"
import { Publisher } from "../../../../../../redux/types/publisherTypes"
import { SELLER_TYPE } from "../../../../../../redux/types/sellersJsonTypes"
import SellersJson from "../../../../../common/SellersJson"
import { clearErrors } from "../../../../../../redux/actions/errorActions"
import clsx from "clsx"
import styles from "./styles"
import { trimPayloadProperties } from "../../../../../../helpers/formatterHelper"
import { useHistory } from "react-router"
import { useSelector } from "react-redux"

const PublisherForm = ({ classes, match }) => {
  const dispatch = useAppDispatch()
  const publisher = useSelector((state: RootState) => state.publisherForm.selectedPublisher)
  const isSaved = useSelector((state: RootState) => state.publisherForm.isSaved)
  const isLoading = useSelector((state: RootState) => state.publisherForm.isLoading)
  const isSubmitting = useSelector((state: RootState) => state.publisherForm.isSubmitting)
  const history = useHistory()
  const endPath = match.path.split("/").pop()

  const isEdit = endPath === "edit"
  const isCreate = endPath === "create"
  const isInfo = !isEdit && !isCreate

  useEffect(() => {
    // when the publisher is successfully created navigate back to publishers list
    isSaved && history.push(`/admin/publishers`)
  }, [publisher, isSaved])

  // TODO: Possibly not needed, leaving just in case
  useEffect(() => {
    // clean up stale data when component unmounts
    return () => {
      dispatch(clearErrors())
      dispatch(resetPublisherFormState())
    }
  }, [])

  const publisherFormStatusOptions = [
    { value: ENTITY_STATUSES.ACTIVE, label: "Active" },
    { value: ENTITY_STATUSES.INACTIVE, label: "Inactive" },
  ]

  const initialFormValues = {
    name: publisher.name || "",
    status: publisher.status || ENTITY_STATUSES.ACTIVE,
    appNexusId: publisher.appNexusId || "",
    isRtbxEnabled: publisher.isRtbxEnabled || false,
    isSellersJsonEnabled: publisher.isSellersJsonEnabled || false,
    revShare: publisher.revShare ?? "",
    isOandO: publisher.isOandO ? "true" : "false",
    hardfloor: publisher.hardfloor ?? "",
    sellersJsonEntry: publisher.sellersJsonEntry || {
      name: "",
      domain: "",
      sellerType: SELLER_TYPE.PUBLISHER,
      isPassthrough: false,
      isConfidential: false,
      comment: "",
    },
  }

  const formValidationSchema = Yup.object().shape({
    name: Yup.string().max(255).required(),
    status: Yup.string(),
    appNexusId: Yup.number().required(),
    isRtbxEnabled: Yup.boolean(),
    revShare: Yup.number()
      .nullable(true)
      .min(0, "Please select a value between 0 and 199")
      .max(199, "Please select a value between 0 and 199"),
    isOandO: Yup.boolean(),
    hardfloor: Yup.number()
      .min(0, "Please select a value between 0 and 99")
      .max(99, "Please select a value between 0 and 99"),
    isSellersJsonEnabled: Yup.boolean(),
    sellersJsonEntry: Yup.object().shape({
      isConfidential: Yup.boolean(),
      sellerType: Yup.mixed().oneOf(Object.values(SELLER_TYPE)),
      isPassthrough: Yup.boolean(),
      name: Yup.string(),
      domain: Yup.string(),
      comment: Yup.string(),
    }),
  })

  const isOandOOptions = [
    { value: "false", label: "No" },
    { value: "true", label: "Yes" },
  ]

  const handleFormikSubmit = (values) => {
    // Note: we need to do this so we don't have to use type=number on the input field which would result in having up/down arrows in the input field
    values.appNexusId && (values.appNexusId = parseInt(values.appNexusId))
    values.isOandO = values.isOandO === "true"
    values.revShare === "" && delete values.revShare && delete values.isOandO
    values.hardfloor === "" && delete values.hardfloor
    !values.isSellersJsonEnabled && delete values.sellersJsonEntry
    if (values.sellersJsonEntry?.isConfidential) {
      values.sellersJsonEntry.name = ""
      values.sellersJsonEntry.domain = ""
    }

    if (!isEdit) {
      dispatch(createPublisher(trimPayloadProperties(values) as Publisher))
    } else if (isEdit) {
      dispatch(
        updatePublisher(
          trimPayloadProperties({
            id: publisher.id,
            ...values,
          }) as Publisher
        )
      )
    }
  }

  return (
    <>
      {!isLoading ? (
        <Box mt={3} mb={3} className={clsx({ isInfo: classes.cursorOverrideDisable })}>
          <Formik
            enableReinitialize={true}
            initialValues={initialFormValues}
            validationSchema={formValidationSchema}
            onSubmit={(values) => handleFormikSubmit(values)}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
              setFieldValue,
            }) => {
              return (
                <>
                  <ErrorMessages />
                  <form onSubmit={handleSubmit} className={clsx(isInfo && classes.overrideDisable)}>
                    <Card>
                      <CardContent className={classes.cardPadding}>
                        <Grid container spacing={3} className={classes.roundedBorder}>
                          {/* Publisher Name */}
                          <Grid item md={6} xs={12}>
                            <TextField
                              error={!!(touched.name && errors.name)}
                              fullWidth
                              helperText={touched.name && errors.name}
                              label={
                                <span className={clsx(isInfo && classes.overrideDisable)}>
                                  Publisher name
                                </span>
                              }
                              name="name"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              required
                              value={values.name}
                              variant="outlined"
                              inputProps={{ "data-testid": "publisher-form-name-input" }}
                              InputProps={{
                                classes: {
                                  disabled: classes.overrideDisable,
                                },
                              }}
                              disabled={isInfo}
                            />
                          </Grid>

                          {/* Publisher Status */}
                          <Grid item md={6} xs={12}>
                            <TextField
                              select
                              label={
                                <Typography className={clsx(isInfo && classes.overrideDisable)}>
                                  Publisher Status
                                </Typography>
                              }
                              name="status"
                              value={values.status}
                              onChange={(e) => {
                                setFieldValue("status", e.target.value)
                              }}
                              className={classes.publisherStatusField}
                              inputProps={{
                                "data-testid": "publisher-form-status-input",
                              }}
                              InputProps={{
                                classes: {
                                  disabled: classes.overrideDisable,
                                },
                              }}
                              disabled={isInfo}
                            >
                              {publisherFormStatusOptions.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Grid>

                          {/* Publisher AppNexus ID */}
                          <Grid item md={6} xs={12}>
                            <TextField
                              error={!!(touched.appNexusId && errors.appNexusId)}
                              fullWidth
                              helperText={touched.appNexusId && errors.appNexusId}
                              label={
                                <span className={clsx(isInfo && classes.overrideDisable)}>
                                  Publisher AppNexus ID
                                </span>
                              }
                              name="appNexusId"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              required
                              value={values.appNexusId}
                              variant="outlined"
                              inputProps={{
                                "data-testid": "publisher-form-app-nexus-id-input",
                              }}
                              InputProps={{
                                classes: {
                                  disabled: classes.overrideDisable,
                                },
                              }}
                              disabled={isInfo}
                            />
                          </Grid>

                          {/* Rev Share */}
                          <Grid item lg={2} xs={12}>
                            {!isCreate && !(publisher.revShare == null) && (
                              <TextField
                                label={
                                  <span className={clsx(isInfo && classes.overrideDisable)}>
                                    Rev Share (%)
                                  </span>
                                }
                                name="revShare"
                                type="number"
                                error={!!(touched.revShare && errors.revShare)}
                                helperText={touched.revShare && errors.revShare}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.revShare}
                                className={classes.smallField}
                                inputProps={{
                                  "data-testid": "publisher-form-rev-share-input",
                                }}
                                InputProps={{
                                  classes: {
                                    disabled: classes.overrideDisable,
                                  },
                                }}
                                onWheel={(e) => (e.target as HTMLElement).blur()}
                                disabled={isInfo}
                              />
                            )}
                          </Grid>

                          {/* Enable RTB-X Traffic */}
                          <Grid item md={6} xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={values.isRtbxEnabled}
                                  onChange={(event) => {
                                    setFieldValue("isRtbxEnabled", event.target.checked)
                                  }}
                                  data-testid="publisher-form-is-rtbx-enabled-input"
                                  disabled={isInfo}
                                  className={clsx(isInfo && classes.overrideDisable)}
                                />
                              }
                              name="isRtbxEnabled"
                              className={clsx(isInfo && classes.overrideDisable)}
                              label={
                                <Typography className={clsx(isInfo && classes.overrideDisable)}>
                                  Enable RTB-X Traffic
                                </Typography>
                              }
                            />
                            <FormHelperText component="div">
                              <Typography className={classes.helperText}>
                                ** Do not enable this unless sites and tags have already been
                                created **
                              </Typography>
                            </FormHelperText>
                          </Grid>

                          {/*Is Owned and Operated*/}
                          <Grid item lg={2} xs={12}>
                            {!isCreate && !(publisher.isOandO === undefined) && (
                              <TextField
                                select
                                label={
                                  <span className={clsx(isInfo && classes.overrideDisable)}>
                                    Is Owned And Operated
                                  </span>
                                }
                                name="isOandO"
                                error={!!(touched.isOandO && errors.isOandO)}
                                helperText={touched.isOandO && errors.isOandO}
                                value={values.isOandO}
                                onChange={handleChange}
                                className={classes.smallField}
                                inputProps={{
                                  "data-testid": "publisher-form-is-oando-input",
                                }}
                                InputProps={{
                                  classes: {
                                    disabled: classes.overrideDisable,
                                  },
                                }}
                                disabled={isInfo}
                              >
                                {isOandOOptions
                                  ? isOandOOptions.map((option) => (
                                      <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                      </MenuItem>
                                    ))
                                  : null}
                              </TextField>
                            )}
                          </Grid>

                          {/* Enable on Sellers Json */}
                          <Grid item md={6} xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={values.isSellersJsonEnabled}
                                  onChange={(event) => {
                                    setFieldValue("isSellersJsonEnabled", event.target.checked)
                                  }}
                                  data-testid="publisher-form-is-sellers-json-enabled-input"
                                  disabled={isInfo}
                                  className={clsx(isInfo && classes.overrideDisable)}
                                />
                              }
                              name="isSellersJsonEnabled"
                              className={clsx(isInfo && classes.overrideDisable)}
                              label={
                                <Typography className={clsx(isInfo && classes.overrideDisable)}>
                                  Enable on Sellers.json
                                </Typography>
                              }
                            />
                          </Grid>

                          {/* Hardfloor */}
                          <Grid item md={6} xs={12}>
                            {!isCreate && !(publisher.hardfloor === undefined) && (
                              <TextField
                                label={
                                  <span className={clsx(isInfo && classes.overrideDisable)}>
                                    Hardfloor
                                  </span>
                                }
                                name="hardfloor"
                                type="number"
                                error={!!(touched.hardfloor && errors.hardfloor)}
                                helperText={touched.hardfloor && errors.hardfloor}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                required
                                value={values.hardfloor}
                                className={classes.smallField}
                                inputProps={{
                                  "data-testid": "publisher-form-hardfloor-input",
                                }}
                                InputProps={{
                                  classes: {
                                    disabled: classes.overrideDisable,
                                  },
                                }}
                                onWheel={(e) => (e.target as HTMLElement).blur()}
                                disabled={isInfo}
                              />
                            )}
                          </Grid>
                        </Grid>

                        {values.isSellersJsonEnabled && (
                          <Grid
                            container
                            spacing={3}
                            className={clsx(classes.roundedBorder, classes.sellersJsonBox)}
                          >
                            <SellersJson
                              values={values}
                              isInfo={isInfo}
                              touched={touched}
                              errors={errors}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              parentTestId={"publisher-form"}
                              setFieldValue={setFieldValue}
                            />
                          </Grid>
                        )}
                        <Box mt={2}>
                          {!isInfo && (
                            <Button
                              variant="contained"
                              color="secondary"
                              type="submit"
                              disabled={isSubmitting}
                              data-testid={"publisher-form-submit-button"}
                            >
                              {isCreate && "Create Publisher"}
                              {isEdit && "Update Publisher"}
                            </Button>
                          )}
                        </Box>
                      </CardContent>
                    </Card>
                  </form>
                </>
              )
            }}
          </Formik>
        </Box>
      ) : (
        <Box mt={3} mb={3}>
          <Card>
            <CardContent className={classes.loadingForm}>
              <CircularProgress />
            </CardContent>
          </Card>
        </Box>
      )}
    </>
  )
}

export default withStyles(styles)(PublisherForm)
