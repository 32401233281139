import { createStyles, Theme } from "@material-ui/core/styles"

export default (theme: Theme) => {
  return createStyles({
    queryField: {
      width: 500,
      marginRight: theme.spacing(1),
    },
    statusField: {
      width: 200,
      margin: "15px",
    },
    statusDone: {
      backgroundColor: "#5cb85c",
      padding: "10px",
    },
    statusNew: {
      backgroundColor: "#f39c12",
      padding: "10px",
    },
    statusBtn: {
      margin: "15px",
      height: "52px",
    },
    tableWrapper: {
      width: "100%",
      overflowX: "auto",
    },
    startDate: {
      minWidth: "130px",
    },
    colWidth_150: {
      minWidth: "150px",
    },
    colWidth_200: {
      minWidth: "250px",
    },
    tableCustomWidth: {
      minWidth: "2000px",
    },
    errorText: {
      color: "rgb(211, 47, 47)",
      borderColor: "rgb(211, 47, 47)",
    },
    bgError: {
      background: "rgb(211 47 47 / 11%)",
      borderBottom: "1.2px solid #ff000017 !important",
      "&.MuiTableRow-hover:hover": {
        background: "rgb(211 47 47 / 11%)",
      },
    },
  })
}
