import { clearErrors, closeError, generalError } from "../actions/errorActions"
import {
  createAudienceFailed,
  fetchAudienceByIdFailed,
  fetchAudiencePopulationFailed,
  fetchAudiencesFailed,
  updateAudienceFailed,
} from "../actions/audienceActions"
import {
  createCompanyExternalLineItemDisplayNameFailed,
  createCompanyFailed,
  fetchCompaniesFailed,
  fetchCompanyByIdFailed,
  fetchCompanyExternalLineItemDisplayNamesByCompanyIdFailed,
  updateCompanyEnableCampaignBIFailed,
  updateCompanyFailed,
} from "../actions/companyActions"
import {
  createDealFailed,
  fetchAndExportDealsFailed,
  fetchDealBiddersFailed,
  fetchDealByIdFailed,
  fetchDealCountriesFailed,
  fetchDealDomainsFailed,
  fetchDealPublishersFailed,
  fetchDealsFailed,
  updateDealFailed,
} from "../actions/dealActions"
import {
  createDomainFailed,
  fetchDomainByIdFailed,
  fetchDomainsFailed,
  updateDomainFailed,
} from "../actions/domainActions"
import {
  createPublisherFailed,
  fetchAndExportPublishersFailed,
  fetchPublisherByIdFailed,
  fetchPublishersFailed,
  updatePublisherFailed,
} from "../actions/publisherActions"
import {
  createRoleFailed,
  fetchRoleByIdFailed,
  fetchRolesFailed,
  updateRoleFailed,
} from "../actions/roleActions"
import {
  createSiteFailed,
  fetchAndExportTagsFromSitesFailed,
  fetchSiteBiddersFailed,
  fetchSiteBlockAttributesFailed,
  fetchSiteBlockCategoriesFailed,
  fetchSiteBlockTypesFailed,
  fetchSiteByIdFailed,
  fetchSitesFailed,
  updateSiteFailed,
} from "../actions/siteActions"
import {
  createSubdomainFailed,
  fetchSubdomainByIdFailed,
  fetchSubdomainsFailed,
  updateSubdomainFailed,
} from "./../actions/subdomainActions"
import {
  createTagFailed,
  fetchAndExportTagsFromTagsFailed,
  fetchTagByIdFailed,
  fetchTagsFailed,
  updateTagFailed,
} from "../actions/tagActions"
import {
  createUserFailed,
  fetchCurrentUserFailed,
  fetchUserByIdFailed,
  fetchUsersFailed,
  updateUserActiveCompanyFailed,
  updateUserFailed,
} from "../actions/userActions"
import {
  displaySegmentCSVValidationErrors,
  fetchSegmentsFailed,
  uploadSegmentFailed,
} from "../actions/segmentActions"
import { fetchCampaignBITokenFailed, loginToCampaignBIFailed } from "../actions/dataViewActions"
import { fetchDealRequestsFailed, updatDealRequestsFailed } from "../actions/dealRequestsActions"
import { fetchFeatureFlagsFailed, updateFeatureFlagsFailed } from "../actions/featureFlagActions"
import { fetchHomePageFailed, updateHomePageFailed } from "../actions/homePageActions"
import {
  fetchPublisherByAppNexusIdFailed,
  migratePublisherByAppNexusIdFailed,
} from "../actions/appNexusMigrationActions"

import { createReducer } from "@reduxjs/toolkit"
import { fetchChangeLogFailed } from "../actions/changeLogActions"
import { fetchPermissionsFailed } from "../actions/permissionActions"
import { fetchPricingEstimateFailed } from "../actions/pricingEstimateActions"
import { fetchSegmentVendorsFailed } from "../actions/segmentVendorActions"
import { fetchSellersJsonFailed } from "../actions/sellersJsonActions"

interface InitialErrorState {
  generalErrors: string[]
}

const initialState: InitialErrorState = {
  generalErrors: [],
}

const errorHandler = (state, action) => {
  if (!state.generalErrors.includes(action.payload)) state.generalErrors.push(action.payload)
}

export default createReducer(initialState, (builder) =>
  /*================================ User action handlers ================================*/
  builder
    .addCase(createUserFailed, errorHandler)
    .addCase(updateUserFailed, errorHandler)
    .addCase(updateUserActiveCompanyFailed, errorHandler)
    .addCase(fetchUsersFailed, errorHandler)
    .addCase(fetchUserByIdFailed, errorHandler)
    .addCase(fetchCurrentUserFailed, errorHandler)

    /*================================ Role action handlers ================================*/
    .addCase(createRoleFailed, errorHandler)
    .addCase(updateRoleFailed, errorHandler)
    .addCase(fetchRolesFailed, errorHandler)
    .addCase(fetchRoleByIdFailed, errorHandler)

    /*================================ Company action handlers ================================*/
    .addCase(createCompanyFailed, errorHandler)
    .addCase(updateCompanyFailed, errorHandler)
    .addCase(updateCompanyEnableCampaignBIFailed, errorHandler)
    .addCase(fetchCompaniesFailed, errorHandler)
    .addCase(fetchCompanyByIdFailed, errorHandler)

    /*================================ Company External Line Item Display Name action handlers ================================*/
    .addCase(fetchCompanyExternalLineItemDisplayNamesByCompanyIdFailed, errorHandler)
    .addCase(createCompanyExternalLineItemDisplayNameFailed, errorHandler)

    /*================================ Audience action handlers ================================*/
    .addCase(createAudienceFailed, errorHandler)
    .addCase(updateAudienceFailed, errorHandler)
    .addCase(fetchAudiencesFailed, errorHandler)
    .addCase(fetchAudienceByIdFailed, errorHandler)
    .addCase(fetchAudiencePopulationFailed, errorHandler)

    /*================================ Permission action handlers ================================*/
    .addCase(fetchPermissionsFailed, (state, action) => {
      state.generalErrors.push(action.payload)
    })
    /*================================ Home Page action handlers ================================*/
    .addCase(fetchHomePageFailed, errorHandler)
    .addCase(updateHomePageFailed, errorHandler)
    /*================================ Change log action handlers ================================*/
    .addCase(fetchChangeLogFailed, (state, action) => {
      state.generalErrors.push(action.payload)
    })
    /*================================ Deal action handlers ================================*/
    .addCase(createDealFailed, errorHandler)
    .addCase(updateDealFailed, errorHandler)
    .addCase(fetchDealsFailed, errorHandler)
    .addCase(fetchDealByIdFailed, errorHandler)
    .addCase(fetchDealBiddersFailed, errorHandler)
    .addCase(fetchDealCountriesFailed, errorHandler)
    .addCase(fetchDealDomainsFailed, errorHandler)
    .addCase(fetchDealPublishersFailed, errorHandler)
    .addCase(fetchAndExportDealsFailed, errorHandler)

    /*================================ Segment Vendor action handlers ================================*/
    .addCase(fetchSegmentVendorsFailed, errorHandler)

    /*================================ PricingEstimate action handlers ================================*/
    .addCase(fetchPricingEstimateFailed, errorHandler)

    /*================================ Segment action handlers ================================*/
    .addCase(fetchSegmentsFailed, errorHandler)
    .addCase(uploadSegmentFailed, errorHandler)
    .addCase(displaySegmentCSVValidationErrors, (state, action) => {
      const errors = action.payload
      errors.forEach((error) => {
        if (!state.generalErrors.includes(error)) state.generalErrors.push(error)
      })
    })

    /*================================ Campaign BI action handlers ================================*/
    .addCase(fetchCampaignBITokenFailed, errorHandler)
    .addCase(loginToCampaignBIFailed, errorHandler)

    /*================================ Deal Request action handlers ================================*/
    .addCase(fetchDealRequestsFailed, errorHandler)
    .addCase(updatDealRequestsFailed, errorHandler)

    /*================================== Domain action handlers ==================================*/
    .addCase(fetchDomainsFailed, errorHandler)
    .addCase(fetchDomainByIdFailed, errorHandler)
    .addCase(createDomainFailed, errorHandler)
    .addCase(updateDomainFailed, errorHandler)

    /*================================== Subdomain action handlers ==================================*/
    .addCase(fetchSubdomainsFailed, errorHandler)
    .addCase(fetchSubdomainByIdFailed, errorHandler)
    .addCase(createSubdomainFailed, errorHandler)
    .addCase(updateSubdomainFailed, errorHandler)

    /*================================ Publisher action handlers ================================*/
    .addCase(fetchPublishersFailed, errorHandler)
    .addCase(fetchPublisherByIdFailed, errorHandler)
    .addCase(createPublisherFailed, errorHandler)
    .addCase(updatePublisherFailed, errorHandler)
    .addCase(fetchAndExportPublishersFailed, errorHandler)

    /*================================ Sellers.json action handlers ================================*/
    .addCase(fetchSellersJsonFailed, errorHandler)

    /*================================ AppNexus migration action handlers ================================*/
    .addCase(fetchPublisherByAppNexusIdFailed, errorHandler)
    .addCase(migratePublisherByAppNexusIdFailed, errorHandler)

    /*================================ Site action handlers ================================*/
    .addCase(fetchSitesFailed, errorHandler)
    .addCase(fetchSiteByIdFailed, errorHandler)
    .addCase(fetchSiteBiddersFailed, errorHandler)
    .addCase(fetchSiteBlockCategoriesFailed, errorHandler)
    .addCase(fetchSiteBlockAttributesFailed, errorHandler)
    .addCase(fetchSiteBlockTypesFailed, errorHandler)
    .addCase(createSiteFailed, errorHandler)
    .addCase(updateSiteFailed, errorHandler)

    /*================================ Tag action handlers ================================*/
    .addCase(fetchTagsFailed, errorHandler)
    .addCase(fetchTagByIdFailed, errorHandler)
    .addCase(createTagFailed, errorHandler)
    .addCase(updateTagFailed, errorHandler)
    .addCase(fetchAndExportTagsFromSitesFailed, errorHandler)
    .addCase(fetchAndExportTagsFromTagsFailed, errorHandler)

    /*=========================== Configuration action handlers ===========================*/
    .addCase(fetchFeatureFlagsFailed, errorHandler)
    .addCase(updateFeatureFlagsFailed, errorHandler)

    /*================================ Error action handlers ================================*/
    .addCase(generalError, errorHandler)
    .addCase(closeError, (state, action) => {
      state.generalErrors = state.generalErrors.filter((err, i) => action.payload !== i)
    })
    .addCase(clearErrors, (state) => {
      state.generalErrors = initialState.generalErrors
    })
)
