import { createAction } from "@reduxjs/toolkit"
import { SellersJson } from "../types/sellersJsonTypes"

/* ===================================== Sellers Json actions ====================================== */
export const fetchSellersJson = createAction("Effects_FETCH_SELLERS_JSON")

export const setSellersJsonLoadingFlag = createAction<boolean>("SET_SELLERS_JSON_LOADING_FLAG")

export const fetchSellersJsonFailed = createAction<string>("FETCH_SELLERS_JSON_FAILED")
export const fetchSellersJsonSuccess = createAction<SellersJson>("FETCH_SELLERS_JSON_SUCCESS")

export const clearSellersJson = createAction<string>("CLEAR_SELLERS_JSON")
