import { createReducer } from "@reduxjs/toolkit"
import {
  clearAudiences,
  closeSaveAudienceSuccess,
  createAudienceSuccess,
  fetchAudiences,
  fetchAudiencesFailed,
  fetchAudiencesSuccess,
  updateAudienceSuccess,
  setAudiencesLoading,
} from "../actions/audienceActions"
import { UpdateAudiencePayload } from "../types/audienceTypes"
import { PaginatedComponentState } from "../types/sharedTypes"
import { END_PATHS } from "../../constants"

interface InitialAudienceState extends PaginatedComponentState {
  audiences: UpdateAudiencePayload[]
  audienceSuccessMessage: string
  isLoading: boolean
}

const initialState: InitialAudienceState = {
  audiences: [],
  audienceSuccessMessage: "",
  isLoading: true,
  pager: {
    totalCount: 0,
    page: 0,
  },
}

export default createReducer(initialState, (builder) =>
  builder
    /*================================ Audiences List action handlers ================================*/
    .addCase(fetchAudiences, (state) => {
      state.isLoading = true
    })
    .addCase(fetchAudiencesSuccess, (state, action) => {
      state.isLoading = false
      state.audiences = action.payload.audiences
      state.pager = action.payload.pager
    })
    .addCase(fetchAudiencesFailed, (state) => {
      state.isLoading = false
    })
    .addCase(clearAudiences, () => initialState)
    .addCase(setAudiencesLoading, (state, action) => {
      state.isLoading = action.payload
    })

    /*================================ Audience Form action handlers ================================*/
    .addCase(createAudienceSuccess, (state, action) => {
      const successType =
        action.payload.audienceCreationType === END_PATHS.CREATE ? "created" : "copied"
      state.audienceSuccessMessage = `Successfully ${successType} audience: ${action.payload.name} (${action.payload.id})`
    })
    .addCase(updateAudienceSuccess, (state, action) => {
      state.audienceSuccessMessage = `Successfully updated audience: ${action.payload.name} (${action.payload.id})`
    })
    .addCase(closeSaveAudienceSuccess, (state) => {
      state.audienceSuccessMessage = initialState.audienceSuccessMessage
    })
)
