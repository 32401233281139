import { call, put, takeEvery } from "redux-saga/effects"
import { API_ROOT, API, SECTION } from "../../constants"
import { AxiosRequestConfig } from "axios"
import {
  fetchChangeLog,
  fetchChangeLogFailed,
  fetchChangeLogSuccess,
} from "../actions/changeLogActions"
import { handleAxiosRequest, handleSagaError } from "../../helpers/sagaHelpers"
import { SagaIterator } from "redux-saga"

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* fetchChangeLogSaga(action) {
  const ajaxConfig: AxiosRequestConfig = {
    method: "POST",
    url: `${API_ROOT}${API.V1}${SECTION.ADMIN}/change-log/find`,
    data: action.payload,
  }
  try {
    const response = yield call(handleAxiosRequest, ajaxConfig)
    yield put(
      fetchChangeLogSuccess({
        changeLogData: response.data,
        pager: {
          page: parseInt(response.headers["x-pagination-page"], 10),
          totalCount: parseInt(response.headers["x-pagination-total-count"], 10),
        },
      })
    )
  } catch (err) {
    yield handleSagaError(fetchChangeLogFailed, err)
  }
}

export function* watchFetchChangeLog(): SagaIterator {
  yield takeEvery(fetchChangeLog, fetchChangeLogSaga)
}
