import { createStyles, Theme } from "@material-ui/core/styles"

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default (theme: Theme) => {
  return createStyles({
    dropdownInput: {
      margin: theme.spacing(1),
      width: "17ch",
    },
    inlineLabel: {
      display: "flex",
      flexDirection: "column",
      marginRight: "15px",
      justifyContent: "center",
    },
    vendorDropdown: {
      display: "flex",
      justifyContent: "flex-start",
    },
  })
}
