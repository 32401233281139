import { API, API_ROOT, SECTION } from "../../constants"
import { call, put, takeEvery } from "redux-saga/effects"
import {
  createSite,
  createSiteFailed,
  createSiteSuccess,
  setSiteFormSubmittingFlag,
} from "../actions/siteActions"
import { handleAxiosRequest, handleSagaError } from "../../helpers/sagaHelpers"

import { AxiosRequestConfig } from "axios"
import { PayloadAction } from "@reduxjs/toolkit"
import { SagaIterator } from "redux-saga"
import { Site } from "../types/siteTypes"
import { clearErrors } from "../actions/errorActions"

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* createSiteSaga(action: PayloadAction<Site>) {
  const ajaxConfig: AxiosRequestConfig = {
    method: "POST",
    url: `${API_ROOT}${API.V1}${SECTION.ADMIN}/publishers/${action.payload.publisherId}/sites`,
    data: {
      site: action.payload,
    },
  }
  yield put(clearErrors())
  yield put(setSiteFormSubmittingFlag(true))
  try {
    const response = yield call(handleAxiosRequest, ajaxConfig)
    yield put(createSiteSuccess(response.data))
  } catch (err) {
    yield handleSagaError(createSiteFailed, err)
  }
  yield put(setSiteFormSubmittingFlag(false))
}

export function* watchCreateSite(): SagaIterator {
  yield takeEvery(createSite, createSiteSaga)
}
