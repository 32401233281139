import { API, API_ROOT, SECTION } from "../../constants"
import { call, put, takeEvery } from "redux-saga/effects"
import {
  fetchSiteBidders,
  fetchSiteBiddersFailed,
  fetchSiteBiddersSuccess,
  setSiteFormLoadingBiddersFlag,
} from "../actions/siteActions"
import { handleAxiosRequest, handleSagaError } from "../../helpers/sagaHelpers"

import { AxiosRequestConfig } from "axios"
import { SagaIterator } from "redux-saga"

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* fetchSiteBiddersSaga() {
  const ajaxConfig: AxiosRequestConfig = {
    method: "POST",
    url: `${API_ROOT}${API.V1}${SECTION.ADMIN}/publishers/bidders/find`,
  }
  yield put(setSiteFormLoadingBiddersFlag(true))
  try {
    const response = yield call(handleAxiosRequest, ajaxConfig)
    yield put(fetchSiteBiddersSuccess(response.data))
  } catch (err) {
    yield handleSagaError(fetchSiteBiddersFailed, err)
  }
  yield put(setSiteFormLoadingBiddersFlag(false))
}

export function* watchFetchSiteBidders(): SagaIterator {
  yield takeEvery(fetchSiteBidders, fetchSiteBiddersSaga)
}
