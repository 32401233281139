import { put, takeEvery, call } from "redux-saga/effects"
import { API_ROOT, API, SECTION } from "../../constants"
import { AxiosRequestConfig } from "axios"
import { handleAxiosRequest, handleSagaError } from "../../helpers/sagaHelpers"
import { handleDealRequestSagaValidationErrors } from "../../helpers/dealRequestHelper"
import { SagaIterator } from "redux-saga"
import { PayloadAction } from "@reduxjs/toolkit"
import {
  updatDealRequestsFailed,
  updatDealRequestsSuccess,
  updateDealRequests,
  setReloadDealRequests,
  setDealRequestIsLoading,
} from "../actions/dealRequestsActions"
import { UpdateDealRequestsPayload } from "../types/dealRequestTypes"
import _ from "lodash"

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* updateDealRequestsSaga(action: PayloadAction<UpdateDealRequestsPayload>) {
  const ajaxConfig: AxiosRequestConfig = {
    method: "PUT",
    url: `${API_ROOT}${API.V1}${SECTION.TOOLS}/deal-requests/updateDealRequests`,
    data: { dealRequests: [...action.payload.dealRequests] },
  }
  const selectedDealRequestIds = action.payload.dealRequests.map((dealRequest) => dealRequest.id)
  try {
    yield put(setDealRequestIsLoading(true))
    yield call(handleAxiosRequest, ajaxConfig)
    yield put(setReloadDealRequests(action.payload.shouldReloadDealRequests))
    yield put(updatDealRequestsSuccess(selectedDealRequestIds))
  } catch (err) {
    yield handleSagaError(updatDealRequestsFailed, err)
    if (_.get(err, "response.data.validations")) {
      yield handleDealRequestSagaValidationErrors(updatDealRequestsFailed, err.response.data)
    }
  } finally {
    yield put(setDealRequestIsLoading(false))
  }
}

export function* watchUpdateDealRequests(): SagaIterator {
  yield takeEvery(updateDealRequests, updateDealRequestsSaga)
}
