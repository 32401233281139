import { hot } from "react-hot-loader/root"
import React from "react"
import { withStyles } from "@material-ui/core"
import styles from "./styles"
import { useAuth0 } from "@auth0/auth0-react"
import { isLoggedIn, setToken } from "../helpers/loginServices"
import Loading from "./Loading"
import Authorization from "../Authorization"
import "../index.scss"

const App = () => {
  const { isLoading, error, user, isAuthenticated, loginWithRedirect, getAccessTokenSilently } =
    useAuth0()

  const setTokenInLocalStorage = async () => {
    try {
      const token = await getAccessTokenSilently()

      setToken(token)
    } catch (err) {
      // Certain browsers do not allow getting/setting token. Login still works, but we do not want to display the error.
    }
  }

  if (isLoading) {
    return <Loading />
  }
  if (error) {
    return <div>Oops... {error.message}</div>
  }

  if (isAuthenticated || isLoggedIn()) {
    setTokenInLocalStorage()

    return <Authorization auth0User={user} />
  } else {
    loginWithRedirect()
    return null
  }
}

export default hot(withStyles(styles)(App))
