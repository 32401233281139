import { API, API_ROOT, SECTION } from "../../constants"
import { call, put, takeEvery } from "redux-saga/effects"
import {
  fetchAndExportTagsFromSites,
  fetchAndExportTagsFromSitesFailed,
  fetchAndExportTagsFromSitesSuccess,
  setLoadingTagsForExportSitesFlag,
} from "../actions/siteActions"
import { handleAxiosRequest, handleSagaError } from "../../helpers/sagaHelpers"

import { AxiosRequestConfig } from "axios"
import { PayloadAction } from "@reduxjs/toolkit"
import { SagaIterator } from "redux-saga"
import { exportTagsAsCsvFromSites } from "../../helpers/csvHelpers"
import { clearErrors } from "../actions/errorActions"
import { FetchAndExportTagsFromSitesPayload } from "../types/tagTypes"
import _ from "lodash"

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* fetchAndExportTagsFromSitesSaga(
  action: PayloadAction<FetchAndExportTagsFromSitesPayload>
) {
  const ajaxConfig: AxiosRequestConfig = {
    method: "POST",
    url: `${API_ROOT}${API.V1}${SECTION.ADMIN}/publishers/${action.payload.publisherId}/tags/export`,
    data: _.pick(action.payload, "siteFilters"),
  }

  yield put(clearErrors())
  yield put(setLoadingTagsForExportSitesFlag(true))
  try {
    const response = yield call(handleAxiosRequest, ajaxConfig)
    exportTagsAsCsvFromSites(response.data, action.payload)
    yield put(fetchAndExportTagsFromSitesSuccess("Successfully exported tags"))
  } catch {
    yield handleSagaError(fetchAndExportTagsFromSitesFailed, "Failed to export tags")
  }
  yield put(setLoadingTagsForExportSitesFlag(false))
}

export function* watchFetchAndExportTagsFromSites(): SagaIterator {
  yield takeEvery(fetchAndExportTagsFromSites, fetchAndExportTagsFromSitesSaga)
}
