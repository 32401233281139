import { Box, Tab, Tabs, withStyles } from "@material-ui/core"
import { BreadCrumb, HeaderInfo } from "../../../../../redux/types/newHeaderTypes"
import { ENTITY_STATUSES, PERMISSIONS } from "../../../../../constants"
import { Edit as EditIcon, PlusCircle as PlusCircleIcon } from "react-feather"
import { Link, Route, Switch } from "react-router-dom"
import React, { useEffect, useState } from "react"
import { RootState, useAppDispatch } from "../../../../../redux/store"
import {
  fetchPublisherById,
  resetPublisherFormState,
} from "../../../../../redux/actions/publisherActions"
import { fetchSiteById, resetSiteFormState } from "../../../../../redux/actions/siteActions"

import Header from "../../../../NewHeader"
import { Publisher } from "../../../../../redux/types/publisherTypes"
import { Site } from "../../../../../redux/types/siteTypes"
import SiteForm from "./SiteForm"
import TagsList from "./TagsList"
import { clearErrors } from "../../../../../redux/actions/errorActions"
import { fetchAndExportTagsFromTags } from "../../../../../redux/actions/tagActions"
import styles from "./styles"
import { useSelector } from "react-redux"

export const buildHeaderInfo = (
  isCreate,
  isEdit,
  isInfo,
  isTags,
  publisher: Publisher = {} as Publisher,
  dispatch,
  site: Site = {} as Site,
  tagsQuery: string,
  tagsStatusFilterTags: ENTITY_STATUSES,
  tagsSelectedTags: number[],
  disabled: boolean
): HeaderInfo => {
  let headerText
  const breadcrumbs: BreadCrumb[] = [
    {
      name: "Dashboard",
      route: "/",
    },
    {
      name: "Publishers",
      route: "/admin/publishers",
    },
    {
      name: `${publisher.name} (${publisher.id})`,
      route: `/admin/publishers/${publisher.id}`,
    },
    {
      name: "Sites",
      route: `/admin/publishers/${publisher.id}/sites`,
    },
  ]
  const headerButtons = []

  if (isCreate) {
    headerText = "Create New Site"
    breadcrumbs.push({
      name: `Create`,
    })
  }

  if (isEdit) {
    headerText = "Site: "
    if (site.id) {
      headerText = `Site: ${site.name} (${site.id})`
      breadcrumbs.push({
        name: `${site.name} (${site.id})`,
        route: `/admin/publishers/${publisher.id}/sites/${site.id}`,
      })
      breadcrumbs.push({
        name: `Edit`,
      })
    }
  }

  if (isInfo) {
    headerText = "Site: "
    if (site.id) {
      headerText = `Site: ${site.name} (${site.id})`
      breadcrumbs.push({
        name: `${site.name} (${site.id})`,
      })

      headerButtons.push({
        name: "EDIT",
        route: `/admin/publishers/${publisher.id}/sites/${site.id}/edit`,
        permission: PERMISSIONS.ADMIN.PUBLISHER.UPDATE,
        icon: <EditIcon />,
      })
    }
  }

  if (isTags) {
    headerText = "Site: "
    if (site.id) {
      headerText = `Site: ${site.name} (${site.id}) - All Tags`
      breadcrumbs.push({
        name: `${site.name} (${site.id})`,
        route: `/admin/publishers/${publisher.id}/sites/${site.id}`,
      })
      breadcrumbs.push({
        name: `Tags`,
      })

      headerButtons.push({
        name: "CREATE NEW TAG",
        route: `/admin/publishers/${publisher.id}/sites/${site.id}/tags/create`,
        permission: PERMISSIONS.ADMIN.PUBLISHER.UPDATE,
        icon: <PlusCircleIcon />,
      })
      headerButtons.push({
        name: tagsSelectedTags.length ? "EXPORT SELECTED TAGS" : "EXPORT ALL TAGS",
        buttonFunction: () =>
          dispatch(
            fetchAndExportTagsFromTags({
              publisherId: publisher.id,
              siteId: site.id,
              tagFilters: {
                status: tagsStatusFilterTags,
                ids: tagsSelectedTags,
              },
            })
          ),
        permission: PERMISSIONS.ADMIN.PUBLISHER.LIST.VIEW,
        disabled,
      })
    }
  }

  return {
    headerText,
    breadcrumbs,
    headerButtons,
  }
}

const SiteFormWrapper = ({ match }) => {
  const dispatch = useAppDispatch()
  const publisher = useSelector((state: RootState) => state.publisherForm.selectedPublisher)
  const site = useSelector((state: RootState) => state.siteForm.selectedSite)
  const isLoadingTags = useSelector((state: RootState) => state.tags.isLoadingTagsForExportTags)

  const [tagsQuery, setTagsQuery] = useState("")
  const [tagsStatusFilterTags, setTagsStatusFilterTags] = useState(ENTITY_STATUSES.ACTIVE)
  const [tagsSelectedTags, setTagsSelectedTags] = useState([])

  const endPath = match.path.split("/").pop()
  const isEdit = endPath === "edit"
  const isCreate = endPath === "create"
  const isTags = endPath === "tags"
  const isInfo = !isEdit && !isCreate && !isTags

  const locationPathname = match.url ?? location.pathname

  const tabStates = {
    create: {
      general: {
        name: "General",
        path: `/admin/publishers/${match.params.publisherId}/sites/create`,
      },
    },
    editInfoAndTags: {
      general: {
        name: "General",
        path: `/admin/publishers/${match.params.publisherId}/sites/${match.params.siteId}${
          isEdit ? "/edit" : ""
        }`,
      },
      tags: {
        name: "Tags",
        path: `/admin/publishers/${match.params.publisherId}/sites/${match.params.siteId}/tags`,
      },
    },
  }

  useEffect(() => {
    return () => {
      dispatch(resetSiteFormState())
      dispatch(resetPublisherFormState())
      dispatch(clearErrors())
    }
  }, [])

  // separate useEffects are necessary due to timing issues where navigating from view to edit will result in fields clearing
  useEffect(() => {
    // called literally so we have the name of the publisher in the breadcrumbs
    if (!publisher.id) dispatch(fetchPublisherById(match.params.publisherId))
  }, [publisher])

  // separate useEffects are necessary due to timing issues where navigating from view to edit will result in fields clearing
  useEffect(() => {
    if (!isCreate && !site.id)
      dispatch(
        fetchSiteById({ publisherId: match.params.publisherId, siteId: match.params.siteId })
      )
  }, [site])

  const { headerText, breadcrumbs, headerButtons } = buildHeaderInfo(
    isCreate,
    isEdit,
    isInfo,
    isTags,
    publisher,
    dispatch,
    site,
    tagsQuery,
    tagsStatusFilterTags,
    tagsSelectedTags,
    isLoadingTags
  )

  return (
    <>
      <Header headerText={headerText} breadcrumbs={breadcrumbs} buttons={headerButtons} />
      <Box mt={3} mb={0}>
        {/* render only general tab when is create leading to site/create */}
        {isCreate && (
          <Tabs data-testid="site-form-tabs-wrapper" value={locationPathname}>
            <Tab
              value={tabStates.create.general.path}
              label={tabStates.create.general.name}
              component={Link}
              to={tabStates.create.general.path}
              data-testid={"site-form-tabs-create-general"}
            />
          </Tabs>
        )}

        {/* render general and tags tab leading to dynamic site routes when is edit or info or sites urls */}
        {!isCreate && (
          <Tabs data-testid="site-form-tabs-wrapper" value={locationPathname}>
            <Tab
              value={tabStates.editInfoAndTags.general.path}
              label={tabStates.editInfoAndTags.general.name}
              component={Link}
              to={tabStates.editInfoAndTags.general.path}
              data-testid={"site-form-tabs-non-create-general"}
            />
            <Tab
              value={tabStates.editInfoAndTags.tags.path}
              label={tabStates.editInfoAndTags.tags.name}
              component={Link}
              to={tabStates.editInfoAndTags.tags.path}
              data-testid={"site-form-tabs-non-create-tags"}
            />
          </Tabs>
        )}

        <Switch>
          {isCreate && (
            <Route
              exact={true}
              path={tabStates.create.general.path}
              render={() => <SiteForm match={match} />}
            />
          )}

          {!isCreate && (
            <>
              <Route
                exact={true}
                path={tabStates.editInfoAndTags.general.path}
                render={() => <SiteForm match={match} />}
              />
              <Route
                path={tabStates.editInfoAndTags.tags.path}
                render={() => (
                  <TagsList
                    setTagsQuery={setTagsQuery}
                    setTagsStatusFilterTags={setTagsStatusFilterTags}
                    setTagsSelectedTags={setTagsSelectedTags}
                    match={match}
                  />
                )}
              />
            </>
          )}
        </Switch>
      </Box>
    </>
  )
}

export default withStyles(styles)(SiteFormWrapper)
