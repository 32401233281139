import {
  BlockAttribute,
  BlockCategory,
  BlockType,
  SITE_AUCTION_TYPE,
  Site,
} from "../types/siteTypes"
import {
  createSiteSuccess,
  fetchSiteBiddersSuccess,
  fetchSiteBlockAttributesSuccess,
  fetchSiteBlockCategoriesSuccess,
  fetchSiteBlockTypesSuccess,
  fetchSiteByIdSuccess,
  resetSiteFormState,
  setSiteFormLoadingBiddersFlag,
  setSiteFormLoadingBlockAttributesFlag,
  setSiteFormLoadingBlockCategoriesFlag,
  setSiteFormLoadingBlockTypesFlag,
  setSiteFormLoadingFlag,
  setSiteFormSubmittingFlag,
  updateSiteSuccess,
} from "../actions/siteActions"

import { Bidder } from "../types/sharedTypes"
import { ENTITY_FILTER_TYPE } from "../../constants"
import { createReducer } from "@reduxjs/toolkit"

export interface InitialSiteFormState {
  selectedSite: Site
  isLoading: boolean
  isLoadingBidders: boolean
  bidders: Bidder[]
  isSaved: boolean
  isSubmitting: boolean
  blockCategories: BlockCategory[]
  isLoadingBlockCategories: boolean
  blockAttributes: BlockAttribute[]
  isLoadingBlockAttributes: boolean
  blockTypes: BlockType[]
  isLoadingBlockTypes: boolean
}

export const initialSiteState = {
  name: "",
  publisherId: null,
  revShare: null,
  isReduceBid: false,
  auctionType: SITE_AUCTION_TYPE.FIRST_PRICE,
  bidders: [],
  demandFiltering: ENTITY_FILTER_TYPE.NONE,
  domains: [],
  isOandO: false,
  blockCategories: [],
  blockAttributes: [],
  blockTypes: [],
  tagsCount: 0,
  appNexusSiteId: "N/A",
}

export const initialState: InitialSiteFormState = {
  selectedSite: initialSiteState,
  isLoading: false,
  bidders: [],
  isLoadingBidders: false,
  blockCategories: [],
  isLoadingBlockCategories: false,
  blockAttributes: [],
  isLoadingBlockAttributes: false,
  blockTypes: [],
  isLoadingBlockTypes: false,
  isSaved: false,
  isSubmitting: false,
}

export default createReducer(initialState, (builder) =>
  builder
    /*============================== Site form action handlers ==============================*/
    .addCase(fetchSiteByIdSuccess, (state, action) => {
      state.selectedSite = action.payload
    })
    // Bidders
    .addCase(fetchSiteBiddersSuccess, (state, action) => {
      state.bidders = action.payload
    })
    .addCase(fetchSiteBlockCategoriesSuccess, (state, action) => {
      state.blockCategories = action.payload
    })
    .addCase(fetchSiteBlockAttributesSuccess, (state, action) => {
      state.blockAttributes = action.payload
    })
    .addCase(fetchSiteBlockTypesSuccess, (state, action) => {
      state.blockTypes = action.payload
    })
    .addCase(createSiteSuccess, (state) => {
      state.isSaved = true
    })
    .addCase(updateSiteSuccess, (state, action) => {
      state.selectedSite = action.payload
      state.isSaved = true
    })
    .addCase(setSiteFormLoadingFlag, (state, action) => {
      state.isLoading = action.payload
    })
    .addCase(setSiteFormLoadingBiddersFlag, (state, action) => {
      state.isLoadingBidders = action.payload
    })
    .addCase(setSiteFormLoadingBlockCategoriesFlag, (state, action) => {
      state.isLoadingBlockCategories = action.payload
    })
    .addCase(setSiteFormLoadingBlockAttributesFlag, (state, action) => {
      state.isLoadingBlockAttributes = action.payload
    })
    .addCase(setSiteFormLoadingBlockTypesFlag, (state, action) => {
      state.isLoadingBlockTypes = action.payload
    })
    .addCase(setSiteFormSubmittingFlag, (state, action) => {
      state.isSubmitting = action.payload
    })
    .addCase(resetSiteFormState, () => initialState)
)
