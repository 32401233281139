import { createAction } from "@reduxjs/toolkit"
import { HomePage } from "../types/homePageTypes"

/* ======================================= Home Page actions ======================================= */

export const updateHomePage = createAction<HomePage>("Effects_UPDATE_HOME_PAGE")
export const updateHomePageSuccess = createAction<HomePage>("UPDATE_HOME_PAGE_SUCCESS")
export const updateHomePageFailed = createAction<string>("UPDATE_HOME_PAGE_FAILED")

export const fetchHomePage = createAction<string>("Effects_FETCH_HOME_PAGE")
export const fetchHomePageSuccess = createAction<HomePage>("FETCH_HOME_PAGE_SUCCESS")
export const fetchHomePageFailed = createAction<string>("FETCH_HOME_PAGE_FAILED")

export const setHomePageLoading = createAction<boolean>("SET_HOME_PAGE_LOADING")

export const resetHomePageState = createAction("RESET_HOME_PAGE_STATE")
