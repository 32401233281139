import { Box, Drawer, IconButton } from "@material-ui/core"
import React, { useCallback, useEffect } from "react"
import { RootState, useAppDispatch } from "../../redux/store"
import { WithStyles, withStyles } from "@material-ui/core/styles"

import AddBoxOutlinedIcon from "@material-ui/icons/AddBoxOutlined"
import BusinessIcon from "@material-ui/icons/Business"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import DashboardIcon from "@material-ui/icons/Dashboard"
import { DataView } from "../../redux/types/userTypes"
import DynamicFeedIcon from "@material-ui/icons/DynamicFeed"
import EditOutlinedIcon from "@material-ui/icons/EditOutlined"
import HistoryIcon from "@material-ui/icons/History"
import ListIcon from "@material-ui/icons/List"
import { PERMISSIONS } from "../../constants"
import PeopleIcon from "@material-ui/icons/People"
import SidebarNav from "./SidebarNav"
import ThumbUp from "@material-ui/icons/ThumbUp"
import TimelineIcon from "@material-ui/icons/Timeline"
import WebIcon from "@material-ui/icons/Web"
import WorkIcon from "@material-ui/icons/Work"
import clsx from "clsx"
import { fetchApplicationVersion } from "../../redux/actions/applicationVersionActions"
import styles from "./styles"
import { useSelector } from "react-redux"

export const toolsSection = {
  subheader: {
    title: "Tools",
    permissions: [],
  },
  listItems: [
    {
      title: "Audiences",
      href: "/tools/audiences",
      permission: PERMISSIONS.TOOLS.AUDIENCE.LIST.VIEW,
      icon: <PeopleIcon />,
    },
    {
      title: "Deals",
      href: "/tools/deals",
      permission: PERMISSIONS.TOOLS.DEAL.LIST.VIEW,
      icon: <ThumbUp />,
    },
    {
      title: "DealRequests",
      href: "/tools/deal-requests",
      permission: PERMISSIONS.TOOLS.DEAL_REQUEST.LIST.VIEW,
      icon: <DynamicFeedIcon />,
    },
    {
      title: "Cadent Audiences",
      href: "/tools/external-audiences",
      permission: PERMISSIONS.TOOLS.DEAL.LIST.VIEW,
      icon: <PeopleIcon />,
    },
  ],
}
export const adminSection = {
  subheader: {
    title: "Admin",
    permissions: [],
  },
  listItems: [
    {
      title: "Users",
      href: "/admin/users",
      permission: PERMISSIONS.ADMIN.USER.LIST.VIEW,
      icon: <PeopleIcon />,
    },
    {
      title: "Roles",
      href: "/admin/roles",
      permission: PERMISSIONS.ADMIN.ROLE.LIST.VIEW,
      icon: <WorkIcon />,
    },
    {
      title: "Companies",
      href: "/admin/companies",
      permission: PERMISSIONS.ADMIN.COMPANY.LIST.VIEW,
      icon: <BusinessIcon />,
    },
    {
      title: "Publishers",
      permission: [PERMISSIONS.ADMIN.PUBLISHER.LIST.VIEW],
      icon: <PeopleIcon />,
      listItems: [
        {
          title: "View Publishers",
          href: "/admin/publishers",
          permission: PERMISSIONS.ADMIN.PUBLISHER.LIST.VIEW,
          icon: <ListIcon />,
        },
        {
          title: "View Sellers.json",
          href: "/admin/publishers/sellers-json",
          icon: <ListIcon />,
          permission: PERMISSIONS.ADMIN.PUBLISHER.LIST.VIEW,
        },
        {
          title: "AppNexus Migration",
          href: "/admin/publishers/app-nexus-migration",
          icon: <ListIcon />,
          permission: PERMISSIONS.ADMIN.PUBLISHER.CREATE,
        },
      ],
    },
    {
      title: "Domains",
      href: "/admin/domains",
      permission: PERMISSIONS.ADMIN.DOMAIN.LIST.VIEW,
      icon: <WebIcon />,
    },
    {
      title: "Changelog",
      href: "/admin/changelog",
      permission: PERMISSIONS.ADMIN.CHANGELOG.LIST.VIEW,
      icon: <HistoryIcon />,
    },
    {
      title: "Segments",
      href: "/admin/segments",
      permission: PERMISSIONS.ADMIN.SEGMENT.CREATE.UPLOAD,
      icon: <AddBoxOutlinedIcon />,
    },
    {
      title: "Configuration",
      href: "/admin/configuration/home-page-editor",
      permission: PERMISSIONS.ADMIN.CONFIGURATION.UPDATE,
      icon: <EditOutlinedIcon />,
    },
  ],
}

// use permissions from submenu items for top level menu items
toolsSection.subheader.permissions = toolsSection.listItems.map((listItem) => listItem.permission)
adminSection.subheader.permissions = adminSection.listItems.map((listItem) => listItem.permission)

export const generateDataViewsSection = (dataViews: DataView[] = []) => {
  return {
    subheader: {
      title: "Data Views",
      permissions: [
        ...dataViews.map((dataView) => dataView.permission.slug),
        PERMISSIONS.DATA_VIEWS.KIBANA.VIEW,
      ],
    },
    listItems: [
      // render data view dashboard routes based on user's permissions/data view access
      ...dataViews.map((dataView) => ({
        title: dataView.mxConfigDataViewName,
        href: dataView.mxConfigRoute,
        permission: dataView.permission.slug,
        icon: <DashboardIcon />,
      })),
      {
        title: "Kibana",
        href: "http://s3-es.local.emxdgt.com/_dashboards/app/discover",
        isOpenInNewTab: true,
        permission: PERMISSIONS.DATA_VIEWS.KIBANA.VIEW,
        icon: <TimelineIcon />,
      },
    ],
  }
}

const Sidebar = ({ classes }: WithStyles) => {
  const dispatch = useAppDispatch()

  const dataViews = useSelector((state: RootState) => state.currentUser.currentUser.dataViews)
  const applicationVersion = useSelector((state: RootState) => state.applicationVersion)
  const isLoadingApplicationVersion = useSelector(
    (state: RootState) => state.applicationVersion.isLoading
  )

  const dataViewsSection = generateDataViewsSection(dataViews)

  const navBarEntries = [{ ...toolsSection }, { ...dataViewsSection }, { ...adminSection }]
  useEffect(() => {
    dispatch(fetchApplicationVersion())
  }, [])

  const defaultWidth = 220
  const maxWidth = 600
  const minWidth = 65 // width when side bar collapse
  const textWidth = 135 // min width to show side bar items
  const [isSidebarExpanded, setIsSidebarExpanded] = React.useState(true)
  const [drawerWidth, setDrawerWidth] = React.useState(defaultWidth)
  //const [delayMenu, setDelayMenu] = React.useState(true)
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(true)

  const toggleSidebarDrawer = () => {
    if (
      (isSidebarExpanded && drawerWidth >= defaultWidth) ||
      (isSidebarExpanded && drawerWidth > textWidth && drawerWidth < defaultWidth)
    ) {
      setDrawerWidth(minWidth)
      setIsSidebarExpanded(false)
      setIsDrawerOpen(false)
    } else if (!isSidebarExpanded && drawerWidth >= minWidth) {
      setDrawerWidth(defaultWidth)
      setIsDrawerOpen(true)
      setTimeout(() => {
        setIsSidebarExpanded(true)
      }, 300)
    }
  }

  const handleMousedown = () => {
    document.addEventListener("mouseup", handleMouseup, true)
    document.addEventListener("mousemove", handleMousemove, true)
    document.getElementById("root").style.userSelect = "none"
  }

  const handleMouseup = () => {
    document.removeEventListener("mouseup", handleMouseup, true)
    document.removeEventListener("mousemove", handleMousemove, true)
    document.getElementById("root").style.userSelect = "auto"
  }

  const handleMousemove = useCallback((e) => {
    const newWidth = e.clientX - document.body.offsetLeft
    if (newWidth >= minWidth) {
      if (newWidth <= textWidth) {
        setIsSidebarExpanded(false)
        setIsDrawerOpen(false)
        setDrawerWidth(minWidth)
      } else {
        if (newWidth > textWidth && (newWidth <= defaultWidth || newWidth <= maxWidth)) {
          setDrawerWidth(newWidth)
          setIsDrawerOpen(true)
          setIsSidebarExpanded(true)
        }
      }
    }
  }, [])

  return (
    <div className={classes.sidebarWrapper}>
      <Drawer
        anchor="left"
        classes={{
          paper: clsx({
            [classes.drawerOpen]: isDrawerOpen,
            [classes.drawerClose]: !isDrawerOpen,
          }),
        }}
        open={true}
        variant={"permanent"}
        data-testid={"sidebar-drawer"}
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: isDrawerOpen,
          [classes.drawerClose]: !isDrawerOpen,
        })}
        style={{ width: drawerWidth }}
        PaperProps={{ style: { width: drawerWidth } }}
      >
        <div className={classes.root}>
          <SidebarNav navBarEntries={navBarEntries} isSidebarExpanded={isSidebarExpanded} />
        </div>
      </Drawer>
      {!isLoadingApplicationVersion && applicationVersion.version.currentVersion && (
        <Box
          className={classes.versionNumber}
          data-testid="application-version"
          title={`V${applicationVersion.version.currentVersion}`}
        >
          {`V${applicationVersion.version.currentVersion}`}
        </Box>
      )}
      <div id="dragger" onMouseDown={() => handleMousedown()} className={classes.dragger}>
        <IconButton
          className={classes.arrowIcons}
          onClick={toggleSidebarDrawer}
          data-testid={"side-bar-arrow-btn"}
        >
          {isSidebarExpanded ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </div>
    </div>
  )
}

export default withStyles(styles)(Sidebar)
