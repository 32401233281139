import {
  fetchSubdomainByIdSuccess,
  resetSubdomainFormState,
  setSubdomainFormLoadingFlag,
  setSubdomainFormSubmittingFlag,
} from "../actions/subdomainActions"

import { Subdomain } from "../types/subdomainTypes"
import { createReducer } from "@reduxjs/toolkit"

export interface InitialSubdomainFormState {
  selectedSubdomain: Subdomain
  isLoading: boolean
  isSubmitting: boolean
}

export const initialSubdomainState = {
  id: null,
  domainId: null,
  name: "",
  createdAt: "",
  updatedAt: "",
}

export const initialState: InitialSubdomainFormState = {
  selectedSubdomain: initialSubdomainState,
  isLoading: false,
  isSubmitting: false,
}

export default createReducer(initialState, (builder) =>
  builder
    /*================================ Subdomain Form action handlers ================================*/
    .addCase(setSubdomainFormLoadingFlag, (state, action) => {
      state.isLoading = action.payload
    })
    .addCase(setSubdomainFormSubmittingFlag, (state, action) => {
      state.isSubmitting = action.payload
    })
    .addCase(fetchSubdomainByIdSuccess, (state, action) => {
      state.selectedSubdomain = action.payload
    })
    .addCase(resetSubdomainFormState, () => initialState)
)
