import { API, API_ROOT, SECTION } from "../../constants"
import { call, put, takeEvery } from "redux-saga/effects"
import {
  migratePublisherByAppNexusId,
  migratePublisherByAppNexusIdFailed,
  migratePublisherByAppNexusIdSuccess,
  setAppNexusMigrationFormMigratingPublisherFlag,
} from "../actions/appNexusMigrationActions"
import { handleAxiosRequest, handleSagaError } from "../../helpers/sagaHelpers"
import { AxiosRequestConfig } from "axios"
import { PayloadAction } from "@reduxjs/toolkit"
import { SagaIterator } from "redux-saga"
import { clearErrors } from "../actions/errorActions"
import { MigrateAppNexusPublisherInputs } from "../types/publisherTypes"
import _ from "lodash"
import { forwardTo } from "../../helpers/historyHelper"

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* migratePublisherByAppNexusIdSaga(
  action: PayloadAction<MigrateAppNexusPublisherInputs>
) {
  const ajaxConfig: AxiosRequestConfig = {
    method: "POST",
    url: `${API_ROOT}${API.V1}${SECTION.ADMIN}/publishers/app-nexus/migration/${action.payload.appNexusId}`,
    data: {
      appNexusPublisherInputs: _.pick(action.payload, "isRTB"),
    },
  }
  yield put(clearErrors())
  yield put(setAppNexusMigrationFormMigratingPublisherFlag(true))
  try {
    const response = yield call(handleAxiosRequest, ajaxConfig)
    yield put(migratePublisherByAppNexusIdSuccess(response.data))
    yield call(forwardTo, `/admin/publishers/${response.data.id}/sites`)
  } catch (err) {
    yield handleSagaError(migratePublisherByAppNexusIdFailed, err)
  }
  yield put(setAppNexusMigrationFormMigratingPublisherFlag(false))
}

export function* watchMigratePublisherByAppNexusId(): SagaIterator {
  yield takeEvery(migratePublisherByAppNexusId, migratePublisherByAppNexusIdSaga)
}
