import { call, put, takeEvery } from "redux-saga/effects"
import { API_ROOT, API, SECTION } from "../../constants"
import { AxiosRequestConfig } from "axios"
import { fetchUsers, fetchUsersFailed, fetchUsersSuccess } from "../actions/userActions"
import { handleAxiosRequest, handleSagaError } from "../../helpers/sagaHelpers"
import { SagaIterator } from "redux-saga"
import { PayloadAction } from "@reduxjs/toolkit"
import { FetchListPayload } from "../types/sharedTypes"

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* fetchUsersSaga(action: PayloadAction<FetchListPayload>) {
  const ajaxConfig: AxiosRequestConfig = {
    method: "POST",
    url: `${API_ROOT}${API.V1}${SECTION.ADMIN}/users/find`,
    data: action.payload,
  }
  try {
    const response = yield call(handleAxiosRequest, ajaxConfig)
    const page = parseInt(response.headers["x-pagination-page"], 10)
    const totalCount = parseInt(response.headers["x-pagination-total-count"], 10)
    yield put(
      fetchUsersSuccess({
        users: response.data,
        pager: {
          page,
          totalCount,
        },
      })
    )
  } catch (err) {
    yield handleSagaError(fetchUsersFailed, err)
  }
}

export function* watchFetchUsers(): SagaIterator {
  yield takeEvery(fetchUsers, fetchUsersSaga)
}
