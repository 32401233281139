import * as Yup from "yup"

import {
  Box,
  Button,
  Card,
  CardContent,
  FormControlLabel,
  Grid,
  TextField,
  withStyles,
} from "@material-ui/core"
import React, { useState } from "react"
import { RootState, useAppDispatch } from "../../../../../../../redux/store"

import Checkbox from "@material-ui/core/Checkbox"
import ErrorMessages from "../../../../../../common/Errors/ErrorMessages"
import { Formik } from "formik"
import { UpdateEnableCampaignBIPayload } from "../../../../../../../redux/types/companyTypes"
import _ from "lodash"
import { formatCompanyDisplayName } from "../../../../../../../helpers/formatterHelper"
import styles from "./styles"
import { updateCompanyEnableCampaignBI } from "../../../../../../../redux/actions/companyActions"
import { useSelector } from "react-redux"

const EnableCampaignBIForm = ({ classes }) => {
  const dispatch = useAppDispatch()
  const company = useSelector((state: RootState) => state.companyForm.selectedCompany)
  const isLoading = useSelector((state: RootState) => state.companyForm.isLoading)
  const isSubmitting = useSelector((state: RootState) => state.companyForm.isSubmitting)
  const isEnableCampaignBISettingsSaved = useSelector(
    (state: RootState) => state.companyForm.isEnableCampaignBISettingsSaved
  )

  // local state is used for this field to ensure that if input is checked, it cannot be unchecked
  const [enableCampaignBIFormInput, setEnableCampaignBIFormInput] = useState(
    company.enableCampaignBi || false
  )

  const initialFormValues = {
    displayName: company.displayName || formatCompanyDisplayName(company.name),
    enableCampaignBi: !!company.enableCampaignBi,
  }

  const formValidationSchema = Yup.object().shape({
    displayName: Yup.string()
      .max(100)
      .test(
        "has-special-characters",
        "Display Name cannot contain special characters (including spaces or -)",
        (displayName) =>
          displayName == null || displayName.toString().match(/^[a-zA-Z0-9_]+$/) !== null
      ),
    enableCampaignBi: Yup.boolean(),
  })

  const handleFormikSubmit = (values) => {
    const valuesCopy: UpdateEnableCampaignBIPayload = _.cloneDeep(values)
    valuesCopy.id = company.id
    dispatch(updateCompanyEnableCampaignBI(valuesCopy))
  }

  const isSubmitDisabled =
    isSubmitting ||
    isLoading ||
    company.enableCampaignBi ||
    !enableCampaignBIFormInput ||
    isEnableCampaignBISettingsSaved

  const isFieldsDisabled =
    isSubmitting || isLoading || company.enableCampaignBi || isEnableCampaignBISettingsSaved

  return (
    <Box mt={3} mb={3} data-testid="enable-campaign-bi-form">
      <Formik
        enableReinitialize={true}
        initialValues={initialFormValues}
        validationSchema={formValidationSchema}
        onSubmit={(values) => handleFormikSubmit(values)}
      >
        {({ handleSubmit, handleBlur, handleChange, setFieldValue, values, touched, errors }) => {
          return (
            <>
              <ErrorMessages />
              <form onSubmit={handleSubmit}>
                <Card>
                  <CardContent className={classes.campaignBiFormCardLayout}>
                    <Grid container spacing={3} className={classes.roundedBorder}>
                      <Grid item md={6} xs={12}>
                        <TextField
                          error={!!(touched.displayName && errors.displayName)}
                          fullWidth
                          helperText={touched.displayName && errors.displayName}
                          label="Display Name"
                          name="displayName"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          required
                          value={values.displayName}
                          variant="outlined"
                          disabled={isFieldsDisabled}
                          inputProps={{
                            "data-testid": "enable-campaign-bi-form-display-name-input",
                          }}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.enableCampaignBi}
                              onChange={(event) => {
                                setFieldValue("enableCampaignBi", event.target.checked)
                                setEnableCampaignBIFormInput(event.target.checked)
                              }}
                              disabled={isFieldsDisabled}
                            />
                          }
                          name="enableCampaignBi"
                          label="Enable Campaign BI"
                        />
                        <Box mt={2}>
                          <Button
                            variant="contained"
                            color="secondary"
                            type="submit"
                            disabled={isSubmitDisabled}
                            data-testid={"enable-campaign-bi-form-submit-button"}
                          >
                            {"Enable Campaign BI"}
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </form>
            </>
          )
        }}
      </Formik>
    </Box>
  )
}

export default withStyles(styles)(EnableCampaignBIForm)
