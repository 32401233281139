import * as Yup from "yup"

import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core"
import { BreadCrumb, HeaderInfo } from "../../../../../redux/types/newHeaderTypes"
import React, { useEffect } from "react"
import { RootState, useAppDispatch } from "../../../../../redux/store"
import {
  fetchPublisherByAppNexusId,
  migratePublisherByAppNexusId,
  resetAppNexusMigrationFormState,
  setAppNexusMigrationFormFindPublisherButtonClickedFlag,
} from "../../../../../redux/actions/appNexusMigrationActions"

import ErrorMessages from "../../../../common/Errors"
import { Formik } from "formik"
import Header from "../../../../NewHeader"
import { clearErrors } from "../../../../../redux/actions/errorActions"
import clsx from "clsx"
import styles from "./styles"
import { useSelector } from "react-redux"

export const buildHeaderInfo = (): HeaderInfo => {
  const breadcrumbs: BreadCrumb[] = [
    {
      name: "Dashboard",
      route: "/",
    },
    {
      name: "Publishers",
      route: "/admin/publishers",
    },
    {
      name: "AppNexus Migration",
    },
  ]

  const headerText = "AppNexus Migration"
  const headerButtons = []

  return {
    headerText,
    breadcrumbs,
    headerButtons,
  }
}

const AppNexusMigrationForm = ({ classes }) => {
  const dispatch = useAppDispatch()
  const publisher = useSelector((state: RootState) => state.appNexusMigrationForm.publisher)
  const isFindPublisherButtonClicked = useSelector(
    (state: RootState) => state.appNexusMigrationForm.isFindPublisherButtonClicked
  )
  const isFindingPublisher = useSelector(
    (state: RootState) => state.appNexusMigrationForm.isFindingPublisher
  )
  const isMigratingPublisherFlag = useSelector(
    (state: RootState) => state.appNexusMigrationForm.isMigratingPublisherFlag
  )

  useEffect(() => {
    // clean up stale data when component unmounts
    return () => {
      dispatch(clearErrors())
      dispatch(resetAppNexusMigrationFormState())
    }
  }, [])

  const initialFormValues = {
    appNexusId: publisher.appNexusId || "",
    name: publisher.name || "",
    isRTB: publisher.firstTag?.isRTB || false,
  }

  const formValidationSchema = Yup.object().shape({
    appNexusId: Yup.number().max(2147483647).required(),
  })

  const handleFindPublisher = (appNexusId) => {
    // This is needed in case the user enters a valid id twice in a row,
    // otherwise redux will not change and the form will not update again to show the correct name
    dispatch(resetAppNexusMigrationFormState())
    dispatch(fetchPublisherByAppNexusId(parseInt(appNexusId)))
  }

  const handleFormikSubmit = (values) => {
    dispatch(migratePublisherByAppNexusId(values))
  }

  const { headerText, breadcrumbs, headerButtons } = buildHeaderInfo()

  return (
    <>
      <Header headerText={headerText} breadcrumbs={breadcrumbs} buttons={headerButtons} />
      <Box mt={3} mb={3}>
        <ErrorMessages />
        <Formik
          enableReinitialize={true}
          initialValues={initialFormValues}
          validationSchema={formValidationSchema}
          onSubmit={(values) => handleFormikSubmit(values)}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, touched, values, setFieldValue }) => {
            return (
              <>
                <form onSubmit={handleSubmit}>
                  <Card>
                    <CardContent className={classes.cardPadding}>
                      <Grid container spacing={3} className={classes.roundedBorder}>
                        {/* After finding of publisher on OD has been resolved */}
                        {!isFindingPublisher ? (
                          <>
                            {/* Publisher AppNexus ID */}
                            <Grid item md={6} xs={12}>
                              <TextField
                                error={!!(touched.appNexusId && errors.appNexusId)}
                                fullWidth
                                helperText={touched.appNexusId && errors.appNexusId}
                                label={
                                  <span className={clsx(classes.overrideDisable)}>
                                    Publisher AppNexus ID
                                  </span>
                                }
                                name="appNexusId"
                                onBlur={handleBlur}
                                onChange={async (e) => {
                                  dispatch(
                                    setAppNexusMigrationFormFindPublisherButtonClickedFlag(false)
                                  )
                                  await handleChange(e)
                                  setFieldValue("name", "")
                                  setFieldValue("isRTB", false)
                                }}
                                required
                                value={values.appNexusId}
                                variant="outlined"
                                inputProps={{
                                  "data-testid": "app-nexus-migration-form-app-nexus-id-input",
                                }}
                                InputProps={{
                                  classes: {
                                    disabled: classes.overrideDisable,
                                  },
                                }}
                              />
                            </Grid>
                            {/* Find Publisher Button */}
                            <Box mt={2}>
                              <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => handleFindPublisher(values.appNexusId)}
                                disabled={
                                  isFindPublisherButtonClicked ||
                                  isFindingPublisher ||
                                  values.name !== "" ||
                                  values.appNexusId === "" ||
                                  !!errors.appNexusId
                                }
                                data-testid={"app-nexus-migration-form-find-button"}
                              >
                                {"Find Publisher"}
                              </Button>
                            </Box>

                            {/* When no publisher with appNexusId has been found on OD */}
                            {isFindPublisherButtonClicked && !publisher.name ? (
                              <>
                                <Grid item md={6} xs={12}>
                                  <Typography
                                    data-testid={
                                      "app-nexus-migration-form-publisher-not-found-message"
                                    }
                                  >
                                    Publisher not found. Please migrate new publisher.
                                  </Typography>
                                </Grid>
                                {/* Publisher isRTB checkbox */}
                                <Grid item md={6} xs={12}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={values.isRTB}
                                        data-testid="app-nexus-migration-form-publisher-is-rtb-input"
                                        onChange={handleChange}
                                      />
                                    }
                                    name="isRTB"
                                    label={<Typography>Is RTB</Typography>}
                                  />
                                </Grid>
                              </>
                            ) : (
                              // When publisher with appNexusId has been found on OD
                              <>
                                {/* Publisher Name */}
                                <Grid item md={6} xs={12}>
                                  <TextField
                                    fullWidth
                                    label={
                                      <span className={clsx(classes.overrideDisable)}>
                                        Publisher name
                                      </span>
                                    }
                                    name="name"
                                    value={values.name}
                                    variant="outlined"
                                    inputProps={{
                                      "data-testid":
                                        "app-nexus-migration-form-publisher-name-input",
                                    }}
                                    InputProps={{
                                      classes: {
                                        disabled: classes.overrideDisable,
                                      },
                                    }}
                                    disabled={true}
                                  />
                                </Grid>
                                {isFindPublisherButtonClicked && publisher.name && (
                                  // Publisher isRTB checkbox
                                  <Grid item md={6} xs={12}>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={values.isRTB}
                                          data-testid="app-nexus-migration-form-publisher-is-rtb-input"
                                          onChange={handleChange}
                                          // cannot modify isRTB when publisher has a first tag
                                          disabled={!!publisher.firstTag}
                                        />
                                      }
                                      name="isRTB"
                                      label={<Typography>Is RTB</Typography>}
                                    />
                                  </Grid>
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          <Grid item md={12} xs={12}>
                            <CardContent className={classes.loadingPublisher}>
                              <CircularProgress />
                            </CardContent>
                          </Grid>
                        )}
                      </Grid>
                      <Box mt={2}>
                        {/* Migrate publisher button */}
                        <Button
                          variant="contained"
                          color="secondary"
                          type="submit"
                          disabled={
                            !isFindPublisherButtonClicked ||
                            isFindingPublisher ||
                            isMigratingPublisherFlag ||
                            !values.appNexusId
                          }
                          data-testid={"app-nexus-migration-form-migrate-button"}
                        >
                          {values.name === "" ? "Migrate New Publisher" : "Migrate New Tags"}
                        </Button>
                      </Box>
                    </CardContent>
                  </Card>
                </form>
              </>
            )
          }}
        </Formik>
      </Box>
    </>
  )
}

export default withStyles(styles)(AppNexusMigrationForm)
