import { Box, Tab, Tabs, withStyles } from "@material-ui/core"
import { BreadCrumb, HeaderInfo } from "../../../../redux/types/newHeaderTypes"
import { Link, Route, Switch } from "react-router-dom"

import FeatureFlags from "./FeatureFlags"
import HomePageEditor from "./HomePageEditor"
import NewHeader from "../../../NewHeader"
import React from "react"
import styles from "../../styles"

export const buildHeaderInfo = (path): HeaderInfo => {
  let headerText

  const endPath = path.split("/").pop()
  const breadcrumbs: BreadCrumb[] = [
    {
      name: "Dashboard",
      route: "/",
    },
  ]
  const headerButtons = []

  if (endPath === "home-page-editor") {
    headerText = "Home Page Editor"
    breadcrumbs.push({
      name: "Home Page Editor",
    })
  }
  if (endPath === "feature-flags") {
    headerText = "Feature Flags"
    breadcrumbs.push({
      name: "Feature Flags",
    })
  }

  return {
    headerText,
    breadcrumbs,
    headerButtons,
  }
}

const ConfigurationWrapper = ({ match }) => {
  const locationPathname = match.path ?? location.pathname

  const tabs = {
    homePageEditor: {
      name: "Home Page Editor",
      path: "/admin/configuration/home-page-editor",
    },
    featureFlags: {
      name: "Feature Flags",
      path: "/admin/configuration/feature-flags",
    },
  }
  const { headerText, breadcrumbs } = buildHeaderInfo(locationPathname)

  return (
    <>
      <NewHeader headerText={headerText} breadcrumbs={breadcrumbs} />
      <Box mt={3} mb={0}>
        <Tabs data-testid="configuration-tabs-wrapper" value={locationPathname}>
          <Tab
            value={tabs.homePageEditor.path}
            label={tabs.homePageEditor.name}
            component={Link}
            to={tabs.homePageEditor.path}
            data-testid={"configuration-tab-home-page-editor"}
          />
          <Tab
            value={tabs.featureFlags.path}
            label={tabs.featureFlags.name}
            component={Link}
            to={tabs.featureFlags.path}
            data-testid={"configuration-tab-feature-flags"}
          />
        </Tabs>

        <Switch>
          <Route exact={true} path={tabs.homePageEditor.path} render={() => <HomePageEditor />} />
          <Route exact={true} path={tabs.featureFlags.path} render={() => <FeatureFlags />} />
        </Switch>
      </Box>
    </>
  )
}

export default withStyles(styles)(ConfigurationWrapper)
