import { createReducer } from "@reduxjs/toolkit"
import {
  fetchPermissions,
  fetchPermissionsFailed,
  fetchPermissionsSuccess,
  resetPermissionsState,
} from "../actions/permissionActions"
import { Permission } from "../types/permissionTypes"

interface InitialPermissionState {
  permissions: Permission[]
  isLoading: boolean
}

const initialState: InitialPermissionState = {
  permissions: [],
  isLoading: true,
}

export default createReducer(initialState, (builder) =>
  builder
    /*================================ Permissions List action handlers ================================*/
    .addCase(fetchPermissions, (state) => {
      state.isLoading = true
    })
    .addCase(fetchPermissionsSuccess, (state, action) => {
      state.isLoading = false
      state.permissions = action.payload
    })
    .addCase(fetchPermissionsFailed, (state) => {
      state.isLoading = false
    })
    .addCase(resetPermissionsState, () => initialState)
)
