import { createAction } from "@reduxjs/toolkit"
import {
  CreateRolePayload,
  FetchRolesSuccessResponse,
  Role,
  UpdateRolePayload,
} from "../types/roleTypes"
import { FetchListPayload } from "../types/sharedTypes"

/* ===================================== Role List actions ===================================== */
export const fetchRoles = createAction<FetchListPayload>("Effects_FETCH_ROLE")
export const fetchRolesSuccess = createAction<FetchRolesSuccessResponse>("FETCH_ROLES_SUCCESS")
export const fetchRolesFailed = createAction<string>("FETCH_ROLES_FAILED")

export const clearRoles = createAction("CLEAR_ROLES")
export const closeSaveRoleSuccess = createAction("CLOSE_SAVE_ROLE_SUCCESS")

/* ======================================= Role form actions ======================================= */
export const createRole = createAction<CreateRolePayload>("Effects_CREATE_ROLE")
export const createRoleSuccess = createAction<Role>("CREATE_ROLE_SUCCESS")
export const createRoleFailed = createAction<string>("CREATE_ROLE_FAILED")

export const updateRole = createAction<UpdateRolePayload>("Effects_UPDATE_ROLE")
export const updateRoleSuccess = createAction<Role>("UPDATE_ROLE_SUCCESS")
export const updateRoleFailed = createAction<string>("UPDATE_ROLE_FAILED")

export const fetchRoleById = createAction<number>("Effects_FETCH_ROLE_BY_ID")
export const fetchRoleByIdSuccess = createAction<Role>("FETCH_ROLE_BY_ID_SUCCESS")
export const fetchRoleByIdFailed = createAction<string>("FETCH_ROLE_BY_ID_FAILED")

export const resetRoleFormState = createAction("RESET_ROLE_FORM_STATE")
