import {
  clearExternalAudiences,
  fetchExternalAudiences,
  fetchExternalAudiencesFailed,
  fetchExternalAudiencesSuccess,
} from "../actions/externalAudienceActions"

import { ExternalAudience } from "../types/externalAudienceTypes"
import { PaginatedComponentState } from "../types/sharedTypes"
import { createReducer } from "@reduxjs/toolkit"

interface InitialExternalAudienceState extends PaginatedComponentState {
  data: ExternalAudience[]
  isLoading: boolean
}

const initialState: InitialExternalAudienceState = {
  data: [],
  isLoading: true,
  pager: {
    totalCount: 0,
    page: 0,
  },
}

export default createReducer(initialState, (builder) =>
  builder
    /*================================ External Audiences List action handlers ================================*/
    .addCase(fetchExternalAudiences, (state) => {
      state.isLoading = true
    })
    .addCase(fetchExternalAudiencesSuccess, (state, action) => {
      state.isLoading = false
      state.pager = action.payload.pager
      state.data = action.payload.externalAudiences
    })
    .addCase(fetchExternalAudiencesFailed, (state) => {
      state.isLoading = false
    })
    .addCase(clearExternalAudiences, (state) => {
      state.isLoading = false
      state.data = []
      state.pager = {
        totalCount: 0,
        page: 0,
      }
    })
)
