import { Domain, FetchDomainsSuccessResponse } from "../types/domainTypes"

import { FetchListPayload } from "../types/sharedTypes"
import { createAction } from "@reduxjs/toolkit"

/* ===================================== Domain List actions ====================================== */
export const fetchDomains = createAction<FetchListPayload>("Effects_FETCH_DOMAINS")
export const fetchDomainsSuccess =
  createAction<FetchDomainsSuccessResponse>("FETCH_DOMAINS_SUCCESS")
export const fetchDomainsFailed = createAction<string>("FETCH_DOMAINS_FAILED")
export const setDomainsLoadingFlag = createAction<boolean>("SET_DOMAINS_LOADING_FLAG")

export const clearDomains = createAction("CLEAR_DOMAINS")
export const closeSaveDomainSuccess = createAction("CLOSE_SAVE_DOMAIN_SUCCESS")

// /* ===================================== Domain Form actions ====================================== */

// Create
export const createDomain = createAction<Domain>("Effects_CREATE_DOMAIN")
export const createDomainSuccess = createAction<Domain>("CREATE_DOMAIN_SUCCESS")
export const createDomainFailed = createAction<string>("CREATE_DOMAIN_FAILED")

// // Update
export const updateDomain = createAction<Domain>("Effects_UPDATE_DOMAIN")
export const updateDomainSuccess = createAction<Domain>("UPDATE_DOMAIN_SUCCESS")
export const updateDomainFailed = createAction<string>("UPDATE_DOMAIN_FAILED")

export const fetchDomainById = createAction<number>("Effects_FETCH_DOMAIN_BY_ID")
export const fetchDomainByIdSuccess = createAction<Domain>("FETCH_DOMAIN_BY_ID_SUCCESS")
export const fetchDomainByIdFailed = createAction<string>("FETCH_DOMAIN_BY_ID_FAILED")

// Shared form actions
export const setDomainFormLoadingFlag = createAction<boolean>("SET_DOMAIN_FORM_LOADING_FLAG")
export const setDomainFormSubmittingFlag = createAction<boolean>("SET_DOMAIN_FORM_SUBMITTING_FLAG")
export const resetDomainFormState = createAction("RESET_DOMAIN_FORM_STATE")
