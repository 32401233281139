import { createAction } from "@reduxjs/toolkit"
import { FetchPermissionsSuccessResponse } from "../types/permissionTypes"

/* ===================================== Permissions List actions ===================================== */
export const fetchPermissions = createAction("Effects_FETCH_PERMISSIONS")
export const fetchPermissionsSuccess = createAction<FetchPermissionsSuccessResponse>(
  "FETCH_PERMISSIONS_SUCCESS"
)
export const fetchPermissionsFailed = createAction<string>("FETCH_PERMISSIONS_FAILED")
export const resetPermissionsState = createAction("RESET_PERMISSIONS_STATE")
