import { createStyles } from "@material-ui/core/styles"

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default () => {
  return createStyles({
    queryField: {
      width: "100%",
    },
    table: {
      minWidth: 650,
    },
  })
}
