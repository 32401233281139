import { migratePublisherByAppNexusIdSuccess } from "../actions/appNexusMigrationActions"
import { closeMigratePublisherByAppNexusIdSuccessMessage } from "../actions/publisherActions"
import {
  clearSites,
  closeSaveSiteSuccess,
  createSiteSuccess,
  fetchAndExportTagsFromSitesSuccess,
  fetchSitesSuccess,
  setLoadingTagsForExportSitesFlag,
  setSitesLoadingFlag,
  updateSiteSuccess,
} from "../actions/siteActions"

import { PaginatedComponentState } from "../types/sharedTypes"
import { Site } from "../types/siteTypes"
import { createReducer } from "@reduxjs/toolkit"

interface InitialSiteState extends PaginatedComponentState {
  sites: Site[]
  isLoading: boolean
  isLoadingTagsForExportSites: boolean
  siteSuccessMessage: string
  migratePublisherByAppNexusIdSuccessMessage: string
}

export const initialState: InitialSiteState = {
  sites: [],
  isLoading: true,
  isLoadingTagsForExportSites: false,
  siteSuccessMessage: "",
  migratePublisherByAppNexusIdSuccessMessage: "",
  pager: {
    totalCount: 0,
    page: 0,
  },
}

export default createReducer(initialState, (builder) =>
  builder
    /*================================ Site List action handlers ================================*/
    .addCase(setSitesLoadingFlag, (state, action) => {
      state.isLoading = action.payload
    })
    .addCase(setLoadingTagsForExportSitesFlag, (state, action) => {
      state.isLoadingTagsForExportSites = action.payload
    })
    .addCase(fetchSitesSuccess, (state, action) => {
      state.sites = action.payload.sites
      state.pager = action.payload.pager
    })
    .addCase(fetchAndExportTagsFromSitesSuccess, (state, action) => {
      state.siteSuccessMessage = action.payload
    })
    .addCase(clearSites, () => initialState)

    /*======== Site Form action handlers (triggered by Site Form component, displayed in Site List component  ========*/
    .addCase(createSiteSuccess, (state, action) => {
      state.siteSuccessMessage = `Successfully created site: ${action.payload.name} (${action.payload.id})`
    })
    .addCase(updateSiteSuccess, (state, action) => {
      state.siteSuccessMessage = `Successfully updated site: ${action.payload.name} (${action.payload.id})`
    })
    .addCase(closeSaveSiteSuccess, (state) => {
      state.siteSuccessMessage = initialState.siteSuccessMessage
    })

    /*========================== Migrate Publisher action handlers (triggered by AppNexusMigrationForm)   ==========================*/
    .addCase(migratePublisherByAppNexusIdSuccess, (state, action) => {
      state.migratePublisherByAppNexusIdSuccessMessage = `Successfully migrated publisher: ${action.payload.name} (${action.payload.id})`
    })
    .addCase(closeMigratePublisherByAppNexusIdSuccessMessage, (state) => {
      state.migratePublisherByAppNexusIdSuccessMessage =
        initialState.migratePublisherByAppNexusIdSuccessMessage
    })
)
