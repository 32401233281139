import { Collapse, ListItem } from "@material-ui/core"
import React, { useState } from "react"

import Can from "../../common/Can"
import ExpandLessIcon from "@material-ui/icons/ExpandLess"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { NavLink } from "react-router-dom"
import clsx from "clsx"
import { lowerCaseAndHyphenate } from "../../../helpers/formatterHelper"
import styles from "./styles"
import { withStyles } from "@material-ui/core/styles"

const Collapsible = ({ listItem, isSidebarExpanded, classes }) => {
  const [isOpen, setIsOpen] = useState(false)
  const handleCollapsibleClick = () => {
    setIsOpen((prev) => !prev)
  }

  return (
    <>
      <ListItem
        button
        className={classes.navListSubItem}
        onClick={handleCollapsibleClick}
        data-testid={`${lowerCaseAndHyphenate(listItem.title)}-sidebar-nav-button`}
      >
        <div className={classes.iconAndTitleContainer} title={listItem.title}>
          <div className={classes.icon}>{listItem.icon}</div>
          <span
            className={clsx({
              [classes.displayNone]: !isSidebarExpanded,
            })}
          >
            {listItem.title}
          </span>
        </div>
        {isSidebarExpanded ? isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon /> : null}
      </ListItem>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        {listItem.listItems?.map((subListItem) => (
          <Can
            key={subListItem.title}
            perform={subListItem.permission}
            yes={
              <ListItem
                button
                className={classes.collapsibleItem}
                disableGutters
                key={subListItem.title}
                component={NavLink}
                to={subListItem.isOpenInNewTab ? { pathname: subListItem.href } : subListItem.href}
                target={subListItem.isOpenInNewTab && "_blank"}
                exact
                activeClassName={classes.active}
                data-testid={`${lowerCaseAndHyphenate(subListItem.title)}-sidebar-nav-button`}
                title={subListItem.title}
              >
                <div className={classes.icon}>{subListItem.icon}</div>
                <span
                  className={clsx({
                    [classes.displayNone]: !isSidebarExpanded,
                  })}
                >
                  {subListItem.title}
                </span>
              </ListItem>
            }
          />
        ))}
      </Collapse>
    </>
  )
}

export default withStyles(styles)(Collapsible)
