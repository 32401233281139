import { Popover, Typography, withStyles } from "@material-ui/core"
import React, { useState } from "react"
import styles from "./styles"
import { SegmentGroup } from "../../../redux/types/audienceTypes"
import ListItemText from "@material-ui/core/ListItemText"
import ListItem from "@material-ui/core/ListItem"
import { numberWithCommas } from "../../../helpers/formatterHelper"
import { TARGET_TYPES } from "../../../constants"
import { StyledReactComponent } from "../../../redux/types/sharedTypes"
import { formatSegmentGroups } from "../../../helpers/segmentHelpers"
import { useAppDispatch } from "../../../redux/store"
import { generalError } from "../../../redux/actions/errorActions"
import { Segment } from "../../../redux/types/segmentTypes"

interface SegmentGroupLogicSummaryProps extends StyledReactComponent {
  segmentGroupsValue: SegmentGroup[]
  segmentData: Segment[]
}

const SegmentGroupLogicSummary = ({
  classes,
  segmentGroupsValue,
  segmentData,
}: SegmentGroupLogicSummaryProps) => {
  segmentGroupsValue = segmentGroupsValue.length ? segmentGroupsValue : formatSegmentGroups([])
  const [anchorElement, setAnchorElement] = useState(null)
  const [popUpValue, setPopUpValue] = useState(null)
  const isOpen = !!anchorElement
  const dispatch = useAppDispatch()

  const renderPopover = () => {
    return (
      <Popover
        id={`popup-info`}
        className={classes.popover}
        open={isOpen}
        data-testid={`segment-groups-popup-info`}
        style={{ pointerEvents: "none" }}
        anchorEl={anchorElement}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        disableRestoreFocus
      >
        {renderPopoverInfo(popUpValue)}
      </Popover>
    )
  }

  const renderPopoverInfo = (segment) => {
    const segmentObject = segmentData.find(({ externalId }) => externalId === segment)

    if (segmentObject) {
      const segmentIPPopulation = segmentObject.populations.find(
        (segmentObject) => segmentObject.segmentTargetTypeId === TARGET_TYPES.IP
      ).population

      return (
        <div className={classes.popoverContent}>
          <ListItem key={`item-popup-${segmentObject.externalId}-${Math.random()}`}>
            <ListItemText
              data-testid={`segment-id-popup-${segmentObject.externalId}`}
              primary={segmentObject.hierarchy.join(" > ")}
              secondary={`Segment ID: ${segmentObject.externalId}, Population: ${numberWithCommas(
                segmentIPPopulation
              )}`}
            />
          </ListItem>
        </div>
      )
    }
  }

  const handlePopoverOpen = (event, popupData) => {
    setPopUpValue(popupData)
    setAnchorElement(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorElement(null)
  }

  const renderSegmentGroupSegments = (segmentGroup: SegmentGroup) => {
    // TODO: Potentially change error handling to use react-error-boundary https://www.npmjs.com/package/react-error-boundary?activeTab=dependencies
    try {
      return segmentGroup.segments?.map((segment, segmentIndex) => (
        <span
          key={`segment-popup-${segment}-${segmentIndex}`}
          data-testid={`segment-popup-${segment}`}
          onMouseEnter={(e) => handlePopoverOpen(e, segment)}
          onMouseLeave={handlePopoverClose}
        >
          {segmentGroup.segments.length !== segmentIndex + 1 ? (
            <>
              <span>{segment}</span>
              <span className={classes.segmentCompareLogic}> OR </span>
            </>
          ) : (
            `${segment}`
          )}
        </span>
      ))
    } catch (err) {
      dispatch(generalError("Segment Data is Malformed"))
    }
  }

  const renderSegmentGroupLogicSummary = () => {
    return segmentGroupsValue.map((segmentGroup: SegmentGroup, segmentGroupIndex: number) => (
      <React.Fragment key={`logic-${segmentGroup.id}-${segmentGroupIndex}`}>
        <ListItemText>
          {/* Expected output from line below (when i === 0): "{{Name}} A:" */}
          {/* 65 is the charcode for "A" */}
          {`${segmentGroup.name} ${String.fromCharCode(65 + segmentGroupIndex)}: `}
          {renderSegmentGroupSegments(segmentGroup)}
        </ListItemText>
        {segmentGroupIndex !== segmentGroupsValue.length - 1 ? (
          <ListItemText>
            <Typography component="p" className={classes.groupCompareLogic}>
              AND
            </Typography>
          </ListItemText>
        ) : (
          ""
        )}
      </React.Fragment>
    ))
  }

  return (
    <div data-testid={"segment-group-logic-summary"}>
      {renderPopover()}
      {renderSegmentGroupLogicSummary()}
    </div>
  )
}

export default withStyles(styles)(SegmentGroupLogicSummary)
