import { Base, FetchListPayload, Pager } from "./sharedTypes"

import { ENTITY_FILTER_TYPE } from "../../constants"

export const DEFAULT_SITE_AUCTION_TYPE = "1st Price"

export enum SITE_AUCTION_TYPE {
  FIRST_PRICE = "first-price",
  SECOND_PRICE = "second-price",
}

export interface Site extends Partial<Base> {
  name: string
  publisherId: number
  revShare: number
  isReduceBid: boolean
  auctionType: SITE_AUCTION_TYPE
  bidders: number[]
  demandFiltering: ENTITY_FILTER_TYPE
  domains: string[]
  isOandO: boolean
  blockCategories: BlockCategory[]
  blockAttributes: BlockAttribute[]
  blockTypes: BlockType[]
  tagsCount: number
  appNexusSiteId: number | string
}

export type BlockCategory = {
  id: number
  name: string
  iab: string
  main: string
  sub: number
  code: string
}

export type BlockAttribute = {
  id: number
  name: string
}

export type BlockType = {
  id: number
  name: string
}

export interface FetchSitesPayload extends FetchListPayload {
  publisherId: number
}

export interface FetchSiteByIdPayload {
  publisherId: number
  siteId: number
}

export type FetchSitesSuccessResponse = {
  sites: Site[]
  pager: Pager
}
