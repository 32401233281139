import {
  FetchSubdomainByIdPayload,
  FetchSubdomainsPayload,
  FetchSubdomainsSuccessResponse,
  Subdomain,
} from "./../types/subdomainTypes"

import { createAction } from "@reduxjs/toolkit"

/* ===================================== Subdomain List actions ====================================== */
export const fetchSubdomains = createAction<FetchSubdomainsPayload>("Effects_FETCH_SUBDOMAINS")
export const fetchSubdomainsSuccess = createAction<FetchSubdomainsSuccessResponse>(
  "FETCH_SUBDOMAINS_SUCCESS"
)
export const fetchSubdomainsFailed = createAction<string>("FETCH_SUBDOMAINS_FAILED")
export const setSubdomainsLoadingFlag = createAction<boolean>("SET_SUBDOMAINS_LOADING_FLAG")

export const clearSubdomains = createAction("CLEAR_SUBDOMAINS")
export const closeSaveSubdomainSuccess = createAction("CLOSE_SAVE_SUBDOMAIN_SUCCESS")

// /* ===================================== Subdomain Form actions ====================================== */

// Create
export const createSubdomain = createAction<Subdomain>("Effects_CREATE_SUBDOMAIN")
export const createSubdomainSuccess = createAction<Subdomain>("CREATE_SUBDOMAIN_SUCCESS")
export const createSubdomainFailed = createAction<string>("CREATE_SUBDOMAIN_FAILED")

// Update
export const updateSubdomain = createAction<Subdomain>("Effects_UPDATE_SUBDOMAIN")
export const updateSubdomainSuccess = createAction<Subdomain>("UPDATE_SUBDOMAIN_SUCCESS")
export const updateSubdomainFailed = createAction<string>("UPDATE_SUBDOMAIN_FAILED")

export const fetchSubdomainById = createAction<FetchSubdomainByIdPayload>(
  "Effects_FETCH_SUBDOMAIN_BY_ID"
)
export const fetchSubdomainByIdSuccess = createAction<Subdomain>("FETCH_SUBDOMAIN_BY_ID_SUCCESS")
export const fetchSubdomainByIdFailed = createAction<string>("FETCH_SUBDOMAIN_BY_ID_FAILED")

// Shared form actions
export const setSubdomainFormLoadingFlag = createAction<boolean>("SET_SUBDOMAIN_FORM_LOADING_FLAG")
export const setSubdomainFormSubmittingFlag = createAction<boolean>(
  "SET_SUBDOMAIN_FORM_SUBMITTING_FLAG"
)
export const resetSubdomainFormState = createAction("RESET_SUBDOMAIN_FORM_STATE")
