import { call, put, takeEvery } from "redux-saga/effects"
import { API_ROOT, API, SECTION } from "../../constants"
import { AxiosRequestConfig } from "axios"
import {
  fetchPublisherById,
  fetchPublisherByIdFailed,
  fetchPublisherByIdSuccess,
  setPublisherFormLoadingFlag,
} from "../actions/publisherActions"
import { handleAxiosRequest, handleSagaError } from "../../helpers/sagaHelpers"
import { SagaIterator } from "redux-saga"
import { PayloadAction } from "@reduxjs/toolkit"
import _ from "lodash"
import { clearErrors } from "../actions/errorActions"
import { forwardTo } from "../../helpers/historyHelper"

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* fetchPublisherByIdSaga(action: PayloadAction<number>) {
  const ajaxConfig: AxiosRequestConfig = {
    method: "GET",
    url: `${API_ROOT}${API.V1}${SECTION.ADMIN}/publishers/${action.payload}`,
  }
  yield put(clearErrors())
  yield put(setPublisherFormLoadingFlag(true))
  try {
    const response = yield call(handleAxiosRequest, ajaxConfig)
    yield put(fetchPublisherByIdSuccess(response.data))
  } catch (err) {
    if (_.get(err, "response.data.type") === "not_found" || "unknown") {
      yield call(forwardTo, "/admin/publishers")
    }
    yield handleSagaError(fetchPublisherByIdFailed, err)
  }
  yield put(setPublisherFormLoadingFlag(false))
}

export function* watchFetchPublisherById(): SagaIterator {
  yield takeEvery(fetchPublisherById, fetchPublisherByIdSaga)
}
