import { createReducer } from "@reduxjs/toolkit"
import {
  fetchDealRequests,
  fetchDealRequestsSuccess,
  fetchDealRequestsFailed,
  setReloadDealRequests,
  clearDealRequests,
  setDealRequestIsLoading,
  updatDealRequestsSuccess,
  updatDealRequestsFailed,
  closeUpdateDealRequestsSuccess,
} from "../actions/dealRequestsActions"
import { DealRequest } from "../types/dealRequestTypes"
import { PaginatedComponentState } from "../types/sharedTypes"

interface InitialDealRequestState extends PaginatedComponentState {
  dealRequests: DealRequest[]
  dealRequestsSuccessMessage: string
  isLoading: boolean
  shouldReloadDealRequests: boolean
}

const initialState: InitialDealRequestState = {
  dealRequests: [],
  dealRequestsSuccessMessage: "",
  isLoading: true,
  shouldReloadDealRequests: false,
  pager: {
    totalCount: 0,
    page: 0,
  },
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(setDealRequestIsLoading, (state, action) => {
      state.isLoading = action.payload
    })
    /*================================ Deal Request List action handlers ================================*/
    .addCase(fetchDealRequests, (state) => {
      state.isLoading = true
    })
    .addCase(fetchDealRequestsSuccess, (state, action) => {
      state.isLoading = false
      state.shouldReloadDealRequests = false
      state.dealRequests = action.payload.dealRequests
      state.pager = action.payload.pager
    })
    .addCase(fetchDealRequestsFailed, (state) => {
      state.isLoading = false
      state.shouldReloadDealRequests = false
    })

    /*================================ Deal Request Update Status handlers ================================*/

    .addCase(updatDealRequestsSuccess, (state, action) => {
      state.isLoading = false
      const updateDealRequestdIds = action.payload.join(", ")
      state.dealRequestsSuccessMessage = `Successfully updated Deal Requests for ID-${updateDealRequestdIds}`
    })
    .addCase(updatDealRequestsFailed, (state) => {
      state.isLoading = false
      state.shouldReloadDealRequests = false
    })
    .addCase(clearDealRequests, () => initialState)
    .addCase(setReloadDealRequests, (state, action) => {
      state.shouldReloadDealRequests = action.payload
    })
    .addCase(closeUpdateDealRequestsSuccess, (state) => {
      state.dealRequestsSuccessMessage = initialState.dealRequestsSuccessMessage
    })
)
