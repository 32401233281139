import { createReducer } from "@reduxjs/toolkit"
import {
  clearSegmentUploadSuccessMessage,
  displaySegmentCSVValidationErrors,
  fetchSegmentsSuccess,
  initSegmentCSVUpload,
  setSegmentsLoadingFlag,
  setSegmentTree,
  uploadSegmentFailed,
  uploadSegmentSuccess,
} from "../actions/segmentActions"
import {
  Segment,
  SegmentsByVendors,
  SegmentsTreeByVendor,
  SegmentTreeNode,
} from "../types/segmentTypes"

interface InitialSegmentUploadState {
  isUploading: boolean
  isSegmentsLoading: boolean
  segmentUploadSuccessMessage: string
  dealsSegments: Segment[]
  dealsSegmentTree: SegmentTreeNode[]
  segmentsByVendor: SegmentsByVendors[]
  segmentTreesByVendor: SegmentsTreeByVendor[]
}

const initialState: InitialSegmentUploadState = {
  isUploading: false,
  isSegmentsLoading: true,
  segmentUploadSuccessMessage: "",
  dealsSegments: [],
  dealsSegmentTree: [],
  segmentsByVendor: [
    {
      segments: [],
      vendorId: 0,
    },
  ],
  segmentTreesByVendor: [
    {
      segmentTree: [],
      vendorId: 0,
    },
  ],
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(setSegmentsLoadingFlag, (state, action) => {
      state.isSegmentsLoading = action.payload
    })
    .addCase(initSegmentCSVUpload, (state) => {
      state.isUploading = true
      state.segmentUploadSuccessMessage = initialState.segmentUploadSuccessMessage
    })
    .addCase(clearSegmentUploadSuccessMessage, (state) => {
      state.segmentUploadSuccessMessage = initialState.segmentUploadSuccessMessage
    })
    .addCase(fetchSegmentsSuccess, (state, action) => {
      const segmentData = []
      if (action.payload.vendorId === null) {
        state.dealsSegments = action.payload.segments
      } else {
        state.segmentsByVendor.forEach((curent) => {
          if (curent.vendorId !== action.payload.vendorId) {
            segmentData.push(curent)
          }
        })
        segmentData.push(action.payload)
        const sortedData = segmentData.sort(function (value1, value2) {
          return value1.id - value2.id
        })
        state.segmentsByVendor = sortedData
      }
    })
    .addCase(uploadSegmentSuccess, (state, action) => {
      state.isUploading = false
      state.segmentUploadSuccessMessage = action.payload.message
    })
    .addCase(uploadSegmentFailed, (state) => {
      state.isUploading = false
    })
    .addCase(displaySegmentCSVValidationErrors, (state) => {
      state.isUploading = false
    })
    .addCase(setSegmentTree, (state, action) => {
      const segmentTree = []
      if (action.payload.vendorId === null) {
        state.dealsSegmentTree = action.payload.segmentTree
      } else {
        state.segmentTreesByVendor.forEach((curent) => {
          if (curent.vendorId !== action.payload.vendorId) {
            segmentTree.push(curent)
          }
        })
        segmentTree.push(action.payload)
        const sortedData = segmentTree.sort(function (value1, value2) {
          return value1.id - value2.id
        })
        state.segmentTreesByVendor = sortedData
      }
    })
)
