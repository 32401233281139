import { PricingAndReachEstimate } from "../redux/types/pricingEstimateTypes"
import { SEGMENT_VENDOR_NAME } from "../constants"
import { SegmentVendor } from "../redux/types/segmentVendorTypes"
import _ from "lodash"
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const trimPayloadProperties = (values) => {
  Object.keys(values).forEach(
    (k) =>
      (values[k] = typeof values[k] == "string" ? values[k].replace(/  +/g, " ").trim() : values[k])
  )
  return _.mapValues(values, (value) => (value === "" ? null : value))
}

export const numberToDollars = (priceAsNumber: number): string =>
  priceAsNumber == null
    ? ""
    : priceAsNumber.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
      })

export const generateRandomString = (): string => {
  return Math.random().toString(36).substr(2, 5)
}

export const toPercent = (numberToConvertToPercent: number, minimumFractionDigits = 2): string =>
  numberToConvertToPercent != null
    ? (numberToConvertToPercent / 100).toLocaleString("en-us", {
        style: "percent",
        minimumFractionDigits,
      })
    : ""

export const numberWithCommas = (x: number | string): string => {
  // using != rather than !== to cover undefined case
  return x != null ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0"
}

export const generateSegmentVendorDropdownOptions = (
  segmentVendors: SegmentVendor[],
  isSegmentVendorsLoading: boolean,
  segmentVendorOptionsOnly: boolean
): { name: string; id: number }[] => {
  if (isSegmentVendorsLoading) return [{ name: "", id: 0 }]

  const segmentVendorDropdownOptions = []

  segmentVendors.length
    ? segmentVendors.map((segmentVendor) => {
        segmentVendorDropdownOptions.push({ name: segmentVendor.name, id: segmentVendor.id })
      })
    : segmentVendorDropdownOptions.push({ name: "No Segment Vendor Available", id: 0 })

  !segmentVendorOptionsOnly &&
    segmentVendors.length > 1 &&
    segmentVendorDropdownOptions.unshift({ name: "All", id: 0 })

  return segmentVendorDropdownOptions
}

export const toStringArray = (values: number[]): string[] => {
  try {
    return values?.map((i) => i.toString()) ?? []
  } catch (err) {
    return []
  }
}

// TODO: update/remove in DealForm when we make class validator ticket
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export const validateArray = (value: any[]): any[] => {
  return Array.isArray(value) ? value : []
}

//Method to calculate CPM values & as per EMXD-5945 for audigent vendor DMP Fee $1 should not be added
const calculateCPM = (cpmObject, cpmKey, isAudigentVendor) =>
  cpmObject[cpmKey] > 0 ? (isAudigentVendor ? cpmObject[cpmKey] : cpmObject[cpmKey] + 1) : 0

export const calculateCpmMetrics = (
  pricingEstimateData: PricingAndReachEstimate[],
  vendorName: string
) => {
  let totalReach = 0
  const isAudigentVendor = vendorName === SEGMENT_VENDOR_NAME.AUDIGENT
  const cpmValues = pricingEstimateData.map((row) => {
    totalReach = totalReach + row.reach
    //TODO-create constant for CPM keys like highCpm, medianCpm, lowCpm
    return {
      ...row,
      highCpm: calculateCPM(row, "highCpm", isAudigentVendor),
      lowCpm: calculateCPM(row, "lowCpm", isAudigentVendor),
      medianCpm: calculateCPM(row, "medianCpm", isAudigentVendor),
    }
  })
  return { totalReach: totalReach, pricingEstimateDataIncremented: [...cpmValues] }
}

export const formatCompanyDisplayName = (companyName: string) => {
  return companyName
    .toLowerCase()
    .replace(/[^a-zA-Z0-9_]+/g, "_")
    .slice(0, 100)
}

export const lowerCaseAndHyphenate = (str) => {
  return str.replace(/\s/g, "-").toLowerCase()
}
