import { API, API_ROOT, SECTION } from "../../constants"
import { call, put, takeEvery } from "redux-saga/effects"
import {
  fetchHomePage,
  fetchHomePageFailed,
  fetchHomePageSuccess,
  setHomePageLoading,
} from "../actions/homePageActions"
import { handleAxiosRequest, handleSagaError } from "../../helpers/sagaHelpers"

import { AxiosRequestConfig } from "axios"
import { SagaIterator } from "redux-saga"

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* fetchHomePageSaga() {
  const ajaxConfig: AxiosRequestConfig = {
    method: "GET",
    url: `${API_ROOT}${API.V1}${SECTION.ADMIN}/configuration/home-page/message`,
  }
  try {
    yield put(setHomePageLoading(true))
    const response = yield call(handleAxiosRequest, ajaxConfig)
    yield put(fetchHomePageSuccess(response.data))
  } catch (err) {
    yield handleSagaError(fetchHomePageFailed, err)
  } finally {
    yield put(setHomePageLoading(false))
  }
}

export function* watchFetchHomePage(): SagaIterator {
  yield takeEvery(fetchHomePage, fetchHomePageSaga)
}
