import React, { useEffect } from "react"
import Iframe from "react-iframe"
import { withStyles } from "@material-ui/core"
import styles from "./styles"
import { RootState, useAppDispatch } from "../../../../redux/store"
import ErrorMessages from "../../../common/Errors"
import { clearErrors } from "../../../../redux/actions/errorActions"
import { useSelector } from "react-redux"
import {
  fetchCampaignBIToken,
  loginToCampaignBI,
  resetDataViewState,
} from "../../../../redux/actions/dataViewActions"

const CampaignBI = ({ classes, dataView }) => {
  const dispatch = useAppDispatch()
  const token = useSelector((state: RootState) => state.dataView.campaignBI.token)
  const isLoggedIn = useSelector((state: RootState) => state.dataView.campaignBI.isLoggedIn)
  const isLoading = useSelector((state: RootState) => state.dataView.isLoading)

  useEffect(() => {
    token ? dispatch(loginToCampaignBI(token)) : dispatch(fetchCampaignBIToken(dataView.id))
  }, [token])

  useEffect(() => {
    return () => {
      dispatch(clearErrors())
      dispatch(resetDataViewState())
    }
  }, [])

  return (
    <>
      <ErrorMessages />
      <div className={classes.iFrameContainer}>
        {!isLoading && isLoggedIn && (
          <Iframe
            url={`${dataView.externalDataViewUrl}?standalone=true`}
            width="100%"
            height="100%"
            position="relative"
          />
        )}
      </div>
    </>
  )
}

export default withStyles(styles)(CampaignBI)
