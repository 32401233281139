import { Box, IconButton, Typography } from "@material-ui/core"

import BackArrowIcon from "@material-ui/icons/KeyboardArrowLeft"
import ForwardArrowIcon from "@material-ui/icons/KeyboardArrowRight"
import JumpBackArrowIcon from "@material-ui/icons/ArrowBackIos"
import JumpForwardArrowIcon from "@material-ui/icons/ArrowForwardIos"
import React from "react"
import { TablePaginationActionsProps } from "@material-ui/core/TablePagination/TablePaginationActions"
import { generateTablePaginationActionHandlers } from "./TablePaginationActions.func"

const TablePaginationActions = (props: TablePaginationActionsProps) => {
  const { count, page, rowsPerPage } = props

  const tenPercentJump = Math.ceil(count / rowsPerPage / 10)
  const totalPages = Math.ceil(count / rowsPerPage)

  const {
    handleJumpBackButtonClick,
    handleBackButtonClick,
    handleForwardButtonClick,
    handleJumpForwardButtonClick,
  } = generateTablePaginationActionHandlers(props)

  return (
    <>
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleJumpBackButtonClick}
          disabled={page === 0}
          data-testid={"table-pagination-actions-jump-back-button"}
          title={`Jump to page ${Math.max(0, page - tenPercentJump) + 1} of ${totalPages}`}
        >
          <JumpBackArrowIcon data-testid={"table-pagination-actions-jump-back-icon"} />
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          data-testid={"table-pagination-actions-back-button"}
          title={"Previous page"}
        >
          <BackArrowIcon />
        </IconButton>
        <Typography
          align="inherit"
          variant="body1"
          display="inline"
          data-testid={"table-pagination-actions-page-number"}
        >
          {page + 1}
        </Typography>
        <IconButton
          onClick={handleForwardButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          data-testid={"table-pagination-actions-forward-button"}
          title={"Next page"}
        >
          <ForwardArrowIcon />
        </IconButton>
        <IconButton
          onClick={handleJumpForwardButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          data-testid={"table-pagination-actions-jump-forward-button"}
          title={`Jump to page ${
            Math.min(page + tenPercentJump, Math.ceil(count / rowsPerPage) - 1) + 1
          } of ${totalPages}`}
        >
          <JumpForwardArrowIcon data-testid={"table-pagination-actions-jump-forward-icon"} />
        </IconButton>
      </Box>
    </>
  )
}

export default TablePaginationActions
