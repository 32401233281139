import {
  fetchCurrentUser,
  fetchCurrentUserFailed,
  fetchCurrentUserSuccess,
  updateUserActiveCompanySuccess,
} from "../actions/userActions"

import { Company } from "../types/companyTypes"
import { CurrentUser } from "../types/userTypes"
import { createReducer } from "@reduxjs/toolkit"

interface InitialCurrentUserState {
  currentUser: CurrentUser
  isLoadingCurrentUser: boolean
  selectedCompanyId: number
  retries: number
}
const initialState: InitialCurrentUserState = {
  currentUser: {
    id: null,
    activePermissions: [],
    notes: "",
    createdAt: "",
    updatedAt: "",
    status: "",
    firstName: "",
    lastName: "",
    email: "",
    loggedInAt: "",
    companyId: null,
    company: {} as Company,
    activeCompanyId: null,
    activeCompany: {} as Company,
    roles: [],
    focusedCompany: {
      id: null,
      notes: "",
      createdAt: "",
      updatedAt: "",
      status: "",
      name: "",
    },
    dataViews: [],
  },
  selectedCompanyId: null,
  isLoadingCurrentUser: true,
  retries: 0,
}

export default createReducer(initialState, (builder) =>
  builder
    /*================================ Permissions List action handlers ================================*/
    .addCase(fetchCurrentUser, (state) => {
      state.isLoadingCurrentUser = true
    })
    .addCase(fetchCurrentUserSuccess, (state, action) => {
      state.isLoadingCurrentUser = false
      state.currentUser = action.payload
    })
    .addCase(fetchCurrentUserFailed, (state) => {
      state.isLoadingCurrentUser = false
      state.retries++
    })
    .addCase(updateUserActiveCompanySuccess, () => {
      window.location.reload()
    })
)
