import React, { useEffect } from "react"
import {
  Box,
  Card,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from "@material-ui/core"
import PerfectScrollbar from "react-perfect-scrollbar"
import styles from "./styles"
import { useSelector } from "react-redux"
import { RootState, useAppDispatch } from "../../../../../redux/store"
import { clearErrors } from "../../../../../redux/actions/errorActions"
import ErrorMessages from "../../../../common/Errors"
import { clearSellersJson, fetchSellersJson } from "../../../../../redux/actions/sellersJsonActions"
import NewHeader from "../../../../NewHeader"

const SellersJsonList = ({ classes }) => {
  const dispatch = useAppDispatch()

  const isLoading = useSelector((state: RootState) => state.sellersJson.isLoading)
  const contactEmail = useSelector((state: RootState) => state.sellersJson.sellersJson.contactEmail)
  const contactAddress = useSelector(
    (state: RootState) => state.sellersJson.sellersJson.contactAddress
  )
  const sellers = useSelector((state: RootState) => state.sellersJson.sellersJson.sellers)
  const identifiers = useSelector((state: RootState) => state.sellersJson.sellersJson.identifiers)

  useEffect(() => {
    dispatch(fetchSellersJson())
    return () => {
      dispatch(clearSellersJson())
      dispatch(clearErrors())
    }
  }, [])

  return (
    <>
      <NewHeader
        headerText={"Sellers.json"}
        breadcrumbs={[
          {
            name: "Dashboard",
            route: "/",
          },
          {
            name: "Publishers",
            route: "/admin/publishers",
          },
          {
            name: "Sellers.json",
          },
        ]}
      />
      <Box mt={3} mb={3}>
        <ErrorMessages />
        <Card>
          <Box minWidth={700}>
            <Table size="small" data-testid={"sellers-json-list-contact-table"}>
              {isLoading ? (
                <TableBody data-testid={"sellers-json-list-contact-spinner"}>
                  <TableRow>
                    <TableCell colSpan={1} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody data-testid={"sellers-json-list-contact-content"}>
                  <TableRow>
                    <TableCell
                      data-testid={"sellers-json-list-contact-email"}
                    >{`Email: ${contactEmail}`}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell data-testid={"sellers-json-list-contact-address"}>
                      {`Address: ${contactAddress}`}
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </Box>
        </Card>
        <Box className={classes.boxSpacing} />
        <Card>
          <Box minWidth={700}>
            <Table size="small" data-testid={"sellers-json-list-company-identifiers-table"}>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2}>
                    <Typography variant="h4" color="textPrimary">
                      Company Identifiers
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableHead>
                <TableRow>
                  <TableCell
                    data-testid={"sellers-json-list-company-identifiers-name-table-column-header"}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    data-testid={"sellers-json-list-company-identifiers-value-table-column-header"}
                  >
                    Value
                  </TableCell>
                </TableRow>
              </TableHead>
              {isLoading ? (
                <TableBody data-testid={"sellers-json-list-company-identifiers-spinner"}>
                  <TableRow>
                    <TableCell colSpan={2} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody data-testid={"sellers-json-list-company-identifiers-content"}>
                  {identifiers.map((identifier) => {
                    return (
                      <TableRow key={identifier.value}>
                        <TableCell
                          data-testid={`sellers-json-list-company-identifiers-name-${identifier.value}`}
                        >
                          {identifier.name}
                        </TableCell>
                        <TableCell
                          data-testid={`sellers-json-list-company-identifiers-value-${identifier.value}`}
                        >
                          {identifier.value}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              )}
            </Table>
          </Box>
        </Card>
        <Box className={classes.boxSpacing} />
        <Card>
          <PerfectScrollbar>
            <Box minWidth={700}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={6}>
                      <Typography variant="h4" color="textPrimary">
                        Sellers
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableHead>
                  <TableRow>
                    <TableCell data-testid={"sellers-json-list-seller-id-table-column-header"}>
                      ID
                    </TableCell>
                    <TableCell data-testid={"sellers-json-list-name-table-column-header"}>
                      Name
                    </TableCell>
                    <TableCell data-testid={"sellers-json-list-domain-table-column-header"}>
                      Domain
                    </TableCell>
                    <TableCell data-testid={"sellers-json-list-seller-type-table-column-header"}>
                      Type
                    </TableCell>
                    <TableCell data-testid={"sellers-json-list-is-passthrough-table-column-header"}>
                      Is Passthrough
                    </TableCell>
                    <TableCell
                      data-testid={"sellers-json-list-is-confidential-table-column-header"}
                    >
                      Is Confidential
                    </TableCell>
                  </TableRow>
                </TableHead>
                {isLoading ? (
                  <TableBody data-testid={"sellers-json-list-sellers-table-spinner"}>
                    <TableRow>
                      <TableCell colSpan={6} align="center">
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody data-testid="sellers-json-list-sellers-content">
                    {sellers.map((seller) => (
                      <TableRow key={seller.sellerId}>
                        <TableCell data-testid={`sellers-json-list-seller-id-${seller.sellerId}`}>
                          {seller.sellerId}
                        </TableCell>
                        <TableCell data-testid={`sellers-json-list-name-${seller.sellerId}`}>
                          {seller.name}
                        </TableCell>
                        <TableCell data-testid={`sellers-json-list-domain-${seller.sellerId}`}>
                          {seller.domain}
                        </TableCell>
                        <TableCell data-testid={`sellers-json-list-seller-type-${seller.sellerId}`}>
                          {seller.sellerType}
                        </TableCell>
                        <TableCell
                          data-testid={`sellers-json-list-is-passthrough-${seller.sellerId}`}
                        >
                          {seller.isPassthrough ? "Yes" : "No"}
                        </TableCell>
                        <TableCell
                          data-testid={`sellers-json-list-is-confidential-${seller.sellerId}`}
                        >
                          {seller.isConfidential ? "Yes" : "No"}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                )}
              </Table>
            </Box>
          </PerfectScrollbar>
        </Card>
      </Box>
      <Box className={classes.boxSpacing} />
    </>
  )
}

export default withStyles(styles)(SellersJsonList)
