import {
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  FormControlLabel,
  InputAdornment,
  Popover,
  SvgIcon,
  TablePagination,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core"
import { BreadCrumb, HeaderInfo } from "../../../../redux/types/newHeaderTypes"
import { Calendar as CalendarIcon, Search as SearchIcon } from "react-feather"
import React, { useCallback, useEffect, useState } from "react"
import { RootState, useAppDispatch } from "../../../../redux/store"
import { clearChangeLog, fetchChangeLog } from "../../../../redux/actions/changeLogActions"
import { clearErrors, closeError } from "../../../../redux/actions/errorActions"
import { endOfDay, format, subDays } from "date-fns"

import { Alert } from "@material-ui/lab"
import { DataGrid } from "@material-ui/data-grid"
import { DateRangePicker } from "react-date-range"
import Header from "../../../NewHeader"
import { PAGE_LIMIT } from "../../../../constants"
import TablePaginationActions from "../../../common/TablePaginationActions"
import changeLogColumns from "./changeLogConfig"
import { debounceFn } from "../../../../helpers/utilityHelpers"
import styles from "./styles"
import { useSelector } from "react-redux"

export const buildHeaderInfo = (): HeaderInfo => {
  const headerText = "Changelog"
  const headerButtons = []

  const breadcrumbs: BreadCrumb[] = [
    {
      name: "Dashboard",
      route: "/",
    },
    {
      name: "Changelog",
    },
  ]

  return {
    headerText,
    breadcrumbs,
    headerButtons,
  }
}

const ChangeLog = ({ classes }) => {
  const dispatch = useAppDispatch()
  const changeLogData = useSelector((state: RootState) => state.changeLog.changeLogData)
  const isLoading = useSelector((state: RootState) => state.changeLog.isLoading)
  const errors = useSelector((state: RootState) => state.errors.generalErrors)
  const pager = useSelector((state: RootState) => state.changeLog.pager)
  const [pageLimit, setPageLimit] = useState(PAGE_LIMIT.DEFAULT)
  const [selectedPage, setSelectedPage] = useState(0)
  const [dateRangeElement, setDateRangeElement] = useState<HTMLButtonElement | null>(null)
  const [query, setQuery] = useState({
    user: "",
    entityId: "",
    entityName: "",
    includeSegmentData: false,
    dateRange: [
      {
        startDate: subDays(new Date(), 30),
        endDate: new Date(),
      },
    ],
  })

  useEffect(() => {
    return () => {
      dispatch(clearErrors())
      dispatch(clearChangeLog())
    }
  }, [])

  const openDaterangePopover = Boolean(dateRangeElement)
  const daterangeId = openDaterangePopover ? "daterange-popover" : undefined

  // Format dates to display as button label
  const startDate = format(query.dateRange[0].startDate, "MMM d, yyyy")
  const endDate = format(query.dateRange[0].endDate, "MMM d, yyyy")

  const handleDaterangeBtnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setDateRangeElement(event.currentTarget)
  }

  const handleDaterangePopoverClose = () => {
    setDateRangeElement(null)
  }

  const handlePageChange = (event, newPage) => {
    setSelectedPage(newPage)
  }

  const handleLimitChange = (event) => {
    setPageLimit(event.target.value)
    setSelectedPage(0)
  }

  const dispatchFetchChangeLogs = (value = query) => {
    value.dateRange[0].endDate = endOfDay(value.dateRange[0].endDate)
    dispatch(
      fetchChangeLog({
        pagination: {
          limit: pageLimit,
          page: selectedPage,
        },
        filters: {
          user: value.user,
          entityId: value.entityId,
          entityName: value.entityName,
          includeSegmentData: value.includeSegmentData,
          dateRange: {
            startDate: value.dateRange[0].startDate.toISOString(),
            endDate: value.dateRange[0].endDate.toISOString(),
          },
        },
      })
    )
  }
  useEffect(dispatchFetchChangeLogs, [pageLimit, selectedPage])

  const debounceSearch = useCallback(debounceFn(dispatchFetchChangeLogs), [pageLimit])

  const handleUserQueryChange = (event) => {
    updateQuery({ ...query, user: event.target.value })
  }

  const handlEntityIdQueryChange = (event) => {
    updateQuery({ ...query, entityId: event.target.value })
  }

  const handlEntityNameQueryChange = (event) => {
    updateQuery({ ...query, entityName: event.target.value })
  }

  const updateQuery = (updatedQuery) => {
    setQuery(updatedQuery)
    debounceSearch(updatedQuery)
    setSelectedPage(0)
  }

  const handleSegmentCheckboxChange = (event) => {
    updateQuery({ ...query, includeSegmentData: event.target.checked })
  }

  const handleDateChange = (event) => {
    setQuery((prevQuery) => ({
      ...prevQuery,
      dateRange: [{ startDate: event.range1.startDate, endDate: event.range1.endDate }],
    }))
  }

  const handleDateSelect = () => {
    debounceSearch(query)
    setSelectedPage(0)
    setDateRangeElement(null)
  }

  const { headerText, breadcrumbs } = buildHeaderInfo()

  return (
    <>
      <Header headerText={headerText} breadcrumbs={breadcrumbs} />
      <>
        <Box mt={3} mb={3}>
          {errors.length > 0 &&
            errors.map((error, i) => (
              <Alert severity="error" key={`${error}-${i}`} onClose={() => dispatch(closeError(i))}>
                {error}
              </Alert>
            ))}
          <Card data-testid="changelog-container">
            <Box
              sx={{
                p: 2,
                pr: 0,
                pb: 0,
              }}
              minHeight={56}
              display="flex"
              alignItems="center"
            >
              <TextField
                label="Filter By Username"
                className={classes.queryField}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                }}
                placeholder="Search by username"
                variant="outlined"
                onChange={handleUserQueryChange}
                value={query.user}
                data-testid={"changelog-user-search-input"}
              />
              <TextField
                label="Filter By Entity ID"
                className={classes.queryField}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                }}
                placeholder="Search by entity ID"
                variant="outlined"
                onChange={handlEntityIdQueryChange}
                value={query.entityId}
                data-testid={"changelog-entity-id-search-input"}
              />
              <TextField
                label="Filter By Entity Name"
                className={classes.queryField}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                }}
                placeholder="Search by entity name"
                variant="outlined"
                onChange={handlEntityNameQueryChange}
                value={query.entityName}
                data-testid={"changelog-entity-name-search-input"}
              />
              <Button
                className={classes.dateRangeButton}
                aria-describedby={daterangeId}
                variant="contained"
                color="secondary"
                onClick={handleDaterangeBtnClick}
                data-testid={"changelog-daterange-button"}
              >
                <SvgIcon className={classes.calendarIcon} fontSize="small">
                  <CalendarIcon />
                </SvgIcon>
                {startDate} - {endDate}
              </Button>
              <Popover
                id={daterangeId}
                open={openDaterangePopover}
                anchorEl={dateRangeElement}
                data-testid={"daterange-popover"}
                onClose={handleDaterangePopoverClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <Typography className={classes.typography} component={"div"}>
                  <DateRangePicker
                    ranges={query.dateRange}
                    onChange={handleDateChange}
                    moveRangeOnFirstSelection={false}
                    retainEndDateOnFirstSelection={true}
                    maxDate={new Date()}
                    inputRanges={[]}
                  />
                </Typography>
                <div className={classes.okButton}>
                  <Button variant="contained" color="secondary" onClick={handleDateSelect}>
                    OK
                  </Button>
                </div>
              </Popover>

              <Box flexGrow={1} />
              <TablePagination
                component="div"
                count={pager.totalCount}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={selectedPage}
                rowsPerPage={pageLimit}
                rowsPerPageOptions={PAGE_LIMIT.OPTIONS}
                data-testid="changelog-pagination"
                ActionsComponent={TablePaginationActions}
              />
            </Box>
            <Box
              sx={{
                pl: 2,
              }}
              minHeight={56}
              display="flex"
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={query.includeSegmentData}
                    onChange={handleSegmentCheckboxChange}
                    data-testid="changelog-include-segment-data-checkbox"
                  />
                }
                name="includeSegmentData"
                label="Include Segment Data"
              />
            </Box>
            <Divider />
            <>
              <DataGrid
                autoHeight={true}
                autoPageSize={true}
                hideFooterRowCount={true}
                hideFooterPagination={true}
                rows={changeLogData}
                columns={changeLogColumns}
                data-testid="changelog-content"
                loading={isLoading}
              />
              <TablePagination
                component="div"
                count={pager.totalCount}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={selectedPage}
                rowsPerPage={pageLimit}
                rowsPerPageOptions={PAGE_LIMIT.OPTIONS}
                data-testid="changelog-pagination"
                ActionsComponent={TablePaginationActions}
              />
            </>
          </Card>
        </Box>
      </>
    </>
  )
}

export default withStyles(styles)(ChangeLog)
