import { createReducer } from "@reduxjs/toolkit"
import {
  createUser,
  createUserFailed,
  createUserSuccess,
  fetchUserById,
  fetchUserByIdSuccess,
  updateUser,
  updateUserFailed,
  updateUserSuccess,
  resetUserFormState,
  fetchUserByIdFailed,
} from "../actions/userActions"
import { User } from "../types/userTypes"

interface InitialUserState {
  selectedUser: User
  isLoading: boolean
  isSaved: boolean
  isSubmitting: boolean
}

const initialState: InitialUserState = {
  selectedUser: {
    activePermissions: [],
    id: null,
    notes: "",
    createdAt: "",
    updatedAt: "",
    status: "",
    firstName: "",
    lastName: "",
    email: "",
    loggedInAt: "",
    companyId: null,
    roles: [],
    company: {
      id: null,
      notes: "",
      createdAt: "",
      updatedAt: "",
      status: "",
      name: "",
    },
    activeCompanyId: null,
  },
  isLoading: false,
  isSaved: false,
  isSubmitting: false,
}

export default createReducer(initialState, (builder) =>
  /*============================== Create User form action handlers ==============================*/
  builder
    .addCase(createUser, (state) => {
      state.isSubmitting = true
    })
    .addCase(createUserSuccess, (state) => {
      state.isSaved = true
      state.isSubmitting = false
    })
    .addCase(createUserFailed, (state) => {
      state.isSubmitting = false
    })

    /*============================== Edit User form action handlers ==============================*/
    .addCase(fetchUserById, (state) => {
      state.isLoading = true
    })
    .addCase(fetchUserByIdSuccess, (state, action) => {
      state.isLoading = false
      state.selectedUser = action.payload
    })
    .addCase(fetchUserByIdFailed, (state) => {
      state.isLoading = false
    })
    .addCase(updateUser, (state) => {
      state.isSubmitting = true
    })
    .addCase(updateUserSuccess, (state) => {
      state.isSaved = true
      state.isSubmitting = false
    })
    .addCase(updateUserFailed, (state) => {
      state.isSubmitting = false
    })

    .addCase(resetUserFormState, () => initialState)
)
