import { API, API_ROOT, SECTION } from "../../constants"
import { call, put, takeEvery } from "redux-saga/effects"
import {
  fetchSiteBlockCategories,
  fetchSiteBlockCategoriesFailed,
  fetchSiteBlockCategoriesSuccess,
  setSiteFormLoadingBlockCategoriesFlag,
} from "../actions/siteActions"
import { handleAxiosRequest, handleSagaError } from "../../helpers/sagaHelpers"

import { AxiosRequestConfig } from "axios"
import { SagaIterator } from "redux-saga"

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* fetchSiteBlockCategoriesSaga() {
  const ajaxConfig: AxiosRequestConfig = {
    method: "GET",
    url: `${API_ROOT}${API.V1}${SECTION.ADMIN}/publishers/block-categories/find`,
  }
  yield put(setSiteFormLoadingBlockCategoriesFlag(true))
  try {
    const response = yield call(handleAxiosRequest, ajaxConfig)
    yield put(fetchSiteBlockCategoriesSuccess(response.data))
  } catch (err) {
    yield handleSagaError(fetchSiteBlockCategoriesFailed, err)
  }
  yield put(setSiteFormLoadingBlockCategoriesFlag(false))
}

export function* watchFetchSiteBlockCategories(): SagaIterator {
  yield takeEvery(fetchSiteBlockCategories, fetchSiteBlockCategoriesSaga)
}
