import { createReducer } from "@reduxjs/toolkit"
import {
  fetchSegmentVendors,
  fetchSegmentVendorsSuccess,
  fetchSegmentVendorsFailed,
  resetSegmentVendorsState,
} from "../actions/segmentVendorActions"
import { SegmentVendor } from "../types/segmentVendorTypes"

export interface InitialSegmentVendorState {
  segmentVendors: SegmentVendor[]
  isLoading: boolean
}

const initialState: InitialSegmentVendorState = {
  segmentVendors: [],
  isLoading: true,
}

export default createReducer(initialState, (builder) =>
  builder
    /*============================== Fetch Segment Vendors action handlers ==============================*/
    .addCase(fetchSegmentVendors, (state) => {
      state.isLoading = true
    })
    .addCase(fetchSegmentVendorsSuccess, (state, action) => {
      state.segmentVendors = action.payload.segmentVendors
      state.isLoading = false
    })
    .addCase(fetchSegmentVendorsFailed, (state) => {
      state.isLoading = false
    })
    .addCase(resetSegmentVendorsState, () => initialState)
)
