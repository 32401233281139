import { API, API_ROOT, SECTION } from "../../constants"
import { call, delay, put, takeEvery } from "redux-saga/effects"
import {
  fetchCurrentUser,
  fetchCurrentUserFailed,
  fetchCurrentUserSuccess,
} from "../actions/userActions"
import { handleAxiosRequest, handleSagaError } from "../../helpers/sagaHelpers"

import { AxiosRequestConfig } from "axios"
import { SagaIterator } from "redux-saga"
import { clearErrors } from "../actions/errorActions"

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* fetchCurrentUserSaga() {
  const ajaxConfig: AxiosRequestConfig = {
    method: "GET",
    url: `${API_ROOT}${API.V1}${SECTION.ADMIN}/users/current`,
  }
  try {
    const response = yield call(handleAxiosRequest, ajaxConfig)
    yield put(fetchCurrentUserSuccess(response.data))
    yield put(clearErrors())
  } catch (err) {
    yield delay(3000)
    yield handleSagaError(fetchCurrentUserFailed, err)
  }
}
export function* watchFetchCurrentUser(): SagaIterator {
  yield takeEvery(fetchCurrentUser, fetchCurrentUserSaga)
}
