import { API, API_ROOT, SECTION } from "../../constants"
import { call, put, takeEvery } from "redux-saga/effects"
import {
  fetchCompanyById,
  fetchCompanyByIdFailed,
  fetchCompanyByIdSuccess,
  setCompanyFormLoadingFlag,
} from "../actions/companyActions"
import { handleAxiosRequest, handleSagaError } from "../../helpers/sagaHelpers"

import { AxiosRequestConfig } from "axios"
import { PayloadAction } from "@reduxjs/toolkit"
import { SagaIterator } from "redux-saga"
import _ from "lodash"
import { clearErrors } from "../actions/errorActions"
import { forwardTo } from "../../helpers/historyHelper"

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* fetchCompanyByIdSaga(action: PayloadAction<number>) {
  const ajaxConfig: AxiosRequestConfig = {
    method: "GET",
    url: `${API_ROOT}${API.V1}${SECTION.ADMIN}/companies/${action.payload}`,
  }
  yield put(clearErrors())
  yield put(setCompanyFormLoadingFlag(true))
  try {
    const response = yield call(handleAxiosRequest, ajaxConfig)
    yield put(fetchCompanyByIdSuccess(response.data))
  } catch (err) {
    if (_.get(err, "response.data.type") === "not_found" || "unknown") {
      yield call(forwardTo, "/admin/companies")
    }
    yield handleSagaError(fetchCompanyByIdFailed, err)
  }
  yield put(setCompanyFormLoadingFlag(false))
}

export function* watchFetchCompanyById(): SagaIterator {
  yield takeEvery(fetchCompanyById, fetchCompanyByIdSaga)
}
