import React from "react"
import { RootState } from "../../../redux/store"
import { isPermitted } from "../../../helpers/permissionHelper"
import { useSelector } from "react-redux"

type CanProps = {
  perform?: string | string[]
  yes: React.ReactNode
  no?: React.ReactNode
}

// setting fake default permission if no permission specified so only super admins will have access
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Can = ({ perform = "-super admin only-", yes, no = null }: CanProps) => {
  const currentUser = useSelector((state: RootState) => state.currentUser.currentUser)
  if (isPermitted(perform, currentUser)) return <>{yes}</>
  return no ? <>{no}</> : null
}

export default Can
