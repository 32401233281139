import { createReducer } from "@reduxjs/toolkit"
import {
  fetchAudienceById,
  fetchAudienceByIdSuccess,
  fetchAudienceByIdFailed,
  resetAudienceFormState,
  updateAudience,
  updateAudienceFailed,
  updateAudienceSuccess,
  createAudienceSuccess,
  createAudience,
  createAudienceFailed,
  fetchAudiencePopulation,
  fetchAudiencePopulationSuccess,
  fetchAudiencePopulationFailed,
  clearAudiencePopulation,
} from "../actions/audienceActions"
import { AudiencePopulation, AudienceWithFormattedSegmentGroups } from "../types/audienceTypes"
import { formatSegmentGroups } from "../../helpers/segmentHelpers"
import _ from "lodash"

interface InitialAudienceState {
  selectedAudience: AudienceWithFormattedSegmentGroups
  isLoading: boolean
  isSaved: boolean
  isSubmitting: boolean
  audiencePopulation: AudiencePopulation
  isLoadingAudiencePopulation: boolean
  targetAudienceSize: number
  // segmentVendors: SegmentVendor[]
  // segmentVendorPaginationState: PaginatedComponentState
}

const initialState: InitialAudienceState = {
  selectedAudience: {
    id: null,
    targetType: "",
    createdAt: "",
    updatedAt: "",
    name: "",
    status: "",
    notes: "",
    activeDeals: 0,
    vendorId: null,
    segmentGroups: [],
    pricingAndReachEstimates: null,
    population: 0,
    companyId: null,
  },
  isLoading: false,
  isSaved: false,
  isSubmitting: false,
  audiencePopulation: { ip: 0, cookie: 0 },
  isLoadingAudiencePopulation: false,
  targetAudienceSize: 0,
}

export default createReducer(initialState, (builder) =>
  builder
    /*============================== Fetch Audience action handlers ==============================*/
    .addCase(fetchAudienceById, (state) => {
      state.isLoading = true
    })
    .addCase(fetchAudienceByIdSuccess, (state, action) => {
      state.isLoading = false
      const audience = action.payload

      // typescript workaround due to type differences between what we save in state, and what is
      // returned from the backend
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      audience.segmentGroups = formatSegmentGroups(audience.segmentGroups)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      state.selectedAudience = audience
    })
    .addCase(fetchAudienceByIdFailed, (state) => {
      state.isLoading = false
    })

    /*============================== Fetch Audience action handlers ==============================*/
    // .addCase(fetchSegmentVendorsSuccess, (state, action) => {
    //   state.segmentVendors = action.payload.segmentVendors
    // })

    /*============================== Create Audience form action handlers ==============================*/

    .addCase(createAudience, (state) => {
      state.isSubmitting = true
    })
    .addCase(createAudienceSuccess, (state) => {
      state.isSaved = true
      state.isSubmitting = false
    })
    .addCase(createAudienceFailed, (state) => {
      state.isSubmitting = false
    })

    /*============================== Edit Audience form action handlers ==============================*/

    .addCase(updateAudience, (state) => {
      state.isSubmitting = true
    })
    .addCase(updateAudienceSuccess, (state) => {
      state.isSaved = true
      state.isSubmitting = false
    })
    .addCase(updateAudienceFailed, (state) => {
      state.isSubmitting = false
    })
    .addCase(resetAudienceFormState, () => initialState)

    /*============================== Fetch Audience Population action handlers ==============================*/
    .addCase(fetchAudiencePopulation, (state) => {
      state.isLoadingAudiencePopulation = true
    })
    .addCase(fetchAudiencePopulationSuccess, (state, action) => {
      state.isLoadingAudiencePopulation = false
      state.audiencePopulation = _.isEqual(action.payload, { ip: undefined, cookie: undefined })
        ? { ip: 0, cookie: 0 }
        : action.payload
    })
    .addCase(fetchAudiencePopulationFailed, (state) => {
      state.isLoadingAudiencePopulation = false
    })
    .addCase(clearAudiencePopulation, (state) => {
      state.audiencePopulation = initialState.audiencePopulation
      state.isLoadingAudiencePopulation = initialState.isLoadingAudiencePopulation
    })
)
