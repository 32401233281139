import React from "react"
import { Box, Card, CardContent, CircularProgress, withStyles } from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import { useAppDispatch } from "../../../../../../redux/store"
import styles from "./styles"
import EnableCampaignBIForm from "./EnableCampaignBIForm/EnableCampaignBIForm"
import ExternalLineItemDisplayNamesMappingTable from "./ExternalLineItemDisplayNamesMappingTable/ExternalLineItemDisplayNamesMappingTable"
import {
  closeCreateCompanyExternalLineItemDisplayNameSuccess,
  closeUpdateCompanyEnableCampaignBISuccess,
} from "../../../../../../redux/actions/companyActions"
import {
  InitialCompanyState,
  initialState,
} from "../../../../../../redux/reducers/companyFormReducer"

const CampaignBISettingsForm = ({
  classes,
  companyForm = initialState,
}: {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  classes: any
  companyForm?: InitialCompanyState
}) => {
  const dispatch = useAppDispatch()
  const company = companyForm.selectedCompany

  const isEnableCampaignBILoading = companyForm.isLoading
  const isLineItemsLoading = companyForm.isExternalLineItemDisplayNamesLoading
  const enableCampaignBISettingsSavedSuccessMessage =
    companyForm.enableCampaignBISettingsSavedSuccessMessage
  const isEnableCampaignBISettingsSaved = companyForm.isEnableCampaignBISettingsSaved
  const externalLineItemDisplayNameSuccessMessage =
    companyForm.externalLineItemDisplayCreateSuccessMessage

  return (
    <>
      {enableCampaignBISettingsSavedSuccessMessage.length > 0 && (
        <Alert
          data-testid="campaign-bi-settings-form-enable-message"
          severity="success"
          key={enableCampaignBISettingsSavedSuccessMessage}
          onClose={() => dispatch(closeUpdateCompanyEnableCampaignBISuccess())}
        >
          {enableCampaignBISettingsSavedSuccessMessage}
        </Alert>
      )}
      {externalLineItemDisplayNameSuccessMessage.length > 0 && (
        <Alert
          data-testid="campaign-bi-settings-form-line-item-message"
          severity="success"
          key={externalLineItemDisplayNameSuccessMessage}
          onClose={() => dispatch(closeCreateCompanyExternalLineItemDisplayNameSuccess())}
        >
          {externalLineItemDisplayNameSuccessMessage}
        </Alert>
      )}
      {!isEnableCampaignBILoading ? (
        <EnableCampaignBIForm />
      ) : (
        <Box mt={3} mb={3}>
          <Card>
            <CardContent className={classes.loadingForm}>
              <CircularProgress />
            </CardContent>
          </Card>
        </Box>
      )}
      {!isLineItemsLoading ? (
        (company.enableCampaignBi || isEnableCampaignBISettingsSaved) && (
          <ExternalLineItemDisplayNamesMappingTable companyForm={companyForm} />
        )
      ) : (
        <Card>
          <CardContent className={classes.loadingForm}>
            <CircularProgress />
          </CardContent>
        </Card>
      )}
    </>
  )
}

export default withStyles(styles)(CampaignBISettingsForm)
