import { createReducer } from "@reduxjs/toolkit"
import {
  clearUsers,
  closeSaveUserSuccess,
  createUserSuccess,
  fetchUserById,
  fetchUserByIdFailed,
  fetchUsers,
  fetchUsersFailed,
  fetchUsersSuccess,
  updateUserSuccess,
  updateUserLastLoginSuccess,
} from "../actions/userActions"
import { User } from "../types/userTypes"
import { PaginatedComponentState } from "../types/sharedTypes"

interface InitialUserState extends PaginatedComponentState {
  users: User[]
  isLoading: boolean
  userSuccessMessage: string
}

// TODO: no is submitting flag/actions after submission
const initialState: InitialUserState = {
  users: [],
  isLoading: true,
  userSuccessMessage: "",
  pager: {
    totalCount: 0,
    page: 0,
  },
}

export default createReducer(initialState, (builder) =>
  builder
    /*================================ User List action handlers ================================*/
    .addCase(fetchUsers, (state) => {
      state.isLoading = true
    })
    .addCase(fetchUsersSuccess, (state, action) => {
      state.isLoading = false
      state.users = action.payload.users
      state.pager = action.payload.pager
    })
    .addCase(fetchUsersFailed, (state) => {
      state.isLoading = false
    })
    .addCase(clearUsers, () => initialState)

    /*======== User Form action handlers (triggered by User Form component, displayed in User List component  ========*/
    .addCase(createUserSuccess, (state, action) => {
      state.userSuccessMessage = `Successfully created user: ${action.payload.firstName} ${action.payload.lastName} (${action.payload.id})`
    })
    .addCase(updateUserSuccess, (state, action) => {
      state.userSuccessMessage = `Successfully updated user: ${action.payload.firstName} ${action.payload.lastName} (${action.payload.id})`
    })
    .addCase(closeSaveUserSuccess, (state) => {
      state.userSuccessMessage = initialState.userSuccessMessage
    })

    /*===================================== Shared handlers  =====================================*/
    .addCase(fetchUserById, (state) => {
      state.isLoading = true
    })
    .addCase(fetchUserByIdFailed, (state) => {
      state.isLoading = false
    })
    /*===================================== Update User Last Login Action Handler  =====================================*/

    .addCase(updateUserLastLoginSuccess, (state) => {
      // We want this blank on front end since it's only to set the user last login date
      state.userSuccessMessage = ``
    })
)
