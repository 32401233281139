import {
  ArrowRight as ArrowRightIcon,
  Edit as EditIcon,
  PlusCircle as PlusCircleIcon,
  Search as SearchIcon,
} from "react-feather"
import {
  Box,
  Card,
  Checkbox,
  CircularProgress,
  IconButton,
  InputAdornment,
  Link,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core"
import { BreadCrumb, HeaderInfo } from "../../../../../redux/types/newHeaderTypes"
import { PAGE_LIMIT, PERMISSIONS } from "../../../../../constants"
import React, { useCallback, useEffect, useState } from "react"
import { RootState, useAppDispatch } from "../../../../../redux/store"
import { clearErrors, closeError } from "../../../../../redux/actions/errorActions"
import {
  clearUsers,
  closeSaveUserSuccess,
  fetchUsers,
} from "../../../../../redux/actions/userActions"

import { Alert } from "@material-ui/lab"
import Can from "../../../../common/Can"
import Header from "../../../../NewHeader"
import PerfectScrollbar from "react-perfect-scrollbar"
import { Link as RouterLink } from "react-router-dom"
import TablePaginationActions from "../../../../common/TablePaginationActions"
import { debounceFn } from "../../../../../helpers/utilityHelpers"
import styles from "./styles"
import { useSelector } from "react-redux"

export const buildHeaderInfo = (): HeaderInfo => {
  const headerText = "All Users"
  const headerButtons = [
    {
      name: "CREATE NEW USER",
      route: `/admin/users/create`,
      permission: PERMISSIONS.ADMIN.USER.CREATE,
      disabled: false,
      icon: <PlusCircleIcon />,
    },
  ]

  const breadcrumbs: BreadCrumb[] = [
    {
      name: "Dashboard",
      route: "/",
    },
    {
      name: "Users",
    },
  ]

  return {
    headerText,
    breadcrumbs,
    headerButtons,
  }
}

const UsersList = ({ classes }) => {
  const dispatch = useAppDispatch()

  const isLoading = useSelector((state: RootState) => state.users.isLoading)
  const users = useSelector((state: RootState) => state.users.users)
  const errors = useSelector((state: RootState) => state.errors.generalErrors)
  const userSuccessMessage = useSelector((state: RootState) => state.users.userSuccessMessage)
  const pager = useSelector((state: RootState) => state.users.pager)

  const sortOptions = [
    {
      value: "id|DESC",
      label: "ID (descending)",
    },
    {
      value: "id|ASC",
      label: "ID (ascending)",
    },
    {
      value: "firstName|ASC",
      label: "First Name (ascending)",
    },
    {
      value: "firstName|DESC",
      label: "First Name (descending)",
    },
    {
      value: "lastName|ASC",
      label: "Last Name (ascending)",
    },
    {
      value: "lastName|DESC",
      label: "Last Name (descending)",
    },
    {
      value: "email|ASC",
      label: "Email (ascending)",
    },
    {
      value: "email|DESC",
      label: "Email (descending)",
    },
  ]
  const [selectedUsers, setSelectedUsers] = useState([])
  const [query, setQuery] = useState("")
  const [sortByFilter, setSortByFilter] = useState({
    value: "id",
    order: "DESC",
  })
  const [pageLimit, setPageLimit] = useState(PAGE_LIMIT.DEFAULT)
  const [selectedPage, setSelectedPage] = useState(0)

  const dispatchFetchUsers = (value = query) => {
    dispatch(
      fetchUsers({
        pagination: {
          limit: pageLimit,
          page: selectedPage,
        },
        sort: {
          value: sortByFilter.value,
          order: sortByFilter.order,
        },
        filters: {
          term: value,
        },
      })
    )
  }
  useEffect(dispatchFetchUsers, [pageLimit, sortByFilter, selectedPage])

  useEffect(() => {
    return () => {
      dispatch(clearErrors())
      dispatch(clearUsers())
      dispatch(closeSaveUserSuccess())
    }
  }, [])

  const debounceSearch = useCallback(debounceFn(dispatchFetchUsers), [pageLimit, sortByFilter])
  const handleQueryChange = (event) => {
    setQuery(event.target.value)
    debounceSearch(event.target.value)
    setSelectedPage(0)
  }
  const handleSortChange = (event) => {
    const [sortValue, orderValue] = event.target.value.split("|")
    setSortByFilter({
      value: sortValue,
      order: orderValue,
    })
    setSelectedPage(0)
  }
  const handleSelectAllUsers = (event) => {
    setSelectedUsers(event.target.checked ? users.map((user) => user.id) : [])
  }
  const handleSelectOneUser = (event, userId) => {
    if (!selectedUsers.includes(userId)) {
      setSelectedUsers((prevSelected) => [...prevSelected, userId])
    } else {
      setSelectedUsers((prevSelected) => prevSelected.filter((id) => id !== userId))
    }
  }

  const handlePageChange = (event, newPage) => {
    setSelectedPage(newPage)
  }
  const handleLimitChange = (event) => {
    setPageLimit(event.target.value)
    setSelectedPage(0)
  }
  const selectedSomeUsers = selectedUsers.length > 0 && selectedUsers.length < users.length
  const selectedAllUsers = selectedUsers.length === users.length && users.length > 0

  const { headerText, breadcrumbs, headerButtons } = buildHeaderInfo()

  return (
    <>
      <Header headerText={headerText} breadcrumbs={breadcrumbs} buttons={headerButtons} />
      <Box mt={3} mb={3}>
        {errors.length > 0 &&
          errors.map((error, i) => (
            <Alert severity="error" key={`${error}-${i}`} onClose={() => dispatch(closeError(i))}>
              {error}
            </Alert>
          ))}
        {userSuccessMessage.length > 0 && (
          <Alert
            severity="success"
            key={userSuccessMessage}
            onClose={() => dispatch(closeSaveUserSuccess())}
          >
            {userSuccessMessage}
          </Alert>
        )}
        <Card data-testid="users-list-table-container">
          {/* Search Field */}
          <Box
            sx={{
              p: 2,
              pr: 0,
            }}
            minHeight={56}
            display="flex"
            alignItems="center"
          >
            <TextField
              className={classes.queryField}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              onChange={handleQueryChange}
              placeholder="Search users"
              value={query}
              variant="outlined"
              data-testid={"users-list-search-input"}
            />

            {/* Sort By */}
            <TextField
              label="Sort By"
              name="sort"
              onChange={handleSortChange}
              select
              SelectProps={{ native: true }}
              value={`${sortByFilter.value}|${sortByFilter.order}`}
              variant="outlined"
              data-testid={"users-list-sort-input"}
            >
              {sortOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>

            <Box flexGrow={1} />
            <TablePagination
              component="div"
              count={pager.totalCount}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleLimitChange}
              page={selectedPage}
              rowsPerPage={pageLimit}
              rowsPerPageOptions={PAGE_LIMIT.OPTIONS}
              data-testid="users-list-pagination"
              ActionsComponent={TablePaginationActions}
            />
          </Box>
          <PerfectScrollbar>
            <Box minWidth={700}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell width="5%" padding="checkbox">
                      <Checkbox
                        checked={selectedAllUsers}
                        indeterminate={selectedSomeUsers}
                        onChange={handleSelectAllUsers}
                      />
                    </TableCell>
                    <TableCell data-testid={"users-list-name-table-column-header"}>Name</TableCell>
                    <TableCell data-testid={"users-list-roles-table-column-header"}>
                      Roles
                    </TableCell>
                    <TableCell data-testid={"users-list-status-table-column-header"}>
                      Status
                    </TableCell>
                    <Can
                      perform={[PERMISSIONS.ADMIN.USER.VIEW, PERMISSIONS.ADMIN.USER.UPDATE]}
                      yes={
                        <TableCell
                          align="right"
                          data-testid={"users-list-actions-table-column-header"}
                        >
                          Actions
                        </TableCell>
                      }
                    />
                  </TableRow>
                </TableHead>
                {isLoading ? (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody data-testid="users-list-content">
                    {users.map((user) => {
                      const isUserSelected = selectedUsers.includes(user.id)

                      return (
                        <TableRow hover key={user.id} selected={isUserSelected}>
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isUserSelected}
                              onChange={(event) => handleSelectOneUser(event, user.id)}
                              value={isUserSelected}
                            />
                          </TableCell>
                          <TableCell>
                            <Box display="flex" alignItems="center">
                              <div>
                                <Link
                                  color="inherit"
                                  component={RouterLink}
                                  to={`/admin/users/${user.id}`}
                                  variant="h6"
                                  data-testid={`users-list-name-${user.id}`}
                                >
                                  {`${user.firstName} ${user.lastName}`}
                                </Link>
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                  data-testid={`users-list-email-${user.id}`}
                                >
                                  {user.email}
                                </Typography>
                              </div>
                            </Box>
                          </TableCell>
                          <TableCell data-testid={`users-list-roles-${user.id}`}>
                            {user.roles
                              .map((role) => role.name)
                              .join(", ")
                              .trim()}
                          </TableCell>
                          <TableCell data-testid={`users-list-status-${user.id}`}>
                            {user.status}
                          </TableCell>
                          <Can
                            perform={[PERMISSIONS.ADMIN.USER.VIEW, PERMISSIONS.ADMIN.USER.UPDATE]}
                            yes={
                              <TableCell align="right">
                                <Can
                                  perform={PERMISSIONS.ADMIN.USER.UPDATE}
                                  yes={
                                    <IconButton
                                      component={RouterLink}
                                      to={`/admin/users/${user.id}/edit`}
                                      data-testid={`users-list-edit-button-${user.id}`}
                                    >
                                      <SvgIcon fontSize="small">
                                        <EditIcon />
                                      </SvgIcon>
                                    </IconButton>
                                  }
                                />
                                <Can
                                  perform={PERMISSIONS.ADMIN.USER.VIEW}
                                  yes={
                                    <IconButton
                                      component={RouterLink}
                                      to={`/admin/users/${user.id}`}
                                      data-testid={`users-list-view-button-${user.id}`}
                                    >
                                      <SvgIcon fontSize="small">
                                        <ArrowRightIcon />
                                      </SvgIcon>
                                    </IconButton>
                                  }
                                />
                              </TableCell>
                            }
                          />
                        </TableRow>
                      )
                    })}
                  </TableBody>
                )}
              </Table>
            </Box>
          </PerfectScrollbar>
          <TablePagination
            component="div"
            count={pager.totalCount}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={selectedPage}
            rowsPerPage={pageLimit}
            rowsPerPageOptions={PAGE_LIMIT.OPTIONS}
            data-testid="users-list-pagination"
            ActionsComponent={TablePaginationActions}
          />
        </Card>
      </Box>
      <Box sx={{ height: "24px" }} />
    </>
  )
}

export default withStyles(styles)(UsersList)
