import { API, API_ROOT, SECTION } from "../../constants"
import { call, put, takeEvery } from "redux-saga/effects"
import {
  fetchCompanyExternalLineItemDisplayNamesByCompanyId,
  fetchCompanyExternalLineItemDisplayNamesByCompanyIdFailed,
  fetchCompanyExternalLineItemDisplayNamesByCompanyIdSuccess,
  setIsExternalLineItemDisplayNamesLoadingFlag,
} from "../actions/companyActions"
import { handleAxiosRequest, handleSagaError } from "../../helpers/sagaHelpers"

import { AxiosRequestConfig } from "axios"
import { PayloadAction } from "@reduxjs/toolkit"
import { SagaIterator } from "redux-saga"

// TODO: For Code Review - Add Test for This File
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* fetchCompanyExternalLineItemDisplayNamesByCompanyIdSaga(
  action: PayloadAction<number>
) {
  const ajaxConfig: AxiosRequestConfig = {
    method: "GET",
    url: `${API_ROOT}${API.V1}${SECTION.ADMIN}/companies/${action.payload}/external-line-item-display-names`,
  }

  yield put(setIsExternalLineItemDisplayNamesLoadingFlag(true))
  try {
    const response = yield call(handleAxiosRequest, ajaxConfig)
    yield put(setIsExternalLineItemDisplayNamesLoadingFlag(false))
    yield put(fetchCompanyExternalLineItemDisplayNamesByCompanyIdSuccess(response.data))
  } catch (err) {
    yield handleSagaError(fetchCompanyExternalLineItemDisplayNamesByCompanyIdFailed, err)
  }
  yield put(setIsExternalLineItemDisplayNamesLoadingFlag(false))
}

export function* watchFetchCompanyExternalLineItemDisplayNamesByCompanyId(): SagaIterator {
  yield takeEvery(
    fetchCompanyExternalLineItemDisplayNamesByCompanyId,
    fetchCompanyExternalLineItemDisplayNamesByCompanyIdSaga
  )
}
