import { createReducer } from "@reduxjs/toolkit"
import {
  createRole,
  createRoleFailed,
  createRoleSuccess,
  fetchRoleById,
  fetchRoleByIdFailed,
  fetchRoleByIdSuccess,
  resetRoleFormState,
  updateRole,
  updateRoleFailed,
  updateRoleSuccess,
} from "../actions/roleActions"
import { Role } from "../types/roleTypes"

interface InitialRoleState {
  selectedRole: Role
  isLoading: boolean
  isSaved: boolean
  isSubmitting: boolean
}

const initialState: InitialRoleState = {
  selectedRole: {
    id: null,
    notes: "",
    createdAt: "",
    updatedAt: "",
    status: "",
    name: "",
    description: "",
    permissions: [],
  },
  isLoading: false,
  isSaved: false,
  isSubmitting: false,
}

export default createReducer(initialState, (builder) =>
  /*============================== Create Role form action handlers ==============================*/
  builder
    .addCase(createRole, (state) => {
      state.isSubmitting = true
    })
    .addCase(createRoleSuccess, (state) => {
      state.isSaved = true
      state.isSubmitting = false
    })
    .addCase(createRoleFailed, (state) => {
      state.isSubmitting = false
    })

    /*============================== Edit Role form action handlers ==============================*/
    .addCase(fetchRoleById, (state) => {
      state.isLoading = true
    })
    .addCase(fetchRoleByIdSuccess, (state, action) => {
      state.isLoading = false
      state.selectedRole = action.payload
    })
    .addCase(fetchRoleByIdFailed, (state) => {
      state.isLoading = false
    })
    .addCase(updateRole, (state) => {
      state.isSubmitting = true
    })
    .addCase(updateRoleSuccess, (state) => {
      state.isSaved = true
      state.isSubmitting = false
    })
    .addCase(updateRoleFailed, (state) => {
      state.isSubmitting = false
    })
    .addCase(resetRoleFormState, () => initialState)
)
