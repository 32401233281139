import {
  MigrateAppNexusPublisher,
  MigrateAppNexusPublisherInputs,
  Publisher,
} from "../types/publisherTypes"

import { createAction } from "@reduxjs/toolkit"

/* ===================================== AppNexus Migration Form actions ====================================== */
// Fetch Single Publisher by AppNexus Id
export const fetchPublisherByAppNexusId = createAction<number>(
  "Effects_FETCH_PUBLISHER_BY_APP_NEXUS_ID"
)
export const fetchPublisherByAppNexusIdSuccess = createAction<MigrateAppNexusPublisher>(
  "FETCH_PUBLISHER_BY_APP_NEXUS_ID_SUCCESS"
)
// For fail case we need to still be able to show id in the form
export const setPublisherAppNexusId = createAction<number>("FETCH_PUBLISHER_BY_APP_NEXUS_ID_SET_ID")
// Used to handle showing of failed banner
export const fetchPublisherByAppNexusIdFailed = createAction<string>(
  "FETCH_PUBLISHER_BY_APP_NEXUS_ID_FAILED"
)

export const clearAppNexusPublisher = createAction<boolean>("CLEAR_APP_NEXUS_PUBLISHER")

export const setAppNexusMigrationFormFindPublisherButtonClickedFlag = createAction<boolean>(
  "SET_APP_NEXUS_MIGRATION_FORM_FIND_PUBLISHER_BUTTON_CLICKED_FLAG"
)

export const setAppNexusMigrationFormFindingPublisherFlag = createAction<boolean>(
  "SET_APP_NEXUS_MIGRATION_FORM_FINDING_PUBLISHER_FLAG"
)

export const setAppNexusMigrationFormMigratingPublisherFlag = createAction<boolean>(
  "SET_APP_NEXUS_MIGRATION_FORM_MIGRATING_PUBLISHER_FLAG"
)

export const migratePublisherByAppNexusId = createAction<MigrateAppNexusPublisherInputs>(
  "MIGRATE_PUBLISHER_BY_APP_NEXUS_ID"
)

export const migratePublisherByAppNexusIdSuccess = createAction<Publisher>(
  "MIGRATE_PUBLISHER_BY_APP_NEXUS_ID_SUCCESS"
)
export const migratePublisherByAppNexusIdFailed = createAction<string>(
  "MIGRATE_PUBLISHER_BY_APP_NEXUS_ID_FAILED"
)

export const resetAppNexusMigrationFormState = createAction("RESET_APP_NEXUS_MIGRATION_FORM_STATE")
