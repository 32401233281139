import {
  clearTags,
  closeSaveTagSuccess,
  createTagSuccess,
  fetchAndExportTagsFromTagsSuccess,
  fetchTagsSuccess,
  setLoadingTagsForExportTagsFlag,
  setTagsLoadingFlag,
  updateTagSuccess,
} from "../actions/tagActions"

import { PaginatedComponentState } from "../types/sharedTypes"
import { Tag } from "../types/tagTypes"
import { createReducer } from "@reduxjs/toolkit"

interface InitialTagState extends PaginatedComponentState {
  tags: Tag[]
  isLoading: boolean
  isLoadingTagsForExportTags: boolean
  tagSuccessMessage: string
}

export const initialState: InitialTagState = {
  tags: [],
  isLoading: true,
  tagSuccessMessage: "",
  isLoadingTagsForExportTags: false,
  pager: {
    totalCount: 0,
    page: 0,
  },
}

export default createReducer(initialState, (builder) =>
  builder
    /*================================ Tag List action handlers ================================*/
    .addCase(setTagsLoadingFlag, (state, action) => {
      state.isLoading = action.payload
    })
    .addCase(setLoadingTagsForExportTagsFlag, (state, action) => {
      state.isLoadingTagsForExportTags = action.payload
    })
    .addCase(fetchTagsSuccess, (state, action) => {
      state.tags = action.payload.tags
      state.pager = action.payload.pager
    })
    .addCase(fetchAndExportTagsFromTagsSuccess, (state, action) => {
      state.tagSuccessMessage = action.payload
    })
    .addCase(clearTags, () => initialState)

    // /*======== Tag Form action handlers (triggered by Tag Form component, displayed in Tag List component  ========*/
    .addCase(createTagSuccess, (state, action) => {
      state.tagSuccessMessage = `Successfully created tag: ${action.payload.name} (${action.payload.id})`
    })
    .addCase(updateTagSuccess, (state, action) => {
      state.tagSuccessMessage = `Successfully updated tag: ${action.payload.name} (${action.payload.id})`
    })
    .addCase(closeSaveTagSuccess, (state) => {
      state.tagSuccessMessage = initialState.tagSuccessMessage
    })
)
