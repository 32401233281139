import {
  clearDomains,
  closeSaveDomainSuccess,
  createDomainSuccess,
  fetchDomainsSuccess,
  setDomainsLoadingFlag,
  updateDomainSuccess,
} from "../actions/domainActions"

import { Domain } from "../types/domainTypes"
import { PaginatedComponentState } from "../types/sharedTypes"
import { createReducer } from "@reduxjs/toolkit"

interface InitialDomainState extends PaginatedComponentState {
  domains: Domain[]
  isLoading: boolean
  domainSuccessMessage: string
}

const initialState: InitialDomainState = {
  domains: [],
  isLoading: true,
  domainSuccessMessage: "",
  pager: {
    totalCount: 0,
    page: 0,
  },
}

export default createReducer(initialState, (builder) =>
  builder
    /*================================ Domain List action handlers ================================*/
    .addCase(setDomainsLoadingFlag, (state, action) => {
      state.isLoading = action.payload
    })
    .addCase(fetchDomainsSuccess, (state, action) => {
      state.domains = action.payload.domains
      state.pager = action.payload.pager
    })

    /*================================ Domain Form action handlers ================================*/
    .addCase(createDomainSuccess, (state, action) => {
      state.domainSuccessMessage = `Successfully created domain: ${action.payload.name} (${action.payload.id})`
    })
    .addCase(updateDomainSuccess, (state, action) => {
      state.domainSuccessMessage = `Successfully updated domain: ${action.payload.name} (${action.payload.id})`
    })
    .addCase(closeSaveDomainSuccess, (state) => {
      state.domainSuccessMessage = initialState.domainSuccessMessage
    })
    .addCase(clearDomains, () => initialState)
)
