import { ArrowRight as ArrowRightIcon, Edit as EditIcon, Search as SearchIcon } from "react-feather"
import {
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  IconButton,
  InputAdornment,
  Link,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  withStyles,
} from "@material-ui/core"
import { ENTITY_FILTER_TYPE, PAGE_LIMIT, PERMISSIONS } from "../../../../../../constants"
import React, { useCallback, useEffect, useState } from "react"
import { RootState, useAppDispatch } from "../../../../../../redux/store"
import { SITE_AUCTION_TYPE, Site } from "../../../../../../redux/types/siteTypes"
import {
  clearSites,
  closeSaveSiteSuccess,
  fetchSites,
} from "../../../../../../redux/actions/siteActions"

import { Alert } from "@material-ui/lab"
import Can from "../../../../../common/Can"
import ErrorMessages from "../../../../../common/Errors"
import PerfectScrollbar from "react-perfect-scrollbar"
import { Link as RouterLink } from "react-router-dom"
import TablePaginationActions from "../../../../../common/TablePaginationActions"
import _ from "lodash"
import { clearErrors } from "../../../../../../redux/actions/errorActions"
import { closeMigratePublisherByAppNexusIdSuccessMessage } from "../../../../../../redux/actions/publisherActions"
import { debounceFn } from "../../../../../../helpers/utilityHelpers"
import styles from "./styles"
import { toPercent } from "../../../../../../helpers/formatterHelper"
import { useSelector } from "react-redux"

export const generateFilteredBiddersFieldValue = (site: Site) => {
  if (site.demandFiltering === ENTITY_FILTER_TYPE.NONE) return "NONE"

  return `${_.capitalize(site.demandFiltering)} (${site.bidders?.length || 0})`
}

const SitesList = ({ setSitesQuery, setSitesSelectedSites, classes, match }) => {
  const dispatch = useAppDispatch()

  const publisher = useSelector((state: RootState) => state.publisherForm.selectedPublisher)
  const isLoading = useSelector((state: RootState) => state.sites.isLoading)
  const sites = useSelector((state: RootState) => state.sites.sites)
  const siteSuccessMessage = useSelector((state: RootState) => state.sites.siteSuccessMessage)
  const migratePublisherByAppNexusIdSuccessMessage = useSelector(
    (state: RootState) => state.sites.migratePublisherByAppNexusIdSuccessMessage
  )
  const pager = useSelector((state: RootState) => state.sites.pager)

  const sortOptions = [
    {
      value: "id|DESC",
      label: "ID (descending)",
    },
    {
      value: "id|ASC",
      label: "ID (ascending)",
    },
    {
      value: "name|ASC",
      label: "Name (A-Z)",
    },
    {
      value: "name|DESC",
      label: "Name (Z-A)",
    },
  ]
  const [query, setQuery] = useState("")
  const [selectedPage, setSelectedPage] = useState(0)
  const [pageLimit, setPageLimit] = useState(PAGE_LIMIT.DEFAULT)
  const [sortByFilter, setSortByFilter] = useState({
    value: "id",
    order: "DESC",
  })
  const [selectedSites, setSelectedSites] = useState([])

  const publisherId = match.params.publisherId

  const dispatchFetchSites = (value = query) => {
    dispatch(
      fetchSites({
        publisherId,
        pagination: {
          page: selectedPage,
          limit: pageLimit,
        },
        filters: {
          term: value,
        },
        sort: {
          value: sortByFilter.value,
          order: sortByFilter.order,
        },
      })
    )
  }
  useEffect(dispatchFetchSites, [selectedPage, pageLimit, sortByFilter])

  useEffect(() => {
    return () => {
      dispatch(clearErrors())
      dispatch(clearSites())
      dispatch(closeSaveSiteSuccess())
      dispatch(closeMigratePublisherByAppNexusIdSuccessMessage())
    }
  }, [])

  const debounceSearch = useCallback(debounceFn(dispatchFetchSites), [pageLimit, sortByFilter])
  const handleQueryChange = (event) => {
    // required to pass query in outer component for tag export
    setSitesQuery(event.target.value)
    setQuery(event.target.value)
    debounceSearch(event.target.value)
  }
  const handleSortChange = (event) => {
    const [sortValue, orderValue] = event.target.value.split("|")
    setSortByFilter({
      value: sortValue,
      order: orderValue,
    })
    setSelectedPage(0)
  }
  const handleSelectAllSites = (event) => {
    const checkedSites = event.target.checked ? sites.map((site) => site.id) : []
    // required to pass ids to header in outer component for tag export
    setSitesSelectedSites(checkedSites)
    setSelectedSites(checkedSites)
  }
  const handleSelectOneSite = (event, siteId) => {
    if (!selectedSites.includes(siteId)) {
      const selectedSites = (prevSelected) => [...prevSelected, siteId]
      // required to pass ids to header in outer component for tag export
      setSitesSelectedSites(selectedSites)
      setSelectedSites(selectedSites)
    } else {
      const selectedSites = (prevSelected) => prevSelected.filter((id) => id !== siteId)
      // required to pass ids to header in outer component for tag export
      setSitesSelectedSites(selectedSites)
      setSelectedSites(selectedSites)
    }
  }

  const handlePageChange = (event, newPage) => {
    setSelectedPage(newPage)
  }
  const handleLimitChange = (event) => {
    setPageLimit(event.target.value)
    setSelectedPage(0)
  }

  const selectedSomeSites = selectedSites.length > 0 && selectedSites.length < sites.length
  const selectedAllSites = selectedSites.length === sites.length && sites.length > 0

  return (
    <>
      <Box mt={3} mb={3}>
        <ErrorMessages />
        {siteSuccessMessage.length > 0 && (
          <Alert
            severity="success"
            key={siteSuccessMessage}
            onClose={() => dispatch(closeSaveSiteSuccess())}
          >
            {siteSuccessMessage}
          </Alert>
        )}
        {migratePublisherByAppNexusIdSuccessMessage.length > 0 && (
          <div data-testid={"sites-list-migrate-publisher-by-app-nexus-id-success-message-banner"}>
            <Alert
              severity="success"
              key={migratePublisherByAppNexusIdSuccessMessage}
              onClose={() => dispatch(closeMigratePublisherByAppNexusIdSuccessMessage())}
            >
              {migratePublisherByAppNexusIdSuccessMessage}
            </Alert>
          </div>
        )}
        <Card data-testid="sites-list-table-container">
          <Box
            sx={{
              p: 2,
              pr: 0,
            }}
            minHeight={56}
            display="flex"
            alignItems="center"
          >
            {/* Search field */}
            <TextField
              className={classes.queryField}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              onChange={handleQueryChange}
              placeholder="Search sites"
              value={query}
              variant="outlined"
              data-testid={"sites-list-search-input"}
            />

            {/* Sort by */}
            <TextField
              label="Sort By"
              name="sort"
              onChange={handleSortChange}
              select
              SelectProps={{ native: true }}
              value={`${sortByFilter.value}|${sortByFilter.order}`}
              variant="outlined"
              data-testid={"sites-list-sort-input"}
            >
              {sortOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>

            <Box flexGrow={1} />
            <TablePagination
              component="div"
              count={pager.totalCount}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleLimitChange}
              page={selectedPage}
              rowsPerPage={pageLimit}
              rowsPerPageOptions={PAGE_LIMIT.OPTIONS}
              data-testid="sites-list-pagination"
              ActionsComponent={TablePaginationActions}
            />
          </Box>
          <PerfectScrollbar>
            <Box minWidth={700}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell width="5%" padding="checkbox">
                      <Checkbox
                        checked={selectedAllSites}
                        indeterminate={selectedSomeSites}
                        onChange={handleSelectAllSites}
                      />
                    </TableCell>
                    <TableCell data-testid={"sites-list-id-table-column-header"}>ID</TableCell>
                    <TableCell data-testid={"sites-list-name-table-column-header"}>Name</TableCell>
                    <TableCell data-testid={"sites-list-filtered-bidders-table-column-header"}>
                      Filtered Bidders
                    </TableCell>
                    <TableCell data-testid={"sites-list-auction-type-table-column-header"}>
                      Auction Type
                    </TableCell>
                    <TableCell data-testid={"sites-list-rev-share-table-column-header"}>
                      Rev Share
                    </TableCell>
                    <TableCell data-testid={"sites-list-app-nexus-site-id-table-column-header"}>
                      App Nexus Site ID
                    </TableCell>
                    <TableCell data-testid={"sites-list-tags-table-column-header"}>Tags</TableCell>
                    <Can
                      perform={[
                        PERMISSIONS.ADMIN.PUBLISHER.VIEW,
                        PERMISSIONS.ADMIN.PUBLISHER.UPDATE,
                      ]}
                      yes={
                        <TableCell
                          align="right"
                          data-testid={"sites-list-actions-table-column-header"}
                        >
                          Actions
                        </TableCell>
                      }
                    />
                  </TableRow>
                </TableHead>
                {isLoading ? (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={10} align="center">
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody data-testid="sites-list-content">
                    {sites.map((site) => {
                      const isSiteSelected = selectedSites.includes(site.id)

                      return (
                        <TableRow hover key={site.id} selected={isSiteSelected}>
                          {/* Checkbox */}
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isSiteSelected}
                              onChange={(event) => handleSelectOneSite(event, site.id)}
                              value={isSiteSelected}
                            />
                          </TableCell>

                          {/* Site Id */}
                          <TableCell>
                            <Box display="flex" alignItems="center">
                              <div>
                                <Link
                                  color="inherit"
                                  component={RouterLink}
                                  to={`/admin/publishers/${publisherId}/sites/${site.id}/tags`}
                                  variant="h6"
                                  data-testid={`sites-list-id-${site.id}`}
                                >
                                  {`${site.id}`}
                                </Link>
                              </div>
                            </Box>
                          </TableCell>

                          {/* Site Name */}
                          <TableCell>
                            <Box display="flex" alignItems="center">
                              <div>
                                <Link
                                  color="inherit"
                                  component={RouterLink}
                                  to={`/admin/publishers/${publisherId}/sites/${site.id}/tags`}
                                  variant="h6"
                                  data-testid={`sites-list-name-${site.id}`}
                                >
                                  {`${site.name}`}
                                </Link>
                              </div>
                            </Box>
                          </TableCell>

                          {/* Filtered Bidders */}
                          <TableCell data-testid={`sites-list-filtered-bidders-${site.id}`}>
                            {generateFilteredBiddersFieldValue(site)}
                          </TableCell>

                          {/* Auction Type */}
                          <TableCell data-testid={`sites-list-auction-type-${site.id}`}>
                            {site.auctionType === SITE_AUCTION_TYPE.FIRST_PRICE
                              ? "1st Price"
                              : "2nd Price"}
                          </TableCell>

                          {/* Rev Share */}
                          <TableCell data-testid={`sites-list-rev-share-${site.id}`}>
                            {`${toPercent(site.revShare)}`}
                          </TableCell>

                          {/* App Nexus Site Id */}
                          <TableCell data-testid={`sites-list-app-nexus-site-id-${site.id}`}>
                            {`${site.appNexusSiteId || "N/A"}`}
                          </TableCell>

                          {/* Tags */}
                          <TableCell data-testid={`sites-list-tags-${site.id}`}>
                            <Button
                              color="secondary"
                              variant="contained"
                              to={`/admin/publishers/${publisher.id}/sites/${site.id}/tags`}
                              component={RouterLink}
                              disabled={!site.tagsCount}
                              data-testid={`header-edit-button`}
                            >
                              {`List (${site.tagsCount})`}
                            </Button>
                          </TableCell>

                          {/* Edit/Info Action buttons */}
                          <Can
                            perform={[
                              PERMISSIONS.ADMIN.PUBLISHER.VIEW,
                              PERMISSIONS.ADMIN.PUBLISHER.UPDATE,
                            ]}
                            yes={
                              <TableCell align="right">
                                {/* Edit Button*/}
                                <Can
                                  perform={PERMISSIONS.ADMIN.PUBLISHER.UPDATE}
                                  yes={
                                    <IconButton
                                      component={RouterLink}
                                      to={`/admin/publishers/${publisherId}/sites/${site.id}/edit`}
                                      data-testid={`sites-list-edit-button-${site.id}`}
                                    >
                                      <SvgIcon fontSize="small">
                                        <EditIcon />
                                      </SvgIcon>
                                    </IconButton>
                                  }
                                />

                                {/* Info Button */}
                                <Can
                                  perform={PERMISSIONS.ADMIN.PUBLISHER.VIEW}
                                  yes={
                                    <IconButton
                                      component={RouterLink}
                                      to={`/admin/publishers/${publisherId}/sites/${site.id}`}
                                      data-testid={`sites-list-view-button-${site.id}`}
                                    >
                                      <SvgIcon fontSize="small">
                                        <ArrowRightIcon />
                                      </SvgIcon>
                                    </IconButton>
                                  }
                                />
                              </TableCell>
                            }
                          />
                        </TableRow>
                      )
                    })}
                  </TableBody>
                )}
              </Table>
            </Box>
          </PerfectScrollbar>
          <TablePagination
            component="div"
            count={pager.totalCount}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={selectedPage}
            rowsPerPage={pageLimit}
            rowsPerPageOptions={PAGE_LIMIT.OPTIONS}
            data-testid="sites-list-pagination"
            ActionsComponent={TablePaginationActions}
          />
        </Card>
      </Box>
      <Box sx={{ height: "24px" }} />
    </>
  )
}

export default withStyles(styles)(SitesList)
