import { createReducer } from "@reduxjs/toolkit"
import {
  resetDataViewState,
  setDataViewLoading,
  loginToCampaignBISuccess,
} from "../actions/dataViewActions"
import { fetchCampaignBITokenSuccess } from "../actions/dataViewActions"

interface CampaignBIInitialState {
  token: string
  isLoggedIn: boolean
}

interface InitialDataViewState {
  campaignBI: CampaignBIInitialState
  isLoading: boolean
}

const initialState: InitialDataViewState = {
  campaignBI: {
    token: "",
    isLoggedIn: false,
  },
  isLoading: false,
}
export default createReducer(initialState, (builder) => {
  builder
    /*==============================  CampaignBI action handlers ==============================*/
    .addCase(fetchCampaignBITokenSuccess, (state, action) => {
      state.campaignBI.token = action.payload
    })
    .addCase(loginToCampaignBISuccess, (state) => {
      state.campaignBI.isLoggedIn = true
    })

    /*==============================  General data view action handlers ==============================*/
    .addCase(setDataViewLoading, (state, action) => {
      state.isLoading = action.payload
    })
    .addCase(resetDataViewState, () => initialState)
})
