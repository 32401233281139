import { createAction } from "@reduxjs/toolkit"
import {
  Company,
  CreateCompanyPayload,
  ExternalLineItemDisplayName,
  FetchCompaniesSuccessResponse,
  UpdateCompanyPayload,
  UpdateEnableCampaignBIPayload,
} from "../types/companyTypes"
import { FetchListPayload } from "../types/sharedTypes"

/* ===================================== Company List actions ===================================== */
export const fetchCompanies = createAction<FetchListPayload>("Effects_FETCH_COMPANIES")
export const fetchCompaniesSuccess =
  createAction<FetchCompaniesSuccessResponse>("FETCH_COMPANIES_SUCCESS")
export const fetchCompaniesFailed = createAction<string>("FETCH_COMPANIES_FAILED")

export const clearCompanies = createAction("CLEAR_COMPANIES")
export const closeSaveCompanySuccess = createAction("CLOSE_SAVE_COMPANY_SUCCESS")

/* ======================================= Company form actions ======================================= */
export const setCompanyFormLoadingFlag = createAction<boolean>("SET_COMPANY_FORM_LOADING_FLAG")
export const setCompanyFormSubmittingFlag = createAction<boolean>(
  "SET_COMPANY_FORM_SUBMITTING_FLAG"
)
export const createCompany = createAction<CreateCompanyPayload>("Effects_CREATE_COMPANY")
export const createCompanySuccess = createAction<Company>("CREATE_COMPANY_SUCCESS")
export const createCompanyFailed = createAction<string>("CREATE_COMPANY_FAILED")

export const updateCompany = createAction<UpdateCompanyPayload>("Effects_UPDATE_COMPANY")
export const updateCompanySuccess = createAction<Company>("UPDATE_COMPANY_SUCCESS")
export const updateCompanyFailed = createAction<string>("UPDATE_COMPANY_FAILED")

export const fetchCompanyById = createAction<number>("Effects_FETCH_COMPANY_BY_ID")
export const fetchCompanyByIdSuccess = createAction<Company>("FETCH_COMPANY_BY_ID_SUCCESS")
export const fetchCompanyByIdFailed = createAction<string>("FETCH_COMPANY_BY_ID_FAILED")

export const resetCompanyFormState = createAction("RESET_COMPANY_FORM_STATE")

/* ================================= Campaign BI Settings form actions ================================= */
export const fetchCompanyExternalLineItemDisplayNamesByCompanyId = createAction<number>(
  "Effects_FETCH_COMPANY_EXTERNAL_LINE_ITEM_DISPLAY_NAMES_BY_COMPANY_ID"
)
export const fetchCompanyExternalLineItemDisplayNamesByCompanyIdSuccess = createAction<
  ExternalLineItemDisplayName[]
>("FETCH_COMPANY_EXTERNAL_LINE_ITEM_DISPLAY_NAMES_BY_COMPANY_ID_SUCCESS")
export const fetchCompanyExternalLineItemDisplayNamesByCompanyIdFailed = createAction<string>(
  "FETCH_COMPANY_EXTERNAL_LINE_ITEM_DISPLAY_NAMES_BY_COMPANY_ID_FAILED"
)

/* ================================= Update Campaign BI Settings form actions ================================= */
export const updateCompanyEnableCampaignBI = createAction<UpdateEnableCampaignBIPayload>(
  "Effects_UPDATE_COMPANY_ENABLE_CAMPAIGN_BI"
)
export const updateCompanyEnableCampaignBISuccess = createAction<Company>(
  "UPDATE_COMPANY_ENABLE_CAMPAIGN_BI_SUCCESS"
)
export const updateCompanyEnableCampaignBIFailed = createAction<string>(
  "UPDATE_COMPANY_ENABLE_CAMPAIGN_BI_FAILED"
)
export const createCompanyExternalLineItemDisplayName = createAction<ExternalLineItemDisplayName>(
  "Effects_CREATE_COMPANY_EXTERNAL_LINE_ITEM_DISPLAY_NAME"
)
export const createCompanyExternalLineItemDisplayNameSuccess =
  createAction<ExternalLineItemDisplayName>(
    "CREATE_COMPANY_EXTERNAL_LINE_ITEM_DISPLAY_NAME_SUCCESS"
  )
export const createCompanyExternalLineItemDisplayNameFailed = createAction<string>(
  "CREATE_COMPANY_EXTERNAL_LINE_ITEM_DISPLAY_NAME_FAILED"
)

export const closeUpdateCompanyEnableCampaignBISuccess = createAction(
  "CLOSE_UPDATE_COMPANY_ENABLE_CAMPAIGN_BI_SUCCESS"
)

export const closeCreateCompanyExternalLineItemDisplayNameSuccess = createAction(
  "CLOSE_CREATE_COMPANY_EXTERNAL_LINE_ITEM_DISPLAY_NAME_SUCCESS"
)

export const setExternalLineItemDisplayNameSavedFlag = createAction<boolean>(
  "SET_EXTERNAL_LINE_ITEM_DISPLAY_NAME_SAVED_FLAG"
)

// TODO investigate this, this is not working... causing multiple refreshes
export const setIsExternalLineItemDisplayNamesLoadingFlag = createAction<boolean>(
  "SET_EXTERNAL_LINE_ITEM_DISPLAY_NAMES_LOADING_FLAG"
)
