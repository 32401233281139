import { API, API_ROOT, SECTION } from "../../constants"
import { call, put, takeEvery } from "redux-saga/effects"
import {
  fetchPublisherByAppNexusId,
  fetchPublisherByAppNexusIdFailed,
  fetchPublisherByAppNexusIdSuccess,
  setAppNexusMigrationFormFindPublisherButtonClickedFlag,
  setAppNexusMigrationFormFindingPublisherFlag,
  setPublisherAppNexusId,
} from "../actions/appNexusMigrationActions"
import { handleAxiosRequest, handleSagaError } from "../../helpers/sagaHelpers"

import { AxiosRequestConfig } from "axios"
import { PayloadAction } from "@reduxjs/toolkit"
import { SagaIterator } from "redux-saga"
import _ from "lodash"
import { clearErrors } from "../actions/errorActions"

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* fetchPublisherByAppNexusIdSaga(action: PayloadAction<number>) {
  const ajaxConfig: AxiosRequestConfig = {
    method: "GET",
    url: `${API_ROOT}${API.V1}${SECTION.ADMIN}/publishers/app-nexus/${action.payload}`,
  }
  yield put(clearErrors())
  yield put(setAppNexusMigrationFormFindingPublisherFlag(true))
  try {
    const response = yield call(handleAxiosRequest, ajaxConfig)
    yield put(fetchPublisherByAppNexusIdSuccess(response.data))
  } catch (err) {
    if (_.get(err, "response.data.type") !== "not_found") {
      yield handleSagaError(fetchPublisherByAppNexusIdFailed, err)
    }

    //This is needed to show the failed id in the form so user can then migrate publisher
    yield put(setPublisherAppNexusId(action.payload))
  }

  yield put(setAppNexusMigrationFormFindPublisherButtonClickedFlag(true))
  yield put(setAppNexusMigrationFormFindingPublisherFlag(false))
}

export function* watchFetchPublisherByAppNexusId(): SagaIterator {
  yield takeEvery(fetchPublisherByAppNexusId, fetchPublisherByAppNexusIdSaga)
}
