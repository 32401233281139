import { createReducer } from "@reduxjs/toolkit"
import {
  createCompanySuccess,
  fetchCompanyByIdSuccess,
  updateCompanySuccess,
  resetCompanyFormState,
  fetchCompanyExternalLineItemDisplayNamesByCompanyIdSuccess,
  updateCompanyEnableCampaignBISuccess,
  createCompanyExternalLineItemDisplayNameSuccess,
  createCompanyExternalLineItemDisplayNameFailed,
  setExternalLineItemDisplayNameSavedFlag,
  updateCompanyEnableCampaignBIFailed,
  closeUpdateCompanyEnableCampaignBISuccess,
  closeCreateCompanyExternalLineItemDisplayNameSuccess,
  setCompanyFormLoadingFlag,
  setCompanyFormSubmittingFlag,
  fetchCompanyByIdFailed,
  setIsExternalLineItemDisplayNamesLoadingFlag,
} from "../actions/companyActions"
import { ExternalLineItemDisplayName, Company } from "../types/companyTypes"

export interface InitialCompanyState {
  selectedCompany: Company
  isLoading: boolean
  isSaved: boolean
  isSubmitting: boolean
  isEnableCampaignBISettingsSaved: boolean
  enableCampaignBISettingsSavedSuccessMessage: string
  externalLineItemDisplayNames?: ExternalLineItemDisplayName[]
  isExternalLineItemDisplayNamesLoading?: boolean
  isExternalLineItemDisplayNameSaved: boolean
  externalLineItemDisplayCreateSuccessMessage: string
}

export const initialCompanyState = {
  id: null,
  notes: "",
  createdAt: "",
  updatedAt: "",
  status: "",
  name: "",
  segmentVendors: [],
  enableCampaignBi: false,
  displayName: "",
}

export const initialState: InitialCompanyState = {
  selectedCompany: initialCompanyState,
  isLoading: false,
  isSaved: false,
  isSubmitting: false,
  isEnableCampaignBISettingsSaved: false,
  enableCampaignBISettingsSavedSuccessMessage: "",
  externalLineItemDisplayNames: [],
  isExternalLineItemDisplayNamesLoading: false,
  isExternalLineItemDisplayNameSaved: false,
  externalLineItemDisplayCreateSuccessMessage: "",
}

export default createReducer(initialState, (builder) =>
  builder
    /*============================== Generic Company form action handlers ==============================*/
    .addCase(setCompanyFormLoadingFlag, (state, action) => {
      state.isLoading = action.payload
    })
    .addCase(setCompanyFormSubmittingFlag, (state, action) => {
      state.isSubmitting = action.payload
    })

    /*============================== General form action handlers ==============================*/
    .addCase(fetchCompanyByIdSuccess, (state, action) => {
      state.selectedCompany = action.payload
    })
    .addCase(fetchCompanyByIdFailed, (state) => {
      state.isLoading = false
    })
    .addCase(createCompanySuccess, (state) => {
      state.isSaved = true
    })
    .addCase(updateCompanySuccess, (state) => {
      state.isSaved = true
    })
    .addCase(resetCompanyFormState, () => initialState)

    /*================== Enable Campaign BI form action handlers ========================*/
    .addCase(updateCompanyEnableCampaignBISuccess, (state, action) => {
      state.enableCampaignBISettingsSavedSuccessMessage = `Enable Campaign BI Succeeded (${action.payload.id})`
      state.isEnableCampaignBISettingsSaved = true
    })
    .addCase(updateCompanyEnableCampaignBIFailed, (state) => {
      // TODO Move this logic into error reducer...
      state.isEnableCampaignBISettingsSaved = false
    })
    .addCase(closeUpdateCompanyEnableCampaignBISuccess, (state) => {
      state.enableCampaignBISettingsSavedSuccessMessage =
        initialState.enableCampaignBISettingsSavedSuccessMessage
    })

    /*================== Fetch/Create External Line Items form action handlers ========================*/
    .addCase(setIsExternalLineItemDisplayNamesLoadingFlag, (state, action) => {
      state.isExternalLineItemDisplayNamesLoading = action.payload
    })
    .addCase(fetchCompanyExternalLineItemDisplayNamesByCompanyIdSuccess, (state, action) => {
      state.externalLineItemDisplayNames = action.payload
    })
    .addCase(createCompanyExternalLineItemDisplayNameSuccess, (state, action) => {
      state.enableCampaignBISettingsSavedSuccessMessage = `Successfully Created External Line Item (${action.payload.id})`
      state.isExternalLineItemDisplayNameSaved = true
    })
    .addCase(createCompanyExternalLineItemDisplayNameFailed, (state) => {
      state.isExternalLineItemDisplayNameSaved = false
    })
    .addCase(closeCreateCompanyExternalLineItemDisplayNameSuccess, (state) => {
      state.externalLineItemDisplayCreateSuccessMessage =
        initialState.externalLineItemDisplayCreateSuccessMessage
    })
    .addCase(setExternalLineItemDisplayNameSavedFlag, (state, action) => {
      state.isExternalLineItemDisplayNameSaved = action.payload
    })
)
