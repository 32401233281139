import { createReducer } from "@reduxjs/toolkit"
import { PaginatedComponentState } from "../types/sharedTypes"
import {
  fetchChangeLog,
  fetchChangeLogSuccess,
  fetchChangeLogFailed,
  clearChangeLog,
} from "../actions/changeLogActions"
import { ChangeLog } from "../types/changeLogTypes"

interface InitialChangeLogState extends PaginatedComponentState {
  changeLogData: ChangeLog[]
  isLoading: boolean
}

const initialState: InitialChangeLogState = {
  changeLogData: [],
  isLoading: true,
  pager: {
    totalCount: 0,
    page: 0,
  },
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(fetchChangeLog, (state) => {
      state.isLoading = true
    })
    .addCase(fetchChangeLogSuccess, (state, action) => {
      const { pager, changeLogData } = action.payload
      state.pager = pager
      state.changeLogData = changeLogData
      state.isLoading = false
    })
    .addCase(fetchChangeLogFailed, (state) => {
      state.isLoading = false
    })
    .addCase(clearChangeLog, () => initialState)
)
