import {
  createTagSuccess,
  fetchTagByIdSuccess,
  resetTagFormState,
  setTagFormLoadingFlag,
  setTagFormSubmittingFlag,
  updateTagSuccess,
} from "../actions/tagActions"

import { ENTITY_STATUSES } from "../../constants"
import { Tag } from "../types/tagTypes"
import { createReducer } from "@reduxjs/toolkit"

export interface InitialTagFormState {
  selectedTag: Tag
  isLoading: boolean
  isSaved: boolean
  isSubmitting: boolean
}

export const initialTagState = {
  name: "",
  siteId: null,
  status: ENTITY_STATUSES.ACTIVE,
  deals: [],
  hardfloor: 0,
  siteCode: "",
  publisherCode: "",
  code: "",
  isRTB: false,
  blocklisted: false,
  appNexusTagId: "N/A",
}

export const initialState: InitialTagFormState = {
  selectedTag: initialTagState,
  isLoading: false,
  isSaved: false,
  isSubmitting: false,
}

export default createReducer(initialState, (builder) =>
  builder
    /*============================== Tag form action handlers ==============================*/
    .addCase(fetchTagByIdSuccess, (state, action) => {
      state.selectedTag = action.payload
    })
    .addCase(createTagSuccess, (state) => {
      state.isSaved = true
    })
    .addCase(updateTagSuccess, (state, action) => {
      state.selectedTag = action.payload
      state.isSaved = true
    })
    .addCase(setTagFormLoadingFlag, (state, action) => {
      state.isLoading = action.payload
    })
    .addCase(setTagFormSubmittingFlag, (state, action) => {
      state.isSubmitting = action.payload
    })
    .addCase(resetTagFormState, () => initialState)
)
