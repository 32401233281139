import { createStyles } from "@material-ui/core/styles"

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default () => {
  return createStyles({
    iFrameContainer: {
      width: "100%",
      height: "100%",
    },
  })
}
