import { createReducer } from "@reduxjs/toolkit"
import {
  clearSellersJson,
  fetchSellersJsonSuccess,
  setSellersJsonLoadingFlag,
} from "../actions/sellersJsonActions"

import { SellersJson } from "../types/sellersJsonTypes"

interface InitialSellerJsonState {
  sellersJson: SellersJson
  isLoading: boolean
}

const initialState: InitialSellerJsonState = {
  sellersJson: {
    contactEmail: "",
    contactAddress: "",
    version: 0,
    sellers: [],
    identifiers: [],
  },
  isLoading: true,
}

export default createReducer(initialState, (builder) =>
  builder
    /*================================ Seller List action handlers ================================*/
    .addCase(setSellersJsonLoadingFlag, (state, action) => {
      state.isLoading = action.payload
    })
    .addCase(fetchSellersJsonSuccess, (state, action) => {
      state.sellersJson = action.payload
    })
    .addCase(clearSellersJson, () => initialState)
)
