import jwtDecode, { JwtPayload } from "jwt-decode"

export const isLoggedIn = (): boolean => {
  const token = getToken()
  return !!token && !isTokenExpired()
}

export const setToken = (accessToken: string): void => {
  window.localStorage.setItem("access_token", accessToken)
}

export const removeToken = (): void => {
  window.localStorage.removeItem("access_token")
}

export const getToken = (): string => {
  return window.localStorage.getItem("access_token")
}

export const getTokenExpirationDate = (): Date => {
  const token = getToken()
  const decoded = jwtDecode<JwtPayload>(token)
  if (!decoded.exp) {
    return null
  }

  const date = new Date(0)
  date.setUTCSeconds(decoded.exp)
  return date
}

export const isTokenExpired = (): boolean => {
  const token = getToken()
  if (!token) return true
  const date = getTokenExpirationDate()
  const offsetSeconds = 0
  if (date === null) {
    return false
  }
  return !(date.valueOf() > new Date().valueOf() + offsetSeconds * 1000)
}
