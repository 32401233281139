import { API, API_ROOT, SECTION } from "../../constants"
import { call, put, takeEvery } from "redux-saga/effects"
import {
  createPublisher,
  createPublisherFailed,
  createPublisherSuccess,
  setPublisherFormSubmittingFlag,
} from "../actions/publisherActions"
import { handleAxiosRequest, handleSagaError } from "../../helpers/sagaHelpers"

import { AxiosRequestConfig } from "axios"
import { Publisher } from "../types/publisherTypes"
import { PayloadAction } from "@reduxjs/toolkit"
import { SagaIterator } from "redux-saga"
import { clearErrors } from "../actions/errorActions"

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* createPublisherSaga(action: PayloadAction<Publisher>) {
  const ajaxConfig: AxiosRequestConfig = {
    method: "POST",
    url: `${API_ROOT}${API.V1}${SECTION.ADMIN}/publishers`,
    data: {
      publisher: action.payload,
    },
  }
  yield put(clearErrors())
  yield put(setPublisherFormSubmittingFlag(true))
  try {
    const response = yield call(handleAxiosRequest, ajaxConfig)
    yield put(createPublisherSuccess(response.data))
  } catch (err) {
    yield handleSagaError(createPublisherFailed, err)
  }
  yield put(setPublisherFormSubmittingFlag(false))
}

export function* watchCreatePublisher(): SagaIterator {
  yield takeEvery(createPublisher, createPublisherSaga)
}
