import * as Yup from "yup"

import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Hidden,
  MenuItem,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core"
import { BreadCrumb, HeaderInfo } from "../../../../../redux/types/newHeaderTypes"
import { CreateUserPayload, UpdateUserPayload, User } from "../../../../../redux/types/userTypes"
import { ENTITY_STATUSES, PERMISSIONS } from "../../../../../constants"
import React, { useEffect } from "react"
import { RootState, useAppDispatch } from "../../../../../redux/store"
import { clearRoles, fetchRoles } from "../../../../../redux/actions/roleActions"
import {
  createUser,
  fetchUserById,
  resetUserFormState,
  updateUser,
} from "../../../../../redux/actions/userActions"

import Can from "../../../../common/Can"
import { Edit as EditIcon } from "react-feather"
import EntityAssignmentTable from "../../../../common/EntityAssignmentTable"
import ErrorMessages from "../../../../common/Errors"
import { Formik } from "formik"
import NewHeader from "../../../../NewHeader"
import _ from "lodash"
import { clearErrors } from "../../../../../redux/actions/errorActions"
import clsx from "clsx"
import dayjs from "dayjs"
import { fetchCompanies } from "../../../../../redux/actions/companyActions"
import { isPermitted } from "../../../../../helpers/permissionHelper"
import styles from "./styles"
import { trimPayloadProperties } from "../../../../../helpers/formatterHelper"
import { useHistory } from "react-router"
import { useSelector } from "react-redux"

export const buildHeaderInfo = (isCreate, isEdit, isInfo, user: User = {} as User): HeaderInfo => {
  let headerText
  const breadcrumbs: BreadCrumb[] = [
    {
      name: "Dashboard",
      route: "/",
    },
    {
      name: "Users",
      route: "/admin/users",
    },
  ]
  const headerButtons = []

  if (isCreate) {
    headerText = "Create New User"
    breadcrumbs.push({
      name: `Create`,
    })
  }

  if (isEdit && user.id) {
    headerText = `User: ${user.firstName} ${user.lastName} (${user.id})`
    breadcrumbs.push({
      name: `${user.firstName} ${user.lastName} (${user.id})`,
      route: `/admin/users/${user.id}`,
    })
    breadcrumbs.push({
      name: `Edit`,
    })
  }

  if (isInfo && user.id) {
    headerText = `User: ${user.firstName} ${user.lastName} (${user.id})`
    breadcrumbs.push({
      name: `${user.firstName} ${user.lastName} (${user.id})`,
    })

    headerButtons.push({
      name: "EDIT",
      route: `/admin/users/${user.id}/edit`,
      permission: PERMISSIONS.ADMIN.USER.UPDATE,
      icon: <EditIcon />,
    })
  }

  return {
    headerText,
    breadcrumbs,
    headerButtons,
  }
}

const UserForm = ({ match, classes }) => {
  const dispatch = useAppDispatch()
  const currentUser = useSelector((state: RootState) => state.currentUser.currentUser)
  const user = useSelector((state: RootState) => state.userForm.selectedUser)
  const roles = useSelector((state: RootState) => state.roles.roles)
  const companies = useSelector((state: RootState) => state.companies.allCompanies)
  const isLoading = useSelector((state: RootState) => state.userForm.isLoading)
  const isSaved = useSelector((state: RootState) => state.userForm.isSaved)
  const isSubmitting = useSelector((state: RootState) => state.userForm.isSubmitting)
  const history = useHistory()
  const endPath = match.path.split("/").pop()

  const isEdit = endPath === "edit"
  const isCreate = endPath === "create"
  const isInfo = !isEdit && !isCreate

  const mergedCompanies = _.unionBy(companies, [user.company], "id").filter(
    (company) => company.id !== null
  )

  useEffect(() => {
    // checks if form is create or edit, if edit, fetch user info and pre-populate form data
    if ((isEdit || isInfo) && !user.id) dispatch(fetchUserById(match.params.userId))
    // when the user is successfully created navigate back to users list
    isSaved && history.push("/admin/users")
  }, [user, isSaved])

  useEffect(() => {
    if (isPermitted(PERMISSIONS.ADMIN.ROLE.LIST.VIEW, currentUser))
      dispatch(fetchRoles({ pagination: { paginate: false } }))
    if (isPermitted(PERMISSIONS.ADMIN.COMPANY.LIST.VIEW, currentUser))
      dispatch(fetchCompanies({ pagination: { paginate: false } }))
    // clean up stale data when component unmounts
    return () => {
      dispatch(clearErrors())
      dispatch(resetUserFormState())
      dispatch(clearRoles())
    }
  }, [])

  const initialFormValues = {
    email: user.email || "",
    firstName: user.firstName || "",
    lastName: user.lastName || "",
    notes: user.notes || "",
    status: user.status || "",
    companyId: user.companyId || "",
    roles: user.roles || [],
  }
  const formValidationSchema = Yup.object().shape({
    email: Yup.string().max(255),
    firstName: Yup.string().max(255),
    lastName: Yup.string().max(255),
    notes: Yup.string().max(5000).nullable(true),
    companyId: Yup.number().positive(),
    status: Yup.string().max(255),
  })
  const handleFormikSubmit = (values) => {
    if (isCreate) {
      const valuesWithoutStatus = _.omit(values, ["status"])
      dispatch(createUser(trimPayloadProperties(valuesWithoutStatus) as CreateUserPayload))
    } else if (isEdit) {
      dispatch(
        updateUser(
          trimPayloadProperties({
            id: user.id,
            ...values,
          }) as UpdateUserPayload
        )
      )
    }
  }

  const statusOptions = [
    { value: ENTITY_STATUSES.ACTIVE, label: "Active" },
    { value: ENTITY_STATUSES.INACTIVE, label: "Inactive" },
    { value: ENTITY_STATUSES.ARCHIVED, label: "Archived" },
  ]

  const { headerText, breadcrumbs, headerButtons } = buildHeaderInfo(isCreate, isEdit, isInfo, user)

  return (
    <>
      <NewHeader headerText={headerText} breadcrumbs={breadcrumbs} buttons={headerButtons} />
      <Box mt={3} mb={3}>
        <Formik
          enableReinitialize={true}
          initialValues={initialFormValues}
          validationSchema={formValidationSchema}
          onSubmit={(values) => handleFormikSubmit(values)}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, setFieldValue, touched, values }) => {
            return (
              <>
                <ErrorMessages />
                <form onSubmit={handleSubmit} className={clsx(isInfo && classes.overrideDisable)}>
                  <Card>
                    <CardContent className={classes.userFormCardLayout}>
                      <Grid container spacing={3} className={classes.roundedBorder}>
                        {/* First Name */}
                        <Grid item md={6} xs={12}>
                          <TextField
                            required
                            error={!!(touched.firstName && errors.firstName)}
                            fullWidth
                            helperText={touched.firstName && errors.firstName}
                            label={
                              <span className={clsx(isInfo && classes.overrideDisable)}>
                                First Name
                              </span>
                            }
                            name="firstName"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.firstName}
                            variant="outlined"
                            inputProps={{
                              "data-testid": "user-form-first-name-input",
                            }}
                            InputProps={{
                              classes: {
                                disabled: classes.overrideDisable,
                              },
                            }}
                            disabled={isLoading || isInfo}
                          />
                          {user.createdAt && (
                            <div
                              className={classes.timestamps}
                              data-testid={"user-form-timestamps"}
                            >
                              <Typography>
                                <b>Created on: </b>
                                {dayjs(user?.createdAt).format("M-D-YYYY h:mm a")}
                                <b> Last updated: </b>
                                {dayjs(user?.updatedAt).format("M-D-YYYY h:mm a")}
                              </Typography>
                            </div>
                          )}
                        </Grid>

                        {/* Company */}
                        <Grid item md={3} xs={12}>
                          <TextField
                            required
                            select
                            label={
                              <span className={clsx(isInfo && classes.overrideDisable)}>
                                Company
                              </span>
                            }
                            name="companyId"
                            type="number"
                            value={values.companyId}
                            onChange={(e) => {
                              setFieldValue("companyId", e.target.value)
                            }}
                            className={classes.dropDownField}
                            inputProps={{
                              "data-testid": "user-form-company-input",
                            }}
                            InputProps={{
                              classes: {
                                disabled: classes.overrideDisable,
                              },
                            }}
                            onWheel={(e) => (e.target as HTMLElement).blur()}
                            disabled={isLoading || isInfo}
                          >
                            {mergedCompanies.map((option) => (
                              <MenuItem key={option.id} value={option.id}>
                                {option.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>

                        {/* Last Name */}
                        <Grid item md={6} xs={12}>
                          <TextField
                            required
                            error={!!(touched.lastName && errors.lastName)}
                            fullWidth
                            helperText={touched.lastName && errors.lastName}
                            label={
                              <span className={clsx(isInfo && classes.overrideDisable)}>
                                Last Name
                              </span>
                            }
                            name="lastName"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.lastName}
                            variant="outlined"
                            inputProps={{
                              "data-testid": "user-form-last-name-input",
                            }}
                            InputProps={{
                              classes: {
                                disabled: classes.overrideDisable,
                              },
                            }}
                            disabled={isLoading || isInfo}
                          />
                        </Grid>

                        {/* User Status */}
                        {isEdit || isInfo ? (
                          <Grid item md={3} xs={12}>
                            <TextField
                              select
                              label={
                                <span className={clsx(isInfo && classes.overrideDisable)}>
                                  User Status
                                </span>
                              }
                              name="status"
                              value={values.status}
                              onChange={(e) => {
                                setFieldValue("status", e.target.value)
                              }}
                              className={classes.dropDownField}
                              inputProps={{
                                "data-testid": "user-form-status-input",
                              }}
                              InputProps={{
                                classes: {
                                  disabled: classes.overrideDisable,
                                },
                              }}
                              disabled={isLoading || isInfo}
                            >
                              {statusOptions.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Grid>
                        ) : (
                          <Hidden smDown>
                            <Grid item md={3} />
                          </Hidden>
                        )}

                        {/* Email */}
                        <Grid item md={6} xs={12}>
                          <TextField
                            required
                            error={!!(touched.email && errors.email)}
                            fullWidth
                            helperText={touched.email && errors.email}
                            label={
                              <span className={clsx(isInfo && classes.overrideDisable)}>
                                Email address
                              </span>
                            }
                            name="email"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.email}
                            variant="outlined"
                            inputProps={{
                              "data-testid": "user-form-email-input",
                            }}
                            InputProps={{
                              classes: {
                                disabled: classes.overrideDisable,
                              },
                            }}
                            disabled={isLoading || isInfo}
                          />
                        </Grid>
                        <Hidden smDown>
                          <Grid item md={6} />
                        </Hidden>
                        {/* Notes */}
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={!!(touched.notes && errors.notes)}
                            fullWidth
                            multiline
                            helperText={touched.notes && errors.notes}
                            label={
                              <span
                                className={clsx(isInfo && values.notes && classes.overrideDisable)}
                              >
                                Notes
                              </span>
                            }
                            name="notes"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.notes}
                            variant="outlined"
                            inputProps={{
                              "data-testid": "user-form-notes-input",
                            }}
                            InputProps={{
                              classes: {
                                disabled: classes.overrideDisable,
                              },
                            }}
                            disabled={isLoading || isInfo}
                          />
                        </Grid>
                        <Can
                          perform={PERMISSIONS.ADMIN.ROLE.LIST.VIEW}
                          yes={
                            <Grid item md={12} xs={12}>
                              <EntityAssignmentTable
                                testId={"user-form-roles-table"}
                                label="Role"
                                listItems={isInfo ? values.roles : roles}
                                checkedItems={values.roles}
                                onChange={(selectedRoles) => {
                                  setFieldValue("roles", selectedRoles)
                                }}
                                disabled={isInfo}
                              />
                            </Grid>
                          }
                        />
                      </Grid>
                      {!isInfo && (
                        <Box mt={2}>
                          <Button
                            variant="contained"
                            color="secondary"
                            type="submit"
                            disabled={isSubmitting || isLoading}
                            data-testid={"user-form-submit-button"}
                          >
                            {isCreate && "Create User"}
                            {isEdit && "Update User"}
                          </Button>
                        </Box>
                      )}
                    </CardContent>
                  </Card>
                </form>
              </>
            )
          }}
        </Formik>
      </Box>
    </>
  )
}

export default withStyles(styles)(UserForm)
