import { API_ROOT, API } from "../../constants"
import { call, put, takeEvery } from "redux-saga/effects"
import { AxiosRequestConfig } from "axios"
import data from "../../../package.json"
import { handleAxiosRequest, handleSagaError } from "../../helpers/sagaHelpers"
import { SagaIterator } from "redux-saga"
import {
  fetchApplicationVersionSuccess,
  fetchApplicationVersionFailed,
  fetchApplicationVersion,
} from "../actions/applicationVersionActions"

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* fetchApplicationVersionSaga() {
  const ajaxConfig: AxiosRequestConfig = {
    method: "POST",
    url: `${API_ROOT}${API.V1}/application-version`,
    data: { frontEndVersion: data.version },
  }
  try {
    const response = yield call(handleAxiosRequest, ajaxConfig)
    yield put(fetchApplicationVersionSuccess(response.data))
  } catch (err) {
    yield handleSagaError(fetchApplicationVersionFailed, err)
  }
}

export function* watchFetchApplicationVersion(): SagaIterator {
  yield takeEvery(fetchApplicationVersion, fetchApplicationVersionSaga)
}
