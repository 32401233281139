import { Box, CircularProgress } from "@material-ui/core"
import React, { useEffect } from "react"
import { RootState, useAppDispatch } from "../../../redux/store"

import { DEFAULT_PAGE_TITLE } from "../../../constants"
import { fetchHomePage } from "../../../redux/actions/homePageActions"
import { useDocumentTitle } from "../../../helpers/utilityHelpers"
import { useSelector } from "react-redux"

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const HomePage = () => {
  useDocumentTitle(DEFAULT_PAGE_TITLE)

  const dispatch = useAppDispatch()
  const homePageContent = useSelector((state: RootState) => state.homePage)

  useEffect(() => {
    // Get Home page message
    dispatch(fetchHomePage())
  }, [])

  return (
    <Box
      display="flex"
      data-testid={"home-page-message"}
      justifyContent="center"
      alignItems="center"
      minHeight="80vh"
    >
      {homePageContent.isLoadingHomePage ? (
        <CircularProgress data-testid={"home-page-message-loading"} />
      ) : (
        <div
          title="home-page-message"
          dangerouslySetInnerHTML={{ __html: `${homePageContent.homePage.value}` }}
        ></div>
      )}
    </Box>
  )
}
export default HomePage
