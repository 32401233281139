import { createAction } from "@reduxjs/toolkit"
/* ===================================== Application version actions ===================================== */

export const fetchApplicationVersion = createAction<void>("Effects_FETCH_APPLICATION_VERSION")
export const fetchApplicationVersionSuccess = createAction<string>(
  "FETCH_APPLICATION_VERSION_SUCCESS"
)
export const fetchApplicationVersionFailed = createAction<string>(
  "FETCH_APPLICATION_VERSION_FAILED"
)

export const clearApplicationVersion = createAction("CLEAR_APPLICATION_VERSION")
