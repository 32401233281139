import { API, API_ROOT, SECTION } from "../../constants"
import { call, put, takeEvery } from "redux-saga/effects"
import {
  fetchSiteBlockAttributes,
  fetchSiteBlockAttributesFailed,
  fetchSiteBlockAttributesSuccess,
  setSiteFormLoadingBlockAttributesFlag,
} from "../actions/siteActions"
import { handleAxiosRequest, handleSagaError } from "../../helpers/sagaHelpers"

import { AxiosRequestConfig } from "axios"
import { SagaIterator } from "redux-saga"

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* fetchSiteBlockAttributesSaga() {
  const ajaxConfig: AxiosRequestConfig = {
    method: "GET",
    url: `${API_ROOT}${API.V1}${SECTION.ADMIN}/publishers/block-attributes/find`,
  }
  yield put(setSiteFormLoadingBlockAttributesFlag(true))
  try {
    const response = yield call(handleAxiosRequest, ajaxConfig)
    yield put(fetchSiteBlockAttributesSuccess(response.data))
  } catch (err) {
    yield handleSagaError(fetchSiteBlockAttributesFailed, err)
  }
  yield put(setSiteFormLoadingBlockAttributesFlag(false))
}

export function* watchFetchSiteBlockAttributes(): SagaIterator {
  yield takeEvery(fetchSiteBlockAttributes, fetchSiteBlockAttributesSaga)
}
