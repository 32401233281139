import { createAction } from "@reduxjs/toolkit"

/*================================ CampaignBI actions ================================*/
export const fetchCampaignBIToken = createAction<number>("FETCH_CAMPAIGN_BI_TOKEN")
export const fetchCampaignBITokenSuccess = createAction<string>("FETCH_CAMPAIGN_BI_TOKEN_SUCCESS")
export const fetchCampaignBITokenFailed = createAction<string>("FETCH_CAMPAIGN_BI_TOKEN_FAILED")

export const loginToCampaignBI = createAction<string>("LOGIN_TO_CAMPAIGN_BI")
export const loginToCampaignBISuccess = createAction("LOGIN_TO_CAMPAIGN_BI_SUCCESS")
export const loginToCampaignBIFailed = createAction<string>("LOGIN_TO_CAMPAIGN_BI_FAILED")

/*================================ General data view actions ================================*/
export const setDataViewLoading = createAction<boolean>("SET_DATA_VIEW_LOADING")
export const resetDataViewState = createAction("RESET_DATA_VIEW_STATE")
