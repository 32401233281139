import { API_ROOT, API, SECTION } from "../../constants"
import _ from "lodash"
import { call, put, takeEvery } from "redux-saga/effects"
import { AxiosRequestConfig } from "axios"
import {
  fetchAudienceById,
  fetchAudienceByIdSuccess,
  fetchAudienceByIdFailed,
} from "../actions/audienceActions"
import { handleAxiosRequest, handleSagaError } from "../../helpers/sagaHelpers"
import { forwardTo } from "../../helpers/historyHelper"
import { SagaIterator } from "redux-saga"

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* fetchAudienceByIdSaga(action) {
  const ajaxConfig: AxiosRequestConfig = {
    method: "GET",
    url: `${API_ROOT}${API.V1}${SECTION.TOOLS}/audiences/${action.payload}`,
  }
  try {
    const response = yield call(handleAxiosRequest, ajaxConfig)
    yield put(fetchAudienceByIdSuccess(response.data))
  } catch (err) {
    if (_.get(err, "response.data.type") === "not_found" || "unknown") {
      yield call(forwardTo, `${SECTION.TOOLS}/audiences`)
    }
    yield handleSagaError(fetchAudienceByIdFailed, err)
  }
}

export function* watchFetchAudienceById(): SagaIterator {
  yield takeEvery(fetchAudienceById, fetchAudienceByIdSaga)
}
